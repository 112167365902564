import {
	deleteRequestV2,
	getRequest,
	IApiHttpResponse,
	IApiResponse,
	IApiSimpleHttpResponse,
	mapData,
	mapHttpData,
	mapHttpResponse,
	mapResponse,
	patchRequestV2,
	postFormRequestV2,
	postRequest,
	postRequestV2,
} from '../api-service';
import { endpoints } from '../endpoints';
import {
	IForgotPasswordRequest,
	IForgotPasswordResponse,
	IGetUsersResponse,
	IMacroCreateResult,
	IProviderMacroResponseItem,
	IProviderWordReplacementResponseItem,
	IUserAuthResponse,
	IValidateEhrLoginResponse,
	IValidatePinRequest,
	ScribePermission,
} from './user.api.models';
import { IServiceResul, IServiceResult } from '../../models/service.models';
import { IUserAuth, IUserDisplayName, IUserSettings, IValidateEhrLoginResult } from '../../models/users/user.models';
import { environment } from '../../../environment/environment';
import { IMacro } from '../../models/macros/macros.models';
import { IWordReplacement } from '../../models/replacements/replacements.models';
import { EHRType } from '../../models/ehr.models';

export async function userSignIn(params: {
	username: string;
	password: string;
	mfaAuthorizationToken?: string;
	mfaVerificationPIN?: string;
}): Promise<
	IServiceResult<{
		settings?: IUserSettings;
		auth: IUserAuth;
	}>
> {
	const appVersion = environment.VERSION;
	const body = {
		UserName: params.username,
		Password: params.password,
		Version: `Web ${appVersion}`,
		MFAAuthorizationToken: params.mfaAuthorizationToken,
		VerificationPIN: params.mfaVerificationPIN,
	};

	const endpoint = endpoints.auth.login;
	const result = await postRequest<IUserAuthResponse>(endpoint, body);

	return mapData<IUserAuthResponse, { settings?: IUserSettings; auth: IUserAuth }>(result, (res: IUserAuthResponse) =>
		// eslint-disable-next-line no-nested-ternary
		res?.MFARequired && !res.Success
			? {
					auth: {
						apiKey: res.ApiKey,
						bearer: res.BearerToken,
						mfaRequired: res.MFARequired,
						invalidMfaPin: res.InvalidMFAPin,
						emailAddress: res.EmailAddress,
						mfaAuthorizationToken: res.MFAAuthorizationToken,
					},
			  }
			: res.Success
			? {
					auth: {
						apiKey: res.ApiKey,
						bearer: res.BearerToken,
						mfaRequired: res.MFARequired,
						invalidMfaPin: res.InvalidMFAPin,
						emailAddress: res.EmailAddress,
						mfaAuthorizationToken: res.MFAAuthorizationToken,
					},
					settings: {
						shortname: params.username,
						username: res.Username,
						customerId: res.CustomerId,
						apiKey: res.ApiKey,
						userInfoId: res.UserInfoID,
						departmentId: res.DepartmentId,
						iScribeUsername: result.iScribeUsername,
						featureFlags: {
							autoSelectSingleDocument: {
								enabled: res.FeatureFlags?.AutoSelectSingleDocument?.IsEnabled,
							},
							viewBillingServices: {
								enabled: res.FeatureFlags?.ViewBillingServices?.IsEnabled,
							},
							editBillingServices: {
								enabled: res.FeatureFlags?.EditBillingServices?.IsEnabled,
							},
							editProcedureDocumentation: {
								enabled: res.FeatureFlags?.EditProcedureDocumentation?.IsEnabled,
							},
							saveOrderInterpretations: {
								enabled: res.FeatureFlags?.SaveOrderInterpretations?.IsEnabled,
							},
							ehrLogin: {
								enabled: res.FeatureFlags?.EHRLogin?.IsEnabled,
							},
							vssForceAllJobsToQA: {
								enabled: res.FeatureFlags?.VSSForceAllJobsToQA?.IsEnabled,
							},
							retrieveOpenAISummary: {
								enabled: res.FeatureFlags?.RetrieveOpenAISummary?.IsEnabled,
							},
						},
						permissions: {
							qa: !!(res.ScribePermissions && res.ScribePermissions.find((x) => x === ScribePermission.QA)),
							admin: !!(res.ScribePermissions && res.ScribePermissions.find((x) => x === ScribePermission.Admin)),
							aiAccess: !!(res.ScribePermissions && res.ScribePermissions.find((x) => x === ScribePermission.AIAccess)),
							allCustomers: !!(
								res.ScribePermissions && res.ScribePermissions.find((x) => x === ScribePermission.AllCustomers)
							),
							tenantAccess: !!(
								res.ScribePermissions && res.ScribePermissions.find((x) => x === ScribePermission.TenantAccess)
							),
							downloadNotes: !!(
								res.ScribePermissions && res.ScribePermissions.find((x) => x === ScribePermission.DownloadNotes)
							),
						},
						userCustomerPermissions: res.UserCustomerPermissions.map((x) => ({
							internalType: 'default',
							customerId: x.CustomerID,
							customerName: x.CustomerName,
							ehrUserId: x.EhrUserID,
							ehrUsername: x.EhrUsername,
							userCustomerProviderPermissions: x.UserCustomerProviderPermissions.map(
								(userCustomerProviderPermission) => ({
									providerId: userCustomerProviderPermission.ProviderID,
									userInfoId: userCustomerProviderPermission.ProviderUserInfoID,
									providerUsername: userCustomerProviderPermission.ProviderUsername,
								})
							),
						})),
						system: Object.values(EHRType).includes(res.System) ? res.System : EHRType.NonIntegrated,
					},
			  }
			: ({} as {
					settings?: IUserSettings;
					auth: IUserAuth;
			  })
	);
}

export async function validateEhrLogin(): Promise<IServiceResult<IValidateEhrLoginResult>> {
	const endpoint = endpoints.user.validateEhrLogin;
	const result = await postRequest<IValidateEhrLoginResponse>(endpoint, {}, {}, false);

	const success = result.StatusMessage === 'Login authorized' || result.StatusMessage === 'OK';
	const error = result.StatusMessage || result.Message || result.Errors || 'Something went wrong';

	return {
		success,
		error,
		data: {
			daysUntilExpiration: result.DaysUntilExpiration || null,
			error: !success ? error : null,
		},
	};
}

export async function getProviderMacros(request: { providerId: string }): Promise<IMacro[]> {
	const body = {
		userInfoID: request.providerId,
	};
	const endpoint = endpoints.user.macros;
	const result = await getRequest<IProviderMacroResponseItem[]>(endpoint, body);

	return result.map((x: IProviderMacroResponseItem) => {
		return {
			macroId: x.MacroID,
			description: x.Description,
			shortCode: x.ShortCode,
			section: x.Section,
			macro: x.Macro,
			spokenForm: x.SpokenForm || '',
		};
	});
}

export async function saveProviderMacro(request: {
	shortCode: string;
	section: string;
	providerId: string;
	description: string;
	macro: string;
	spokenForm: string;
}): Promise<IServiceResult<number>> {
	const body = {
		UserInfoID: request.providerId,

		Section: request.section,
		ShortCode: request.shortCode,

		Description: request.description,
		Macro: request.macro,
		SpokenForm: request.spokenForm,
	};
	const endpoint = endpoints.user.macro;
	const response: IApiHttpResponse<number> = await postRequestV2<number>(endpoint, body);
	return mapHttpData<number, number>(response, (r) => r);
}

export async function updateProviderMacro(request: {
	macroId: number;
	section: string;
	macro: string;
	spokenForm: string;
}): Promise<IServiceResult<void>> {
	const body = {
		ID: request.macroId,
		Section: request.section,
		Macro: request.macro,
		SpokenForm: request.spokenForm,
	};
	const endpoint = endpoints.user.macro;
	const response: IApiHttpResponse<number> = await patchRequestV2(endpoint, body);
	return mapHttpResponse(response);
}

export async function deleteProviderMacro(request: { macroId: number }): Promise<IServiceResult<void>> {
	const body = {
		MacroID: request.macroId,
	};
	const endpoint = endpoints.user.macro;
	const result: IApiSimpleHttpResponse = await deleteRequestV2(endpoint, body);
	return mapHttpResponse(result);
}

export async function importProviderMacros(request: {
	providerId: string;
	file: File;
}): Promise<IServiceResult<IMacroCreateResult>> {
	const formData = new FormData();
	formData.append('file', request.file);
	formData.append('userInfoID', request.providerId);

	const endpoint = endpoints.user.importMacros;
	const result = await postFormRequestV2<IMacroCreateResult>(endpoint, formData);
	return mapHttpData(result, (r) => ({
		Success: r.Success,
		MacrosCount: r.MacrosCount,
		MacrosCreated: r.MacrosCreated,
	}));
}

export async function getUsers(): Promise<IUserDisplayName[]> {
	const endpoint = endpoints.user.users;
	const result = await getRequest<IGetUsersResponse[]>(endpoint);

	return result.map((x: IGetUsersResponse) => {
		return {
			userInfoId: x.UserInfoID.toString(),
			userKey: x.UserKey,
			displayName: x.DisplayName,
		};
	});
}

export async function resetPassword(request: IForgotPasswordRequest): Promise<IServiceResult<string | null>> {
	const appVersion = environment.VERSION;
	const body = {
		...request,
		AppVersion: `Web ${appVersion}`,
	};
	const endpoint = endpoints.auth.forgotPassword;
	const result = await postRequest<IForgotPasswordResponse>(endpoint, body, {
		'Verification-PIN': request.VerificationPin,
	});

	return mapData(result, (r) => r.VerificationCode);
}

export async function validatePin(request: IValidatePinRequest): Promise<IServiceResul> {
	const appVersion = environment.VERSION;
	const body = {
		Username: request.Username,
		AppVersion: `Web ${appVersion}`,
	};
	const endpoint = endpoints.auth.validatePIN;
	const result = await postRequest<IApiResponse>(endpoint, body, {
		'Verification-PIN': request.VerificationPin,
	});

	return mapResponse(result);
}

// Provider word replacements

export async function saveProviderWordReplacement(request: {
	word: string;
	wordReplacement: string;
	providerId: string;
}): Promise<IServiceResult<number>> {
	const body = {
		OriginalText: request.word,
		ReplacementText: request.wordReplacement,
		UserInfoID: request.providerId,
	};
	const endpoint = endpoints.user.replacement;
	const response: IApiHttpResponse<number> = await postRequestV2<number>(endpoint, body);
	return mapHttpData<number, number>(response, (r) => r);
}

export async function updateProviderWordReplacement(request: {
	id: string;
	wordReplacement: string;
}): Promise<IServiceResult<void>> {
	const body = {
		ReplacementText: request.wordReplacement,
		ID: request.id,
	};
	const endpoint = endpoints.user.replacement;
	const response: IApiSimpleHttpResponse = await patchRequestV2(endpoint, body);
	return mapHttpResponse(response);
}

export async function getProviderWordReplacements({ providerId }: { providerId: string }): Promise<IWordReplacement[]> {
	const endpoint = endpoints.user.replacements;
	const result = await getRequest<IProviderWordReplacementResponseItem[]>(endpoint, { userInfoId: providerId });

	return result.map((x: IProviderWordReplacementResponseItem) => {
		return {
			id: x.ID,
			text: x.OriginalText,
			replacement: x.ReplacementText,
		};
	});
}

export async function deleteProviderWordReplacement(request: { id: string }): Promise<IServiceResult<void>> {
	const body = {
		wordReplacementId: request.id,
	};
	const endpoint = endpoints.user.replacement;
	const result: IApiSimpleHttpResponse = await deleteRequestV2(endpoint, body);
	return mapHttpResponse(result);
}
