const PRIMARY = {
	main: '#2C3F50',
};

const SECONDARY = {
	main: '#5AB7AE',
	contrastText: '#fff',
};

const palette = {
	common: { black: '#000', white: '#fff' },
	primary: { ...PRIMARY },
	secondary: { ...SECONDARY },
};

export default palette;
