import { useTranslation } from 'react-i18next';
import { Box, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import * as React from 'react';
import { namespaces } from '../../application/i18n.constants';

function ConfirmCodeStep({
	code,
	loading,
	setCode,
	onConfirmCode,
}: {
	code: string;
	loading: boolean;
	setCode: (code: string) => void;
	onConfirmCode: (code: string) => void;
}) {
	const { t } = useTranslation(namespaces.pages.signIn);

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', width: 320 }}>
			<TextField
				value={code}
				margin="normal"
				required
				fullWidth
				id="confirmationCode"
				label={t('confirmationCode')}
				name="confirmationCode"
				autoFocus
				onChange={(e) => setCode(e.target.value)}
			/>
			<LoadingButton
				loading={loading}
				type="submit"
				disabled={code.length < 1}
				fullWidth
				variant="contained"
				sx={{ mt: 3, mb: 2 }}
				onClick={() => onConfirmCode(code)}
			>
				{t('confirmCode')}
			</LoadingButton>
		</Box>
	);
}

export default ConfirmCodeStep;
