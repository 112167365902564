import * as React from 'react';
import {
	Alert,
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	IconButton,
	List,
	ListItem,
	Paper,
	Stack,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DiagnoseIcon from './diagnosis.png';
import AddOrderIcon from './add_order_semibold.png';
import { namespaces } from '../../../application/i18n.constants';
import {
	IDiagnoseField,
	IICDCode,
	IInterpretationField,
	IOrderField,
} from '../../../core/models/dictations/dictations.models';
import { diagHash, orderHash } from '../../../core/services/documents/documents.services';
import OrderItem from './OrderItem';
import Spinner from '../../spinner/Spinner';
import LazyInput from '../../inputs/LazyInput';
import SelectFiled from '../../select/SelectField';
import { DiagnoseLaterality } from '../../../core/models/dictations/document.models';

export interface IDiagnoseError {
	type: 'lateralityRequired';
	snomed: string;
}

interface IDiagnoseItemProps {
	caption: string;
	laterality?: DiagnoseLaterality;
	note: string;
	problem: boolean;
	icdCodes: IICDCode[];
	addToProblemList: boolean;
	error?: IDiagnoseError;
	onNoteChange: (text: string) => void;
	onProblemChange: (problem: boolean) => void;
	onLateralityChange?: (laterality: DiagnoseLaterality | undefined) => void;
	onDelete: () => void;
	orders: IOrderField[];
	onAddOrder?: () => void;
	isEditOrderInterpretationEnabled: boolean;
	onOrderInterpretationChange: (hash: string, interpretation: IInterpretationField) => void;
	onDeleteOrder: (hash: string) => void;
	canDeleteOrder: (order: IOrderField) => boolean;
	isFirst: boolean;
	isLast: boolean;
	onIncreaseRanking?: () => void;
	onDecreaseRanking?: () => void;
}

function DiagnoseItem({
	isFirst,
	isLast,
	orders,
	caption,
	laterality,
	note,
	problem,
	icdCodes,
	addToProblemList,
	error,
	onNoteChange,
	onProblemChange,
	onLateralityChange,
	onDelete,
	onAddOrder,
	isEditOrderInterpretationEnabled,
	onOrderInterpretationChange,
	canDeleteOrder,
	onDeleteOrder,
	onIncreaseRanking,
	onDecreaseRanking,
}: IDiagnoseItemProps) {
	const { t } = useTranslation(namespaces.components.diagnosis);

	const errorText = error?.type === 'lateralityRequired' ? t('selectLaterality') : undefined;

	return (
		<Paper
			sx={{
				display: 'flex',
				flex: 1,
				flexDirection: 'column',
				p: 2,
				borderColor: errorText ? 'error.main' : undefined,
			}}
			variant={errorText ? 'outlined' : undefined}
		>
			{errorText && <Alert severity="error">{errorText}</Alert>}
			<Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<img alt="" src={DiagnoseIcon} width="25" height="25" style={{ marginTop: 4 }} />
					<Box sx={{ display: 'flex', flexDirection: 'column', pl: 2 }}>
						<Typography>{caption}</Typography>
						{icdCodes &&
							icdCodes
								.filter((x) => x.code && x.codeset.toLowerCase() === 'icd10')
								.map((icdCode) => (
									<Stack direction="row" alignItems="center" gap={1} key={icdCode.code}>
										<Typography variant="caption">{icdCode.code}</Typography>
										<Typography variant="caption">{icdCode.description}</Typography>
									</Stack>
								))}
						{onLateralityChange ? (
							<SelectFiled
								sx={{ width: 160, mt: 1 }}
								label={t('laterality')}
								value={laterality || ''}
								size="small"
								data={[
									{ value: '' as DiagnoseLaterality, text: t('noLaterality') },
									...Object.values(DiagnoseLaterality).map((x) => ({ value: x, text: x })),
								].filter((x) => x.value !== ('' as DiagnoseLaterality) || laterality)}
								onChange={(e) => {
									onLateralityChange(e.target.value !== '' ? (e.target.value as DiagnoseLaterality) : undefined);
								}}
							/>
						) : null}
					</Box>
				</Box>
				<Stack direction="row">
					{(!isFirst || !isLast) && (
						<Stack sx={{ mr: 2 }} direction="column">
							{!isFirst && (
								<IconButton sx={{ width: 32, height: 32 }} onClick={onIncreaseRanking}>
									<KeyboardArrowUpIcon sx={{ fontSize: '2rem' }} />
								</IconButton>
							)}
							{!isLast && (
								<IconButton sx={{ width: 32, height: 32 }} onClick={onDecreaseRanking}>
									<KeyboardArrowDownIcon sx={{ fontSize: '2rem' }} />
								</IconButton>
							)}
						</Stack>
					)}
					<IconButton sx={{ width: 32, height: 32 }} onClick={onDelete}>
						<CloseIcon sx={{ fontSize: '1rem' }} />
					</IconButton>
				</Stack>
			</Box>
			<Box sx={{ p: 1, pl: 4.5 }} display="flex" flexDirection="column">
				{!problem && (
					<FormControlLabel
						control={<Checkbox checked={addToProblemList} onChange={(x) => onProblemChange(x.target.checked)} />}
						label={<Typography sx={{ color: 'error.main' }}>{t('activeProblem')}</Typography>}
					/>
				)}
				<Box>
					<Typography sx={{ mr: 1 }}>{t('note')}:</Typography>
					<LazyInput
						style={{ width: '100%', resize: 'none' }}
						maxRows={5}
						value={note}
						onLazyChange={(value) => onNoteChange(value)}
					/>
				</Box>
			</Box>
			<Box>
				{orders && orders.length > 0 ? (
					<List sx={{ width: '100%' }}>
						{orders.map((x) => {
							const oHash = orderHash(x);
							return (
								<ListItem sx={{ px: 1 }} key={`${oHash}-${x.description.toLowerCase()}`}>
									<OrderItem
										interpretation={x.interpretation}
										description={x.description}
										canDeleteOrder={canDeleteOrder(x)}
										isEditOrderInterpretationEnabled={isEditOrderInterpretationEnabled}
										onInterpretationChange={(value) => onOrderInterpretationChange(oHash, value)}
										onDelete={() => onDeleteOrder(oHash)}
									/>
								</ListItem>
							);
						})}
					</List>
				) : null}
				{onAddOrder && (
					<Button onClick={onAddOrder}>
						<img alt="" src={AddOrderIcon} width="20" height="20" />
						<Typography sx={{ pl: 1, textTransform: 'none' }} variant="subtitle1">
							{t('addOrder')}
						</Typography>
					</Button>
				)}
			</Box>
		</Paper>
	);
}

export interface IDiagnoseItemListProps {
	isLoading: boolean;
	diagnoses: IDiagnoseField[];
	errors?: IDiagnoseError[];
	onNoteChange: (hash: string, text: string) => void;
	onProblemChange: (hash: string, problem: boolean) => void;
	onLateralityChange?: (hash: string, laterality: DiagnoseLaterality | undefined) => void;
	onDeleteDiagnose: (hash: string) => void;
	isEditOrderInterpretationEnabled: boolean;
	onOrderInterpretationChange: (dHash: string, oHash: string, interpretation: IInterpretationField) => void;
	onAddOrder?: (dHash: string) => void;
	onDeleteOrder: (dHash: string, oHash: string) => void;
	canDeleteOrder: (order: IOrderField) => boolean;
	onChangeRanking: (dHash: string, swapDHash: string) => void;
}

function DiagnoseItemList({
	isLoading,
	diagnoses,
	errors,
	onNoteChange,
	onProblemChange,
	onLateralityChange,
	onDeleteDiagnose,
	isEditOrderInterpretationEnabled,
	onOrderInterpretationChange,
	onAddOrder,
	canDeleteOrder,
	onDeleteOrder,
	onChangeRanking,
}: IDiagnoseItemListProps) {
	return (
		<List sx={{ width: '100%' }}>
			{isLoading ? (
				<Box sx={{ display: 'fex', width: '100%', alignItems: 'center', justifyContent: 'center', mt: 1 }}>
					<Spinner />
				</Box>
			) : null}
			{diagnoses.map((x, index) => {
				const dHash = diagHash(x);
				const caption = x.caption || x.SNOMEDName || '';

				const isFirst = index === 0;
				const isLast = index === diagnoses.length - 1;

				const error = errors?.find((e) => e.snomed === x.SNOMEDCode);

				return (
					<ListItem sx={{ px: 0 }} key={`${dHash}-${caption.toLowerCase()}`}>
						<DiagnoseItem
							caption={caption}
							icdCodes={x.icdCodes.filter((y) => y.codeset.toLowerCase() === 'icd10')}
							laterality={x.laterality}
							note={x.note}
							problem={x.problem}
							addToProblemList={x.addToProblemList}
							error={error}
							onNoteChange={(text) => onNoteChange(dHash, text)}
							onProblemChange={(text) => onProblemChange(dHash, text)}
							onLateralityChange={onLateralityChange ? (text) => onLateralityChange(dHash, text) : undefined}
							onDelete={() => onDeleteDiagnose(dHash)}
							orders={x.orders}
							isEditOrderInterpretationEnabled={isEditOrderInterpretationEnabled}
							onOrderInterpretationChange={(oHash, value) => onOrderInterpretationChange(dHash, oHash, value)}
							onAddOrder={onAddOrder ? () => onAddOrder(dHash) : onAddOrder}
							onDeleteOrder={(oHash: string) => onDeleteOrder(dHash, oHash)}
							canDeleteOrder={canDeleteOrder}
							isFirst={isFirst}
							isLast={isLast}
							onIncreaseRanking={
								diagnoses[index - 1] ? () => onChangeRanking(dHash, diagHash(diagnoses[index - 1])) : undefined
							}
							onDecreaseRanking={
								diagnoses[index + 1] ? () => onChangeRanking(dHash, diagHash(diagnoses[index + 1])) : undefined
							}
						/>
					</ListItem>
				);
			})}
		</List>
	);
}

export default DiagnoseItemList;
