import { Box, BoxProps, List, ListItemButton, Popper, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import * as React from 'react';
import { ReactElement, useEffect, useState } from 'react';
import getCaretCoordinates from 'textarea-caret';
import { getInnerTextCaretPosition, setNativeValue } from '../../utils/inputSelection.utils';
import {
	getAutocompleteOptions,
	getReplacedShortCode,
	IAutocompleteProps,
	IShortCode,
	IShortCodeSection,
} from './autocomplete.utils';

function AutocompleteListItem({
	id,
	selected,
	option,
	autocomplete,
	onUseShortcut,
}: {
	id?: string;
	selected: boolean;
	option: IShortCode;
	autocomplete: IAutocompleteProps;
	onUseShortcut: (option: IShortCode) => void;
}) {
	return (
		<ListItemButton
			id={id}
			key={option.shortCode}
			sx={{
				p: 2,
				textTransform: 'none',
				backgroundColor: selected ? grey['300'] : 'none',
				'&:hover': {
					backgroundColor: selected ? grey['300'] : grey['100'],
				},
			}}
			onPointerDown={(e) => e.preventDefault()}
			onClick={() => onUseShortcut(option)}
		>
			{`${autocomplete?.trigger}${option.shortCode} - ${option.description}`}
		</ListItemButton>
	);
}

interface IDotPhrasesPopperProps extends BoxProps {
	id: string;
	type: 'input' | 'contentEditable';
	inputRef: null | HTMLTextAreaElement | HTMLInputElement | HTMLDivElement;
	disabled?: boolean;
	children: ReactElement;
	autocomplete: IAutocompleteProps;
}

function AutocompletePopper({
	id,
	type,
	inputRef,
	disabled,
	children,
	autocomplete,
	...other
}: IDotPhrasesPopperProps) {
	const [anchor, setAnchor] = useState(inputRef);
	const [focused, setFocused] = useState<boolean>(false);

	const [actualCaretPosition, setActualCaretPosition] = useState<number | null>(null);
	const [caretCoords, setCaretCoords] = useState<{ left: number; top: number } | null>(null);

	const [selectedShortCodeIndex, setShortCodeIndex] = useState<number | null>(0);

	const [autoCompleteOptions, setAutoCompleteOptions] = useState<{
		shortCodes: IShortCode[];
		sections?: IShortCodeSection[];
		minimumReached: boolean;
	} | null>(null);

	const optionsAvailable = !!(
		autoCompleteOptions &&
		(autoCompleteOptions.shortCodes.length > 0 ||
			(autoCompleteOptions.sections && autoCompleteOptions.sections.length > 0))
	);

	const getSelectedIndex = (index: number | null): { sectionIndex: number; itemIndex: number } | null => {
		if (index === null || autoCompleteOptions === null) {
			return null;
		}

		if (index < autoCompleteOptions.shortCodes.length) {
			return { sectionIndex: -1, itemIndex: index };
		}

		let currentIndex = autoCompleteOptions.shortCodes.length;

		if (!autoCompleteOptions.sections) {
			return null;
		}

		// eslint-disable-next-line no-plusplus
		for (let sectionIndex = 0; sectionIndex < autoCompleteOptions.sections.length; ++sectionIndex) {
			for (
				let itemIndex = 0;
				itemIndex < autoCompleteOptions.sections[sectionIndex].options.length;
				// eslint-disable-next-line no-plusplus
				++itemIndex, ++currentIndex
			) {
				if (currentIndex === index) {
					return { sectionIndex, itemIndex };
				}
			}
		}

		return null;
	};

	const selectedIndex = getSelectedIndex(selectedShortCodeIndex);

	const setSelectedShortCodeIndex = (index: number | null) => {
		setShortCodeIndex(index);

		if (index !== null) {
			const codeIndex = getSelectedIndex(index);
			let itemId: string | null = null;

			if (codeIndex !== null) {
				if (autocomplete.sections && codeIndex.sectionIndex >= 0 && codeIndex.itemIndex >= 0) {
					const section = autocomplete.sections[codeIndex.sectionIndex];
					const code = section.options[codeIndex.itemIndex];
					itemId = `${id}-${section.title}${code.shortCode}`;
				} else if (codeIndex.sectionIndex === -1 && codeIndex.itemIndex >= 0) {
					const code = autocomplete.options[codeIndex.itemIndex];
					itemId = `${id}-${code.shortCode}`;
				}
				if (itemId) {
					const listItem = document.getElementById(itemId);
					if (listItem) {
						listItem.scrollIntoView({
							behavior: 'smooth',
							block: 'nearest',
							inline: 'start',
						});
					}
				}
			}
		}
	};

	useEffect(() => {
		setSelectedShortCodeIndex(null);
	}, [autoCompleteOptions]);

	useEffect(() => {
		if (focused && inputRef) {
			setAnchor(inputRef);
		} else {
			setAnchor(null);
		}
	}, [focused, inputRef]);

	const getCaretSelectionPos = () => {
		if (!inputRef) {
			return null;
		}

		let caretPos: number | null = null;
		if (type === 'input') {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			caretPos = inputRef.selectionEnd;
		} else {
			const selection = getSelection();
			if (selection) {
				caretPos = selection.anchorOffset;
			}
		}

		return caretPos !== undefined ? caretPos : null;
	};

	const getCaretContainerPos = () => {
		if (!inputRef) {
			return null;
		}

		let caretPos: number;
		if (type === 'input') {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			caretPos = inputRef.selectionEnd;
		} else {
			caretPos = getInnerTextCaretPosition(inputRef);
		}

		return caretPos !== undefined ? caretPos : null;
	};

	const getInputText = () => {
		if (type === 'input') {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			return inputRef?.value;
		}
		const selection = getSelection();

		if (!selection) {
			return null;
		}

		return selection.focusNode?.textContent || null;
	};

	const getAllOptions = (options: { shortCodes: IShortCode[]; sections?: IShortCodeSection[] } | null) =>
		options
			? [options.shortCodes, options.sections ? options.sections.map((sec) => sec.options).flat(1) : []].flat(1)
			: [];

	useEffect(() => {
		if (!focused || !autocomplete) {
			setAutoCompleteOptions(null);
			return;
		}

		const inputText = getInputText();

		if (!inputText) {
			setAutoCompleteOptions(null);
			return;
		}

		if (inputText.length >= autocomplete.trigger.length) {
			const options = getAutocompleteOptions({
				inputText,
				caret: getCaretSelectionPos() || 0,
				config: autocomplete,
				minLength: 1,
			});

			if (type === 'input' && inputRef) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				const coords = getCaretCoordinates(inputRef, inputRef.selectionEnd);
				setCaretCoords(coords || null);
			}

			setAutoCompleteOptions(options);

			// TODO: double-check if needed, might cause issues with Procedure documentations 'select option' functionality
			if (getAllOptions(options).length > 0 && inputRef?.focus) {
				inputRef.focus();
			}
		} else {
			setAutoCompleteOptions(null);
		}
	}, [actualCaretPosition, focused]);

	const onUseShortcut = (option: IShortCode) => {
		if (!autocomplete || !focused) {
			return;
		}

		const inputText = getInputText();

		setAutoCompleteOptions(null);

		const caretPos = getCaretSelectionPos() || 0;
		const { text, offset } = getReplacedShortCode({
			inputText,
			caret: caretPos,
			option,
			separator: autocomplete.separator,
		});

		if (type === 'input') {
			if (inputRef) {
				setNativeValue(inputRef, text);
				inputRef.dispatchEvent(new Event('input', { bubbles: true }));
				inputRef.dispatchEvent(new Event('resize', { bubbles: true }));

				const pos = caretPos + offset;
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				inputRef.setSelectionRange(pos, pos);
			}
		} else {
			const selection = getSelection();
			if (selection) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				selection.anchorNode.nodeValue = text;

				const newSelection = getSelection();
				if (newSelection) {
					newSelection.collapse(newSelection.focusNode, caretPos + offset);
				}
			}
		}
		setActualCaretPosition(getCaretContainerPos());
	};

	const getOffset = (): (({
		reference,
		placement,
	}: {
		reference: { x: number; y: number; width: number; height: number };
		placement: string;
	}) => number[]) => {
		const xPosOffset = 8;
		const yPosOffset = 16;

		if (type === 'input') {
			return ({
				reference,
				placement,
			}: {
				reference: { x: number; y: number; width: number; height: number };
				placement: string;
			}) => {
				if (!inputRef) {
					return [0, 0];
				}
				const yPos =
					placement === 'bottom-start'
						? (caretCoords?.top || 0) - reference.height + yPosOffset
						: -(caretCoords?.top || 0);
				return [(caretCoords?.left || 0) + xPosOffset, yPos];
			};
		}
		return ({
			reference,
			placement,
		}: {
			reference: { x: number; y: number; width: number; height: number };
			placement: string;
		}) => {
			if (!anchor) {
				return [0, 0];
			}

			const selection = getSelection();
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const inputRect = anchor.getBoundingClientRect();

			if (!selection || !inputRect) {
				return [0, 0];
			}
			const range = selection.getRangeAt(0);
			const caretRect = range.getClientRects()[0];

			if (!range || !caretRect) {
				return [0, 0];
			}

			const yPos =
				placement === 'bottom-start'
					? caretRect.y - inputRect.y - reference.height + yPosOffset
					: -(caretRect.y - inputRect.y);

			return [caretRect.x - inputRect.x + 8, yPos];
		};
	};

	const enabled = focused && inputRef && !disabled && optionsAvailable;

	const allOptions = getAllOptions(autoCompleteOptions);

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<Box {...other}>
			{React.cloneElement(children, {
				onFocus: (e: FocusEvent) => {
					if (children.props.onFocus) {
						children.props.onFocus(e);
					}
					setFocused(true);
				},
				onBlur: (e: FocusEvent) => {
					if (children.props.onBlur) {
						children.props.onBlur(e);
					}
					setFocused(false);
				},
				onKeyUp: (e: KeyboardEvent) => {
					if (children.props.onKeyUp) {
						children.props.onKeyUp(e);
					}
					setActualCaretPosition(getCaretContainerPos());
				},
				onMouseUp: (e: MouseEvent) => {
					if (children.props.onMouseUp) {
						children.props.onMouseUp(e);
					}
					setActualCaretPosition(getCaretContainerPos());
				},
				onKeyDown: (keyEvent: KeyboardEvent) => {
					if (keyEvent.key === ' ') {
						if (allOptions.length === 1) {
							if (keyEvent) {
								keyEvent.preventDefault();
							}
							onUseShortcut(allOptions[0]);
						}
					}
					if (keyEvent.key === 'Enter') {
						if (selectedShortCodeIndex != null) {
							if (keyEvent) {
								keyEvent.preventDefault();
							}
							onUseShortcut(allOptions[selectedShortCodeIndex]);
						}
					}
					if (keyEvent.key === 'ArrowUp') {
						if (allOptions.length > 0) {
							if (keyEvent) {
								keyEvent.preventDefault();
							}
							if (selectedShortCodeIndex === null) {
								setSelectedShortCodeIndex(0);
							} else if (selectedShortCodeIndex > 0) {
								setSelectedShortCodeIndex((selectedShortCodeIndex as number) - 1);
							} else {
								setSelectedShortCodeIndex(allOptions.length - 1);
							}
						}
					}
					if (keyEvent.key === 'ArrowDown') {
						if (allOptions.length > 0) {
							if (keyEvent) {
								keyEvent.preventDefault();
							}
							if (selectedShortCodeIndex === null) {
								setSelectedShortCodeIndex(0);
							} else if (selectedShortCodeIndex < allOptions.length - 1) {
								setSelectedShortCodeIndex((selectedShortCodeIndex as number) + 1);
							} else {
								setSelectedShortCodeIndex(0);
							}
						}
					}
				},
			})}
			{enabled ? (
				<Popper
					sx={{ zIndex: 1 }}
					open
					anchorEl={anchor}
					placement="bottom-start"
					modifiers={[
						{
							name: 'offset',
							enabled: true,
							options: {
								offset: getOffset(),
							},
						},
						{
							name: 'flip',
							options: {
								fallbackPlacements: ['top-start'],
							},
						},
					]}
				>
					<Box
						sx={{
							maxHeight: 280,
							overflowY: 'auto',
							backgroundColor: (t) => t.palette.background.paper,
						}}
					>
						<List>
							{autoCompleteOptions.shortCodes.map((option, optionIndex) => (
								<AutocompleteListItem
									id={`${id}-${option.shortCode}`}
									key={option.shortCode}
									selected={
										!!(selectedIndex && selectedIndex.sectionIndex === -1 && optionIndex === selectedIndex.itemIndex)
									}
									option={option}
									autocomplete={autocomplete}
									onUseShortcut={onUseShortcut}
								/>
							))}
							{autoCompleteOptions.sections &&
								autoCompleteOptions.sections.map((section, sectionIndex) => (
									<React.Fragment key={section.title}>
										<Typography align="center" fontWeight="bold">
											{section.title}
										</Typography>
										{section.options.map((option, optionIndex) => (
											<AutocompleteListItem
												id={`${id}-${section.title}${option.shortCode}`}
												key={`${section.title}${option.shortCode}`}
												selected={
													!!(
														selectedIndex &&
														selectedIndex.sectionIndex === sectionIndex &&
														optionIndex === selectedIndex.itemIndex
													)
												}
												option={option}
												autocomplete={autocomplete}
												onUseShortcut={onUseShortcut}
											/>
										))}
									</React.Fragment>
								))}
						</List>
					</Box>
				</Popper>
			) : null}
		</Box>
	);
}

export default AutocompletePopper;
