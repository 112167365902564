import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, GridProps, Stack, Switch, Typography } from '@mui/material';
import { namespaces } from '../../application/i18n.constants';
import SelectProviders from '../../components/providers/SelectProviders';
import ChipList from '../../components/chip-list/ProvidersChipList';
import { IProvider } from '../../core/models/providers/providers.models';
import PageContainer from '../../components/page-container/PageContainer';
import { sortProviders } from '../../core/services/providers/providers.services';

interface IAccountSettingsPageProps {
	username: string;
	selectedProviders: IProvider[];
	providersAreEditable: boolean;
	isStreamAudio: boolean;
	isHtml5Audio: boolean;
	onIsStreamAudioChange: (isStream: boolean) => void;
	onIsHtml5AudioChange: (isStream: boolean) => void;
}

interface IGridRowProps {
	fieldName: string;
	children: React.ReactNode;
}

function GridRow({ fieldName, children }: IGridRowProps) {
	return (
		<Grid item container direction="row" justifyContent="space-between" alignItems="center">
			<Grid item alignItems="center">
				<Typography>{fieldName}</Typography>
			</Grid>
			<Grid item alignItems="center">
				{children}
			</Grid>
		</Grid>
	);
}

function GridSection({ children, ...rest }: GridProps & { children: React.ReactNode }) {
	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<Grid item container sx={{ py: 1, borderBottom: 1 }} {...rest}>
			{children}
		</Grid>
	);
}

function AccountSettingsPage({
	username,
	selectedProviders,
	providersAreEditable,
	isStreamAudio,
	isHtml5Audio,
	onIsStreamAudioChange,
	onIsHtml5AudioChange,
}: IAccountSettingsPageProps) {
	const { t } = useTranslation(namespaces.pages.accountSettings);

	const [isSelectProvidersOpen, setIsSelectProvidersOpen] = React.useState(false);

	const handleOpenSelectProviders = () => {
		setIsSelectProvidersOpen(true);
	};

	const handleCloseSelectProviders = () => {
		setIsSelectProvidersOpen(false);
	};

	return (
		<PageContainer
			sx={{
				flexDirection: 'column',
				alignItems: 'center',
			}}
		>
			<Grid container item sx={{ padding: '0 2rem' }} xl={4} lg={5} md={8}>
				<GridSection>
					<Typography sx={{ fontWeight: 'bold' }}>{t('account')}</Typography>
				</GridSection>
				<GridSection>
					<GridRow fieldName={t('username')}>
						<Typography>{username}</Typography>
					</GridRow>
				</GridSection>
				<GridSection>
					<GridRow fieldName={t('audioPlayer')}>
						<Stack direction="row" spacing={1} alignItems="center">
							<Typography> {t('audioStream')}</Typography>
							<Switch checked={isStreamAudio} onChange={(e, value) => onIsStreamAudioChange(value)} />
							<Typography>{t('audioPreload')} </Typography>
						</Stack>
					</GridRow>
					<GridRow fieldName={t('html5Audio')}>
						<Stack direction="row" spacing={1} alignItems="center">
							<Switch
								disabled={isStreamAudio}
								checked={isHtml5Audio || isStreamAudio}
								onChange={(e, value) => onIsHtml5AudioChange(value)}
							/>
						</Stack>
					</GridRow>
					<Typography color="grey" variant="body2">
						{t('html5AudioNote')}
					</Typography>
				</GridSection>
				<GridSection direction="row" alignItems="center" justifyContent="space-between" wrap="nowrap">
					<Grid item>
						<Grid item alignItems="center">
							<Typography>{t('providers')}</Typography>
						</Grid>
						<Grid item>
							<ChipList items={sortProviders(selectedProviders).map((x) => ({ ...x, title: x.name }))} />
						</Grid>
					</Grid>
					<Grid item>
						<Button
							type="button"
							disabled={!providersAreEditable}
							color="secondary"
							variant="text"
							sx={{ mt: 1, mb: 1 }}
							onClick={handleOpenSelectProviders}
						>
							{t('manage')}
						</Button>
					</Grid>
				</GridSection>
				<SelectProviders open={isSelectProvidersOpen} onClose={handleCloseSelectProviders} />
			</Grid>
		</PageContainer>
	);
}

export default AccountSettingsPage;
