import { Alert, Box, SxProps, Tab, Theme } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import LoadingButton from '@mui/lab/LoadingButton';
import EditIcon from '@mui/icons-material/Edit';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import LazyInput from '../inputs/LazyInput';
import { namespaces } from '../../application/i18n.constants';
import { AIModelType } from '../../core/api/dictations/dictations.api.models';
import SplitButton from '../buttons/split-button/SplitButton';
import { useAppSelector } from '../../core/core.types';
import { IAIModel } from '../../core/api/system/system.api.models';

enum AIPromptTabType {
	PROMPT = 'prompt',
	INSTRUCTIONS = 'instructions',
}

function TabTextInputs({ value, onChange }: { value: string; onChange?: (value: string) => void }) {
	return (
		<LazyInput
			maxRows={20}
			style={
				{
					paddingLeft: '8px',
					paddingRight: '8px',
					width: '100%',
					maxHeight: '100%',
					resize: 'none',
					alignItems: 'flex-start',
				} as React.CSSProperties
			}
			readOnly={!onChange}
			value={value}
			onChange={onChange}
		/>
	);
}

interface IAIPromptProps {
	containerSx?: SxProps<Theme>;
	lastTimePromptSubmitted: number | null;
	prompt: string;
	instructions: string;
	onSubmit?: (aiModel: AIModelType | null, prompt: { prompt: string; instructions: string }) => void;
	onChangePrompt: (prompt: string) => void;
	onChangeInstructions: (instructions: string) => void;
}

function AIPrompt({
	containerSx,
	lastTimePromptSubmitted,
	prompt,
	instructions,
	onSubmit,
	onChangePrompt,
	onChangeInstructions,
}: IAIPromptProps) {
	const { t } = useTranslation(namespaces.pages.dictation);

	const aiModels: IAIModel[] = useAppSelector((state) => state.app.aiModels);

	const [currentTab, setCurrentTab] = React.useState<AIPromptTabType>(AIPromptTabType.PROMPT);
	const [isAIPromptLoading, setIsAIPromptLoading] = useState(false);
	const [aiModel, setAiModel] = useState<AIModelType | null>(null);

	const handleSidebarTabChange = (event: React.SyntheticEvent, newValue: AIPromptTabType) => {
		setCurrentTab(newValue);
	};

	const timedOut = (time: number) => {
		return new Date().getTime() - time < 120 * 1000;
	};

	useEffect(() => {
		if (lastTimePromptSubmitted) {
			setIsAIPromptLoading(timedOut(lastTimePromptSubmitted));
			const interval = setInterval(() => {
				setIsAIPromptLoading(timedOut(lastTimePromptSubmitted));
			}, 5 * 1000);

			// Clear the interval when the component is unmounted or value changes
			return () => clearInterval(interval);
		}
		setIsAIPromptLoading(false);
		return () => {};
	}, [lastTimePromptSubmitted]);

	useEffect(() => {
		if (!isAIPromptLoading && lastTimePromptSubmitted && !timedOut(lastTimePromptSubmitted)) {
			toast.warning(t('aiPromptTimeout'));
		}
	}, [isAIPromptLoading]);

	const gpt4Disabled = (prompt.length + instructions.length) / 4 >= 6000;
	const displayWarning = aiModel === AIModelType.GPT4 && gpt4Disabled;

	const buttons = useMemo(() => {
		return onSubmit
			? [
					{
						name: t('submitAiPrompt'),
						shortText: t('submit'),
						onClick: () => onSubmit(null, { prompt, instructions }),
					},
					...aiModels.map((model) => ({
						name: model.ModelName,
						shortText: model.ModelName,
						onClick: () => onSubmit(model.ModelName as AIModelType, { prompt, instructions }),
					})),
			  ]
			: [];
	}, [t, onSubmit, prompt, instructions]);

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', ...containerSx }}>
			<TabContext value={currentTab}>
				<Box sx={{ display: 'flex', flexDirection: 'row' }}>
					<TabList onChange={handleSidebarTabChange}>
						<Tab value={AIPromptTabType.PROMPT} label={t(AIPromptTabType.PROMPT)} />
						<Tab value={AIPromptTabType.INSTRUCTIONS} label={t(AIPromptTabType.INSTRUCTIONS)} />
					</TabList>
					{onSubmit ? (
						<Box sx={{ display: 'flex', justifyContent: 'flex-end', flex: 1, p: 1 }}>
							{isAIPromptLoading ? (
								<LoadingButton
									loading={isAIPromptLoading}
									startIcon={<EditIcon />}
									size="small"
									onClick={() => onSubmit(null, { prompt, instructions })}
								>
									{t('submit')}
								</LoadingButton>
							) : (
								<SplitButton buttons={buttons} onChangeOption={(name) => setAiModel(name as AIModelType)} />
							)}
						</Box>
					) : null}
				</Box>
				<Box sx={{ height: '100%', overflowY: 'hidden' }}>
					{displayWarning ? (
						<Alert sx={{ px: 2, mt: 1, minHeight: 75 }} color="warning">
							{t('gpt4TokensLimitWarning')}
						</Alert>
					) : null}
					<TabPanel
						sx={{ px: 0, overflowY: 'hidden', height: displayWarning ? '80%' : '100%' }}
						value={AIPromptTabType.PROMPT}
					>
						<TabTextInputs value={prompt} onChange={onChangePrompt} />
					</TabPanel>
					<TabPanel
						sx={{ px: 0, overflowY: 'hidden', height: displayWarning ? '80%' : '100%' }}
						value={AIPromptTabType.INSTRUCTIONS}
					>
						<TabTextInputs value={instructions} onChange={onChangeInstructions} />
					</TabPanel>
				</Box>
			</TabContext>
		</Box>
	);
}

export default AIPrompt;
