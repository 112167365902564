import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, DialogActions } from '@mui/material';
import dayjs from 'dayjs';
import Dialog from '../dialog/Dialog';
import ValidationForm from '../validators/ValidationForm';
import ValidationTextField from '../validators/ValidationTextField';
import { ICustomPatientDemographics } from '../../core/models/patients/patients.models';
import { dateFormat } from '../../core/core.constants';

interface IUpdatePatientDemographicsDialogProps {
	open: boolean;
	isLoading?: boolean;
	action: {
		action: (params: ICustomPatientDemographics) => void;
		text: string;
		disabled?: boolean;
	};
	cancel: {
		action: () => void;
		text: string;
	};
	data: ICustomPatientDemographics;
}
function UpdatePatientDemographicsDialog({
	data,
	open,
	isLoading,
	action,
	cancel,
}: IUpdatePatientDemographicsDialogProps) {
	const [disableValidation, setDisableValidation] = useState<boolean>(true);
	const [isFromValid, setIsFromValid] = useState<boolean>(false);
	const [isComplete, setIsComplete] = useState<boolean>(false);

	const [firstName, setFirstName] = useState<string>(data.patientFirstName);
	const [lastName, setLastName] = useState<string>(data.patientLastName);
	const [mrn, setMrn] = useState<string>(data.mrn);
	const [dob, setDob] = useState<string>(dayjs(data.dateOfBirth).format(dateFormat));

	useEffect(() => {
		if (open) {
			setFirstName(data.patientFirstName);
			setLastName(data.patientLastName);
			setMrn(data.mrn);
			setDob(data.dateOfBirth);
		}
	}, [open]);

	const onSubmit = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		if (isFromValid) {
			action.action({
				patientFirstName: firstName,
				patientLastName: lastName,
				mrn,
				dateOfBirth: dayjs(dob).format(dateFormat),
			});
		} else {
			setDisableValidation(false);
		}
	};

	return (
		<Dialog fullWidth maxWidth="sm" onClose={cancel.action} open={open} loading={isLoading} title="Update Patient">
			<ValidationForm
				component="form"
				disableValidation={disableValidation}
				onValidationChange={(valid) => setIsFromValid(valid)}
				onCompletenessChange={(complete) => setIsComplete(complete)}
				sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', p: 2 }}
			>
				<ValidationTextField
					id="patientFirstNameInput"
					fullWidth
					sx={{ my: 1 }}
					type="text"
					value={firstName}
					label="First Name"
					onChange={(e) => setFirstName(e.target.value)}
				/>
				<ValidationTextField
					id="patientLastNameInput"
					fullWidth
					sx={{ my: 1 }}
					type="text"
					value={lastName}
					label="Last Name"
					onChange={(e) => setLastName(e.target.value)}
				/>
				<ValidationTextField
					id="mrnInput"
					fullWidth
					sx={{ my: 1 }}
					type="text"
					value={mrn}
					label="MRN"
					onChange={(e) => setMrn(e.target.value)}
				/>
				<ValidationTextField
					id="dobInput"
					defaultValue={dayjs(dob).format('YYYY-MM-DD')}
					fullWidth
					sx={{ my: 1 }}
					type="date"
					value={dayjs(dob).format('YYYY-MM-DD')}
					label="Date of Birth"
					onChange={(e) => setDob(dayjs(e.target.value).format(dateFormat))}
				/>
			</ValidationForm>
			<DialogActions>
				<Button onClick={cancel.action}>Cancel</Button>
				<Button variant="contained" disabled={!isComplete} onClick={onSubmit}>
					Update
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default UpdatePatientDemographicsDialog;
