import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { IDiagnoseField } from '../../core/models/dictations/dictations.models';
import { useAppSelector } from '../../core/core.types';
import { namespaces } from '../../application/i18n.constants';
import ItemsList from '../list/ItemsList';
import { diagHash } from '../../core/services/documents/documents.services';

interface IDiagnoseItemsListProps {
	searchQuery: string;
	selectedDiagnoses: IDiagnoseField[];
	isLoading: (waitingResult: boolean) => boolean;
	onSelectDiagnose: (diagnose: IDiagnoseField) => void;
}

function DiagnoseItem({ diagnose }: { diagnose: IDiagnoseField }) {
	return (
		<Box sx={{ flexDirection: 'column' }}>
			<Typography sx={{ fontWeight: 'bold' }}>{diagnose.caption}</Typography>
			{diagnose.icdCodes.map((x) => (
				<Typography variant="subtitle1" key={`${x.codeset}-${x.code}`}>
					{x.codeset}: {x.code || ''} - {x.description}
				</Typography>
			))}
		</Box>
	);
}

function DiagnoseItemsList({ searchQuery, selectedDiagnoses, isLoading, onSelectDiagnose }: IDiagnoseItemsListProps) {
	const searchDiagnosesResults = useAppSelector<IDiagnoseField[]>((state) => state.worklist.diagnosesSearchResult);
	const searchDiagnosesIsLoading = useAppSelector<boolean>((state) => state.worklist.diagnoseSearchIsLoading);
	const { t } = useTranslation(namespaces.components.diagnosesAndOrdersDialog);

	const loading = isLoading(searchDiagnosesIsLoading);
	return searchQuery.length > 0 ? (
		<ItemsList
			button
			loading={loading}
			listHeader={
				searchDiagnosesResults && searchDiagnosesResults.length > 0 && !loading
					? t('searchResults')
					: t('noSearchResults')
			}
			items={searchDiagnosesResults.map((x) => ({ id: diagHash(x), title: x.caption, ...x }))}
			renderItem={(diagnose: IDiagnoseField) => <DiagnoseItem diagnose={diagnose} />}
			isSelected={(diagnose) => !!selectedDiagnoses.find((x) => diagHash(x) === diagHash(diagnose))}
			canSelectSelected
			onClick={onSelectDiagnose}
		/>
	) : (
		<ItemsList
			listHeader={t('addedItems')}
			items={selectedDiagnoses.map((x) => ({ id: diagHash(x), title: x.caption, ...x }))}
			isSelected={(diagnose) => !!selectedDiagnoses.find((x) => diagHash(x) === diagHash(diagnose))}
			renderItem={(diagnose: IDiagnoseField) => <DiagnoseItem diagnose={diagnose} />}
		/>
	);
}

export default DiagnoseItemsList;
