import * as React from 'react';
import { ChangeEvent, useEffect, useState } from 'react';
import { StandardTextFieldProps, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ValidationResult } from '../../core/validators/types';
import { IValidationField } from './validationField.types';
import { namespaces } from '../../application/i18n.constants';

interface IValidationTextFieldProps extends Omit<StandardTextFieldProps, 'error'>, IValidationField {
	validation?: (value: string) => ValidationResult;
	onChange?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}

function ValidationTextField({
	validation,
	value,
	required,
	helperText,
	onChange,
	onValidationChange,
	disableValidation,
	...props
}: IValidationTextFieldProps) {
	const { t } = useTranslation(namespaces.common.validations);

	const check = (inputValue: unknown): { message?: string; valid: boolean; isNotFilled?: boolean } => {
		if (required && (!inputValue || inputValue === '')) {
			return { valid: false, message: t('fieldIsRequired'), isNotFilled: true };
		}

		if (!validation || inputValue === '') {
			return { valid: true };
		}

		const result = validation(inputValue as string);
		return result.success ? { valid: true } : { message: result.error, valid: false };
	};

	const [validationResult, setValidationResult] = useState<{ message?: string; valid: boolean; isNotFilled?: boolean }>(
		check(value)
	);

	useEffect(() => {
		const checkResult = check(value);
		setValidationResult(checkResult);
		if (onValidationChange) {
			onValidationChange(checkResult);
		}
	}, [value]);

	const onValueChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		if (onChange) {
			onChange(event);
		}
		if (validation || required) {
			const checkResult = check(event.target.value);
			setValidationResult(checkResult);
			if (onValidationChange) {
				onValidationChange(checkResult);
			}
		}
	};

	return (
		<TextField
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
			required={required}
			value={value}
			onChange={onValueChange}
			error={!validationResult.valid && !disableValidation}
			helperText={
				validationResult.valid || disableValidation ? helperText : validationResult.message || t('inputIsNotValid')
			}
		/>
	);
}

export default ValidationTextField;
