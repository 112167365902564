import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import SearchBar from '../search-bar/SearchBar';
import DiagnoseItemsList from './DiagnoseItemsList';
import { IDiagnoseField } from '../../core/models/dictations/dictations.models';
import { actions } from '../../core/state/actions';
import { useAppDispatch } from '../../core/core.types';
import { namespaces } from '../../application/i18n.constants';
import Dialog from '../dialog/Dialog';

interface ISelectDiagnosesDialogProps {
	searchText: string;
	selectedDiagnoses: IDiagnoseField[];
	onSelectDiagnose: (item: IDiagnoseField) => void;
	open: boolean;
	onClose?: () => void;
}
function SelectDiagnosesDialog({
	searchText,
	selectedDiagnoses,
	onSelectDiagnose,
	open,
	onClose,
}: ISelectDiagnosesDialogProps) {
	const { t } = useTranslation(namespaces.components.diagnosesAndOrdersDialog);

	const dispatch = useAppDispatch();

	const [isTyping, setIsTyping] = useState<boolean>(false);
	const [searchQuery, setSearchQuery] = useState<string>('');

	useEffect(() => {
		setSearchQuery(searchText);
	}, [searchText]);

	const onCloseDialog = () => {
		setSearchQuery('');
		dispatch(actions.worklist.setDiagnosesSearchResult([]));
		if (onClose) {
			onClose();
		}
	};

	const onSearch = () => {
		dispatch(actions.worklist.searchDiagnoses(searchQuery));
	};

	const onSelectDiagnoseField = (diagnose: IDiagnoseField) => {
		onSelectDiagnose(diagnose);
	};

	const isLoading = (isWaitingResult: boolean) => searchQuery.length > 0 && (isTyping || isWaitingResult);

	return (
		<Dialog fullWidth maxWidth="sm" onClose={onCloseDialog} open={open}>
			<SearchBar
				placeholder={t('search')}
				value={searchQuery}
				onInputChange={setSearchQuery}
				onTyping={setIsTyping}
				onSearch={onSearch}
			/>
			{/* <Box sx={{ p: selectedDiagnoses.length === 0 ? 0 : 1 }}> */}
			{/*	<ChipList items={selectedDiagnoses.map((x) => ({ id: diagHash(x), title: x.caption, ...x }))} /> */}
			{/* </Box> */}
			<DiagnoseItemsList
				searchQuery={searchQuery}
				selectedDiagnoses={selectedDiagnoses}
				isLoading={isLoading}
				onSelectDiagnose={onSelectDiagnoseField}
			/>
		</Dialog>
	);
}

export default connect()(SelectDiagnosesDialog);
