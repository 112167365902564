import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import ThemeProvider from '../../theme';
import Router from './Router';
import { actions } from '../../core/state/actions';
import { routes } from './routes';
import { useAppDispatch } from '../../core/core.types';
import SessionTimeoutDialog from '../../components/session-timeout-dialog/SessionTimeoutDialog';
import { ISignOutActionPayload } from '../../core/state/auth/auth.actions';
import { ISaveDocumentActionPayload } from '../../core/state/worklist/worklist.actions';
import { useAuth } from '../../utils/auth.hooks';
import useSignalRjQuery from '../../application/useSignalRjQuery';
import eventHandlers from '../../application/signalREventHandler';

function App() {
	const dispatch = useAppDispatch();
	useSignalRjQuery(dispatch, eventHandlers());

	const onSignOut = useCallback(
		(args: ISignOutActionPayload) => dispatch(actions.auth.signOutAction(args)),
		[dispatch]
	);
	const onSaveDocument = useCallback(
		(args: ISaveDocumentActionPayload) => dispatch(actions.worklist.saveDocumentAction(args)),
		[dispatch]
	);

	const { handleSessionContinue, sessionTimeRemaining, openTimeoutModal } = useAuth({
		onSignOut,
		onSaveDocument,
		routes,
		authTimeout: 1000 * 60 * 10,
		promptBeforeIdle: 1000 * 60,
		cacheActivityInterval: 1000 * 10,
		eventsThrottle: 1000,
	});

	useEffect(() => {
		dispatch(actions.app.initAppAction());
	}, []);

	return (
		<ThemeProvider>
			<Router />
			<SessionTimeoutDialog
				open={openTimeoutModal}
				remainingTime={sessionTimeRemaining}
				onSessionContinue={handleSessionContinue}
			/>
		</ThemeProvider>
	);
}

export default connect()(App);
