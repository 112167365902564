import * as React from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, ButtonGroup, IconButton, Menu, MenuItem } from '@mui/material';

interface ISplitButtonProps {
	buttons: {
		name: string;
		disabled?: boolean;
		shortText?: string;
		onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	}[];
	onChangeOption?: (name: string) => void;
}
export default function SplitButton({ buttons, onChangeOption }: ISplitButtonProps) {
	const [open, setOpen] = React.useState(false);
	const actionRef = React.useRef<() => void | null>(null);
	const anchorRef = React.useRef<HTMLDivElement>(null);
	const [selectedIndex, setSelectedIndex] = React.useState(0);

	return (
		<>
			<ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
				<Button onClick={buttons[selectedIndex].onClick} disabled={buttons[selectedIndex].disabled}>
					{buttons[selectedIndex].shortText ?? buttons[selectedIndex].name}
				</Button>
				<IconButton
					aria-controls={open ? 'split-button-menu' : undefined}
					aria-expanded={open ? 'true' : undefined}
					aria-haspopup="menu"
					onMouseDown={() => {
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						actionRef.current = () => setOpen(!open);
					}}
					onKeyDown={() => {
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						actionRef.current = () => setOpen(!open);
					}}
					onClick={() => {
						actionRef.current?.();
					}}
				>
					<ArrowDropDownIcon />
				</IconButton>
			</ButtonGroup>
			<Menu open={open} onClose={() => setOpen(false)} anchorEl={anchorRef.current}>
				{buttons.map((button, index) => (
					<MenuItem
						key={button.name}
						selected={index === selectedIndex}
						onClick={() => {
							setSelectedIndex(index);
							onChangeOption?.(button.name);
							setOpen(false);
						}}
					>
						{button.name}
					</MenuItem>
				))}
			</Menu>
		</>
	);
}
