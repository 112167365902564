import * as React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import StatFlagEmpty from '../icons/stat-flag-false.svg';
import StatFlag from '../icons/stat-flag.svg';
import { namespaces } from '../../application/i18n.constants';
import Spinner from '../spinner/Spinner';

interface IPatientCardHeaderProps {
	avatar: React.ReactNode;
	title?: string;
	subheader?: string;
	subtitle?: string;
	stat?: boolean;
	noEncounterSelected?: boolean;
	encountersLoading?: boolean;
	noPatientSelected?: boolean;
	changePatientDisabled?: boolean;
	changeEncounterDisabled?: boolean;
	onPatientChangeClick?: () => void;
	onPatientUpdateClick?: () => void;
	onChangeEncounterClick?: (() => void) | null;
	dataPrivate?: boolean;
}

function PatientCardHeader({
	avatar,
	title,
	subtitle,
	subheader,
	stat,
	noEncounterSelected,
	encountersLoading,
	changePatientDisabled,
	changeEncounterDisabled,
	noPatientSelected,
	onPatientChangeClick,
	onPatientUpdateClick,
	onChangeEncounterClick,
	dataPrivate,
}: IPatientCardHeaderProps) {
	const { t } = useTranslation(namespaces.components.patientDetails);

	const handlePatientChangeClick = () => {
		if (onPatientChangeClick) onPatientChangeClick();
	};

	const handleChangeEncounterClick = () => {
		if (onChangeEncounterClick) onChangeEncounterClick();
	};

	const additionalProps = dataPrivate ? { 'data-private': true } : {};
	return (
		<>
			<Box style={{ display: 'flex', padding: '1rem 1rem 0 1rem', alignItems: 'center' }}>
				<Box style={{ flex: '0 0 auto', marginRight: '1rem' }}>{avatar}</Box>
				<Box style={{ flex: '1 1 auto', marginRight: '1rem' }}>
					{noPatientSelected ? (
						<Button variant="outlined" color="error" onClick={handlePatientChangeClick} sx={{ fontWeight: 'normal' }}>
							{t('unknownPatient')}
						</Button>
					) : (
						<Typography
							variant="subtitle1"
							sx={{
								color: noEncounterSelected ? '#B15050' : '',
							}}
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...additionalProps}
						>
							{title}
						</Typography>
					)}
					{subtitle && (
						<Box sx={{ display: 'flex' }}>
							<Typography
								variant="subtitle1"
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...additionalProps}
							>
								{subtitle}
							</Typography>
						</Box>
					)}
					{subheader && (
						// eslint-disable-next-line react/jsx-props-no-spreading
						<Typography variant="body2" {...additionalProps}>
							{subheader}
						</Typography>
					)}
				</Box>
				{stat !== undefined && (
					<Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
						<Typography variant="body2">{t('stat')}</Typography>
						<img alt="" src={stat ? StatFlag : StatFlagEmpty} width="15" />
					</Box>
				)}
			</Box>
			{onChangeEncounterClick && (
				<Box sx={{ display: 'flex', alignItems: 'center', height: 24 }}>
					<Button
						onClick={handleChangeEncounterClick}
						disabled={changeEncounterDisabled}
						size="small"
						sx={{ ml: 8.5, pt: 0, fontSize: '.5rem' }}
					>
						{t('changeEncounter')}
					</Button>
					{encountersLoading && (
						<Box sx={{ ml: 1 }}>
							<Spinner size={16} />
						</Box>
					)}
				</Box>
			)}
			{onPatientChangeClick && !noPatientSelected && (
				<Box sx={{ display: 'flex', alignItems: 'center', height: 24 }}>
					<Button
						onClick={handlePatientChangeClick}
						size="small"
						sx={{ ml: 8.5, pt: 0, fontSize: '.5rem' }}
						disabled={changePatientDisabled}
					>
						{t('changePatient')}
					</Button>
				</Box>
			)}
			{onPatientUpdateClick && (
				<Box sx={{ display: 'flex', alignItems: 'center', height: 24 }}>
					<Button onClick={onPatientUpdateClick} size="small" sx={{ ml: 8.5, pt: 0, fontSize: '.5rem' }}>
						{t('updatePatient')}
					</Button>
				</Box>
			)}
		</>
	);
}

PatientCardHeader.defaultProps = {
	stat: undefined,
	noEncounterSelected: false,
	noPatientSelected: false,
};

export default PatientCardHeader;
