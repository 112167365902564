import React, { useEffect, useState } from 'react';
import { Alert, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { namespaces } from '../../application/i18n.constants';
import SignInForm from '../../components/singin/SignInForm';
import PageContainer from '../../components/page-container/PageContainer';
import ConfirmCodeStep from '../../components/singin/ConfirmCodeStep';

export interface ISignInPageProps {
	onSingIn: (username: string, password: string, pin?: string) => void;
	signInError: string | undefined;
	loading: boolean;
	mfaRequired: boolean;
	invalidPin: boolean;
	emailAddress: string;
	appVersion: string;
}

function SignInPage({
	onSingIn,
	mfaRequired,
	invalidPin,
	emailAddress,
	signInError,
	loading,
	appVersion,
}: ISignInPageProps) {
	const { t } = useTranslation(namespaces.pages.signIn);
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [code, setCode] = useState('');
	const [isCodeInvalid, setIsCodeInvalid] = useState(invalidPin);

	useEffect(() => {
		if (invalidPin) {
			setIsCodeInvalid(true);
			setCode('');
		}
	}, [invalidPin]);

	const onSingInInCallback = () => {
		onSingIn(username, password, code);
	};

	const onCredentialsChange = (u: string, p: string) => {
		setUsername(u);
		setPassword(p);
	};

	const onCodeChange = (c: string) => {
		setCode(c);
		setIsCodeInvalid(false);
	};

	return (
		<PageContainer
			navbar={false}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<Typography component="h1" variant="h5">
				{t('signIn')}
			</Typography>
			{mfaRequired ? (
				<>
					<Typography sx={{ width: 320 }} align="center">
						{t(`confirmMfaCode`, { emailAddress })}
					</Typography>
					{isCodeInvalid ? (
						<Alert sx={{ width: 320 }} severity="error">
							{t('invalidConfirmationCode')}
						</Alert>
					) : null}
					<ConfirmCodeStep
						code={code}
						onConfirmCode={() => onSingInInCallback()}
						setCode={onCodeChange}
						loading={loading}
					/>
				</>
			) : (
				<SignInForm
					signInError={signInError}
					loading={loading}
					onSingIn={() => onSingInInCallback()}
					onCredentialsChange={onCredentialsChange}
				/>
			)}
			<Box
				sx={{
					position: 'absolute',
					zIndex: 1,
					right: '0.5rem',
					bottom: '0.5rem',
				}}
			>
				<Typography variant="overline">{appVersion}</Typography>
			</Box>
		</PageContainer>
	);
}

export default SignInPage;
