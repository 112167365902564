import { createAction } from '@reduxjs/toolkit';
import { adminConstants } from './admin.constants';
import { IMacro } from '../../models/macros/macros.models';
import { IProvider } from '../../models/providers/providers.models';
import { IWordReplacement } from '../../models/replacements/replacements.models';

// CUSTOMER MACROS
const getCustomerMacrosAction = createAction(adminConstants.GET_CUSTOMER_MACROS);

const setCustomerMacrosIsLoading = createAction<boolean>(adminConstants.SET_CUSTOMER_MACROS_IS_LOADING);
const setCustomerMacros = createAction<IMacro[]>(adminConstants.SET_CUSTOMER_MACROS);
const setSaveCustomerMacroIsLoading = createAction<boolean>(adminConstants.SET_SAVE_CUSTOMER_MACRO_IS_LOADING);

export interface ISaveCustomerMacroActionPayload {
	section: string;
	shortCode: string;

	description: string;
	macro: string;
	spokenForm: string;
}
const saveCustomerMacro = createAction<ISaveCustomerMacroActionPayload>(adminConstants.SAVE_CUSTOMER_MACRO);

export interface IUpdateCustomerMacroActionPayload {
	macroId: number;
	section: string;

	macro: string;
	spokenForm: string;
}
const updateCustomerMacro = createAction<IUpdateCustomerMacroActionPayload>(adminConstants.UPDATE_CUSTOMER_MACRO);

export interface IDeleteCustomerMacroActionPayload {
	macroId: number;
}
const deleteCustomerMacro = createAction<IDeleteCustomerMacroActionPayload>(adminConstants.DELETE_CUSTOMER_MACRO);

export interface IImportCustomerMacrosActionPayload {
	customerId: string;
	file: File;
}
const importCustomerMacrosAction = createAction<IImportCustomerMacrosActionPayload>(
	adminConstants.IMPORT_CUSTOMER_MACRO
);

// PROVIDER MACROS
export interface IGetProviderMacrosActionPayload {
	providerId: string;
}
const getProviderMacrosAction = createAction<IGetProviderMacrosActionPayload>(adminConstants.GET_PROVIDER_MACROS);

const setProviderMacrosIsLoading = createAction<boolean>(adminConstants.SET_PROVIDER_MACROS_IS_LOADING);
const setProviderMacros = createAction<IMacro[]>(adminConstants.SET_PROVIDER_MACROS);
const setSaveProviderMacroIsLoading = createAction<boolean>(adminConstants.SET_SAVE_PROVIDER_MACRO_IS_LOADING);

export interface ISaveProviderMacroActionPayload {
	providerId: string;

	section: string;
	shortCode: string;

	description: string;
	macro: string;
	spokenForm: string;
}
const saveProviderMacro = createAction<ISaveProviderMacroActionPayload>(adminConstants.SAVE_PROVIDER_MACRO);

export interface IUpdateProviderMacroActionPayload {
	macroId: number;
	providerId: string;

	section: string;
	macro: string;
	spokenForm: string;
}
const updateProviderMacro = createAction<IUpdateProviderMacroActionPayload>(adminConstants.UPDATE_PROVIDER_MACRO);

export interface IDeleteProviderMacroActionPayload {
	macroId: number;
}
const deleteProviderMacro = createAction<IDeleteProviderMacroActionPayload>(adminConstants.DELETE_PROVIDER_MACRO);

const getProvidersAction = createAction(adminConstants.GET_PROVIDERS);
const setProvidersIsLoadingAction = createAction<boolean>(adminConstants.SET_PROVIDERS_IS_LOADING);

interface ISetProvidersActionPayload {
	providers: IProvider[];
}

const setProvidersAction = createAction<ISetProvidersActionPayload>(adminConstants.SET_PROVIDERS);

export interface IImportProviderMacrosActionPayload {
	providerId: string;
	file: File;
}

const importProviderMacrosAction = createAction<IImportProviderMacrosActionPayload>(
	adminConstants.IMPORT_PROVIDER_MACRO
);

export interface ICopyProviderMacrosActionPayload {
	providerId: string;
	macros: IMacro[];
}
const copyProviderMacrosAction = createAction<ICopyProviderMacrosActionPayload>(adminConstants.COPY_PROVIDER_MACROS);
// PROVIDER WORD REPLACEMENTS

export interface IGetProviderWordReplacementsActionPayload {
	providerId: string;
}
const getProviderWordReplacements = createAction<IGetProviderWordReplacementsActionPayload>(
	adminConstants.GET_PROVIDER_WORD_REPLACEMENTS
);

const setProviderWordReplacementsIsLoading = createAction<boolean>(
	adminConstants.SET_PROVIDER_WORD_REPLACEMENTS_IS_LOADING
);
const setProviderWordReplacements = createAction<IWordReplacement[]>(adminConstants.SET_PROVIDER_WORD_REPLACEMENTS);

export interface ISaveProviderWordReplacementActionPayload {
	providerId: string;
	word: string;
	wordReplacement: string;
}
const saveProviderWordReplacement = createAction<ISaveProviderWordReplacementActionPayload>(
	adminConstants.SAVE_PROVIDER_WORD_REPLACEMENT
);
const setSaveProviderWordReplacementIsLoading = createAction<boolean>(
	adminConstants.SET_SAVE_PROVIDER_WORD_REPLACEMENT_IS_LOADING
);

export interface IUpdateProviderWordReplacementActionPayload {
	id: string;
	providerId: string;
	word: string;
	wordReplacement: string;
}
const updateProviderWordReplacement = createAction<IUpdateProviderWordReplacementActionPayload>(
	adminConstants.UPDATE_PROVIDER_WORD_REPLACEMENT
);

export interface IDeleteProviderWordReplacementActionPayload {
	id: string;
}
const deleteProviderWordReplacement = createAction<IDeleteProviderWordReplacementActionPayload>(
	adminConstants.DELETE_PROVIDER_WORD_REPLACEMENT
);

// CUSTOMER WORD REPLACEMENTS
export interface IGetCustomerWordReplacementsActionPayload {
	customerId: string;
}
const getCustomerWordReplacements = createAction<IGetCustomerWordReplacementsActionPayload>(
	adminConstants.GET_CUSTOMER_WORD_REPLACEMENTS
);

const setCustomerWordReplacementsIsLoading = createAction<boolean>(
	adminConstants.SET_CUSTOMER_WORD_REPLACEMENTS_IS_LOADING
);
const setCustomerWordReplacements = createAction<IWordReplacement[]>(adminConstants.SET_CUSTOMER_WORD_REPLACEMENTS);

export interface ISaveCustomerWordReplacementActionPayload {
	customerId: string;
	word: string;
	wordReplacement: string;
}

const saveCustomerWordReplacement = createAction<ISaveCustomerWordReplacementActionPayload>(
	adminConstants.SAVE_CUSTOMER_WORD_REPLACEMENT
);

export interface IUpdateCustomerWordReplacementActionPayload {
	id: string;
	customerId: string;
	word: string;
	wordReplacement: string;
}

const updateCustomerWordReplacement = createAction<IUpdateCustomerWordReplacementActionPayload>(
	adminConstants.UPDATE_CUSTOMER_WORD_REPLACEMENT
);
const setSaveCustomerWordReplacementIsLoading = createAction<boolean>(
	adminConstants.SET_SAVE_CUSTOMER_WORD_REPLACEMENT_IS_LOADING
);

export interface IDeleteCustomerWordReplacementActionPayload {
	id: string;
}
const deleteCustomerWordReplacement = createAction<IDeleteCustomerWordReplacementActionPayload>(
	adminConstants.DELETE_CUSTOMER_WORD_REPLACEMENT
);

export const adminActions = {
	getCustomerMacrosAction,
	setCustomerMacrosIsLoading,
	setCustomerMacros,
	saveCustomerMacro,
	setSaveCustomerMacroIsLoading,
	updateCustomerMacro,
	deleteCustomerMacro,
	importCustomerMacrosAction,
	copyProviderMacrosAction,

	getProviderMacrosAction,
	setProviderMacrosIsLoading,
	setProviderMacros,
	saveProviderMacro,
	setSaveProviderMacroIsLoading,
	updateProviderMacro,
	deleteProviderMacro,
	importProviderMacrosAction,

	getProvidersAction,
	setProvidersIsLoadingAction,
	setProvidersAction,

	getProviderWordReplacements,
	setProviderWordReplacementsIsLoading,
	setProviderWordReplacements,
	saveProviderWordReplacement,
	updateProviderWordReplacement,
	setSaveProviderWordReplacementIsLoading,
	deleteProviderWordReplacement,

	getCustomerWordReplacements,
	setCustomerWordReplacementsIsLoading,
	setCustomerWordReplacements,
	saveCustomerWordReplacement,
	updateCustomerWordReplacement,
	setSaveCustomerWordReplacementIsLoading,
	deleteCustomerWordReplacement,
};
