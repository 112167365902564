import * as React from 'react';
import { ReactNode } from 'react';
import { Box, SxProps, Typography } from '@mui/material';
import { Theme } from '@mui/system';
import IScribeLogo from '../navbar/iscribe-logo.svg';

function DictationPageHeader({ children, sx, logo }: { children?: ReactNode; sx?: SxProps<Theme>; logo?: boolean }) {
	return (
		<Box
			sx={{
				display: 'flex',
				width: '100%',
				height: '4rem',
				p: 2,
				alignItems: 'center',
				justifyContent: 'space-between',
				backgroundColor: 'primary.main',
				...sx,
			}}
		>
			{logo ? (
				<Typography
					variant="h6"
					noWrap
					component="a"
					href="/"
					sx={{
						mr: 2,
						display: { xs: 'none', md: 'flex' },
						fontFamily: 'monospace',
						fontWeight: 700,
						letterSpacing: '.3rem',
						color: 'inherit',
						textDecoration: 'none',
					}}
				>
					<img src={IScribeLogo} alt="iScribe logo" />
				</Typography>
			) : null}
			{children}
		</Box>
	);
}

export default DictationPageHeader;
