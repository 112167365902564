import * as React from 'react';
import { useState } from 'react';
import { InputBase, Popper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { alpha, styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { namespaces } from '../../application/i18n.constants';
import NavbarSearchDropdown from './NavbarSearchDropdown';

const Search = styled('div')(({ theme }) => ({
	position: 'relative',
	borderRadius: theme.shape.borderRadius,
	backgroundColor: alpha(theme.palette.common.white, 0.15),
	'&:hover': {
		backgroundColor: alpha(theme.palette.common.white, 0.25),
	},
	marginLeft: 0,
	width: '100%',
	[theme.breakpoints.up('sm')]: {
		marginLeft: theme.spacing(1),
		width: 'auto',
	},
}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
	padding: theme.spacing(0, 2),
	height: '100%',
	position: 'absolute',
	pointerEvents: 'none',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
	color: 'inherit',
	'& .MuiInputBase-input': {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)})`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: '12ch',
			'&:focus': {
				width: '20ch',
			},
		},
	},
}));

interface INavbarSearchProps {
	value?: string;
	onPatientSearch?: (query: string) => void;
	onJobsSearch?: (query: string) => void;
	onInput?: (query: string) => void;
}

const StyledPopper = styled(Popper)(({ theme }) => ({
	border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
	boxShadow: `0 8px 24px ${theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'}`,
	borderRadius: 6,
	width: 300,
	zIndex: theme.zIndex.modal,
	fontSize: 13,
	color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
	backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
}));

function NavbarSearch({ value, onPatientSearch, onJobsSearch, onInput }: INavbarSearchProps) {
	const { t } = useTranslation(namespaces.components.navbar);
	const [searchQuery, setSearchQuery] = useState<string>(value !== undefined ? value : '');
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [searchPopper, setSearchPopperOpen] = useState<boolean>(false);

	const handlePatientSearch = (e?: React.FormEvent<HTMLFormElement>) => {
		if (e) e.preventDefault();
		if (onPatientSearch) {
			onPatientSearch(searchQuery);
		}
		setSearchPopperOpen(false);
	};

	const handleJobsSearch = () => {
		setSearchPopperOpen(false);
		if (onJobsSearch) {
			onJobsSearch(searchQuery);
		}
	};

	React.useEffect(() => {
		if (searchQuery) {
			setSearchPopperOpen(true);
		} else {
			setSearchPopperOpen(false);
		}
	}, [searchQuery]);

	return (
		<form onSubmit={handlePatientSearch}>
			<Search>
				<SearchIconWrapper>
					<SearchIcon />
				</SearchIconWrapper>
				<StyledInputBase
					onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
						setSearchQuery(e.target.value);
						if (!anchorEl) {
							setAnchorEl(e.currentTarget);
						}
						if (onInput) {
							onInput(e.target.value);
						}
					}}
					placeholder={t('search')}
					onBlur={() => {
						setSearchPopperOpen(false);
					}}
					onFocus={(e) => {
						if (!anchorEl) {
							setAnchorEl(e.currentTarget);
						}
						setSearchPopperOpen(true);
					}}
					value={value !== undefined ? value : searchQuery}
					inputProps={{ 'aria-label': 'search' }}
				/>
				<StyledPopper id="search-dropdown" open={searchPopper} anchorEl={anchorEl} placement="bottom-start">
					<NavbarSearchDropdown
						handlePatientsSearch={handlePatientSearch}
						handleJobsSearch={handleJobsSearch}
						searchQuery={searchQuery}
					/>
				</StyledPopper>
			</Search>
		</form>
	);
}

NavbarSearch.defaultProps = {
	onInput: undefined,
};

export default NavbarSearch;
