import * as React from 'react';
import { createRef, ForwardedRef, forwardRef, RefObject, useEffect, useImperativeHandle, useState } from 'react';
import { Alert, Box, Input } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { namespaces } from '../../application/i18n.constants';
import { useAppSelector } from '../../core/core.types';
import {
	IBillingCode,
	IBillingModifier,
	IDiagnoseField,
	IDiagnoseFields,
	IInterpretationField,
	IOrderField,
	IOrderSet,
	IProcedureCode,
	IProcedureDocumentationField,
	IProcedureDocumentationTemplate,
} from '../../core/models/dictations/dictations.models';
import SelectEncounters from '../encounters/SelectEncounters';
import { IPatientEncounter } from '../../core/models/patients/patients.models';
import DiagnosisTextField, { ITextField } from './DiagnosisTextField';
import DiagnosisField, { DiagnoseFieldType, DiagnoseTextFieldType } from './DiagnosisField';
import {
	addDiagnoseToFields,
	addOrderToDiagnoseFields,
	deleteDiagnoseOrderFromFields,
	diagHash,
	getICD10Codes,
	onDeleteDiagnoseFromFields,
	orderHash,
} from '../../core/services/documents/documents.services';
import OrdersList from './diagnose-list/OrdersList';
import ProcedureDocumentationDialog from '../diagnoses-and-orders/ProcedureDocumentationDialog';
import ProcedureDocumentationList from '../procedure-documentation/ProcedureDocumentationList';
import PullAssessmentPlanDialog from '../pull-assessment-plan-dialog/PullAssessmentPlanDialog';
import {
	addBillingServices,
	billingHash,
	isBillingServiceSelected,
} from '../../core/services/billingCodes/billing-codes.services';
import Spinner from '../spinner/Spinner';
import { IMacro, MacroSection } from '../../core/models/macros/macros.models';
import { IAutocompleteProps } from '../dot-phrases-popper/autocomplete.utils';
import { DiagnoseLaterality } from '../../core/models/dictations/document.models';
import EhrDiagnosisList from './diagnose-list/EhrDiagnosisList';
import { EHRType } from '../../core/models/ehr.models';
import EhrDiagnosesAndOrdersDialog from '../diagnoses-and-orders/EhrDiagnosesAndOrdersDialog';
import DiagnosisTextFieldWrapper from './DiagnosisTextFieldWrapper';
import EhrBillingServicesSection from '../billing-services/EhrBillingServicesSection';
import EhrBillingServicesDialog from '../billing-services/EhrBillingServicesDialog';

export interface IDiagnosis {
	focusTextField: (textFieldId: DiagnoseTextFieldType) => void;
}

export interface IDiagnoseError {
	field: 'diagnose';
	type: 'lateralityRequired';
	snomed: string;
}

export type DiagnosisFeatureType = 'addDiagnosis';
export interface IDiagnosisProps {
	ehr: EHRType;
	activeInputs: DiagnoseFieldType[];
	disabledFeatures?: DiagnosisFeatureType[];

	currentEncounter: IPatientEncounter;
	encountersIsLoading: boolean;
	encounters: IPatientEncounter[];

	fields: IFields;
	onChange: (fields: IFields) => void;
	onDiagnosisRankingChanged: () => void;

	diagnoseErrors?: IDiagnoseError[];

	isPullLoading: boolean;
	onPull?: (fieldId: string, encounter: IPatientEncounter) => void;
	pulledDiagnoses: IDiagnoseField[] | null;
	onCloseDiagnosesPull: () => void;
	onMergePulledAssessmentPlan: (diagnoses: IDiagnoseField[]) => void;

	onApplyOrderSet: (orderSet: { orderSetId: number; diagnoseHash?: string }) => void;

	unassignedOrders: IOrderField[];
	onUnassignedOrdersChange: (unsavedOrders: IOrderField[]) => void;

	billingLoaded: boolean;
	billingClaimCreated: boolean;
	billingCodes: IBillingCode[];
	handleBillingCodesChange: (codes: IBillingCode[]) => void;

	billingModifiersLoading: boolean;
	billingModifiers: IBillingModifier[];

	codeSearchIsLoading: boolean;
	procedureCodeSearchResults: IProcedureCode[];

	saveProcedureDocumentationIsLoading: boolean;
	applyProcedureTemplateIsLoading: boolean;
	onApplyProcedureDocumentationTemplate: (template: IProcedureDocumentationTemplate) => void;
	onSaveProcedureDocumentations: (procedureDocumentation: IProcedureDocumentationField[]) => void;

	onTextFieldFocusChanged: (
		event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
		fieldId: DiagnoseTextFieldType,
		focus: boolean
	) => void;

	providerDotPhrases: IMacro[];

	settings: {
		viewBillingServicesEnabled: boolean;
		editBillingServicesEnabled: boolean;
		editProcedureDocumentationEnabled: boolean;
		saveOrderInterpretationsEnabled: boolean;
	};
}

interface IFields extends IDiagnoseFields {}

const Diagnosis: React.ForwardRefRenderFunction<IDiagnosis, IDiagnosisProps> = function DiagnosisFunc(
	{
		ehr,
		activeInputs,
		disabledFeatures,
		currentEncounter,
		encountersIsLoading,
		encounters,
		fields,
		diagnoseErrors,
		onChange,
		onDiagnosisRankingChanged,
		isPullLoading,
		onPull,
		pulledDiagnoses,
		onCloseDiagnosesPull,
		onMergePulledAssessmentPlan,
		onApplyOrderSet,
		unassignedOrders,
		onUnassignedOrdersChange,
		billingLoaded,
		billingClaimCreated,
		billingCodes,
		handleBillingCodesChange,
		billingModifiersLoading,
		codeSearchIsLoading,
		procedureCodeSearchResults,
		billingModifiers,
		saveProcedureDocumentationIsLoading,
		applyProcedureTemplateIsLoading,
		onApplyProcedureDocumentationTemplate,
		onSaveProcedureDocumentations,
		onTextFieldFocusChanged,
		providerDotPhrases,
		settings,
	}: IDiagnosisProps,
	forwardedRef: ForwardedRef<IDiagnosis>
) {
	const { t } = useTranslation(namespaces.components.diagnosis);

	const applyOrderSetIsLoading = useAppSelector<boolean>((state) => state.worklist.applyOrderSetIsLoading);

	const [contextPullField, setContextPullField] = useState<string | null>(null);
	const [contextDiagnose, setContextDiagnose] = useState<IDiagnoseField | null>(null);
	const [contextOrder, setContextOrder] = useState<IOrderField | null>(null);

	const [openSelectEncounter, setOpenSelectEncounter] = useState<boolean>(false);
	const [openSelectDiagnose, setOpenSelectDiagnose] = useState<boolean>(false);
	const [openBillingServices, setOpenBillingServices] = useState<boolean>(false);
	const [openProcedureDocumentationTemplates, setOpenProcedureDocumentationTemplates] = useState<boolean>(false);

	const [isLoading, setIsLoading] = useState<boolean>(isPullLoading);

	const [textFieldsRefs] = useState<{ [fieldId: string]: RefObject<ITextField> }>({
		hpiSketchPad: createRef(),
		rosSketchPad: createRef(),
		peSketchPad: createRef(),
		patientGoals: createRef(),
		patientInstructions: createRef(),
		discussionNotes: createRef(),
		assessmentPlan: createRef(),
		assessment: createRef(),
		plan: createRef(),
	});

	useImperativeHandle(forwardedRef, () => ({
		focusTextField: (textFieldId: DiagnoseTextFieldType) => {
			const textFieldRef = textFieldsRefs[textFieldId];
			textFieldRef?.current?.focusWithCursorAtEnd();
		},
	}));

	useEffect(() => {
		if (isLoading && !isPullLoading) {
			setOpenSelectEncounter(false);
		}
		setIsLoading(isPullLoading);
	}, [isPullLoading]);

	const icd10Codes: string[] = getICD10Codes(fields.diagnoses);
	const isFieldActive = (fieldId: DiagnoseFieldType): boolean => !!activeInputs.find((x) => x === fieldId);
	const isFeatureActive = (feature: DiagnosisFeatureType): boolean =>
		!disabledFeatures || !disabledFeatures.find((x) => x === feature);

	const onPullEncounterSelect = (encounter: IPatientEncounter) => {
		if (contextPullField && onPull) {
			onPull(contextPullField, encounter);
		}
	};

	const onTextValueChanged = ({ key, value }: { key: DiagnoseTextFieldType; value: string }) => {
		const newFields: IFields = { ...fields };
		newFields[key] = value;
		onChange(newFields);
	};

	const onPullField = (fieldId: string) => {
		setContextPullField(fieldId);
		setOpenSelectEncounter(true);
	};

	const onPullEncounter: ((fieldId: string) => void) | undefined = onPull ? onPullField : undefined;

	const onCloseDiagnosesPullHandler = () => {
		onCloseDiagnosesPull();
	};

	const onMergePulledAssessmentPlanHandler = (diagnoses: IDiagnoseField[]) => {
		onMergePulledAssessmentPlan(diagnoses);
		onCloseDiagnosesPullHandler();
	};

	const onAddField = isFeatureActive('addDiagnosis')
		? (fieldId: string) => {
				setContextPullField(fieldId);
				setOpenSelectDiagnose(true);
		  }
		: undefined;

	const onCloseEncounterSelect = () => {
		setOpenSelectEncounter(false);
	};

	const onCloseDiagnoseSelect = () => {
		setOpenSelectDiagnose(false);
		setContextDiagnose(null);
		setContextOrder(null);
	};

	const onCloseBillingServices = () => {
		setOpenBillingServices(false);
	};

	const onOpenBillingServices = () => {
		setOpenBillingServices(true);
	};

	const onCloseProcedureDocumentationTemplates = () => {
		setOpenProcedureDocumentationTemplates(false);
	};

	const onOpenProcedureDocumentationTemplates = () => {
		setOpenProcedureDocumentationTemplates(true);
	};

	const onDiagnoseSelect = (diagnose: IDiagnoseField) => {
		onChange(addDiagnoseToFields(fields, diagnose));
	};

	const onDeleteDiagnose = (hash: string) => {
		const contextFields = onDeleteDiagnoseFromFields(fields, hash);
		onChange(contextFields);
	};

	const onSelectDiagnoseOrder = (dHash: string, order: IOrderField) => {
		onChange(addOrderToDiagnoseFields(fields, dHash, order));
	};

	const onDeleteDiagnoseOrder = (dHash: string, oHash: string) => {
		onChange(deleteDiagnoseOrderFromFields(fields, dHash, oHash));
	};

	const onDiagnoseNoteChanged = (hash: string, text: string) => {
		const diagnoses = cloneDeep(fields.diagnoses);
		const diagnose = diagnoses.filter((x) => x.modified !== 2).find((x) => diagHash(x) === hash);
		if (diagnose) {
			diagnose.note = text;
			diagnose.modified = diagnose.diagnosisId ? 3 : 1;
			onChange({ ...fields, diagnoses });
		}
	};

	const onProblemChange = (hash: string, problem: boolean) => {
		const diagnoses = cloneDeep(fields.diagnoses);
		const diagnose = diagnoses.filter((x) => x.modified !== 2).find((x) => diagHash(x) === hash);

		if (diagnose) {
			diagnose.addToProblemList = problem;
			diagnose.modified = diagnose.diagnosisId ? 3 : 1;
			onChange({ ...fields, diagnoses });
		}
	};

	const onLateralityChange = (hash: string, laterality: DiagnoseLaterality | undefined) => {
		const diagnoses = cloneDeep(fields.diagnoses);
		const diagnose = diagnoses.filter((x) => x.modified !== 2).find((x) => diagHash(x) === hash);

		if (diagnose) {
			diagnose.laterality = laterality;
			diagnose.modified = diagnose.diagnosisId ? 3 : 1;
			onChange({ ...fields, diagnoses });
		}
	};

	const onChangeRanking = (dHash: string, swapDHash: string) => {
		const diagnoses = cloneDeep(fields.diagnoses);
		const diagIndex = diagnoses.findIndex((x) => diagHash(x) === dHash);
		const newDiagIndex = diagnoses.findIndex((x) => diagHash(x) === swapDHash);

		if (
			diagIndex < 0 ||
			diagIndex >= diagnoses.length ||
			newDiagIndex < 0 ||
			newDiagIndex >= diagnoses.length ||
			newDiagIndex === diagIndex
		) {
			return;
		}

		const tempRank = diagnoses[diagIndex].ranking;
		diagnoses[diagIndex].ranking = diagnoses[newDiagIndex].ranking;
		diagnoses[newDiagIndex].ranking = tempRank;

		onChange({ ...fields, diagnoses });
		onDiagnosisRankingChanged();
	};

	const onDiagnoseOrderInterpretationChange = (dHash: string, oHash: string, interpretation: IInterpretationField) => {
		const diagnoses = cloneDeep(fields.diagnoses);
		const diagnose = diagnoses.find((x) => diagHash(x) === dHash);
		if (diagnose) {
			const order = diagnose.orders.find((x) => orderHash(x) === oHash);
			if (order && order.interpretation) {
				diagnose.modified = diagnose.diagnosisId ? 3 : 1;
				order.interpretation.note = interpretation.note;
				order.interpretation.discussedWithPatient = interpretation.discussedWithPatient;
				order.modified = order.orderId ? 3 : 1;
				onChange({ ...fields, diagnoses });
			}
		}
	};

	const onOrderInterpretationChange = (oHash: string, interpretation: IInterpretationField) => {
		const orders = cloneDeep(unassignedOrders);
		const order = orders.find((x) => orderHash(x) === oHash);
		if (order && order.interpretation) {
			order.interpretation.note = interpretation.note;
			order.interpretation.discussedWithPatient = interpretation.discussedWithPatient;
			onUnassignedOrdersChange(orders);
		}
	};

	const onAddOrder = (dHash: string) => {
		const diagnose = fields.diagnoses.find((x) => diagHash(x) === dHash);
		setContextDiagnose(diagnose || null);
		setOpenSelectDiagnose(true);
	};

	const onSelectOrder = (order: IOrderField) => {
		onUnassignedOrdersChange([...unassignedOrders, order]);
	};

	const onDeleteOrder = (oHash: string) => {
		onUnassignedOrdersChange(unassignedOrders.filter((x) => orderHash(x) !== oHash));
	};

	const onAddDiagnoseForOrder = (oHash: string) => {
		const order = unassignedOrders.find((x) => orderHash(x) === oHash);
		if (order) {
			setOpenSelectDiagnose(true);
			setContextOrder(order);
		}
	};

	const onSelectDiagnoseForOrder = (oHash: string, diagnose: IDiagnoseField) => {
		const order = unassignedOrders.find((x) => orderHash(x) === oHash);

		if (order) {
			let contextFields = addDiagnoseToFields(fields, diagnose);
			contextFields = addOrderToDiagnoseFields(contextFields, diagHash(diagnose), order);
			onDeleteOrder(orderHash(order));
			onCloseDiagnoseSelect();
			onChange(contextFields);
		}
	};

	const onSelectOrderSet = (orderSet: IOrderSet) => {
		onApplyOrderSet({
			orderSetId: orderSet.orderSetId,
			diagnoseHash: contextDiagnose ? diagHash(contextDiagnose) : undefined,
		});
		onCloseDiagnoseSelect();
	};

	const onSelectProcedureDocumentation = (template: IProcedureDocumentationTemplate) => {
		onApplyProcedureDocumentationTemplate(template);
	};

	const onDeleteProcedureDocumentation = (templateId: string) => {
		const procedureDocumentation = fields.procedureDocumentation
			.filter((x) => x.modified !== 2)
			.find((x) => x.procedureTemplateId === templateId);

		if (procedureDocumentation) {
			procedureDocumentation.modified = 2;
			onChange(fields);
			onSaveProcedureDocumentations(fields.procedureDocumentation);
		}
	};

	const onChangeProcedureDocumentation = (templateId: string, templateData: string, templateText: string) => {
		const procedureDocumentation = fields.procedureDocumentation
			.filter((x) => x.modified !== 2)
			.find((x) => x.procedureTemplateId === templateId);

		if (procedureDocumentation && settings.editProcedureDocumentationEnabled) {
			procedureDocumentation.templateData = templateData;
			procedureDocumentation.procedureDocumentationText = templateText;
			procedureDocumentation.modified = procedureDocumentation.modified === 1 ? 1 : 3;
			onChange(fields);
		}
	};

	const isProcedureDocumentations = fields.procedureDocumentation.filter((x) => x.modified !== 2).length > 0;

	const hasChangesThatMayAffectBillingServicesData = () => {
		const hasOrdersChangesThatMayAffectBillingServicesData: boolean =
			fields.diagnoses.filter((x) => x.modified === 1 || x.modified === 2).length > 0;

		const hasProcedureDocumentationChangesThatMayAffectBillingServicesData: boolean =
			fields.procedureDocumentation.filter((x) => x.modified === 1 || x.modified === 2).length > 0;

		return (
			hasOrdersChangesThatMayAffectBillingServicesData ||
			hasProcedureDocumentationChangesThatMayAffectBillingServicesData
		);
	};

	const onAddBillingCode = (procedureCode: string) => {
		handleBillingCodesChange(addBillingServices(billingCodes, icd10Codes, procedureCode));
		setOpenBillingServices(false);
	};

	const onBillingModifierSelect = (code: IBillingCode, modifier: IBillingModifier) => {
		const codes = cloneDeep(billingCodes);
		const billingCode = codes
			.filter((x) => isBillingServiceSelected(x))
			.find((x) => billingHash(x) === billingHash(code));
		if (billingCode) {
			billingCode.modified = billingCode.modified === 1 ? 1 : 3;
			billingCode.modifiers = [...billingCode.modifiers, modifier];
			handleBillingCodesChange(codes);
		}
	};

	const onBillingModifierDelete = (code: IBillingCode, modifier: IBillingModifier) => {
		const codes = cloneDeep(billingCodes);
		const billingCode = codes
			.filter((x) => isBillingServiceSelected(x))
			.find((x) => billingHash(x) === billingHash(code));
		if (billingCode) {
			billingCode.modified = billingCode.modified === 1 ? 1 : 3;
			billingCode.modifiers = billingCode.modifiers.filter((a) => a.modifierID !== modifier.modifierID);
			handleBillingCodesChange(codes);
		}
	};

	const onBillingCodeDelete = (code: IBillingCode) => {
		const codes = cloneDeep(billingCodes);
		const billingCode = codes.find((x) => isBillingServiceSelected(x) && billingHash(x) === billingHash(code));

		if (billingCode) {
			if (billingCode.serviceID) {
				billingCode.modified = 2;
				handleBillingCodesChange(codes);
			} else {
				handleBillingCodesChange(codes.filter((x) => x.procedureCode !== code.procedureCode));
			}
		} else {
			handleBillingCodesChange(codes);
		}
	};

	const onBillingCodeUnitChange = (code: IBillingCode, units: number | null) => {
		const codes = cloneDeep(billingCodes);
		const billingCode = codes
			.filter((x) => isBillingServiceSelected(x))
			.find((x) => billingHash(x) === billingHash(code));
		if (billingCode) {
			billingCode.modified = billingCode.modified === 1 ? 1 : 3;
			billingCode.units = units;
			handleBillingCodesChange(codes);
		}
	};

	const onIcd10CodeSelect = (code: IBillingCode, icd10Code: string) => {
		const codes = cloneDeep(billingCodes);
		const billingCode = codes
			.filter((x) => isBillingServiceSelected(x))
			.find((x) => billingHash(x) === billingHash(code));
		if (billingCode) {
			billingCode.modified = billingCode.modified === 1 ? 1 : 3;
			billingCode.iCD10Codes = [...billingCode.iCD10Codes, icd10Code];
			handleBillingCodesChange(codes);
		}
	};

	const onIcd10CodeDelete = (code: IBillingCode, icd10Code: string) => {
		const codes = cloneDeep(billingCodes);
		const billingCode = codes
			.filter((x) => isBillingServiceSelected(x))
			.find((x) => billingHash(x) === billingHash(code));
		if (billingCode) {
			billingCode.modified = billingCode.modified === 1 ? 1 : 3;
			billingCode.iCD10Codes = billingCode.iCD10Codes.filter((x) => x !== icd10Code);
			handleBillingCodesChange(codes);
		}
	};

	const onApplyAllIcdCodes = (codes: IBillingCode[]) => {
		const bCodes = cloneDeep(billingCodes);
		const codesToUpdate = bCodes
			.filter((code) => codes.find((x) => billingHash(x) === billingHash(code)))
			.filter((code) => isBillingServiceSelected(code));

		// eslint-disable-next-line no-plusplus
		for (let i = 0; i < codesToUpdate.length; i++) {
			codesToUpdate[i].modified = codesToUpdate[i].modified === 1 ? 1 : 3;
			codesToUpdate[i].iCD10Codes = [...icd10Codes];
		}
		handleBillingCodesChange(bCodes);
	};

	const onBillForServiceToggle = (code: IBillingCode, bill: boolean) => {
		const codes = cloneDeep(billingCodes);
		const billingCode = codes
			.filter((x) => isBillingServiceSelected(x))
			.find((x) => billingHash(x) === billingHash(code));

		if (billingCode) {
			billingCode.modified = billingCode.modified === 1 ? 1 : 3;
			billingCode.billForService = bill;
			handleBillingCodesChange(codes);
		}
	};

	const onSelectBillingCode = (code: IBillingCode, select: boolean) => {
		const codes = cloneDeep(billingCodes);
		const billingCode = codes
			.filter((x) => x.modified !== 2 || (x.serviceID && x.modified === 2))
			.find((x) => billingHash(x) === billingHash(code));

		if (billingCode) {
			billingCode.isSelected = select;
			if (select) {
				billingCode.modified = 3;
			} else {
				billingCode.modified = billingCode.serviceID ? 2 : 0;
			}
			handleBillingCodesChange(codes);
		}
	};

	const getAutocompleteOptionsFromDotPhrases = (
		section: MacroSection,
		dotPhrases: IMacro[],
		additionalSections?: MacroSection[]
	): IAutocompleteProps => {
		const getSectionOptions = (sec: MacroSection) => {
			const sectionDotPhrases = dotPhrases.filter((x) => x.section === sec);
			return sectionDotPhrases.map((x) => ({
				shortCode: x.shortCode,
				text: x.macro,
				description: x.description,
			}));
		};

		return {
			separator: ' ',
			trigger: '.',
			options: getSectionOptions(section),
			sections: additionalSections
				? additionalSections.map((sec) => ({
						title: sec,
						options: getSectionOptions(sec),
				  }))
				: undefined,
		};
	};

	const billingIsLoading = !billingLoaded || saveProcedureDocumentationIsLoading;
	const billingIsReadOnly = billingIsLoading || !settings.editBillingServicesEnabled || billingClaimCreated;

	return (
		<Box sx={{ width: '100%' }}>
			{isFieldActive('chiefComplaints') && (
				<DiagnosisTextField
					id="chiefComplaints"
					name={t('chiefComplaints')}
					text={
						fields.chiefComplaints && fields.chiefComplaints.length > 0
							? fields.chiefComplaints.map((x) => x.Value).reduce((x, y) => `${x}\n\n${y}`)
							: t('reasonNotProvided')
					}
					onFocusChange={onTextFieldFocusChanged}
				/>
			)}
			{isFieldActive('hpiSketchPad') && (
				<DiagnosisTextFieldWrapper
					id="hpiSketchPad"
					fields={fields}
					textFieldsRefs={textFieldsRefs}
					onTextValueChanged={onTextValueChanged}
					onPull={onPullEncounter}
					onFocusChange={onTextFieldFocusChanged}
					autocomplete={getAutocompleteOptionsFromDotPhrases(MacroSection.HPI, providerDotPhrases)}
				/>
			)}
			{isFieldActive('rosSketchPad') && (
				<DiagnosisTextFieldWrapper
					id="rosSketchPad"
					fields={fields}
					textFieldsRefs={textFieldsRefs}
					onTextValueChanged={onTextValueChanged}
					onPull={onPullEncounter}
					onFocusChange={onTextFieldFocusChanged}
					autocomplete={getAutocompleteOptionsFromDotPhrases(MacroSection.ROS, providerDotPhrases)}
				/>
			)}
			{isFieldActive('peSketchPad') && (
				<DiagnosisTextFieldWrapper
					id="peSketchPad"
					fields={fields}
					textFieldsRefs={textFieldsRefs}
					onTextValueChanged={onTextValueChanged}
					onPull={onPullEncounter}
					onFocusChange={onTextFieldFocusChanged}
					autocomplete={getAutocompleteOptionsFromDotPhrases(MacroSection.PE, providerDotPhrases, [
						MacroSection.Imaging,
					])}
				/>
			)}
			{isFieldActive('patientGoals') && (
				<DiagnosisTextFieldWrapper
					id="patientGoals"
					fields={fields}
					textFieldsRefs={textFieldsRefs}
					onTextValueChanged={onTextValueChanged}
					onPull={onPullEncounter}
					onFocusChange={onTextFieldFocusChanged}
				/>
			)}
			{isFieldActive('patientInstructions') && (
				<DiagnosisTextFieldWrapper
					id="patientInstructions"
					fields={fields}
					textFieldsRefs={textFieldsRefs}
					onTextValueChanged={onTextValueChanged}
					onPull={onPullEncounter}
					onFocusChange={onTextFieldFocusChanged}
				/>
			)}
			{isFieldActive('discussionNotes') && (
				<DiagnosisTextFieldWrapper
					id="discussionNotes"
					fields={fields}
					textFieldsRefs={textFieldsRefs}
					onTextValueChanged={onTextValueChanged}
					onPull={onPullEncounter}
					onFocusChange={onTextFieldFocusChanged}
					autocomplete={getAutocompleteOptionsFromDotPhrases(MacroSection.Discussion, providerDotPhrases)}
				/>
			)}
			{isFieldActive('procedureDocumentation') && (
				<>
					<DiagnosisField
						sx={{ pb: isProcedureDocumentations ? 0 : 2 }}
						id="procedureDocumentation"
						name={t('procedureDocumentation')}
						onAdd={settings.editProcedureDocumentationEnabled ? onOpenProcedureDocumentationTemplates : undefined}
					>
						{applyProcedureTemplateIsLoading && (
							<Box sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', mt: 1 }}>
								<Spinner />
							</Box>
						)}
						{!applyProcedureTemplateIsLoading && !isProcedureDocumentations && (
							<Input
								sx={{ display: 'flex', flex: 1 }}
								id="procedureDocumentation-input"
								readOnly
								value={t('noRecords')}
							/>
						)}
					</DiagnosisField>
					<ProcedureDocumentationList
						editable={settings.editProcedureDocumentationEnabled}
						procedureDocumentations={fields.procedureDocumentation
							.filter((x) => x.templateData || x.procedureDocumentationText)
							.filter((x) => x.modified !== 2)}
						onDeleteProcedureDocumentation={
							settings.editProcedureDocumentationEnabled ? onDeleteProcedureDocumentation : undefined
						}
						onChangeProcedureDocumentation={onChangeProcedureDocumentation}
						autocomplete={getAutocompleteOptionsFromDotPhrases(MacroSection.Procedures, providerDotPhrases, [
							MacroSection.Imaging,
						])}
					/>
				</>
			)}
			{isFieldActive('assessmentPlan') && (
				<DiagnosisTextFieldWrapper
					id="assessmentPlan"
					fields={fields}
					textFieldsRefs={textFieldsRefs}
					onTextValueChanged={onTextValueChanged}
					onAdd={onAddField}
					onPull={onPullEncounter}
					onFocusChange={onTextFieldFocusChanged}
					autocomplete={getAutocompleteOptionsFromDotPhrases(MacroSection.AP, providerDotPhrases, [
						MacroSection.Imaging,
					])}
				/>
			)}
			{isFieldActive('assessment') && (
				<DiagnosisTextFieldWrapper
					id="assessment"
					fields={fields}
					textFieldsRefs={textFieldsRefs}
					onTextValueChanged={onTextValueChanged}
					onAdd={onAddField}
					onPull={onPullEncounter}
					onFocusChange={onTextFieldFocusChanged}
					autocomplete={getAutocompleteOptionsFromDotPhrases(MacroSection.AP, providerDotPhrases, [
						MacroSection.Imaging,
					])}
				/>
			)}
			{isFieldActive('plan') && (
				<DiagnosisTextFieldWrapper
					id="plan"
					fields={fields}
					textFieldsRefs={textFieldsRefs}
					onTextValueChanged={onTextValueChanged}
					onAdd={onAddField}
					onPull={onPullEncounter}
					onFocusChange={onTextFieldFocusChanged}
					autocomplete={getAutocompleteOptionsFromDotPhrases(MacroSection.AP, providerDotPhrases, [
						MacroSection.Imaging,
					])}
				/>
			)}
			<EhrDiagnosisList
				ehr={ehr}
				isLoading={applyOrderSetIsLoading}
				diagnoses={fields.diagnoses
					.sort((x, y) => (x.ranking || 0) - (y.ranking || 0))
					.filter((d) => d.modified !== 2)
					.map((d) => ({ ...d, orders: d.orders.filter((o) => o.modified !== 2) }))}
				errors={diagnoseErrors}
				onNoteChange={onDiagnoseNoteChanged}
				onProblemChange={onProblemChange}
				onLateralityChange={onLateralityChange}
				onDeleteDiagnose={onDeleteDiagnose}
				onDeleteOrder={onDeleteDiagnoseOrder}
				isEditOrderInterpretationEnabled={settings.saveOrderInterpretationsEnabled}
				onOrderInterpretationChange={onDiagnoseOrderInterpretationChange}
				onAddOrder={onAddOrder}
				canDeleteOrder={(order) => order.modified === 1 || !order.orderId}
				onChangeRanking={onChangeRanking}
			/>
			{unassignedOrders.length > 0 && (
				<OrdersList
					orders={unassignedOrders}
					onDelete={onDeleteOrder}
					onAddDiagnose={onAddDiagnoseForOrder}
					isEditOrderInterpretationEnabled={settings.saveOrderInterpretationsEnabled}
					onInterpretationChange={onOrderInterpretationChange}
				/>
			)}
			{settings.viewBillingServicesEnabled && (
				<DiagnosisField
					id="billingServices"
					name={t('billingServices')}
					onAdd={!billingIsReadOnly ? onOpenBillingServices : undefined}
				>
					{billingClaimCreated ? (
						<Alert sx={{ mb: 2 }} severity="warning">
							{t('billingClaimCreated')}
						</Alert>
					) : null}
					<EhrBillingServicesSection
						ehr={ehr}
						billingCodes={billingCodes}
						readonly={billingIsReadOnly}
						isLoading={billingIsLoading}
						procedureDocumentationTemplates={fields.procedureDocumentation
							.filter((b) => b.modified !== 2)
							.map((x) => x.procedureTemplateId)}
						hasChangesThatMayAffectBillingServicesData={hasChangesThatMayAffectBillingServicesData()}
						icd10Codes={icd10Codes}
						onIcd10CodeSelect={onIcd10CodeSelect}
						onIcd10CodeDelete={onIcd10CodeDelete}
						billingModifiersLoading={billingModifiersLoading}
						billingModifiers={billingModifiers}
						onBillingModifierSelect={onBillingModifierSelect}
						onBillingModifierDelete={onBillingModifierDelete}
						onBillingCodeUnitChange={onBillingCodeUnitChange}
						onBillingCodeDelete={onBillingCodeDelete}
						onApplyAllIcdCodes={onApplyAllIcdCodes}
						onBillForServiceToggle={onBillForServiceToggle}
						onSelectBillingCode={onSelectBillingCode}
					/>
				</DiagnosisField>
			)}
			<EhrDiagnosesAndOrdersDialog
				ehr={ehr}
				searchContext={{
					patientId: currentEncounter.patientID,
					encounterId: currentEncounter.documentID,
				}}
				contextDiagnose={contextDiagnose}
				contextOrder={contextOrder}
				selectedDiagnoses={fields.diagnoses.filter((x) => x.modified !== 2)}
				open={openSelectDiagnose}
				onSelectDiagnose={onDiagnoseSelect}
				unassignedOrders={unassignedOrders}
				onDeleteDiagnose={onDeleteDiagnose}
				onSelectDiagnoseOrder={onSelectDiagnoseOrder}
				onSelectOrder={onSelectOrder}
				onSelectDiagnoseForOrder={onSelectDiagnoseForOrder}
				onSelectOrderSet={onSelectOrderSet}
				onDeleteDiagnoseOrder={onDeleteDiagnoseOrder}
				onDeleteOrder={onDeleteOrder}
				onClose={onCloseDiagnoseSelect}
			/>
			<SelectEncounters
				isLoading={encountersIsLoading || isPullLoading}
				open={openSelectEncounter}
				encounters={encounters}
				onSelect={onPullEncounterSelect}
				onClose={onCloseEncounterSelect}
			/>
			<PullAssessmentPlanDialog
				open={!!pulledDiagnoses}
				onClose={onCloseDiagnosesPullHandler}
				currentDiagnoses={fields.diagnoses}
				pulledDiagnoses={pulledDiagnoses}
				onMergeDiagnoses={onMergePulledAssessmentPlanHandler}
			/>
			<EhrBillingServicesDialog
				ehr={ehr}
				isLoading={isPullLoading}
				billingCodes={billingCodes}
				codeSearchIsLoading={codeSearchIsLoading}
				procedureCodeSearchResults={procedureCodeSearchResults}
				open={openBillingServices}
				onClose={onCloseBillingServices}
				onAddBillingCode={onAddBillingCode}
			/>
			<ProcedureDocumentationDialog
				documentId={currentEncounter.documentID}
				selectedProcedureDocumentations={fields.procedureDocumentation.filter((x) => x.modified !== 2)}
				open={openProcedureDocumentationTemplates}
				onClose={onCloseProcedureDocumentationTemplates}
				onSelectProcedureDocumentation={onSelectProcedureDocumentation}
				onDeleteProcedureDocumentation={onDeleteProcedureDocumentation}
			/>
		</Box>
	);
};

export default forwardRef(Diagnosis);
