import { IDepartmentDictation } from '../../models/dictations/dictations.models';
import { IPatientEncounter } from '../../models/patients/patients.models';
import { getObjectCache, setObjectCache } from '../../../system/local-storage';

interface IDictationsCache {
	[blobId: string]: IBlobDataCache & { updateTimestamp: number };
}

interface IBlobDataCache {
	dictation?: IDepartmentDictation;
	encounter?: IPatientEncounter | null;
}

function setDictationsCache(blobId: string, data: IBlobDataCache): void {
	let dictations = getObjectCache<IDictationsCache>('dictations');
	if (!dictations) {
		dictations = {};
	}

	const blobData: IBlobDataCache = dictations[blobId] || {};
	const updatedDictations = {
		...dictations,
	};
	updatedDictations[blobId] = {
		updateTimestamp: new Date().getTime(),
		dictation: data.dictation !== undefined ? data.dictation : blobData.dictation,
		encounter: data.encounter !== undefined ? data.encounter : blobData.encounter,
	};
	setObjectCache('dictations', updatedDictations);
}

export function setDictationCache(blobId: string, dictation: IDepartmentDictation): void {
	setDictationsCache(blobId, { dictation });
}

export function getDictationCache(blobId: string): IDepartmentDictation | null {
	const dictations = getObjectCache<IDictationsCache>('dictations');

	if (!dictations) return null;

	return dictations[blobId]?.dictation || null;
}

export function setEncounterCache(blobId: string, encounter: IPatientEncounter | null): void {
	setDictationsCache(blobId, { encounter });
}

export function getEncounterCache(blobId: string): IPatientEncounter | null {
	const dictations = getObjectCache<IDictationsCache>('dictations');

	if (!dictations) return null;

	return dictations[blobId]?.encounter || null;
}

export function deleteDictationDataCache(blobId: string): void {
	const dictations = getObjectCache<IDictationsCache>('dictations');
	if (dictations && dictations[blobId]) {
		delete dictations[blobId];
		setObjectCache('dictations', dictations);
	}
}

export function cleanupDictationsCache(): void {
	const dictations = getObjectCache<IDictationsCache>('dictations');
	if (dictations) {
		const keys = Object.keys(dictations);
		keys.forEach((key) => {
			if (
				dictations[key] &&
				// if dictation cache is older than a week delete cache
				Math.abs(dictations[key].updateTimestamp - new Date().getTime()) > 7 * 24 * 60 * 60 * 1000
			) {
				delete dictations[key];
			}
		});
		setObjectCache('dictations', dictations);
	}
}
