import { call, put, select } from 'redux-saga/effects';
import { actions } from '../actions';
import { IServiceResult } from '../../models/service.models';
import { IDepartmentDictation, IOrderSet } from '../../models/dictations/dictations.models';
import { getOrderSets } from '../../api/dictations/dictations.api';
import { handleGlobalException } from '../../../application/exception-handlers';
import { AppState } from '../../core.types';

export function* getOrderSetsSaga() {
	try {
		const dictation: IDepartmentDictation = yield select((state: AppState) => state.worklist.currentDocument.dictation);

		yield put(actions.worklist.setOrdersSearchIsLoading(true));
		const orderSets: IServiceResult<IOrderSet[]> = yield call(getOrderSets, {
			providerId: dictation.providerUserInfoID,
		});

		if (orderSets.success) {
			yield put(actions.worklist.setOrderSetsResult(orderSets.data));
		}
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.worklist.setOrdersSearchIsLoading(false));
	}
}
