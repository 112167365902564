import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../core/core.types';
import { actions } from '../../core/state/actions';
import JobsSearchPage from './JobsSearchPage';
import { navigateToWorkListTab, push } from '../../system/navigator';
import { IDictation, IJobDictation, IJobsSearchFilters } from '../../core/models/dictations/dictations.models';
import { IScriberPermissions, IUserDisplayName } from '../../core/models/users/user.models';
import { routes } from '../App/routes';
import { namespaces } from '../../application/i18n.constants';
import { DocumentType, JobStatus } from '../../core/models/dictations/document.models';

function JobsSearch() {
	const { t } = useTranslation(namespaces.pages.jobsSearch);

	const prevFiltersRef = useRef<IJobsSearchFilters | undefined>(undefined);

	const dispatch = useAppDispatch();
	const [searchParams] = useSearchParams();

	const userApiKey = useAppSelector<string>((state) => state.user.settings.apiKey);
	const username = useAppSelector<string>((state) => state.user.settings.shortname);

	const [validationMessage, setValidationMessage] = useState<{
		message: string;
		errorType: string;
	} | null>(null);

	const [searchInitiated, setSearchInitiated] = useState<boolean>(false);

	const patientID: string | null = searchParams.get('patientID');
	const documentStatus: string | null = searchParams.get('documentStatus');
	const transcribedBy: string | null = searchParams.get('transcribedBy');
	const dictatedBy: string | null = searchParams.get('dictatedBy');
	const documentType: string | null = searchParams.get('documentType');
	const qaBy: string | null = searchParams.get('qaBy');
	const dictatedStartDate: string | null = searchParams.get('dictatedStartDate');
	const dictatedEndDate: string | null = searchParams.get('dictatedEndDate');
	const sentStartDate: string | null = searchParams.get('sentStartDate');
	const sentEndDate: string | null = searchParams.get('sentEndDate');

	const jobsSearchResults = useAppSelector<IJobDictation[]>((state) => state.jobs.jobs);
	const jobsSearchIsLoading = useAppSelector<boolean>((state) => state.jobs.searchIsLoading);
	const users = useAppSelector<IUserDisplayName[]>((state) => state.jobs.users);
	const usersIsLoading = useAppSelector<boolean>((state) => state.jobs.getUsersIsLoading);
	const permissions = useAppSelector<IScriberPermissions>((state) => state.user.settings.permissions);

	const getFilters = () => ({
		patientID: patientID || undefined,
		documentStatus: documentStatus || undefined,
		transcribedBy: transcribedBy || undefined,
		dictatedBy: !permissions.admin ? userApiKey : dictatedBy || undefined,
		documentType: documentType || undefined,
		qaBy: qaBy || undefined,
		dictatedStartDate: dictatedStartDate || undefined,
		dictatedEndDate: dictatedEndDate || undefined,
		sentStartDate: sentStartDate || undefined,
		sentEndDate: sentEndDate || undefined,
	});

	const [advancedSearchFilters, setAdvancedSearchFilters] = useState<IJobsSearchFilters>(getFilters());

	const isAnyFilters =
		Object.keys(advancedSearchFilters).filter((x) => !!advancedSearchFilters[x as keyof IJobsSearchFilters]).length > 0;

	useEffect(() => {
		dispatch(actions.jobs.getUsersAction());
		prevFiltersRef.current = getFilters();
	}, []);

	useEffect(() => {
		const newFilters = getFilters();

		if (JSON.stringify(prevFiltersRef.current) === JSON.stringify(newFilters)) {
			return;
		}

		setSearchInitiated(true);
		setAdvancedSearchFilters(newFilters);
		prevFiltersRef.current = newFilters;
	}, [
		patientID,
		documentStatus,
		transcribedBy,
		dictatedBy,
		documentType,
		qaBy,
		dictatedStartDate,
		dictatedEndDate,
		sentStartDate,
		sentEndDate,
	]);

	const dateParser = (dateString?: string | null) =>
		dateString
			? dayjs(dateString, {
					format: 'MM-DD-YYYY',
			  })
			: undefined;

	const dateFormatter = (date?: Dayjs | null) => (date ? date.format('MM-DD-YYYY') : undefined);

	const processValidation = (filters: IJobsSearchFilters) => {
		if (
			(filters.dictatedStartDate && filters.dictatedEndDate) ||
			(filters.sentStartDate && filters.sentEndDate) ||
			filters.patientID?.length
		) {
			// do nothing
		} else {
			return {
				message: t('requiredFieldsError'),
				errorType: 'requiredFieldsError',
			};
		}

		const validateDateRange = (startDate?: string, endDate?: string) => {
			if (startDate || endDate) {
				if (!startDate || !endDate) {
					return false;
				}
				if (dayjs(endDate).diff(dayjs(startDate), 'days') > 90) {
					return false;
				}
			}
			return true;
		};

		if (!validateDateRange(filters.dictatedStartDate, filters.dictatedEndDate)) {
			return {
				errorType: 'dictatedDateRangeError',
				message: t('dictatedDateRangeError'),
			};
		}
		if (!validateDateRange(filters.sentStartDate, filters.sentEndDate)) {
			return {
				errorType: 'completedDateRangeError',
				message: t('completedDateRangeError'),
			};
		}
		return null;
	};

	useEffect(() => {
		const validationResult = processValidation(advancedSearchFilters);

		if (searchInitiated) {
			if (isAnyFilters) {
				setValidationMessage(validationResult);
				if (!validationResult) {
					dispatch(
						actions.jobs.jobsSearchAction({
							...advancedSearchFilters,
							dictatedStartDate: dateFormatter(dateParser(dictatedStartDate)),
							dictatedEndDate: dateFormatter(dateParser(dictatedEndDate)),
							sentStartDate: dateFormatter(dateParser(sentStartDate)),
							sentEndDate: dateFormatter(dateParser(sentEndDate)),
						})
					);
				}
			} else {
				setValidationMessage(null);
				dispatch(actions.jobs.setJobsSearchResultAction({ jobs: [] }));
			}
		}
	}, [advancedSearchFilters]);

	const onJobSearch = (filters: IJobsSearchFilters) => {
		navigateToWorkListTab({ to: routes.searchTabs.jobs, filters });
	};

	const onJobClick = (job: IJobDictation) => {
		push(`/dictation/${job.blobID}/view`);
		dispatch(actions.jobs.openDictation({ job }));
	};

	const onUpdateJob = (
		job: IDictation,
		update: {
			documentStatus?: JobStatus;
			documentTypeName?: DocumentType;
			stat?: boolean;
		}
	) => {
		dispatch(actions.jobs.updateJob({ blobId: job.blobID, job, update }));
	};

	return (
		<JobsSearchPage
			isDictatorEnabled={permissions.admin}
			username={username}
			permissions={permissions}
			dateParser={dateParser}
			dateFormatter={dateFormatter}
			jobsSearchResults={jobsSearchResults}
			handleJobClick={onJobClick}
			loading={jobsSearchIsLoading}
			advancedSearchFilters={advancedSearchFilters}
			onJobSearch={onJobSearch}
			usersIsLoading={usersIsLoading}
			users={users}
			validationMessage={validationMessage}
			onUpdateJob={onUpdateJob}
		/>
	);
}

export default connect()(JobsSearch);
