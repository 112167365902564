import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IProvider } from '../../core/models/providers/providers.models';
import ValidationSelectField from '../validators/ValidationSelectField';
import { namespaces } from '../../application/i18n.constants';

interface ICopyMacrosDialogProps {
	open: boolean;
	providers: {
		allProviders: IProvider[];
		preSelectedProvider: string;
	};
	onClose: () => void;
	onCopy: (params: { providerId: string }) => void;
}
function CopyMacrosDialog({ open, providers, onCopy, onClose }: ICopyMacrosDialogProps) {
	const { t } = useTranslation(namespaces.components.dotPhrases);
	const [selectedProvider, setSelectedProvider] = useState<string | null>(null);

	const handleSetProvider = (providerId: string) => {
		setSelectedProvider(providerId);
	};

	useEffect(() => {
		if (!open) {
			setSelectedProvider(null);
		}
	}, [open]);

	return (
		<Dialog onClose={onClose} open={open}>
			<Box
				flexDirection="column"
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				sx={{ width: 312 }}
			>
				<DialogTitle id="alert-dialog-title">Copy Macro to</DialogTitle>
				<DialogContent
					sx={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<ValidationSelectField
						sx={{ minWidth: 166, my: 2, mr: 1 }}
						id="providerSelect"
						type="text"
						required={!!providers}
						value={selectedProvider || ''}
						data={providers.allProviders
							.filter((x) => x.id !== providers.preSelectedProvider)
							.map((x) => ({ value: x.id, text: x.name }))}
						label={t('selectProvider')}
						onChange={(e) => handleSetProvider(e.target.value)}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose} color="primary">
						{t('cancel')}
					</Button>
					<Button
						onClick={() => onCopy({ providerId: selectedProvider || '' })}
						color="primary"
						autoFocus
						disabled={!!providers && !selectedProvider}
					>
						{t('copy')}
					</Button>
				</DialogActions>
			</Box>
		</Dialog>
	);
}

export default CopyMacrosDialog;
