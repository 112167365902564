import * as React from 'react';
import { useEffect } from 'react';
import { SxProps, Theme, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { namespaces } from '../../application/i18n.constants';
import DataTable, { Align, IDataTableRow } from '../data-table/DataTable';
import { IMacro } from '../../core/models/macros/macros.models';
import { getNameForDotPhraseSection } from '../../core/services/macros/macros.services';

interface IMacrosTableProps {
	macros: IMacro[];
	onRowClick?: (idx: number) => void;
	onDeleteClick?: (idx: number) => void;
	onCopyClick?: (idx: number) => void;
	onSelectionChange?: (ids: number[]) => void;
}

export default function MacrosTable({
	macros,
	onRowClick,
	onDeleteClick,
	onCopyClick,
	onSelectionChange,
}: IMacrosTableProps) {
	const { t } = useTranslation(namespaces.components.dotPhrases);
	const smallScreen = useMediaQuery((th: Theme) => th.breakpoints.down('sm'));
	const [selectedRows, setSelectedRows] = React.useState<number[]>([]);

	useEffect(() => {
		setSelectedRows([]);
	}, [JSON.stringify(macros)]);

	useEffect(() => {
		if (onSelectionChange) {
			onSelectionChange(selectedRows);
		}
	}, [JSON.stringify(selectedRows)]);

	const alignLeft: Align = 'left';
	const columns = [];

	if (onSelectionChange) {
		columns.push({
			name: ' ',
		});
	}

	columns.push({
		name: t('section'),
	});
	columns.push({
		name: t('shortCode'),
		align: alignLeft,
	});
	columns.push({
		name: t('description'),
		align: alignLeft,
		style: {
			width: '20rem',
		},
	});

	columns.push({
		name: t('macro'),
		align: alignLeft,
	});
	columns.push({
		name: t('spokenForm'),
		align: alignLeft,
	});

	if (onCopyClick || onDeleteClick) {
		columns.push({
			name: '',
		});
	}

	const rows: Array<{ data: IDataTableRow[] }> = macros.map((dotPhrase: IMacro) => {
		const row: { data: IDataTableRow[] } = {
			data: [
				{
					value: getNameForDotPhraseSection(dotPhrase.section),
				},
				{
					// eslint-disable-next-line no-nested-ternary
					value: dotPhrase.shortCode
						? dotPhrase.shortCode[0] !== '.'
							? `.${dotPhrase.shortCode}`
							: dotPhrase.shortCode
						: 'N/A',
					align: alignLeft,
				},
				{
					value: dotPhrase.description,
					align: alignLeft,
				},
			],
		};

		const customSx: SxProps = {
			overflow: 'hidden',
			maxWidth: smallScreen ? 100 : 210,
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		};
		row.data.push({
			value: (dotPhrase as IMacro).macro,
			align: alignLeft,
			sx: customSx,
		});

		row.data.push({
			value: (dotPhrase as IMacro).spokenForm || 'N/A',
			align: alignLeft,
			sx: customSx,
		});

		return row;
	});

	const onSelectedClick = (idx: number, value: boolean) => {
		setSelectedRows((prev) => {
			if (value) {
				if (!prev.includes(idx)) return [...prev, idx];
			}
			return prev.filter((x) => x !== idx);
		});
	};

	return rows.length > 0 ? (
		<DataTable
			columns={columns}
			rows={rows.map((row, idx) => ({
				...row,
				rowProps: {
					selected: selectedRows.includes(idx),
				},
			}))}
			onRowClick={onRowClick}
			onDeleteClick={onDeleteClick}
			onCopyClick={onCopyClick}
			onSelectClick={onSelectionChange ? onSelectedClick : undefined}
			tableContainerProps={{ sx: { height: '70vh', overflowY: 'auto' } }}
		/>
	) : (
		<Typography sx={{ textAlign: 'center', p: 1 }}>{t('noResults')}</Typography>
	);
}
