import { createReducer } from '@reduxjs/toolkit';
import { IPatientsSearchResult } from '../../models/patients/patients.models';

import { patientsActions } from './patients.actions';

export interface IPatientsState {
	patients: IPatientsSearchResult[];
	searchIsLoading: boolean;
}

const initialState: IPatientsState = {
	patients: [],
	searchIsLoading: false,
};

const patientsReducer = createReducer<IPatientsState>(initialState, (builder) => {
	builder
		.addCase(patientsActions.patientSearchIsLoadingAction, (state, action) => {
			state.searchIsLoading = action.payload;
		})
		.addCase(patientsActions.setPatientSearchResultAction, (state, action) => {
			state.patients = action.payload.patients;
		});
});

export default patientsReducer;
