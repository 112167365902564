import * as React from 'react';
import ChipList from '../chip-list/ProvidersChipList';
import ItemsList, { IItemsListProps, IListItem } from './ItemsList';
import { AnyObject } from '../../shared/types';

interface IChipItemsList<T> extends Omit<IItemsListProps<T>, 'isSelected'> {
	onDelete?: (id: string) => void;
	selected?: IListItem<AnyObject>[];
	deletable?: IListItem<AnyObject>[];
}

function ChipItemsList<T>({ items, selected, deletable, onDelete, ...rest }: IChipItemsList<T>) {
	const onDeleteItem = (id: string) => {
		if (onDelete) {
			onDelete(id);
		}
	};

	return (
		<>
			{deletable && deletable.length > 0 && (
				<ChipList
					items={deletable.map((x) => ({ id: x.id, title: x.title }))}
					onDelete={onDelete ? onDeleteItem : undefined}
				/>
			)}
			<ItemsList
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...rest}
				items={items}
				isSelected={selected ? (item) => !!selected.find((x) => x.id === item.id) : undefined}
			/>
		</>
	);
}

export default ChipItemsList;
