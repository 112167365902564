import * as React from 'react';
import { Box } from '@mui/material';
import ReadOnlyDictationPage from './ReadOnlyDictationPage';
import { useAppDispatch, useAppSelector } from '../../core/core.types';
import { ISetDictationDataActionPayload } from '../../core/state/jobs/jobs.actions';
import Spinner from '../../components/spinner/Spinner';
import { EHRType } from '../../core/models/ehr.models';
import { IFeatureFlags, IScriberPermissions } from '../../core/models/users/user.models';
import { actions } from '../../core/state/actions';
import { AIModelType } from '../../core/api/dictations/dictations.api.models';

function ReadOnlyDictation() {
	const dispatch = useAppDispatch();
	// const { blobId } = useParams();

	const ehr = useAppSelector<EHRType | null>((state) => state.user.settings.system);
	const documentIsLoading = useAppSelector<boolean>((state) => state.jobs.isCurrentDictationLoading);
	const document = useAppSelector<ISetDictationDataActionPayload | null>((state) => state.jobs.currentDictation);

	const permissions = useAppSelector<IScriberPermissions>((state) => state.user.settings.permissions);
	const featureFlags = useAppSelector<IFeatureFlags>((state) => state.user.settings.featureFlags);

	const aiPrompt = useAppSelector<{
		prompt: string | null;
		instructions: string | null;
	}>((state) => {
		const trns = state.jobs.currentDictation?.transcription;
		return {
			prompt: trns?.prompt ?? null,
			instructions: trns?.instructions ?? null,
		};
	});
	const lastTimePromptSubmitted = useAppSelector<number | null>(
		(state) => state.jobs.currentDictation?.transcription?.lastTimePromptSubmitted ?? null
	);

	/*
  TODO: re-add when getting dictation by blobId through api will be possible
	useEffect(() => {
		dispatch(actions.jobs.setDictationData(null));
		if (blobId) {
			dispatch(actions.jobs.openDictation({ blobId }));
		}
	}, []);
	*/

	const onAIPromptChange = (prompt: string) => {
		dispatch(actions.jobs.setAIPrompt(prompt));
	};

	const onAIInstructionsChange = (instructions: string) => {
		dispatch(actions.jobs.setAIInstructions(instructions));
	};

	const onAIPromptSubmit = (aiModel: AIModelType | null, prompt: { prompt: string; instructions: string }) => {
		if (document?.dictation?.blobID) {
			dispatch(actions.jobs.requestAISummaryForPrompt({ blobId: document.dictation.blobID, prompt, aiModel }));
		}
	};

	if (!document) {
		return (
			<Box sx={{ display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
				<Spinner />
			</Box>
		);
	}

	return (
		<ReadOnlyDictationPage
			ehr={ehr as EHRType}
			document={document}
			permissions={permissions}
			isDocumentLoading={documentIsLoading}
			aiPrompt={{
				prompt: aiPrompt.prompt || '',
				instructions: aiPrompt.instructions || '',
				canUpdate: permissions.aiAccess && featureFlags.retrieveOpenAISummary.enabled,
				onChangePrompt: onAIPromptChange,
				onChangeInstructions: onAIInstructionsChange,
				lastTimePromptSubmitted,
				onPromptSubmit: onAIPromptSubmit,
			}}
		/>
	);
}
export default ReadOnlyDictation;
