import * as React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import Spinner from '../spinner/Spinner';

export interface IConfirmActionDialogProps {
	open: boolean;
	isLoading?: boolean;
	actions?: {
		action: () => void;
		text: string;
		disabled?: boolean;
	}[];
	cancel: {
		action: () => void;
		text: string;
	};
	text: string;
	title: string;
}

function ConfirmActionDialog({ open, isLoading, actions, cancel, title, text }: IConfirmActionDialogProps) {
	return (
		<Dialog fullWidth maxWidth="sm" onClose={cancel.action} open={open}>
			<Box flexDirection="column" aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title" align="center">
					{title}
				</DialogTitle>
				{!isLoading ? (
					<>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">{text}</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={cancel.action}>{cancel.text}</Button>
							{actions && actions.length > 0
								? actions
										.filter((x) => !!x)
										.map((action) => (
											<Button key={action.text} disabled={action.disabled} onClick={action.action}>
												{action.text}
											</Button>
										))
								: null}
						</DialogActions>
					</>
				) : (
					<Box sx={{ display: 'flex', minHeight: 104, width: '100%' }} alignItems="center" justifyContent="center">
						<Spinner />
					</Box>
				)}
			</Box>
		</Dialog>
	);
}

export default ConfirmActionDialog;
