import * as React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { namespaces } from '../../application/i18n.constants';

export interface IDeleteMacrosDialogProps {
	open: boolean;
	cancel: {
		action: () => void;
		text: string;
	};
	deletePhrase: {
		action: () => void;
		text: string;
	};
	text: string;
}

function DeleteMacrosDialog({ open, cancel, deletePhrase, text }: IDeleteMacrosDialogProps) {
	const { t } = useTranslation(namespaces.pages.wordReplacements);
	return (
		<Dialog fullWidth onClose={cancel.action} open={open}>
			<DialogTitle id="alert-dialog-title" align="center">
				{t('deleteReplacementTitle')}
			</DialogTitle>
			<Box flexDirection="column" aria-labelledby="alert-dialog-delete" aria-describedby="alert-dialog-text">
				<DialogContent sx={{ textAlign: 'center' }}>{text}</DialogContent>
				<DialogActions>
					<Button onClick={cancel.action}>{cancel.text}</Button>
					<Button variant="contained" onClick={deletePhrase.action}>
						{deletePhrase.text}
					</Button>
				</DialogActions>
			</Box>
		</Dialog>
	);
}

export default DeleteMacrosDialog;
