import { all, call, takeLatest } from 'redux-saga/effects';
import * as athenaSaga from './index.athena';
import { constants } from './state/constants';
import * as saga from '.';
import { takeLatestGeneric } from './base.sagas';
import { EHRType } from './models/ehr.models';

function* actionWatcher() {
	// APP
	yield takeLatest(constants.app.INIT_APP, saga.initAppSaga);
	yield takeLatest(constants.app.INIT_USER_SERVICES, saga.initUserServicesSaga);

	// USER
	yield takeLatest(constants.user.INIT_USER, saga.initUserSaga);

	// AUTH
	yield takeLatest(constants.auth.SIGN_IN_REQUEST, saga.userSignInSaga);
	yield takeLatest(constants.auth.SIGN_OUT, saga.userSignOutSaga);

	yield takeLatest(constants.auth.RESET_PASSWORD_REQUEST, saga.resetPasswordSaga);
	yield takeLatest(constants.auth.RESET_PASSWORD_CONFIRM_NEW_PASSWORD, saga.resetPasswordSaga);
	yield takeLatest(constants.auth.RESET_PASSWORD_SEND_CONFIRMATION_CODE, saga.resetPasswordSaga);

	// WORKLIST
	yield takeLatest(constants.worklist.INIT_WORKLIST, saga.initWorkListSaga);
	yield takeLatest(constants.worklist.INIT_DICTATIONS, saga.initDictationsSaga);
	yield takeLatest(constants.worklist.SET_CLINIC, saga.setClinicSaga);

	yield takeLatest(constants.worklist.GET_DICTATIONS, saga.getSelectedProvidersDictationsSaga);
	yield takeLatest(constants.worklist.OPEN_DICTATION, saga.openDictationSaga);

	// PROVIDERS
	yield takeLatest(constants.worklist.SET_SELECTED_PROVIDERS, saga.setSelectedProvidersSaga);
	yield takeLatest(constants.worklist.GET_PROVIDERS, saga.getProvidersSaga);

	// PROCEDURE DOCUMENTATION TEMPLATES
	yield takeLatest(
		constants.worklist.GET_PROCEDURE_DOCUMENTATION_TEMPLATES,
		saga.getProcedureDocumentationTemplatesSaga
	);

	yield takeLatest(
		constants.worklist.APPLY_PROCEDURE_DOCUMENTATION_TEMPLATE,
		saga.applyProcedureDocumentationTemplateSaga
	);

	yield takeLatest(constants.worklist.SAVE_PROCEDURE_DOCUMENTATION, saga.saveProcedureDocumentationSaga);

	// PATIENTS
	yield takeLatest(constants.patients.PATIENT_SEARCH_REQUEST, saga.searchPatientSaga);

	// JOBS
	yield takeLatest(constants.jobs.OPEN_DICTATION, saga.openJobSaga);
	yield takeLatest(constants.jobs.JOBS_SEARCH_REQUEST, saga.searchJobsSaga);
	yield takeLatest(constants.jobs.USERS_GET_REQUEST, saga.getUsersSaga);
	yield takeLatest(constants.jobs.UPDATE_JOB, saga.changeDocumentStatusSaga);

	yield takeLatest(constants.jobs.REQUEST_AI_SUMMARY_FOR_PROMPT, saga.requestJobAISummaryForPromptSaga);
	yield takeLatest(constants.jobs.UPDATE_PROMPT_AI_SUMMARY, saga.updateJobPromptAISummarySaga);

	// DICTATIONS
	yield takeLatest(constants.worklist.CLOSE_DICTATION, saga.closeDocumentSaga);
	yield takeLatest(constants.worklist.UNLOCK_AND_OPEN_DICTATION, saga.unlockAndOpenDocumentSaga);

	yield takeLatest(constants.worklist.OPEN_DICTATION_DOCUMENT, saga.openDictationDocumentSaga);
	yield takeLatest(constants.worklist.SET_DICTATION_PATIENT, saga.setDictationPatientSaga);

	yield takeLatest(constants.worklist.GET_DOCUMENT_DETAILS, saga.getCurrentDocumentSaga);
	yield takeLatest(constants.worklist.PULL_DOCUMENT_FILED, saga.pullCurrentDocumentFiledSaga);
	yield takeLatest(constants.worklist.MERGE_PULLED_ASSESSMENT_PLAN, saga.mergePulledAssessmentPlanSaga);

	yield takeLatest(constants.worklist.SAVE_DOCUMENT_DETAILS, saga.saveDocumentSaga);
	yield takeLatest(constants.worklist.WORKLIST_PATIENT_SEARCH_REQUEST, saga.worklistSearchPatientSaga);
	yield takeLatest(constants.worklist.UPDATE_DICTATION_ENCOUNTER, saga.updateDictationEncounterSaga);

	yield takeLatest(constants.worklist.REQUEST_AI_SUMMARY_FOR_PROMPT, saga.requestAISummaryForPromptSaga);
	yield takeLatest(constants.worklist.UPDATE_PROMPT_AI_SUMMARY, saga.updatePromptAISummarySaga);

	// DIAGNOSIS
	yield takeLatest(constants.worklist.SEARCH_DIAGNOSES, saga.searchDiagnosesSaga);

	// ORDERS
	yield takeLatest(constants.worklist.SEARCH_ORDERS, saga.searchOrdersSaga);

	// ORDER SETS
	yield takeLatestGeneric(constants.worklist.GET_ORDER_SETS, [
		{
			criteria: {
				EHR: [EHRType.Athena],
			},
			processor: athenaSaga.getOrderSetsSaga,
		},
	]);
	yield takeLatest(constants.worklist.APPLY_ORDER_SET, saga.applyOrderSetSaga);

	// BILLING SERVICES
	yield takeLatestGeneric<string>(constants.worklist.SEARCH_PROCEDURE_CODE, [
		{
			criteria: {
				EHR: [EHRType.Athena, EHRType.NextGen],
			},
			processor: saga.searchBillingServiceSaga,
		},
	]);
	yield takeLatestGeneric(constants.worklist.GET_BILLING_MODIFIERS, [
		{
			criteria: {
				EHR: [EHRType.Athena, EHRType.NextGen],
			},
			processor: saga.getBillingModifiersSaga,
		},
	]);

	// PREVIEW DOCUMENT
	yield takeLatest(constants.worklist.GET_PATIENT_PREVIEW_DOCUMENT, saga.getPatientPreviewDocument);

	// DOT PHRASES
	yield takeLatest(constants.worklist.GET_DOCUMENT_PROVIDER_DOTPHRASES, saga.getDocumentProviderDotPhrasesSaga);

	// ADMIN
	// MACROS
	yield takeLatest(constants.admin.GET_CUSTOMER_MACROS, saga.getCustomerMacrosSaga);
	yield takeLatest(constants.admin.SAVE_CUSTOMER_MACRO, saga.saveCustomerMacroSaga);
	yield takeLatest(constants.admin.UPDATE_CUSTOMER_MACRO, saga.saveCustomerMacroSaga);
	yield takeLatest(constants.admin.DELETE_CUSTOMER_MACRO, saga.deleteCustomerMacroSaga);
	yield takeLatest(constants.admin.IMPORT_CUSTOMER_MACRO, saga.importCustomerMacrosSaga);

	yield takeLatest(constants.admin.GET_PROVIDER_MACROS, saga.getProviderMacrosSaga);
	yield takeLatest(constants.admin.SAVE_PROVIDER_MACRO, saga.saveProviderMacroSaga);
	yield takeLatest(constants.admin.UPDATE_PROVIDER_MACRO, saga.saveProviderMacroSaga);
	yield takeLatest(constants.admin.DELETE_PROVIDER_MACRO, saga.deleteProviderMacroSaga);
	yield takeLatest(constants.admin.IMPORT_PROVIDER_MACRO, saga.importProviderMacrosSaga);
	yield takeLatest(constants.admin.COPY_PROVIDER_MACROS, saga.copyProviderMacrosSaga);

	// WORD REPLACEMENTS
	yield takeLatest(constants.admin.GET_CUSTOMER_WORD_REPLACEMENTS, saga.getCustomerWordReplacementsSaga);
	yield takeLatest(constants.admin.SAVE_CUSTOMER_WORD_REPLACEMENT, saga.saveCustomerWordReplacementSaga);
	yield takeLatest(constants.admin.UPDATE_CUSTOMER_WORD_REPLACEMENT, saga.saveCustomerWordReplacementSaga);
	yield takeLatest(constants.admin.DELETE_CUSTOMER_WORD_REPLACEMENT, saga.deleteCustomerWordReplacementSaga);

	yield takeLatest(constants.admin.GET_PROVIDER_WORD_REPLACEMENTS, saga.getProviderWordReplacementsSaga);
	yield takeLatest(constants.admin.SAVE_PROVIDER_WORD_REPLACEMENT, saga.saveProviderWordReplacementSaga);
	yield takeLatest(constants.admin.UPDATE_PROVIDER_WORD_REPLACEMENT, saga.saveProviderWordReplacementSaga);
	yield takeLatest(constants.admin.DELETE_PROVIDER_WORD_REPLACEMENT, saga.deleteProviderWordReplacementSaga);

	yield takeLatest(constants.admin.GET_PROVIDERS, saga.getProvidersV2Saga);

	// CUSTOM PATIENT DEMOGRAPHICS
	yield takeLatest(constants.worklist.UPDATE_CUSTOM_PATIENT_DEMOGRAPHICS, saga.updateCustomPatientDemographicsSaga);

	// SYSTEM
	yield takeLatest(constants.app.GET_AI_MODELS, saga.getAiModelsSaga);
}

// function* handler(action: Action) {
//     yield call(saga.getProvidersSaga, action);
// }
export default function* rootSaga() {
	yield all([call(actionWatcher)]);
}
