import { Box, SxProps, Theme } from '@mui/material';
import * as React from 'react';
import { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import PatientDetails from '../../patient-details/PatientDetails';
import Loading from '../../spinner/Loading';
import {
	ICustomPatientDemographicsData,
	IPatientDemographic,
	IPatientEncounter,
} from '../../../core/models/patients/patients.models';
import DictationSidebarTabs, {
	IDictationSidebarTabs,
	IDictationSidebarTabsProps,
} from '../dictation-sidebar-tabs/DictationSidebarTabs';
import HowlerAudioPlayer from '../../audio-player/HowlerAudioPlayer';
import { IAudioPlayer } from '../../audio-player/audioPlayer.interfaces';
import { getIsHtml5AudioCache, getIsStreamAudioCache } from '../../../system/local-storage';
import BrowserAudioPlayer from '../../audio-player/BrowserAudioPlayer';

interface IDictationSideBarProps {
	containerSx?: SxProps<Theme>;
	// TOP
	// audio
	audioUrl: string;
	// patient
	patientId: string;
	isPatientLoading: boolean;
	isEncountersLoading: boolean;
	changeEncounterDisabled?: boolean;
	changePatientDisabled?: boolean;
	patient: {
		demographic?: IPatientDemographic;
		encounter?: IPatientEncounter;
		stat?: boolean;
		customDemographics?: ICustomPatientDemographicsData;
		integratedPatient?: boolean;
	};
	onPatientUpdate: () => void;
	onPatientSelect: (rest: boolean) => void;
	disableEncounter?: boolean;
	onChangeEncounter?: () => void;
	// qa notes
	sidebar: Omit<IDictationSidebarTabsProps, 'handleQANoteMessageClick' | 'handleTranscriptMessageClick'>;
}

export interface IDictationSideBar {
	audioPlayer: Omit<IAudioPlayer, 'setTime'>;
	sidebar: Omit<IDictationSidebarTabs, 'seekTranscription'>;
}

const DictationSideBar: React.ForwardRefRenderFunction<IDictationSideBar, IDictationSideBarProps> =
	function DictationSideBarFunc(
		{
			containerSx,
			audioUrl,
			patientId,
			isPatientLoading,
			isEncountersLoading,
			changePatientDisabled,
			changeEncounterDisabled,
			patient,
			onPatientUpdate,
			onPatientSelect,
			disableEncounter,
			onChangeEncounter,
			sidebar,
		}: IDictationSideBarProps,
		forwardedRef: ForwardedRef<IDictationSideBar>
	) {
		const audioPlayerRef = useRef<IAudioPlayer>(null);
		const sideBarRef = useRef<IDictationSidebarTabs>(null);
		const [streamAudio] = useState<boolean>(getIsStreamAudioCache());
		const [html5Audio] = useState<boolean>(getIsHtml5AudioCache());

		const handleTimestampMessageClick = (timeStamp: number) => {
			audioPlayerRef.current?.setTime(timeStamp);
		};

		useImperativeHandle(forwardedRef, () => ({
			audioPlayer: {
				getTime() {
					return audioPlayerRef.current?.getTime() || 0;
				},
				toggleAudio() {
					audioPlayerRef.current?.toggleAudio();
				},
				jumpBack() {
					audioPlayerRef.current?.jumpBack();
				},
				increasePlaybackSpeed() {
					audioPlayerRef.current?.increasePlaybackSpeed();
				},
				decreasePlaybackSpeed() {
					audioPlayerRef.current?.decreasePlaybackSpeed();
				},
			},
			sidebar: {
				addQaNote() {
					sideBarRef.current?.addQaNote();
				},
			},
		}));

		const onAudioProgress = (secondsPlayed: number) => {
			sideBarRef.current?.seekTranscription(secondsPlayed);
		};

		const onKeyDown = (event: KeyboardEvent) => {
			if (!event) {
				return;
			}

			if (
				(window.navigator.userAgent.toLowerCase().indexOf('mac') > 0 && event.ctrlKey && event.code === 'KeyP') ||
				(event.ctrlKey && event.code === 'Space') ||
				event.code === 'F8'
			) {
				event.preventDefault();
				audioPlayerRef.current?.toggleAudio();
			} else if (event.ctrlKey && event.code === 'Comma') {
				event.preventDefault();
				audioPlayerRef.current?.jumpBack();
			} else if (event.ctrlKey && event.shiftKey && event.code === 'KeyI') {
				event.preventDefault();
				audioPlayerRef.current?.decreasePlaybackSpeed();
			} else if (event.ctrlKey && event.code === 'KeyI') {
				event.preventDefault();
				audioPlayerRef.current?.increasePlaybackSpeed();
			}
		};

		useEffect(() => {
			window.addEventListener('keydown', onKeyDown, false);

			return () => {
				window.removeEventListener('keydown', onKeyDown);
			};
		}, []);

		return (
			<Box sx={containerSx}>
				<Box sx={{ padding: '1rem 0.5rem' }}>
					{/* eslint-disable-next-line no-nested-ternary */}
					{patientId ? (
						isPatientLoading || !patient.demographic ? (
							<Loading />
						) : (
							<PatientDetails
								stat={!!patient.stat}
								demographic={patient.demographic}
								encounter={patient.encounter}
								disableEncounter={disableEncounter}
								encountersLoading={isEncountersLoading}
								changeEncounterDisabled={changeEncounterDisabled}
								changePatientDisabled={changePatientDisabled}
								onChangeEncounterClick={onChangeEncounter}
								onPatientChangeClick={onPatientSelect}
							/>
						)
					) : (
						<PatientDetails
							customDemographics={patient.customDemographics}
							integratedPatient={patient.integratedPatient}
							onPatientChangeClick={onPatientSelect}
							onPatientUpdateClick={onPatientUpdate}
						/>
					)}
				</Box>
				<Box
					sx={{
						padding: '1rem 0.5rem',
					}}
				>
					{streamAudio ? (
						<BrowserAudioPlayer url={audioUrl} ref={audioPlayerRef} onProgress={onAudioProgress} />
					) : (
						<HowlerAudioPlayer
							url={audioUrl}
							ref={audioPlayerRef}
							onProgress={onAudioProgress}
							html5={streamAudio || html5Audio}
						/>
					)}
				</Box>
				<Box sx={{ display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
					<DictationSidebarTabs
						ref={sideBarRef}
						qaNotes={sidebar.qaNotes}
						disabledTabs={sidebar.disabledTabs}
						disableAddNewNote={sidebar.disableAddNewNote}
						canEditNoteIsDone={sidebar.canEditNoteIsDone}
						qaNotesIsEditing={sidebar.qaNotesIsEditing}
						handleQANoteIsEditing={sidebar.handleQANoteIsEditing}
						handleQANoteAdd={sidebar.handleQANoteAdd}
						handleQANotesChange={sidebar.handleQANotesChange}
						handleQANoteSubmit={sidebar.handleQANoteSubmit}
						handleQANoteMessageClick={handleTimestampMessageClick}
						transcription={sidebar.transcription}
						handleTranscriptMessageClick={handleTimestampMessageClick}
						providerNote={sidebar.providerNote}
						onChangeProviderNote={sidebar.onChangeProviderNote}
						aiPrompt={sidebar.aiPrompt}
					/>
				</Box>
			</Box>
		);
	};

export default forwardRef(DictationSideBar);
