import * as React from 'react';
import { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import DiagnosisTextField, { ITextField, ITextFieldProps } from './DiagnosisTextField';
import { DiagnoseTextFieldType } from './DiagnosisField';
import { namespaces } from '../../application/i18n.constants';
import { IDiagnoseFields } from '../../core/models/dictations/dictations.models';

interface IDiagnosisTextFieldWrapperProps extends Omit<ITextFieldProps, 'id' | 'ref' | 'name' | 'text' | 'onChange'> {
	id: DiagnoseTextFieldType;
	textId?: DiagnoseTextFieldType;
	fields: IDiagnoseFields;
	textFieldsRefs: { [fieldId: string]: RefObject<ITextField> };
	onTextValueChanged: ({ key, value }: { key: DiagnoseTextFieldType; value: string }) => void;
}
function DiagnosisTextFieldWrapper({
	id,
	textId,
	fields,
	textFieldsRefs,
	onTextValueChanged,
	...props
}: IDiagnosisTextFieldWrapperProps) {
	const { t } = useTranslation(namespaces.components.diagnosis);

	return (
		<DiagnosisTextField
			id={id}
			ref={textFieldsRefs[id]}
			name={textId ? t(textId) : t(id)}
			text={fields[id] || ''}
			onChange={(text: string) => onTextValueChanged({ key: id, value: text })}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		/>
	);
}

export default DiagnosisTextFieldWrapper;
