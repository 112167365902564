import React from 'react';
import { connect } from 'react-redux';
import SignInPage from './SingInPage';
import { actions } from '../../core/state/actions';
import { useAppDispatch, useAppSelector } from '../../core/core.types';
import { environment } from '../../environment/environment';
import { trackEvent } from '../../application/app-insights';

function SignIn() {
	const dispatch = useAppDispatch();
	const signInError = useAppSelector<string | undefined>((state) => state.auth.signIn.error);
	const isSignInLoading = useAppSelector<boolean>((state) => state.auth.signIn.loading);
	const mfaRequired = useAppSelector<boolean>((state) => state.auth.signIn.mfaRequired);
	const invalidPin = useAppSelector<boolean>((state) => state.auth.signIn.invalidMfaPin);
	const emailAddress = useAppSelector<string>((state) => state.auth.signIn.emailAddress);

	const onSingIn = (username: string, password: string, pin?: string) => {
		trackEvent('1001001', 'LogIn', { username });
		dispatch(actions.auth.signInRequestAction({ username, password, pin }));
	};
	const appVersion = environment.VERSION;
	return (
		<SignInPage
			appVersion={appVersion}
			onSingIn={onSingIn}
			signInError={signInError}
			loading={isSignInLoading}
			emailAddress={emailAddress}
			mfaRequired={mfaRequired}
			invalidPin={invalidPin}
		/>
	);
}

export default connect()(SignIn);
