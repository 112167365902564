import * as React from 'react';
import { useEffect, useState } from 'react';
import { sortBy } from 'lodash';
import { Alert, Box, Button, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { TableRowProps } from '@mui/material/TableRow/TableRow';
import { grey } from '@mui/material/colors';
import { TableCellProps } from '@mui/material/TableCell/TableCell';
import { dateFormat } from '../../core/core.constants';
import { namespaces } from '../../application/i18n.constants';
import DataTable, { IDataTableRow } from '../../components/data-table/DataTable';
import { IDictation } from '../../core/models/dictations/dictations.models';
import SelectProviders from '../../components/providers/SelectProviders';
import StatFlag from '../../components/icons/stat-flag.svg';
import { parseToValidDate } from '../../utils/date.utils';
import Spinner from '../../components/spinner/Spinner';
import ConfirmActionDialog from '../../components/confirm-action-dialog/ConfirmActionDialog';
import { IScriberPermissions, IUserEHRVerification } from '../../core/models/users/user.models';
import { DocumentType, JobStatus } from '../../core/models/dictations/document.models';
import { useJobMenu } from '../../components/jobs/JobMenu';
import PageContainer from '../../components/page-container/PageContainer';

function DictationsTable({
	permissions,
	dictations,
	canOpenJob,
	onJobClick,
	renderMenu,
}: {
	permissions: IScriberPermissions;
	dictations: IDictation[];
	canOpenJob: (dictation: IDictation) => boolean;
	onJobClick?: (job: IDictation) => void;
	renderMenu: (job: IDictation) => JSX.Element;
}) {
	const [jobs, setJobs] = useState<Array<IDictation & { remainingTAT: number; formattedRemainingTAT: string }>>([]);

	useEffect(() => {
		const updatedJobs = dictations.map((job) => {
			const createdDateTime = new Date(job.createdDateTime);
			const statOffset = 4 * 60 * 60 * 1000;
			const basicOffset = 24 * 60 * 60 * 1000;
			const remainingTAT = createdDateTime.getTime() + (job.stat ? statOffset : basicOffset) - Date.now();

			const hours = Math.floor(Math.abs(remainingTAT) / (1000 * 60 * 60));
			const minutes = Math.floor((Math.abs(remainingTAT) / (1000 * 60)) % 60);

			const formattedRemainingTAT = `${remainingTAT < 0 ? '-' : ''}${hours.toString().padStart(2, '0')}:${minutes
				.toString()
				.padStart(2, '0')}`;

			return {
				...job,
				remainingTAT,
				formattedRemainingTAT,
			};
		});

		const sortedJobs = sortBy(updatedJobs, [(job) => (job.stat ? 0 : 1), (job) => job.remainingTAT]);

		setJobs(sortedJobs);
	}, [dictations]);

	const { t } = useTranslation(namespaces.pages.jobs);
	const columns = [
		{
			name: t('patient'),
		},
		{
			name: t('provider'),
		},
		{
			name: t('apptDate'),
		},
		{
			name: t('dictatedDate'),
		},
		{
			name: t('encounterType'),
		},
		{
			name: t('lockedBy'),
		},
		{
			name: t('remainingTAT'),
		},
		{
			name: t('stat'),
		},
	];

	if (permissions.qa) {
		columns.push({
			name: t('qa'),
		});
	}

	if (permissions.admin) {
		columns.push({
			name: '',
		});
	}

	const rows = jobs.map((job) => {
		const canOpen = canOpenJob(job);
		const row: {
			data: IDataTableRow[];
			rowProps?: Partial<TableRowProps & { disabled?: boolean }>;
			cellProps?: Partial<TableCellProps>;
		} = {
			rowProps: {
				disabled: !canOpen,
				hover: canOpen,
			},
			cellProps: {
				sx: !canOpen
					? {
							color: grey['400'],
					  }
					: {},
			},
			data: [
				job.patientLastName && job.patientFirstName
					? {
							value: `${job.patientLastName} ${job.patientFirstName}`,
					  }
					: { value: t('unknownPatient') },
				{
					value: job.careProviderName,
				},
				{
					value: job.appointmentDateTime ? dayjs(parseToValidDate(job.appointmentDateTime)).format(dateFormat) : '',
				},
				{
					value: dayjs(job.createdDateTime).format(dateFormat),
				},
				{
					value: job.documentType,
				},
				{
					value: job.checkedOutBy || '',
				},
				{
					value: job.formattedRemainingTAT,
				},
				{
					value: job.stat ? <img alt="" src={StatFlag} width="15" /> : '',
				},
			],
		};

		if (permissions.qa) {
			row.data.push({
				value: job.markedForQA ? t('qa') : '',
			});
		}

		if (permissions.admin) {
			row.data.push({
				value: renderMenu(job as IDictation),
			});
		}

		return row;
	});

	const handleJobClick = onJobClick ? (rowId: number) => onJobClick(jobs[rowId]) : undefined;

	return rows.length > 0 ? (
		<DataTable
			columns={columns}
			rows={rows}
			onRowClick={handleJobClick}
			dataHiddenColumnIndices={[0]}
			tableContainerProps={{ sx: { height: '70vh', overflowY: 'auto' } }}
			buildRowKey={(rowIndex) => jobs[rowIndex].blobID}
		/>
	) : (
		<Typography>{t('noResults')}</Typography>
	);
}

interface JobsPageProps {
	permissions: IScriberPermissions;
	isLoading: boolean;
	isUnlocking: boolean;
	currentUsername: string;
	userHasSelectedProviders: boolean;
	dictations: IDictation[];
	ehrVerification: IUserEHRVerification;
	canOpenJob: (dictation: IDictation) => boolean;
	onJobOpen: (dictation: IDictation) => void;
	onJobUnlock: (dictation: IDictation) => void;
	onJobUnlockAndEdit: (dictation: IDictation) => void;
	onUpdateJob: (
		job: IDictation,
		update: {
			documentStatus?: JobStatus;
			documentTypeName?: DocumentType;
			stat?: boolean;
		}
	) => void;
}

function JobsPage({
	permissions,
	dictations,
	ehrVerification,
	userHasSelectedProviders,
	currentUsername,
	isLoading,
	isUnlocking,
	canOpenJob,
	onJobOpen,
	onJobUnlock,
	onJobUnlockAndEdit,
	onUpdateJob,
}: JobsPageProps) {
	const { t } = useTranslation(namespaces.pages.jobs);

	const [isSelectProvidersOpen, setIsSelectProvidersOpen] = React.useState(false);
	const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
	const [jobContext, setJobContext] = useState<IDictation | null>(null);

	const { renderDocumentStatusDialog, renderDocumentTypeDialog, renderMenu } = useJobMenu({
		username: currentUsername,
		onUpdateJob,
	});

	useEffect(() => {
		if (!confirmDialogOpen) {
			setJobContext(null);
		}
	}, [confirmDialogOpen]);

	const handleOpenSelectProviders = () => {
		setIsSelectProvidersOpen(true);
	};

	const handleCloseSelectProviders = () => {
		setIsSelectProvidersOpen(false);
	};

	const onUnlockJob = (dictation: IDictation) => {
		onJobUnlock(dictation);
		setConfirmDialogOpen(false);
	};
	const onUnlockAndEditJob = (dictation: IDictation) => {
		onJobUnlockAndEdit(dictation);
		setConfirmDialogOpen(false);
	};

	const handleJobClick = (dictation: IDictation) => {
		setJobContext(dictation);

		const canOpen = !dictation.isCheckedOut || currentUsername === dictation.checkedOutBy;

		if (canOpen) {
			onJobOpen(dictation);
		} else {
			setConfirmDialogOpen(true);
		}
	};

	return (
		<PageContainer
			disablePadding
			sx={{
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Box>
				{!ehrVerification.loading && ehrVerification.error && (
					<Alert severity="warning">{t('ehrLoginUnsuccessful')}</Alert>
				)}
				{!ehrVerification.loading && ehrVerification.daysUntilExpiration && (
					<Alert severity="warning">{t('credentialsExpiring')}</Alert>
				)}
			</Box>
			<Box
				sx={{
					display: 'flex',
					flex: 1,
					py: 4,
					justifyContent: 'center',
				}}
			>
				{!isLoading ? (
					<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
						{userHasSelectedProviders || permissions.aiAccess ? (
							<DictationsTable
								permissions={permissions}
								canOpenJob={canOpenJob}
								dictations={dictations}
								onJobClick={handleJobClick}
								renderMenu={renderMenu}
							/>
						) : (
							<Button type="button" variant="contained" sx={{ mt: 1, mb: 1 }} onClick={handleOpenSelectProviders}>
								{t('Select providers')}
							</Button>
						)}
						<SelectProviders open={isSelectProvidersOpen} onClose={handleCloseSelectProviders} />
					</Box>
				) : (
					<Box
						sx={{
							display: 'flex',
							flex: 1,
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Spinner />
					</Box>
				)}
			</Box>
			<ConfirmActionDialog
				open={confirmDialogOpen}
				isLoading={isUnlocking}
				title={isUnlocking ? t('unlockingJob') : t('isCheckedOutTitle')}
				text={t('isCheckedOutText', { name: jobContext?.checkedOutBy || 'another user' })}
				actions={
					permissions.admin
						? [
								{
									action: () => {
										if (jobContext) {
											onUnlockJob(jobContext);
										}
									},
									text: t('unlock'),
								},
								{
									action: () => {
										if (jobContext) {
											onUnlockAndEditJob(jobContext);
										}
									},
									text: t('unlockAndEdit'),
								},
						  ]
						: undefined
				}
				cancel={{
					action: () => {
						setConfirmDialogOpen(false);
					},
					text: t('cancel'),
				}}
			/>
			{renderDocumentStatusDialog}
			{renderDocumentTypeDialog}
		</PageContainer>
	);
}

export default JobsPage;
