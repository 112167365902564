import * as React from 'react';
import { ForwardedRef, forwardRef } from 'react';
import { EHRType } from '../../core/models/ehr.models';
import Diagnosis, { DiagnosisFeatureType, IDiagnosis, IDiagnosisProps } from './Diagnosis';
import { DiagnoseFieldType } from './DiagnosisField';
import { canEhrAddDiagnosis } from '../../core/services/documents/documents.services';

interface IEhrDiagnosis extends IDiagnosis {}
interface IEhrDiagnosisPros extends Omit<IDiagnosisProps, 'ehr' | 'activeInputs'> {
	ehr: EHRType;
}

const EhrDiagnosis: React.ForwardRefRenderFunction<IEhrDiagnosis, IEhrDiagnosisPros> = function EhrDiagnosisFunc(
	{ ehr, ...props }: IEhrDiagnosisPros,
	forwardedRef: ForwardedRef<IEhrDiagnosis>
) {
	const disabledFeatures: DiagnosisFeatureType[] = canEhrAddDiagnosis(ehr) ? [] : ['addDiagnosis'];

	if (ehr === EHRType.NextGen) {
		return (
			<Diagnosis
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
				ref={forwardedRef}
				ehr={ehr}
				disabledFeatures={disabledFeatures}
				activeInputs={['hpiSketchPad', 'rosSketchPad', 'peSketchPad', 'assessment', 'plan']}
				onPull={undefined}
			/>
		);
	}

	if (ehr === EHRType.AllscriptsTW) {
		return (
			<Diagnosis
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
				ref={forwardedRef}
				ehr={ehr}
				disabledFeatures={disabledFeatures}
				activeInputs={['hpiSketchPad', 'rosSketchPad', 'peSketchPad', 'assessmentPlan']}
				onPull={undefined}
			/>
		);
	}

	if (ehr === EHRType.AllscriptsPro) {
		return (
			<Diagnosis
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
				ref={forwardedRef}
				ehr={ehr}
				disabledFeatures={disabledFeatures}
				activeInputs={['hpiSketchPad', 'rosSketchPad', 'peSketchPad', 'assessmentPlan']}
				onPull={undefined}
			/>
		);
	}
	const activeInputs: DiagnoseFieldType[] = [
		'hpiSketchPad',
		'rosSketchPad',
		'peSketchPad',
		'patientGoals',
		'patientInstructions',
		'discussionNotes',
		'procedureDocumentation',
		'assessmentPlan',
	];
	// eslint-disable-next-line react/jsx-props-no-spreading
	return <Diagnosis {...props} ref={forwardedRef} ehr={ehr} activeInputs={activeInputs} />;
};

export default forwardRef(EhrDiagnosis);
