import { CircularProgress, Grid, SxProps, Theme, Typography } from '@mui/material';
import * as React from 'react';
import { ReactNode } from 'react';

export interface IContentContainerProps {
	children?: ReactNode;
	loading?: boolean;
	error?: string | 'pageNotFound';
	sx?: SxProps<Theme>;
	disablePadding?: boolean;
}

function ContentContainer({ children, sx, disablePadding, loading, error }: IContentContainerProps) {
	const loadingSx =
		loading || error
			? {
					display: 'flex',
					flex: 1,
					justifyContent: 'center',
					alignItems: 'center',
			  }
			: {};
	return (
		<Grid container sx={{ display: 'flex', flex: 1 }}>
			<Grid
				item
				sx={{
					display: 'flex',
					flex: 1,
					p: disablePadding ? 0 : 7,
					...loadingSx,
					...sx,
				}}
			>
				{/* eslint-disable-next-line no-nested-ternary */}
				{loading ? <CircularProgress /> : error ? <Typography variant="h4">{error}</Typography> : children}
			</Grid>
		</Grid>
	);
}
export default ContentContainer;
