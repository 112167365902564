export const worklistConstants = {
	INIT_WORKLIST: 'WORKLIST/INIT_WORKLIST',

	SET_SETTINGS: 'WORKLIST/SET_SETTINGS',

	SET_CLINIC: 'WORKLIST/SET_CLINIC',
	INIT_DICTATIONS: 'WORKLIST/INIT_DICTATIONS',

	GET_PROVIDERS: 'WORKLIST/GET_PROVIDERS',
	SET_PROVIDERS_IS_LOADING: 'WORKLIST/SET_PROVIDERS_IS_LOADING',
	SET_PROVIDERS: 'WORKLIST/SET_PROVIDERS',
	SET_SELECTED_PROVIDERS: 'WORKLIST/SET_SELECTED_PROVIDERS',

	GET_PROCEDURE_DOCUMENTATION_TEMPLATES: 'WORKLIST/GET_PROCEDURE_DOCUMENTATION_TEMPLATES',
	SET_PROCEDURE_DOCUMENTATION_TEMPLATES_IS_LOADING: 'WORKLIST/SET_PROCEDURE_DOCUMENTATION_TEMPLATES_IS_LOADING',
	SET_PROCEDURE_DOCUMENTATION_TEMPLATES: 'WORKLIST/SET_PROCEDURE_DOCUMENTATION_TEMPLATES',
	APPLY_PROCEDURE_DOCUMENTATION_TEMPLATE: 'WORKLIST/APPLY_PROCEDURE_DOCUMENTATION_TEMPLATE',
	SET_APPLY_PROCEDURE_DOCUMENTATION_TEMPLATE_IS_LOADING:
		'WORKLIST/SET_APPLY_PROCEDURE_DOCUMENTATION_TEMPLATE_IS_LOADING',
	SAVE_PROCEDURE_DOCUMENTATION: 'WORKLIST/SAVE_PROCEDURE_DOCUMENTATION',
	SET_SAVE_PROCEDURE_DOCUMENTATION_IS_LOADING: 'WORKLIST/SET_SAVE_PROCEDURE_DOCUMENTATION_IS_LOADING',

	SET_DICTATIONS_IS_LOADING: 'WORKLIST/SET_DICTATIONS_IS_LOADING',
	GET_DICTATIONS: 'WORKLIST/GET_DICTATIONS',
	SET_DICTATIONS: 'WORKLIST/SET_DICTATIONS',

	SET_CURRENT_DICTATION: 'WORKLIST/SET_CURRENT_DICTATION',

	CLOSE_DICTATION: 'WORKLIST/CLOSE_DICTATION',
	SET_UNLOCK_DOCUMENT_IS_LOADING: 'SET_UNLOCK_DOCUMENT_IS_LOADING',
	UNLOCK_AND_OPEN_DICTATION: 'WORKLIST/UNLOCK_AND_OPEN_DICTATION',

	SET_EDITED_DOCUMENT: 'WORKLIST/SET_EDITED_DOCUMENT',
	SET_REORDER_DIAGNOSES: 'WORKLIST/SET_REORDER_DIAGNOSES',

	OPEN_DICTATION_DOCUMENT: 'WORKLIST/OPEN_DICTATION_DOCUMENT',

	SET_PATIENT_DEMOGRAPHIC_IS_LOADING: 'WORKLIST/SET_PATIENT_DEMOGRAPHIC_IS_LOADING',
	SET_PATIENT_DEMOGRAPHIC: 'WORKLIST/SET_PATIENT_DEMOGRAPHIC',

	SET_PATIENT_ENCOUNTERS_IS_LOADING: 'WORKLIST/SET_PATIENT_ENCOUNTERS_IS_LOADING',
	SET_PATIENT_ENCOUNTERS: 'WORKLIST/SET_PATIENT_ENCOUNTERS',

	SET_PATIENT_PREVIEW_DOCUMENT_IS_LOADING: 'WORKLIST/SET_PATIENT_PREVIEW_DOCUMENT_IS_LOADING',
	GET_PATIENT_PREVIEW_DOCUMENT: 'WORKLIST/GET_PATIENT_PREVIEW_DOCUMENT',
	SET_PATIENT_PREVIEW_DOCUMENT: 'WORKLIST/SET_PATIENT_PREVIEW_DOCUMENT',

	OPEN_DICTATION: 'WORKLIST/OPEN_DICTATION',

	GET_DOCUMENT_DETAILS: 'WORKLIST/GET_DOCUMENT_DETAILS',
	SET_DOCUMENT_LOADING: 'WORKLIST/SET_DOCUMENT_LOADING',
	SET_DOCUMENT_DETAILS: 'WORKLIST/SET_DOCUMENT_DETAILS',
	PULL_DOCUMENT_FILED: 'WORKLIST/PULL_DOCUMENT_FIELD',
	PULL_DOCUMENT_FILED_IS_LOADING: 'WORKLIST/PULL_DOCUMENT_FILED_IS_LOADING',
	SET_PULL_ASSESSMENT_PLAN_DIAGNOSES: 'WORKLIST/SET_PULL_ASSESSMENT_PLAN_DIAGNOSES',
	MERGE_PULLED_ASSESSMENT_PLAN: 'WORKLIST/MERGE_PULLED_ASSESSMENT_PLAN',

	SET_DOCUMENT_FIELDS: 'WORKLIST/SET_DOCUMENT_FIELDS',
	SAVE_DOCUMENT_DETAILS: 'WORKLIST/SAVE_DOCUMENT_DETAILS',
	SET_DOCUMENT_SAVE_LOADING: 'WORKLIST/SET_DOCUMENT_SAVE_LOADING',
	SET_DOCUMENT_SAVE_ERROR: 'WORKLIST/SET_DOCUMENT_SAVE_ERROR',

	OPEN_PATIENT_SEARCH: 'WORKLIST/OPEN_PATIENT_SEARCH',
	SET_DICTATION_PATIENT: 'WORKLIST/SET_DICTATION_PATIENT',
	SET_CURRENT_ENCOUNTER: 'WORKLIST/SET_CURRENT_ENCOUNTER',
	UPDATE_DICTATION_ENCOUNTER: 'WORKLIST/UPDATE_DICTATION_ENCOUNTER',
	SET_DICTATION_ENCOUNTER_IS_LOADING: 'WORKLIST/SET_DICTATION_ENCOUNTER_IS_LOADING',

	WORKLIST_PATIENT_SEARCH_REQUEST: 'WORKLIST/PATIENT_SEARCH_REQUEST',
	WORKLIST_PATIENT_SEARCH_IS_LOADING: 'WORKLIST/PATIENT_SEARCH_IS_LOADING',
	WORKLIST_PATIENT_SET_RESULTS: 'WORKLIST/PATIENT_SET_RESULTS',

	SEARCH_DIAGNOSES: 'WORKLIST/SEARCH_DIAGNOSES',
	SET_SEARCH_DIAGNOSES_RESULT: 'WORKLIST/SET_SEARCH_DIAGNOSES_RESULT',
	SET_SEARCH_DIAGNOSES_IS_LOADING: 'WORKLIST/SET_SEARCH_DIAGNOSES_IS_LOADING',

	SEARCH_ORDERS: 'WORKLIST/SEARCH_ORDERS',
	SET_SEARCH_ORDERS_RESULT: 'WORKLIST/SET_SEARCH_ORDERS_RESULT',
	SET_SEARCH_ORDERS_IS_LOADING: 'WORKLIST/SET_SEARCH_ORDERS_IS_LOADING',
	SET_UNASSIGNED_ORDERS: 'SET_UNASSIGNED_ORDERS',

	GET_ORDER_SETS: 'WORKLIST/GET_ORDER_SETS',
	SET_ORDER_SETS_RESULT: 'WORKLIST/SET_ORDER_SETS_RESULT',
	SET_ORDER_SETS_IS_LOADING: 'WORKLIST/SET_ORDER_SETS_IS_LOADING',
	APPLY_ORDER_SET: 'WORKLIST/APPLY_ORDER_SET',
	SET_APPLY_ORDER_SET_IS_LOADING: 'WORKLIST/SET_APPLY_ORDER_SET_IS_LOADING',

	SEARCH_PROCEDURE_CODE: 'WORKLIST/SEARCH_PROCEDURE_CODE',
	SET_SEARCH_PROCEDURE_CODE_RESULT: 'WORKLIST/SET_SEARCH_PROCEDURE_CODE_RESULT',
	SET_SEARCH_PROCEDURE_CODE_IS_LOADING: 'WORKLIST/SET_SEARCH_PROCEDURE_CODE_IS_LOADING',

	GET_BILLING_CODES: 'WORKLIST/GET_BILLING_CODES',
	SET_BILLING_CODES: 'WORKLIST/SET_BILLING_CODES',

	GET_BILLING_MODIFIERS: 'WORKLIST/GET_BILLING_MODIFIERS',
	SET_BILLING_MODIFIERS_SEARCH_RESULT: 'WORKLIST/SET_BILLING_MODIFIERS_SEARCH_RESULT',
	SET_BILLING_MODIFIERS_IS_LOADING: 'WORKLIST/SET_BILLING_MODIFIERS_IS_LOADING',

	SET_QA_NOTES: 'WORKLIST/SET_QA_NOTES',
	SET_TRANSCRIPT: 'WORKLIST/SET_TRANSCRIPT',
	SET_PROVIDER_NOTE: 'WORKLIST/SET_PROVIDER_NOTE',
	SET_CUSTOM_PATIENT_DEMOGRAPHICS: 'WORKLIST/SET_CUSTOM_PATIENT_DEMOGRAPHICS',
	UPDATE_CUSTOM_PATIENT_DEMOGRAPHICS: 'WORKLIST/UPDATE_CUSTOM_PATIENT_DEMOGRAPHICS',

	SET_AI_PROMPT: 'WORKLIST/SET_AI_PROMPT',
	SET_AI_INSTRUCTIONS: 'WORKLIST/SET_AI_INSTRUCTIONS',
	SET_LAST_TIME_PROMPT_SUBMITTED: 'WORKLIST/SET_LAST_TIME_PROMPT_SUBMITTED',
	REQUEST_AI_SUMMARY_FOR_PROMPT: 'WORKLIST/REQUEST_AI_SUMMARY_FOR_PROMPT',
	UPDATE_PROMPT_AI_SUMMARY: 'WORKLIST/UPDATE_PROMPT_AI_SUMMARY',
	SET_PROMPT_AI_SUMMARY: 'WORKLIST/SET_PROMPT_AI_SUMMARY',

	GET_DOCUMENT_PROVIDER_DOTPHRASES: 'WORKLIST/GET_DOCUMENT_PROVIDER_DOTPHRASES',
	SET_DOCUMENT_PROVIDER_DOTPHRASES: 'WORKLIST/SET_DOCUMENT_PROVIDER_DOTPHRASES',
};
