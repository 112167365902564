import { IconButton } from '@mui/material';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import React from 'react';

function SeekIcon({ type, onClick }: { type: 'back' | 'forward'; onClick: () => void }) {
	return (
		<IconButton
			aria-label="seek"
			size="small"
			sx={{ transform: type === 'forward' ? 'rotate(180deg) scale(1, -1)' : '', mx: 0.25 }}
			onClick={onClick}
		>
			<SettingsBackupRestoreIcon sx={{ fontSize: '1.5rem' }} />
		</IconButton>
	);
}

export default SeekIcon;
