import React, { useState } from 'react';
import { connect } from 'react-redux';
import AccountSettingsPage from './AccountSettingsPage';
import { IProvider } from '../../core/models/providers/providers.models';
import { useAppSelector } from '../../core/core.types';
import {
	getIsHtml5AudioCache,
	getIsStreamAudioCache,
	setIsHtml5AudioCache,
	setIsStreamAudioCache,
} from '../../system/local-storage';

function AccountSettings() {
	const username = useAppSelector<string>((state) => state.user.settings.username);
	const selectedProviders = useAppSelector<IProvider[]>((state) => state.worklist.selectedProviders);
	const providersAreEditable = useAppSelector<boolean>((state) => state.user.providersAreEditable);
	const [isStreamAudio, setIsStreamAudio] = useState<boolean>(getIsStreamAudioCache());
	const [istHtml5Audio, setIstHtml5Audio] = useState<boolean>(getIsHtml5AudioCache());

	const onIsStreamAudioChange = (stream: boolean) => {
		setIsStreamAudio(stream);
		setIsStreamAudioCache(stream);
	};

	const onIsHtml5AudioChange = (html5Audio: boolean) => {
		setIstHtml5Audio(html5Audio);
		setIsHtml5AudioCache(html5Audio);
	};

	return (
		<AccountSettingsPage
			selectedProviders={selectedProviders}
			username={username}
			providersAreEditable={providersAreEditable}
			isStreamAudio={isStreamAudio}
			isHtml5Audio={istHtml5Audio}
			onIsStreamAudioChange={onIsStreamAudioChange}
			onIsHtml5AudioChange={onIsHtml5AudioChange}
		/>
	);
}

export default connect()(AccountSettings);
