import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ListItemText } from '@mui/material';
import { connect } from 'react-redux';
import { namespaces } from '../../application/i18n.constants';
import { IProvider } from '../../core/models/providers/providers.models';
import { actions } from '../../core/state/actions';
import { useAppDispatch, useAppSelector } from '../../core/core.types';
import { worklistActions } from '../../core/state/worklist/worklist.actions';
import Dialog from '../dialog/Dialog';
import ChipItemsList from '../list/ChipItemsList';
import SearchBar from '../search-bar/SearchBar';

interface ISelectProvidersProps {
	open: boolean;
	onClose?: () => void;
}

function SelectProviders({ open, onClose }: ISelectProvidersProps) {
	const dispatch = useAppDispatch();
	const username = useAppSelector<string>((state) => state.user.settings.username);
	const customerId = useAppSelector<string>((state) => state.user.settings.customerId);
	const loading = useAppSelector<boolean>((state) => state.worklist.providersIsLoading);
	const selectedProviders = useAppSelector<IProvider[]>((state) => state.worklist.selectedProviders);
	const allProviders = useAppSelector<IProvider[]>((state) => state.worklist.allProviders);
	const { t } = useTranslation(namespaces.components.selectProviders);

	const [searchQuery, setSearchQuery] = React.useState<string | undefined>(undefined);

	useEffect(() => {
		if (open) {
			dispatch(worklistActions.getProvidersAction());
		}
	}, [open]);

	const setSelectedProviders = (providersList: IProvider[]) => {
		dispatch(
			actions.worklist.setSelectedProvidersAction({ username, customerId, providers: providersList, cache: true })
		);
	};

	const handleAddProviders = (provider: IProvider) => {
		if (!selectedProviders.find((x) => x.id === provider.id)) {
			setSelectedProviders([...selectedProviders, provider]);
		}
	};

	const onDialogClose = () => {
		dispatch(actions.worklist.getDictationsAction({}));
		if (onClose) {
			onClose();
		}
	};

	return (
		<Dialog fullWidth maxWidth="sm" onClose={onDialogClose} open={open} title={t('selectProviders')}>
			<SearchBar onSearch={setSearchQuery} searchDelay={250} />
			<ChipItemsList
				button
				loading={loading}
				items={allProviders
					.filter((x) => !searchQuery || x.name.toLowerCase().includes(searchQuery.toLowerCase()))
					.map((x) => ({ ...x, title: x.name }))}
				selected={selectedProviders.map((x) => ({ ...x, id: x.id, title: x.name }))}
				deletable={selectedProviders.map((x) => ({ ...x, title: x.name, id: x.id }))}
				onDelete={(id) => setSelectedProviders(selectedProviders.filter((x) => x.id !== id))}
				onClick={(item) => handleAddProviders(item)}
				renderItem={(provider) => <ListItemText primary={`${provider.name}`} />}
			/>
		</Dialog>
	);
}

SelectProviders.defaultProps = {
	onClose: undefined,
};

export default connect()(SelectProviders);
