import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, IconButton, Typography } from '@mui/material';

interface INumericInputProps {
	value: number;
	maxValue: number;
	minValue: number;
	step: number;
	format: (x: number) => string;
	onChange: (x: number) => void;
}
function NumericInput({ value, maxValue, minValue, step, format, onChange }: INumericInputProps) {
	const handleIncrement = () => {
		const newValue = value + step;
		onChange(newValue);
	};

	const handleDecrement = () => {
		const newValue = value - step;
		onChange(newValue);
	};

	return (
		<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
			<IconButton disabled={value <= minValue} size="small" sx={{ height: 10, width: 10 }} onClick={handleDecrement}>
				<KeyboardArrowDownIcon />
			</IconButton>
			<Typography
				fontSize="0.85rem"
				sx={{ px: 0.5, width: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
			>
				{format(value)}
			</Typography>
			<IconButton
				disabled={value >= maxValue}
				size="small"
				sx={{ height: 10, width: 10, fontSize: 5 }}
				onClick={handleIncrement}
			>
				<KeyboardArrowUpIcon />
			</IconButton>
		</Box>
	);
}
export default NumericInput;
