import { IProvider } from '../core/models/providers/providers.models';
import { TranscriptionOption } from '../core/models/dictations/dictations.models';
import { IUserSettings } from '../core/models/users/user.models';

// Bearer
export function setBearerCache(token: string): void {
	localStorage.setItem('bearer', token);
	window.dispatchEvent(new Event('storage'));
}

export function getBearerCache(): string | null {
	return localStorage.getItem('bearer');
}

export function deleteBearerCache(): void {
	localStorage.removeItem('bearer');
	window.dispatchEvent(new Event('storage'));
}

// Authorization token
export function setMfaAuthorizationTokenKeyCache(mfaAuthorizationToken: string) {
	localStorage.setItem('mfaAuthorizationToken', mfaAuthorizationToken);
}

export function getMfaAuthorizationTokenKeyCache(): string | null {
	return localStorage.getItem('mfaAuthorizationToken');
}

// API Key
export function setApiKeyCache(apiKey: string) {
	localStorage.setItem('apiKey', apiKey);
}

export function getApiKeyCache(): string | null {
	return localStorage.getItem('apiKey');
}

export function deleteApiKeyCache(): void {
	localStorage.removeItem('apiKey');
}

// IsAuthenticated Flag
export function setIsAuthenticatedCache(isAuthenticated: boolean) {
	localStorage.setItem('isAuthenticated', isAuthenticated.toString());
}

export function getIsAuthenticatedCache(): boolean {
	return localStorage.getItem('isAuthenticated') === 'true';
}

export function deleteIsAuthenticatedCache(): void {
	localStorage.removeItem('isAuthenticated');
}

// User settings
export function setUserSettingsCache(userSettings: IUserSettings): void {
	localStorage.setItem('userSettings', JSON.stringify(userSettings));
}

export function getUserSettingsCache(): IUserSettings | null {
	const settings = localStorage.getItem('userSettings');
	return settings ? JSON.parse(settings) : null;
}

export function deleteUserSettingsCache(): void {
	localStorage.removeItem('userSettings');
}

// User clinic
export function setUserSelectedClinicCache(username: string, customerId: string): void {
	localStorage.setItem(`selectedClinic_${username}`, customerId);
}

export function getUserSelectedClinicCache(username: string): string | null {
	return localStorage.getItem(`selectedClinic_${username}`);
}

export function deleteSelectedClinicCache(username: string): void {
	localStorage.removeItem(`selectedClinic_${username}`);
}

// Providers
export function setProvidersCache(username: string, customerId: string, providers: IProvider[]): void {
	localStorage.setItem(`providers_list_v2_${username}_${customerId}`, JSON.stringify(providers.filter((x) => x.id)));
}

export function getProvidersCache(username: string, customerId: string): IProvider[] {
	const cacheProviders = localStorage.getItem(`providers_list_v2_${username}_${customerId}`);
	const result: IProvider[] = cacheProviders ? JSON.parse(cacheProviders) : [];
	return result.filter((x) => x.id);
}

// Object

export function setObjectCache<T>(key: string, dictation: T): void {
	localStorage.setItem(key, JSON.stringify(dictation));
}

export function getObjectCache<T>(key: string): T | null {
	const cacheProviders = localStorage.getItem(key);
	return cacheProviders ? JSON.parse(cacheProviders) : null;
}

// Activity
export function setLastActivityCache(timestamp: number) {
	localStorage.setItem('lastActivity', timestamp.toString());
}

export function getLastActivityCache(): number | null {
	const cachedActivityTimestamp = localStorage.getItem('lastActivity');
	return cachedActivityTimestamp ? parseInt(cachedActivityTimestamp, 10) : null;
}

export function deleteLastActivityCache(): void {
	localStorage.removeItem('lastActivity');
}

// Audio player
export function setIsStreamAudioCache(streamAudio: boolean) {
	localStorage.setItem('streamAudio', streamAudio.toString());
}

export function getIsStreamAudioCache(): boolean {
	return localStorage.getItem('streamAudio') === 'true';
}

export function deleteIsStreamAudioCache(): void {
	localStorage.removeItem('streamAudio');
}

export function setIsHtml5AudioCache(html5audio: boolean) {
	localStorage.setItem('html5Audio', html5audio.toString());
}

export function getIsHtml5AudioCache(): boolean {
	return localStorage.getItem('html5Audio') !== 'false';
}

export function deleteIsHtml5AudioCache(): void {
	localStorage.removeItem('html5Audio');
}

// Transcription option
export function setTranscriptionOptionCache(option: TranscriptionOption) {
	localStorage.setItem('transcriptionOption', option);
}

export function getTranscriptionOptionCache(): TranscriptionOption | null {
	return (localStorage.getItem('transcriptionOption') as TranscriptionOption) || null;
}

// Transcription option
export function setResetPasswordAttemptsCache(attempts: { attempts: number; lastAttempt: Date | null }) {
	localStorage.setItem(
		'resetPasswordAttempts',
		JSON.stringify({
			attempts: attempts.attempts,
			lastAttempt: attempts.lastAttempt ? attempts.lastAttempt.getTime() : attempts.lastAttempt,
		})
	);
}

export function getResetPasswordAttemptsCache(): { attempts: number; lastAttempt: Date | null } | null {
	const attempts = localStorage.getItem('resetPasswordAttempts');
	if (attempts) {
		const result = JSON.parse(attempts) as { attempts: number; lastAttempt: Date | null };
		return { attempts: result.attempts, lastAttempt: result.lastAttempt ? new Date(result.lastAttempt) : null };
	}
	return null;
}

// General
export function deleteUserCache() {
	deleteBearerCache();
	deleteApiKeyCache();
	deleteIsAuthenticatedCache();
	deleteUserSettingsCache();
	deleteLastActivityCache();
}
