import { Autocomplete, TextField } from '@mui/material';
import * as React from 'react';
import { AutocompleteProps } from '@mui/material/Autocomplete/Autocomplete';
import { BaseTextFieldProps } from '@mui/material/TextField/TextField';

export interface IItem {
	value: string;
	text: string;
}

interface ISelectFieldProps extends Partial<AutocompleteProps<string, false, true, false>> {
	value?: string;
	data: IItem[];
	textFieldProps?: Partial<BaseTextFieldProps>;
}

function AutocompleteSelectField({ data, value, textFieldProps, ...props }: ISelectFieldProps) {
	const [displayAllOptions, setDisplayAllOptions] = React.useState<boolean>(false);

	const displayOptionsProps = displayAllOptions ? { filterOptions: (options: string[]) => options } : {};

	return (
		<Autocomplete
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...displayOptionsProps}
			autoHighlight
			getOptionLabel={(option) => data.find((x) => x.value === option)?.text || ''}
			options={data.map((x) => x.value)}
			fullWidth
			value={value || ''}
			size="medium"
			renderInput={(params) => (
				<TextField
					onFocus={() => setDisplayAllOptions(true)}
					onChange={() => {
						setDisplayAllOptions(false);
					}}
					onBlur={() => setDisplayAllOptions(false)}
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...params}
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...textFieldProps}
					// eslint-disable-next-line react/jsx-props-no-spreading,@typescript-eslint/ban-ts-comment
					// @ts-ignore
					InputProps={{ ...params.InputProps, ...textFieldProps?.InputProps }}
					variant="outlined"
				/>
			)}
		/>
	);
}

export default AutocompleteSelectField;
