import { Action } from 'redux';
import { authActions } from './auth/auth.actions';
import { userActions } from './user/user.actions';
import { appActions } from './app/app.actions';
import { worklistActions } from './worklist/worklist.actions';
import { patientsActions } from './patients/patients.actions';
import { adminActions } from './admin/admin.actions';
import { jobsActions } from './jobs/jobs.actions';

export interface IAction<T> extends Action {
	payload: T;
}

export const actions = {
	app: appActions,
	auth: authActions,
	user: userActions,
	worklist: worklistActions,
	patients: patientsActions,
	jobs: jobsActions,
	admin: adminActions,
};
