import * as React from 'react';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../core/core.types';
import {
	ICreateMacroPayload,
	IMacro,
	IUpdateMacroPayload,
	MacroSectionsList,
} from '../../../core/models/macros/macros.models';
import { IProvider } from '../../../core/models/providers/providers.models';
import { actions } from '../../../core/state/actions';
import MacrosPage from '../MacrosPage';
import { IScriberPermissions } from '../../../core/models/users/user.models';

function ProviderMacros() {
	const dispatch = useAppDispatch();

	const currentClinicId = useAppSelector<string>((state) => state.user.settings.customerId);
	const userInfoId = useAppSelector<string>((state) => state.user.settings.userInfoId);
	const permissions = useAppSelector<IScriberPermissions>((state) => state.user.settings.permissions);

	const providerMacros = useAppSelector<IMacro[]>((state) => state.admin.macros.providerMacros);
	const providerMacrosIsLoading = useAppSelector<boolean>((state) => state.admin.macros.providerMacrosIsLoading);
	const saveProviderMacroIsLoading = useAppSelector<boolean>((state) => state.admin.macros.saveProviderMacrosIsLoading);
	const allProviders = useAppSelector<IProvider[]>((state) => state.admin.providers);
	const providersAreLoading = useAppSelector<boolean>((state) => state.admin.providersIsLoading);

	const onDotPhraseAdd = (macro: ICreateMacroPayload) => {
		dispatch(actions.admin.saveProviderMacro(macro));
	};

	const onDotPhraseUpdate = (macro: IUpdateMacroPayload) => {
		dispatch(actions.admin.updateProviderMacro(macro));
	};

	const onDotPhraseDelete = (macroId: number) => {
		dispatch(actions.admin.deleteProviderMacro({ macroId }));
	};

	const onSelectProvider = (providerId: string) => {
		if (providerId) {
			dispatch(actions.admin.getProviderMacrosAction({ providerId }));
		} else {
			dispatch(actions.admin.setProviderMacros([]));
		}
	};

	useEffect(() => {
		if (userInfoId && !permissions.admin) {
			onSelectProvider(userInfoId);
		}
	}, [userInfoId, permissions.admin]);

	const onMacroImport = (params: { file: File; providerId: string }) => {
		if (params.providerId) {
			dispatch(actions.admin.importProviderMacrosAction({ providerId: params.providerId, file: params.file }));
		}
	};

	const onCopyMacros = (providerId: string, macros: IMacro[]) => {
		dispatch(actions.admin.copyProviderMacrosAction({ providerId, macros }));
	};

	useEffect(() => {
		if (!providersAreLoading) {
			dispatch(actions.admin.getProvidersAction());
		}
	}, [currentClinicId]);

	return (
		<MacrosPage
			shortCodeDisabled={!permissions.admin}
			macros={providerMacros.filter((macro) => MacroSectionsList.includes(macro.section))}
			macrosIsLoading={providerMacrosIsLoading}
			saveMacroIsLoading={saveProviderMacroIsLoading}
			onMacroAdd={onDotPhraseAdd}
			onMacroUpdate={onDotPhraseUpdate}
			onMacroDelete={onDotPhraseDelete}
			onMacroImport={onMacroImport}
			providers={{
				disabled: !permissions.admin,
				selectedProvider: !permissions.admin ? userInfoId : undefined,
				allProviders,
				providersAreLoading,
				onSelectProvider,
				onCopyMacros,
			}}
		/>
	);
}

export default ProviderMacros;
