const easing = {
	easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
	easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
	easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
	sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
};

const transitions = {
	easing,
};

export default transitions;
