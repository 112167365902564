import { call, put } from 'redux-saga/effects';
import { actions, IAction } from '../actions';
import { IPatientsAdvancedSearchFilters, IPatientsSearchResult } from '../../models/patients/patients.models';
import { IServiceResult } from '../../models/service.models';
import { patientSearch } from '../../api/patients/patients.api';
import { handleGlobalException } from '../../../application/exception-handlers';
import { IPatientSearchQuery } from '../../api/patients/patients.api.models';

export function* searchPatientSaga(action: IAction<IPatientsAdvancedSearchFilters>) {
	try {
		yield put(actions.patients.patientSearchIsLoadingAction(true));

		const body: IPatientSearchQuery = {
			firstName: action.payload.firstName,
			lastName: action.payload.lastName,
			DOB: action.payload.DOB,
			patientID: action.payload.patientID,
			patientName: '',
			gender: '',
			SSN: '',
			pageSize: 100,
			pageStart: 0,
			searchCriteria: [],
			medicalRecordNumber: '',
		};
		const result: IServiceResult<IPatientsSearchResult[]> = yield call(patientSearch, body);
		if (result.success) {
			yield put(actions.patients.setPatientSearchResultAction({ patients: result.data }));
		}
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.patients.patientSearchIsLoadingAction(false));
	}
}
