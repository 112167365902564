import { all, call, fork, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { actions, IAction } from '../actions';
import {
	ICustomPatientDemographicsData,
	IPatientChartDocuments,
	IPatientDemographic,
	IPatientEncounter,
	IPatientsAdvancedSearchFilters,
	IPatientsChartSearch,
	IPatientsSearchResult,
} from '../../models/patients/patients.models';
import {
	IApplyProcedureDocumentationTemplateActionPayload,
	IGetDocumentDetailsActionPayload,
	IGetDocumentProviderDotPhrasesActionPayload,
	IGetPatientPreviewDocumentActionPayload,
	IPullCurrentDocumentFiledActionPayload,
	IRequestAISummaryForPromptActionPayload,
	ISaveDocumentActionPayload,
	ISaveProcedureDocumentationActionPayload,
	ISetClinicActionPayload,
	ISetDictationEncounterActionPayload,
	ISetDictationPatientActionPayload,
	ISetSelectedProvidersActionPayload,
	IUpdateCustomPatientDemographicsActionPayload,
	IUpdatePromptAISummaryActionPayload,
} from './worklist.actions';

import { handleException, handleGlobalException } from '../../../application/exception-handlers';
import { getProvidersV2 } from '../../api/providers/providers.api';
import { patientChartSearch, patientSearch, updateCustomPatientDemographics } from '../../api/patients/patients.api';
import { IProvider } from '../../models/providers/providers.models';
import {
	getProvidersCache,
	getUserSelectedClinicCache,
	setProvidersCache,
	setUserSelectedClinicCache,
	setUserSettingsCache,
} from '../../../system/local-storage';
import { IServiceResult } from '../../models/service.models';
import {
	IBillingCode,
	IBillingModifier,
	IDepartmentDictation,
	IDiagnoseField,
	IDiagnoseFields,
	IDictation,
	IOpenAISummary,
	IOrderField,
	IOrderSearchResult,
	IOrdersSearchQuery,
	IProcedureCode,
	IProcedureDocumentationField,
	IProcedureDocumentationTemplate,
	IQANote,
	IUnrankedDiagnoseField,
} from '../../models/dictations/dictations.models';
import {
	documentCancelCheckOut,
	documentCheckIn,
	documentCheckOut,
	getBillingCodes,
	getBillingModifiers,
	getDocument,
	getDocumentDetails,
	getEncounter,
	getOpenAISummary,
	getOrderSetDetails,
	getProcedureDocumentation,
	getProcedureDocumentationTemplates,
	getProvidersDictations,
	imoProblemSearch,
	ordersSearch,
	procedureCodeSearch,
	requestAISummary,
	saveBillingCodes,
	saveDocument,
	saveProcedureDocumentation,
} from '../../api/dictations/dictations.api';
import { AppState } from '../../core.types';
import {
	IApiQANote,
	IDocProcedureDocumentationRequest,
	IDocumentCheckInRequest,
	IDocumentCheckInResponse,
	IDocumentCheckOutResult,
	IDocumentTemplate,
	IEncounterData,
	IGetDocumentDetailsRequest,
	IGetDocumentDetailsResponse,
	IimoProblemSearchResponse,
	IOrderDetailsResponse,
	IOrderSearchResponse,
	IPreviewDocumentResponse,
	IRequestAISummaryRequest,
	ISaveBillingDetailsResponse,
	ISaveDocumentRequest,
	ISaveDocumentResponse,
} from '../../api/dictations/dictations.api.models';
import { navigateToDictation, navigator, replace } from '../../../system/navigator';
import {
	addDiagnoseToFields,
	addOrderToDiagnoseFields,
	canJobHaveEncounters,
	createDiagnose,
	diagHash,
	mapApiQaNotes,
	mapDocumentToFields,
	mapProcedureDocumentationFieldsToResponse,
	orderHash,
} from '../../services/documents/documents.services';
import { IScriberPermissions, IUserSettings, IValidateEhrLoginResult } from '../../models/users/user.models';
import { routes } from '../../../pages/App/routes';
import { IApiResponse } from '../../api/api-service';
import {
	cleanupDictationsCache,
	setDictationCache,
	setEncounterCache,
} from '../../services/dictations/dictations-cache.service';
import { IDocumentDetails } from '../../models/dictations/document.models';
import { sortNotes } from '../../services/qaNotes/qa-notes.services';
import { trackError, trackEvent, trackTrace } from '../../../application/app-insights';
import { getObjectSanitizer } from '../../../application/object-sanitizer';
import { IMacro } from '../../models/macros/macros.models';
import { getProviderMacros, validateEhrLogin } from '../../api/user/user.api';
import { mapCodedErrors } from '../../services/apiErrors/apiErrors.mapper';
import { billingHash } from '../../services/billingCodes/billing-codes.services';
import { sortProviders } from '../../services/providers/providers.services';
import { getAiSummaryRequest } from '../../services/dictations/dictations-services';

export function* initWorkListSaga(action: IAction<{ userSettings: IUserSettings }>) {
	yield put(actions.worklist.initDictations({ userSettings: action.payload.userSettings }));
}

function* verifyEhrSaga() {
	yield put(actions.user.setVerifyEhrLoadingAction(true));
	try {
		const result: IServiceResult<IValidateEhrLoginResult> = yield call(validateEhrLogin);
		yield put(
			actions.user.setVerifyEhrResultAction({
				error: result.data.error,
				daysUntilExpiration: result.data.daysUntilExpiration,
			})
		);
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.user.setVerifyEhrLoadingAction(false));
	}
}

function* setClinic({ customerId, settings }: { customerId: string; settings: IUserSettings }) {
	yield put(actions.worklist.setDictationsAction({ dictations: [] }));

	const { username } = settings;
	const newCustomerId = customerId;
	// Init providers
	let selectedProviders: IProvider[] | null = [];
	let providersAreEditable = false;

	// if (userCustomerPermissions?.length) {
	// 	let selectedCustomerPermissions = userCustomerPermissions.find(
	// 		(x: IUserCustomerPermissionsItem) => x.customerId.toString() === newCustomerId
	// 	);
	// 	if (!selectedCustomerPermissions) {
	// 		[selectedCustomerPermissions] = userCustomerPermissions;
	// 		newCustomerId = selectedCustomerPermissions.customerId.toString();
	// 	}
	// 	if (selectedCustomerPermissions.userCustomerProviderPermissions?.length) {
	// 		selectedProviders = selectedCustomerPermissions.userCustomerProviderPermissions
	// 			.filter((x) => x.userInfoId)
	// 			.map((x) => ({
	// 				id: x.userInfoId,
	// 				name: x.providerUsername,
	// 			}));
	// 	}
	// }

	setUserSelectedClinicCache(username, newCustomerId);
	const newSettings = { ...settings, customerId: newCustomerId };
	setUserSettingsCache(newSettings);
	yield put(actions.user.setUserSettingsAction(newSettings));

	const hasPreselectedProviders = selectedProviders && selectedProviders.length !== 0;

	if (!hasPreselectedProviders) {
		selectedProviders = getProvidersCache(username, newCustomerId);
		// update cached provider names from API in an async manner
		yield fork(function* UpdateProviderNames(p: IProvider[]) {
			const allProviders: IProvider[] = yield call(getProvidersV2);
			const newP = p.map((selectedProvider) => {
				const provider = allProviders.find((updatedProvider) => updatedProvider.id === selectedProvider.id);
				if (provider) {
					return { ...selectedProvider, name: provider.name };
				}
				return selectedProvider;
			});

			setProvidersCache(username, newCustomerId, newP);
		}, selectedProviders);

		providersAreEditable = true;
	}

	// if user doesn't have Admin permissions, add his own provider to the list
	if (!hasPreselectedProviders && !settings.permissions.admin) {
		providersAreEditable = false;
		if (!selectedProviders.find((x) => x.id === settings.userInfoId)) {
			selectedProviders = [
				{
					id: settings.userInfoId,
					name: settings.username,
				},
			];
		}
	}

	yield put(actions.user.setProvidersAreEditableAction(providersAreEditable));
	yield put(
		actions.worklist.setSelectedProvidersAction({
			username,
			customerId: newCustomerId,
			providers: selectedProviders,
			cache: providersAreEditable,
		})
	);

	if (settings.featureFlags.ehrLogin.enabled) {
		yield put(actions.worklist.setDictationsIsLoadingAction(true));
		yield call(verifyEhrSaga);
	}
	yield put(actions.worklist.getDictationsAction({}));
}

export function* setClinicSaga(action: IAction<ISetClinicActionPayload>) {
	const { customerId } = action.payload;
	const settings: IUserSettings = yield select((state: AppState) => state.user.settings);
	yield call(setClinic, { customerId, settings });
}

export function* initDictationsSaga(action: IAction<{ userSettings: IUserSettings }>) {
	try {
		const { userSettings } = action.payload;

		cleanupDictationsCache();

		// Init settings
		yield put(actions.worklist.setSettingsAction({ departmentId: userSettings.departmentId }));

		const customerId = getUserSelectedClinicCache(userSettings.username) || userSettings.customerId;

		yield call(setClinic, {
			customerId,
			settings: userSettings,
		});
	} catch (e) {
		handleGlobalException(e);
	}
}

export function* getSelectedProvidersDictationsSaga(action: IAction<{ disableLoading?: boolean }>) {
	try {
		if (!action.payload.disableLoading) {
			yield put(actions.worklist.setDictationsIsLoadingAction(true));
		}
		const permissions: IScriberPermissions = yield select((state: AppState) => state.user.settings.permissions);

		const selectedProviders: IProvider[] = yield select((state: AppState) => state.worklist.selectedProviders);
		const providersIds = selectedProviders.map((x) => x.id);

		if (providersIds.length > 0 || permissions.aiAccess) {
			const result: IServiceResult<IDictation[]> = yield call(getProvidersDictations, providersIds);
			if (result.success) {
				const visits = result.data;
				yield put(actions.worklist.setDictationsAction({ dictations: visits }));
			}
		} else {
			yield put(actions.worklist.setDictationsAction({ dictations: [] }));
		}
		if (action.payload.disableLoading) {
			yield put(actions.worklist.setDictationsIsLoadingAction(false));
		}
	} catch (e) {
		handleGlobalException(e);
	} finally {
		if (!action.payload.disableLoading) {
			yield put(actions.worklist.setDictationsIsLoadingAction(false));
		}
	}
}

export function setSelectedProvidersSaga(action: IAction<ISetSelectedProvidersActionPayload>) {
	const { username, customerId, providers } = action.payload;
	setProvidersCache(username, customerId, providers);
}

export function* getProvidersSaga() {
	try {
		yield put(actions.worklist.setProvidersIsLoadingAction(true));
		const result: IProvider[] = yield call(getProvidersV2);
		if (result) {
			yield put(actions.worklist.setProvidersAction({ providers: sortProviders(result) }));
		}
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.worklist.setProvidersIsLoadingAction(false));
	}
}

export function* openDictationSaga(action: IAction<IDictation>) {
	const departmentId: string = yield select((state: AppState) => state.worklist.departmentId);
	const dictation = { ...action.payload, departmentId };
	setDictationCache(dictation.blobID, dictation);
	yield put(actions.worklist.setCurrentDictation({ dictation, resetEncounter: true }));
	yield put(actions.worklist.setDictationPatientAction({ patientId: dictation.patientID }));
	navigateToDictation(dictation.blobID);
}

export function* setDictationPatientSaga(action: IAction<ISetDictationPatientActionPayload>) {
	const currentDictation: IDepartmentDictation | null = yield select(
		(state: AppState) => state.worklist.currentDocument.dictation
	);

	if (currentDictation) {
		setDictationCache(currentDictation.blobID, { ...currentDictation, patientID: action.payload.patientId });
	}
}

export function* updateDictationEncounterSaga(action: IAction<ISetDictationEncounterActionPayload>) {
	const { encounter, updateEncounter, blobId, ignoreStagedEncounterData } = action.payload;

	yield put(actions.worklist.setCurrentEncounterAction({ encounter }));
	setEncounterCache(blobId, encounter);

	const dictation: IDepartmentDictation | null = yield select(
		(state: AppState) => state.worklist.currentDocument.dictation
	);

	if (dictation) {
		const newDictation: IDepartmentDictation = {
			...dictation,
			documentID: encounter.documentID,
			documentType: encounter.documentTypeName?.toUpperCase(),
		};

		setDictationCache(blobId, newDictation);
		yield put(actions.worklist.setCurrentDictation({ dictation }));

		if (updateEncounter && newDictation.documentID) {
			yield put(
				actions.worklist.getCurrentDocumentAction({
					blobId: newDictation.blobID,
					patientId: newDictation.patientID,
					documentId: newDictation.documentID,
					documentTypeName: newDictation.documentType,
					departmentId: newDictation.departmentId,
					ignoreStagedEncounterData,
					metadata: {
						providerId: newDictation.providerUserInfoID,
					},
				})
			);
		}
	}
}

function filterAndSortEncounters(encounters: IPatientEncounter[]) {
	return encounters
		.slice()
		.filter((x) => x.documentTypeName)
		.sort((a, b) => new Date(b.createDateTime).getTime() - new Date(a.createDateTime).getTime());
}

function* getPatientChartSaga(
	blobId: string,
	patientId: string,
	filters: {
		Documents?: boolean;
		Demographic?: boolean;
	}
) {
	const dictation: IDepartmentDictation | null = yield select(
		(state: AppState) => state.worklist.currentDocument.dictation
	);

	if (filters.Documents) {
		yield put(actions.worklist.setPatientEncountersIsLoadingAction(true));
	}
	if (filters.Demographic) {
		yield put(actions.worklist.setPatientDemographicIsLoadingAction(true));
	}
	try {
		const metadata = dictation?.providerUserInfoID ? { providerId: dictation.providerUserInfoID } : undefined;
		const result: IServiceResult<IPatientChartDocuments> = yield call(
			patientChartSearch,
			{
				PatientID: patientId,
				Filters: filters,
			},
			metadata
		);
		if (result.success) {
			if (result.data.demographic) {
				yield put(actions.worklist.setPatientDemographic(result.data.demographic));
			}
			if (result.data.documents) {
				const userEncounters = filterAndSortEncounters(result.data.documents);
				yield put(actions.worklist.setPatientEncountersAction(userEncounters));
			}
		}
	} catch (e) {
		handleGlobalException(e);
	} finally {
		if (filters.Documents) {
			yield put(actions.worklist.setPatientEncountersIsLoadingAction(false));
		}
		if (filters.Demographic) {
			yield put(actions.worklist.setPatientDemographicIsLoadingAction(false));
		}
	}
}

export function* openDictationDocumentSaga(action: IAction<IPatientsChartSearch>) {
	try {
		const { blobId, patientId } = action.payload;

		const dictation: IDepartmentDictation = yield select((state: AppState) => state.worklist.currentDocument.dictation);

		yield fork(getPatientChartSaga, blobId, patientId, {
			Demographic: true,
			Documents: false,
		});

		const canHaveEncounters = canJobHaveEncounters(dictation.documentType);

		if (!dictation?.documentID || !canHaveEncounters) {
			yield call(getPatientChartSaga, blobId, patientId, { Documents: true, Demographic: true });
		} else {
			try {
				yield put(actions.worklist.setDictationEncounterIsLoadingAction(true));
				let patientEncounter: IPatientEncounter | null = null;

				try {
					patientEncounter = yield call(
						getEncounter,
						{
							encounterId: dictation.documentID,
							patientId,
						},
						{
							providerId: dictation.providerUserInfoID,
						}
					);
				} catch (e) {
					handleGlobalException(e);
				}

				if (patientEncounter && patientEncounter?.documentStatus?.toLowerCase() !== 'deleted') {
					yield put(
						actions.worklist.updateDictationEncounterAction({
							blobId,
							encounter: { ...patientEncounter, patientID: patientId },
							updateEncounter: true,
						})
					);
				} else if (patientEncounter?.documentStatus?.toLowerCase() === 'deleted') {
					setEncounterCache(blobId, null);
				}

				yield fork(getPatientChartSaga, blobId, patientId, {
					Demographic: false,
					Documents: true,
				});
			} catch (e) {
				handleGlobalException(e);
			}
		}
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.worklist.setDictationEncounterIsLoadingAction(false));
	}
}

function* updateBillingServices({
	blobId,
	documentId,
	patientId,
	reset,
}: {
	blobId: string;
	documentId: string;
	patientId: string;
	reset?: boolean;
}) {
	try {
		const dictation: IDepartmentDictation = yield select((state: AppState) => state.worklist.currentDocument.dictation);
		const logObjectSanitizer = getObjectSanitizer();

		const editBillingServices: boolean = yield select(
			(state: AppState) => state.user.settings.featureFlags.editBillingServices.enabled
		);

		if (!editBillingServices) {
			return;
		}

		const billingDetailsResult: { claimCreated: boolean; billingCodes: IBillingCode[] } = yield call(
			getBillingCodes,
			{
				documentID: documentId,
				patientID: patientId,
			},
			{
				providerId: dictation.providerUserInfoID,
			}
		);

		if (billingDetailsResult) {
			trackTrace('0102010', `blobId: ${blobId}; get billing services: got result`, {
				blobId,
				init: reset,
				result: logObjectSanitizer({
					claimCreated: billingDetailsResult.claimCreated,
					billingCodes: billingDetailsResult.billingCodes,
				}),
			});

			if (billingDetailsResult.claimCreated || reset) {
				yield put(
					actions.worklist.setBillingCodes({
						claimCreated: billingDetailsResult.claimCreated,
						billingCodes: billingDetailsResult.billingCodes || [],
					})
				);
				return;
			}
		}

		const billingCodes: IBillingCode[] = yield select((state: AppState) => state.worklist.currentDocument.billingCodes);
		const billingClaimCreated: boolean = yield select(
			(state: AppState) => state.worklist.currentDocument.billingClaimCreated
		);

		const toBeAdded = (x: IBillingCode) => !x.serviceID && x.modified === 0;

		const alreadyAddedOrModified = billingCodes.filter((x) => !toBeAdded(x));
		const suggestionsPlusExcludeAlreadyAddedOrModified = billingDetailsResult.billingCodes.filter(
			(x) => !alreadyAddedOrModified.find((y) => billingHash(y) === billingHash(x))
		);
		const newBillingCodes: IBillingCode[] = [
			...alreadyAddedOrModified,
			...suggestionsPlusExcludeAlreadyAddedOrModified,
		];
		yield put(actions.worklist.setBillingCodes({ claimCreated: billingClaimCreated, billingCodes: newBillingCodes }));
	} catch (e) {
		handleGlobalException(e);
	}
}

function* setBillingServices({
	blobId,
	documentId,
	patientId,
	checkedOutBillingCodes,
}: {
	blobId: string;
	documentId: string;
	patientId: string;
	checkedOutBillingCodes: IBillingCode[] | null;
}) {
	const logObjectSanitizer = getObjectSanitizer();
	const dictation: IDepartmentDictation = yield select((state: AppState) => state.worklist.currentDocument.dictation);

	const billingCodesEnabled: IPatientEncounter = yield select(
		(state: AppState) => state.user.settings.featureFlags.editBillingServices.enabled
	);

	if (!billingCodesEnabled) {
		return;
	}

	trackTrace('0103001', `blobId: ${blobId}; start getting billing codes`, {
		blobId,
	});

	const { claimCreated, billingCodes }: { claimCreated: boolean; billingCodes: IBillingCode[] } = yield call(
		getBillingCodes,
		{
			documentID: documentId,
			patientID: patientId,
		},
		{ providerId: dictation?.providerUserInfoID }
	);

	trackTrace('0103002', `blobId: ${blobId}; got billing codes result`, {
		blobId,
		result: logObjectSanitizer({ claimCreated, billingCodes }),
	});

	if (checkedOutBillingCodes && !claimCreated) {
		yield put(
			actions.worklist.setBillingCodes({
				claimCreated,
				billingCodes: checkedOutBillingCodes,
			})
		);
		trackTrace('0103003', `blobId: ${blobId}; set billing codes: set from checkoutResult`, {
			blobId,
			result: logObjectSanitizer({ claimCreated, checkedOutBillingCodes }),
		});
	} else {
		yield put(
			actions.worklist.setBillingCodes({
				claimCreated,
				billingCodes: billingCodes || [],
			})
		);
		trackTrace('0103004', `blobId: ${blobId}; set billing codes: set from billing codes result`, {
			blobId,
			result: logObjectSanitizer({ claimCreated, billingCodes }),
		});
	}
}

export function* getCurrentDocumentSaga(action: IAction<IGetDocumentDetailsActionPayload>) {
	const { blobId, documentId, departmentId, documentTypeName, patientId, metadata, ignoreStagedEncounterData } =
		action.payload;

	const logObjectSanitizer = getObjectSanitizer();

	yield put(actions.worklist.setCurrentDocumentIsLoadingAction(true));

	try {
		trackTrace('0102002', `blobId: ${blobId}; start checking-out document`, {
			blobId,
		});

		const checkOutResult: IDocumentCheckOutResult = yield call(
			documentCheckOut,
			{
				blobID: blobId,
				encounterID: documentId || null,
			},
			{
				providerId: metadata?.providerId,
			}
		);

		if (!checkOutResult.Success) {
			const checkoutError = `Document check-out: ${checkOutResult.Errors || 'Something went wrong'}`;
			trackError('0102003', checkoutError, {
				blobId,
			});
			toast.error(checkoutError);
			return;
		}
		trackTrace('0102004', `blobId: ${blobId}; got check-out result`, {
			blobId,
			result: logObjectSanitizer(checkOutResult),
		});

		const encounterData: IEncounterData | null = checkOutResult.EncounterData;

		if (patientId && documentId) {
			if (encounterData && encounterData.document && !ignoreStagedEncounterData) {
				trackTrace('0102005', `blobId: ${blobId}; start get encounter data`, {
					blobId,
				});
				if (encounterData.storeVersion === 1) {
					yield put(actions.worklist.setCurrentDocumentAction(encounterData.document));
					yield put(actions.worklist.setReorderDiagnoses(encounterData.reorderDiagnoses || false));
					yield fork(setBillingServices, {
						blobId,
						documentId,
						patientId,
						checkedOutBillingCodes: encounterData.billing || [],
					});
				} else {
					const documentCheckoutParseError = `Document check-out: 'Something went wrong`;
					trackError('0102007', documentCheckoutParseError, {
						blobId,
					});
					toast.error(documentCheckoutParseError);
					return;
				}
			} else {
				trackTrace('0102008', `blobId: ${blobId}; get document details: start `, {
					blobId,
				});
				const [document]: [document: IServiceResult<IGetDocumentDetailsResponse>] = yield all([
					call(
						getDocumentDetails,
						{
							DocumentID: documentId,
							PatientID: patientId,
							DepartmentID: departmentId ?? null,
							DocumentTypeName: documentTypeName.toUpperCase(),
						},
						metadata
					),
				]);

				if (document.success) {
					yield put(actions.worklist.setCurrentDocumentAction(document.data));
					trackTrace('0102009', `blobId: ${blobId}; get document details: got result`, {
						blobId,
						result: logObjectSanitizer(document),
					});
				}
				yield fork(setBillingServices, { blobId, documentId, patientId, checkedOutBillingCodes: null });
			}
		}

		if (checkOutResult.QANotes) {
			yield put(actions.worklist.setQANotes(sortNotes(mapApiQaNotes(checkOutResult.QANotes))));
		}

		yield put(
			actions.worklist.setTranscript({
				azureASR: {
					text: checkOutResult?.AzureASR ? checkOutResult.AzureASR : null,
					timestampedText:
						checkOutResult?.TimestampedDictationResults && checkOutResult?.TimestampedDictationResults.length > 0
							? checkOutResult.TimestampedDictationResults
							: null,
				},
				mModalASR: checkOutResult?.MModalASR ? checkOutResult.MModalASR : null,
				nuanceASR: checkOutResult?.NuanceASR ? checkOutResult.NuanceASR : null,
				openAISummary: checkOutResult?.AISummary ? checkOutResult.AISummary : null,
				openAISummaryStructured: checkOutResult?.AISummaryStructured
					? (JSON.parse(checkOutResult.AISummaryStructured) as IOpenAISummary)
					: null,
				prompt: checkOutResult.Prompt ?? null,
				instructions: checkOutResult.Instructions ?? null,
				customOpenAISummary: null,
				customOpenAISummaryStructured: null,
			})
		);

		yield put(actions.worklist.setProviderNote(checkOutResult.ProviderNote || ''));
		yield put(
			actions.worklist.setCustomPatientDemographics(
				checkOutResult?.PatientDemographics
					? (JSON.parse(checkOutResult.PatientDemographics) as ICustomPatientDemographicsData)
					: null
			)
		);
	} catch (e) {
		handleException('0102001', e, {
			blobId,
		});
	} finally {
		yield put(actions.worklist.setCurrentDocumentIsLoadingAction(false));
	}
}

export function* saveDocumentSaga(action: IAction<ISaveDocumentActionPayload>) {
	const currentDictation: IDictation = yield select((state: AppState) => state.worklist.currentDocument.dictation);

	const logObjectSanitizer = getObjectSanitizer();

	try {
		trackTrace('0101002', `blobId: ${currentDictation.blobID}; save document: start processing`, {
			job: logObjectSanitizer(currentDictation),
			options: action.payload,
			blobId: currentDictation.blobID,
		});

		yield put(actions.worklist.setSaveDocumentLoadingAction(true));
		yield put(actions.worklist.setSaveDocumentErrorsAction({}));

		const currentDocumentResponse: IGetDocumentDetailsResponse = yield select(
			(state: AppState) => state.worklist.currentDocument.document
		);

		const encounter: IPatientEncounter = yield select((state: AppState) => state.worklist.currentDocument.encounter);
		const qaNotes: IQANote[] = yield select((state: AppState) => state.worklist.currentDocument.qaNotes);
		const username: string = yield select((state) => state.user.settings.username);

		const { markTranscriptionComplete, sendToQA, forceSave, forceToQA, timedOutSave } = action.payload;

		if (timedOutSave) {
			trackEvent('0101008', 'JobSave', { blobId: currentDictation.blobID, onTimeOut: true });
		}

		const billingCodes: IBillingCode[] = yield select((state: AppState) => state.worklist.currentDocument.billingCodes);
		const reorderDiagnoses: boolean = yield select(
			(state: AppState) => state.worklist.currentDocument.reorderDiagnoses
		);

		const documentId = encounter.documentID;
		const patientId = encounter.patientID;

		let success = true;

		const isSending = markTranscriptionComplete || forceSave;

		if (isSending) {
			const sanitizeTemplates = (templates: IDocumentTemplate[]): IDocumentTemplate[] => {
				return templates
					? templates.map((template) => ({
							...template,
							TemplateData: template.TemplateData
								? template.TemplateData.map((data) => ({
										...data,
										sentences: data.sentences
											? data.sentences.map((sentence) => ({
													...sentence,
													findings: sentence.findings
														? sentence.findings.filter(
																(finding) => finding.selected === true || finding.selected === 'true'
														  )
														: sentence.findings,
											  }))
											: data.sentences,
								  }))
								: template.TemplateData,
					  }))
					: templates;
			};

			const request: ISaveDocumentRequest = {
				PatientID: patientId,
				DocumentId: documentId,
				EncounterID: documentId,
				DocumentTypeID: currentDocumentResponse.DocumentTypeID,
				DocumentName: currentDocumentResponse.DocumentName,
				BlobID: currentDictation.blobID,

				PracticeLocationID: 0,

				MarkTranscriptionComplete: markTranscriptionComplete,
				DocumentSaveStatus: markTranscriptionComplete ? 'Signed' : 'Saved',
				RemoveHPITemplate: false,
				DeviceToken: null,

				OriginalHPIFreeText: currentDocumentResponse.hpiSketchPad,
				HPISketchPad: currentDocumentResponse.hpiSketchPad,
				ROSSketchPad: currentDocumentResponse.rosSketchPad,
				PESketchPad: currentDocumentResponse.peSketchPad,
				PatientGoals: currentDocumentResponse.PatientGoals,
				PatientInstructions: currentDocumentResponse.PatientInstructions,
				DiscussionNotes: currentDocumentResponse.DiscussionNotes,
				ProcedureDocumentations: currentDocumentResponse.ProcedureDocumentations,
				ASSSketchPad: currentDocumentResponse.assSketchPad,
				PLANSketchPad: currentDocumentResponse.planSketchPad,

				EncounterWellChildRos: currentDocumentResponse.EncounterWellChildRos,
				Templates: sanitizeTemplates(currentDocumentResponse.Templates),
				PESystems: currentDocumentResponse.PESystems,
				UndiagnosedOrders: currentDocumentResponse.UndiagnosedOrders,
				EncounterWellChildRosTemplates: currentDocumentResponse.EncounterWellChildRosTemplates,
				Medications: currentDocumentResponse.Medications,
				AssessmentPlanSketchPad: currentDocumentResponse.AssessmentPlanSketchPad,
				Vitals: currentDocumentResponse.Vitals,
				HistoricalMedications: currentDocumentResponse.HistoricalMedications,
				CCSketchPad: currentDocumentResponse.ccSketchPad,
				Diagnoses: currentDocumentResponse.Diagnoses,
				Instructions: currentDocumentResponse.Instructions,

				ReorderDiagnoses: reorderDiagnoses,
			};

			trackTrace('0101011', `blobId: ${currentDictation.blobID}; save document: start saving`, {
				blobId: currentDictation.blobID,
				request: logObjectSanitizer(request),
			});

			const saveDocumentResult: ISaveDocumentResponse = yield call(saveDocument, request, {
				providerId: currentDictation.providerUserInfoID,
			});

			success = success && saveDocumentResult.Success;

			if (!saveDocumentResult.Success) {
				const errorMessage = `Save document: ${
					saveDocumentResult.ValidationError ||
					saveDocumentResult.Error ||
					saveDocumentResult.Errors ||
					'Something went wrong'
				}`;

				trackError('0101003', `blobId: ${currentDictation.blobID}; ${errorMessage}`, {
					blobId: currentDictation.blobID,
					request: logObjectSanitizer(request),
					result: logObjectSanitizer(saveDocumentResult),
				});
				if (!saveDocumentResult.CodedErrors) {
					toast.error(errorMessage);
				} else {
					yield put(
						actions.worklist.setSaveDocumentErrorsAction({ errors: mapCodedErrors(saveDocumentResult.CodedErrors) })
					);
				}
			} else {
				trackTrace('0101004', `blobId: ${currentDictation.blobID}; save document: success`, {
					blobId: currentDictation.blobID,
				});
				toast.success('Save document: success');
			}

			const billingCodesEnabled: IPatientEncounter = yield select(
				(state: AppState) => state.user.settings.featureFlags.editBillingServices.enabled
			);

			if (billingCodesEnabled && success) {
				const { claimCreated, reviewComplete }: { claimCreated: boolean; reviewComplete: boolean } = yield call(
					getBillingCodes,
					{
						documentID: documentId,
						patientID: patientId,
					},
					{ providerId: currentDictation.providerUserInfoID }
				);
				if (!claimCreated) {
					trackTrace('0101012', `blobId: ${currentDictation.blobID}; save billing services: start saving`, {
						blobId: currentDictation.blobID,
						request: logObjectSanitizer({
							patientId,
							documentId,
							billingCodes,
						}),
					});
					// const billingCodesEdited = billingCodes.find((x) => (x.serviceID && x.modified !== 0) || x.modified === 1);
					const saveBillingCodesResult: ISaveBillingDetailsResponse = yield call(
						saveBillingCodes,
						{
							reviewComplete,
							// TODO: To review the code below for 'reviewComplete'
							// reviewComplete: billingCodesEdited ? false : reviewComplete,
							patientId,
							documentId,
							billingCodes,
						},
						{
							providerId: currentDictation.providerUserInfoID,
						}
					);

					success = success && saveBillingCodesResult.Success;

					if (!saveBillingCodesResult.Success) {
						trackTrace('0101014', `blobId: ${currentDictation.blobID}; save billing services: error`, {
							blobId: currentDictation.blobID,
							result: logObjectSanitizer(saveBillingCodesResult),
						});
						toast.error(
							`Save billing codes: ${
								saveBillingCodesResult.ValidationError || saveBillingCodesResult.Errors || 'Something went wrong'
							}`
						);
					} else {
						trackTrace('0101014', `blobId: ${currentDictation.blobID}; save billing services: success`, {
							blobId: currentDictation.blobID,
							result: logObjectSanitizer(saveBillingCodesResult),
						});
						toast.success('save billing codes: success');
					}
				} else {
					trackTrace(
						'0101013',
						`blobId: ${currentDictation.blobID}; save billing services: not saving because claimCreated is ${claimCreated}`,
						{
							blobId: currentDictation.blobID,
						}
					);
				}
			}
		}

		const newNotes: IApiQANote[] = qaNotes
			.filter((x) => x.id || (x.note && x.note.length > 0))
			.map((note: IQANote) => ({
				Id: note.id,
				AudioTimeMarker: note.audioTimeMarker.toString(),
				Note: note.note,
				CreatedDateTime: note.createdDateTime,
				Username: note.username,
				IsDone: note.isDone,
			}));

		const automatedQaReviewNote = 'Automated QA Review';

		if (forceToQA && !qaNotes.find((x) => x.note === automatedQaReviewNote)) {
			const newNote: IApiQANote = {
				Note: automatedQaReviewNote,
				CreatedDateTime: dayjs().format(),
				AudioTimeMarker: '0',
				Username: username,
				IsDone: false,
			};

			trackTrace('0101005', `blobId: ${currentDictation.blobID}; add '${automatedQaReviewNote}' QA Note`, {
				blobId: currentDictation.blobID,
			});
			newNotes.unshift(newNote);
		}

		const claimWasCreatedAtCheckout: boolean = yield select(
			(state: AppState) => state.worklist.currentDocument.billingClaimCreated
		);

		const providerNote: string = yield select((state: AppState) => state.worklist.currentDocument.providerNote);

		const saveAndHoldRequest: IDocumentCheckInRequest = {
			BlobID: currentDictation.blobID,
			MarkTranscriptionComplete: markTranscriptionComplete,
			SendToQA: currentDictation.markedForQA || sendToQA,
			EncounterID: documentId,
			PatientID: patientId,
			QANotes: newNotes,
			EncounterData: {
				storeVersion: 1,
				document: currentDocumentResponse,
				reorderDiagnoses: isSending ? false : reorderDiagnoses,
				billingClaimCreated: claimWasCreatedAtCheckout,
				billing: billingCodes,
			},
			ProviderNote: providerNote,
		};

		let sendToQaMessageHeader;

		if (markTranscriptionComplete) {
			sendToQaMessageHeader = 'Check-in';
		} else {
			sendToQaMessageHeader = sendToQA ? 'Send to QA' : 'Save & Hold';
		}

		trackTrace('0101010', `blobId: ${currentDictation.blobID}; start ${sendToQaMessageHeader}`, {
			blobId: currentDictation.blobID,
			request: logObjectSanitizer(saveAndHoldRequest),
		});

		if (success) {
			try {
				const checkInResult: IDocumentCheckInResponse = yield call(documentCheckIn, saveAndHoldRequest);
				success = success && !!checkInResult?.Success;

				if (!checkInResult.Success) {
					const checkinErrorMessage = `${sendToQaMessageHeader} document: ${
						checkInResult.ValidationError || checkInResult.Error || checkInResult.Errors || 'Something went wrong'
					}`;
					trackError('0101006', `blobId: ${currentDictation.blobID}; ${checkinErrorMessage}`, {
						blobId: currentDictation.blobID,
					});
					toast.error(checkinErrorMessage);
				} else {
					const checkinSuccessMessage = `${sendToQaMessageHeader} document: success`;
					trackTrace('0101007', `blobId: ${currentDictation.blobID}; ${checkinSuccessMessage}`, {
						blobId: currentDictation.blobID,
					});
					toast.success(checkinSuccessMessage);
				}
			} catch (e) {
				success = false;
				trackError('0101015', `blobId: ${currentDictation.blobID}; document: check-in exception`, {
					blobId: currentDictation.blobID,
				});
				toast.error(`${sendToQaMessageHeader} document: 'Something went wrong'`);
			}
		}

		if (success) {
			if (timedOutSave) {
				yield put(actions.auth.signOutAction({ onTimeOut: true, onDocument: true }));
			} else {
				yield put(actions.worklist.closeDictation({ blobID: currentDictation.blobID, unlock: false }));
			}
		}
	} catch (e) {
		handleException('0101001', e, {
			blobId: currentDictation.blobID,
		});
	} finally {
		yield put(actions.worklist.setSaveDocumentLoadingAction(false));
	}
}

function* documentCancelCheckOutSaga(blobID: string) {
	try {
		yield call(documentCancelCheckOut, {
			BlobID: blobID,
		});
	} catch (e) {
		handleGlobalException(e);
	}
}

export function* closeDocumentSaga(action: IAction<{ blobID: string; unlock: boolean; async?: boolean }>) {
	if (action.payload.unlock) {
		if (action.payload.async) {
			yield fork(documentCancelCheckOutSaga, action.payload.blobID);
		} else {
			yield put(actions.worklist.setUnlockDocumentIsLoading(true));
			yield call(documentCancelCheckOutSaga, action.payload.blobID);
			yield put(actions.worklist.setUnlockDocumentIsLoading(false));
		}
	}

	if (navigator.location.pathname !== routes.workList) {
		replace(routes.workList);
	}

	yield put(actions.worklist.getDictationsAction({}));
}

export function* unlockAndOpenDocumentSaga(action: IAction<IDictation>) {
	yield put(actions.worklist.setUnlockDocumentIsLoading(true));
	yield call(documentCancelCheckOutSaga, action.payload.blobID);
	yield put(actions.worklist.setUnlockDocumentIsLoading(false));

	yield put(actions.worklist.openDictationAction(action.payload));
}

export function* pullCurrentDocumentFiledSaga(action: IAction<IPullCurrentDocumentFiledActionPayload>) {
	yield put(actions.worklist.pullCurrentDocumentFiledIsLoadingAction(true));

	const getFieldFilterFlag = (field: string, matchField: string): boolean => {
		return field === matchField;
	};

	const appendDocumentTextField = (
		fieldId: string,
		matchField: string,
		documentMatchField: string,
		currentDocument: IGetDocumentDetailsResponse,
		result: IGetDocumentDetailsResponse
	): string => {
		const current = currentDocument as unknown as {
			[key: string]: string;
		};

		const response = result as unknown as {
			[key: string]: string;
		};
		// eslint-disable-next-line no-nested-ternary
		return fieldId === matchField && !!response[documentMatchField] && response[documentMatchField].length > 0
			? current[documentMatchField].length > 0 && /\S/.test(current[documentMatchField])
				? `${current[documentMatchField]}\n\n${response[documentMatchField]}`
				: response[documentMatchField]
			: current[documentMatchField];
	};

	const { fieldId, documentId } = action.payload;

	try {
		const currentDictation: IDepartmentDictation = yield select(
			(state: AppState) => state.worklist.currentDocument.dictation
		);

		const request: IGetDocumentDetailsRequest = {
			DocumentID: documentId,
			PatientID: currentDictation.patientID,
			DocumentTypeName: currentDictation.documentType.toUpperCase(),
			DepartmentID: currentDictation.departmentId,
			PullForwardSections: {
				PullForwardToDocumentID: documentId,
				HPI: getFieldFilterFlag(fieldId, 'hpiSketchPad'),
				ROS: getFieldFilterFlag(fieldId, 'rosSketchPad'),
				PE: getFieldFilterFlag(fieldId, 'peSketchPad'),
				Goals: getFieldFilterFlag(fieldId, 'patientGoals'),
				Instructions: getFieldFilterFlag(fieldId, 'patientInstructions'),
				DiscussionNotes: getFieldFilterFlag(fieldId, 'discussionNotes'),
				Assessment: false,
				Vitals: false,
				CC: false,
				ProcedureDocumentation: false,
				Results: false,
				AssessmentPlan: getFieldFilterFlag(fieldId, 'assessmentPlan'),
				Plan: false,
			},
		};

		const pulledDocumentResult: IServiceResult<IGetDocumentDetailsResponse> = yield call(getDocumentDetails, request);
		const pulledDocument: IGetDocumentDetailsResponse = pulledDocumentResult.data;

		if (fieldId === 'assessmentPlan') {
			yield put(actions.worklist.pullCurrentDocumentFiledIsLoadingAction(false));
			yield put(actions.worklist.setPullAssessmentPlanDiagnosesAction(mapDocumentToFields(pulledDocument).diagnoses));
		}
		const currentDocument: IGetDocumentDetailsResponse = yield select(
			(state: AppState) => state.worklist.currentDocument.document
		);

		const updatedDocument: IGetDocumentDetailsResponse = {
			...currentDocument,
			hpiSketchPad: appendDocumentTextField(fieldId, 'hpiSketchPad', 'hpiSketchPad', currentDocument, pulledDocument),
			rosSketchPad: appendDocumentTextField(fieldId, 'rosSketchPad', 'rosSketchPad', currentDocument, pulledDocument),
			peSketchPad: appendDocumentTextField(fieldId, 'peSketchPad', 'peSketchPad', currentDocument, pulledDocument),
			PatientGoals: appendDocumentTextField(fieldId, 'patientGoals', 'PatientGoals', currentDocument, pulledDocument),
			PatientInstructions: appendDocumentTextField(
				fieldId,
				'patientInstructions',
				'PatientInstructions',
				currentDocument,
				pulledDocument
			),
			DiscussionNotes: appendDocumentTextField(
				fieldId,
				'discussionNotes',
				'DiscussionNotes',
				currentDocument,
				pulledDocument
			),
			AssessmentPlanSketchPad: appendDocumentTextField(
				fieldId,
				'assessmentPlan',
				'AssessmentPlanSketchPad',
				currentDocument,
				pulledDocument
			),
		};
		yield put(actions.worklist.setCurrentDocumentAction(updatedDocument));
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.worklist.pullCurrentDocumentFiledIsLoadingAction(false));
	}
}

export function* mergePulledAssessmentPlanSaga(action: IAction<IDiagnoseField[]>) {
	try {
		const currentDocument: IGetDocumentDetailsResponse = yield select(
			(state: AppState) => state.worklist.currentDocument.document
		);

		let dataEntryFields: IDiagnoseFields = mapDocumentToFields(currentDocument);
		dataEntryFields = { ...dataEntryFields, diagnoses: action.payload };

		yield put(actions.worklist.setDocumentFieldsAction({ fields: dataEntryFields }));
	} catch (e) {
		handleGlobalException(e);
	}
}

export function* worklistSearchPatientSaga(action: IAction<IPatientsAdvancedSearchFilters>) {
	const dictation: IDictation = yield select((state: AppState) => state.worklist.currentDocument.dictation);
	try {
		yield put(actions.worklist.worklistPatientSearchIsLoadingAction(true));
		const metadata = dictation?.providerUserInfoID ? { providerId: dictation.providerUserInfoID } : undefined;
		const result: IServiceResult<IPatientsSearchResult[]> = yield call(
			patientSearch,
			{
				firstName: action.payload.firstName,
				lastName: action.payload.lastName,
				DOB: action.payload.DOB,
				patientID: action.payload.patientID,
				gender: '',
				SSN: '',
				pageSize: 100,
				pageStart: 0,
				searchCriteria: [],
				medicalRecordNumber: '',
			},
			metadata
		);
		if (result.success) {
			yield put(actions.worklist.setWorklistPatientSearchResultAction({ patients: result.data }));
		}
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.worklist.worklistPatientSearchIsLoadingAction(false));
	}
}

export function* searchBillingServiceSaga(action: IAction<string>) {
	try {
		yield put(actions.worklist.setProcedureCodeSearchIsLoading(true));

		const dictation: IDepartmentDictation = yield select((state: AppState) => state.worklist.currentDocument.dictation);
		const encounter: IPatientEncounter = yield select((state: AppState) => state.worklist.currentDocument.encounter);
		const result: IServiceResult<IProcedureCode[]> = yield call(
			procedureCodeSearch,
			{
				searchTerm: action.payload,
				encounterId: encounter.documentID,
			},
			{
				providerId: dictation.providerUserInfoID,
			}
		);
		yield put(actions.worklist.setProcedureCodeSearchResult({ procedureCodeSearchResult: result.data }));
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.worklist.setProcedureCodeSearchIsLoading(false));
	}
}

export function* searchDiagnosesSaga(action: IAction<string>) {
	try {
		if (action.payload && action.payload.length > 0) {
			yield put(actions.worklist.setDiagnosesSearchIsLoading(true));
			const imoProblemsResult: IimoProblemSearchResponse = yield call(imoProblemSearch, action.payload);

			const diagnosis: IDiagnoseField[] = imoProblemsResult.Result.data.items.map((x) => {
				return createDiagnose({
					caption: x.title,
					lexicalId: x.IMO_LEXICAL_CODE,
					snomedCode: x.SCT_CONCEPT_ID,
					snomedName: x.SNOMED_DESCRIPTION,
					icd10Codes: [
						{
							code: x.kndg_code,
							codeset: x.kndg_source,
							description: x.kndg_title,
						},
						{
							code: x.ICD10CM_CODE,
							codeset: 'ICD10',
							description: x.ICD10CM_TITLE,
						},
					],
				});
			});

			yield put(actions.worklist.setDiagnosesSearchResult(diagnosis));
		}
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.worklist.setDiagnosesSearchIsLoading(false));
	}
}

export function* searchOrdersSaga(action: IAction<IOrdersSearchQuery>) {
	try {
		const dictation: IDepartmentDictation = yield select((state: AppState) => state.worklist.currentDocument.dictation);
		if (action.payload.searchPhrase && action.payload.searchPhrase.length > 0) {
			yield put(actions.worklist.setOrdersSearchIsLoading(true));
			const imoProblemsResult: IServiceResult<IOrderSearchResult[]> = yield call(ordersSearch, action.payload, {
				providerId: dictation.providerUserInfoID,
			});

			const orders: IOrderField[] = imoProblemsResult.data.map((x) => ({
				orderId: x.orderID,
				selected: true,
				modified: 1,
				procedure: {
					procedureDescription: x.description,
					selected: true,
					procedureLexicalCode: '',
				},
				description: x.description,
				orderTypeGroup: x.orderTypeGroup,
				orderTypeId: x.orderTypeID,
			}));

			yield put(actions.worklist.setOrdersSearchResult(orders));
		}
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.worklist.setOrdersSearchIsLoading(false));
	}
}

export function* applyOrderSetSaga(action: IAction<{ orderSetId: string; diagnoseHash?: string }>) {
	try {
		yield put(actions.worklist.setApplyOrderSetIsLoading(true));

		const { orderSetId, diagnoseHash } = action.payload;
		const orderSetDetails: IOrderDetailsResponse = yield call(getOrderSetDetails, orderSetId);

		if (orderSetDetails) {
			const document: IDocumentDetails | null = yield select(
				(state: AppState) => state.worklist.currentDocument.document
			);

			if (document) {
				let diagnoseFields = mapDocumentToFields(document);

				const mapOrder = (x: IOrderSearchResponse) => {
					return {
						orderId: x.OrderID,
						selected: true,
						modified: 1,
						procedure: {
							procedureDescription: x.Description,
							selected: true,
							procedureLexicalCode: '',
						},
						description: x.Description,
						orderTypeGroup: x.OrderTypeGroup,
						orderTypeId: x.OrderTypeID,
					};
				};

				// handle diagnosed orders
				if (orderSetDetails.Diagnoses && orderSetDetails.Diagnoses.length > 0) {
					orderSetDetails.Diagnoses.forEach((diag) => {
						let diagnose: IUnrankedDiagnoseField | IDiagnoseField | undefined = diagnoseFields.diagnoses
							.filter((x) => x.modified !== 2)
							.find((x) => x.SNOMEDCode === diag.SNOMEDCode);
						if (!diagnose) {
							diagnose = createDiagnose({
								caption: diag.Caption,
								snomedCode: diag.SNOMEDCode,
							});
							diagnoseFields = addDiagnoseToFields(diagnoseFields, { ...diagnose, modified: 1 });
						}

						const diagnoseOrders: IOrderField[] = diag.Orders.map((x) => mapOrder(x));

						diagnoseOrders.forEach((order) => {
							diagnoseFields = addOrderToDiagnoseFields(diagnoseFields, diagHash(diagnose as IDiagnoseField), order);
						});
					});
				}

				// handle undiagnosed orders
				const undiagnosedOrderFields: IOrderField[] = orderSetDetails.UndiagnosedOrders.map((x) => mapOrder(x));

				if (diagnoseHash) {
					undiagnosedOrderFields.forEach((order) => {
						diagnoseFields = addOrderToDiagnoseFields(diagnoseFields, diagnoseHash, order);
					});
				} else {
					const currentOrders: IOrderField[] = yield select(
						(state: AppState) => state.worklist.currentDocument.unassignedOrders
					);

					yield put(
						actions.worklist.setUnassignedOrders([
							...currentOrders,
							...undiagnosedOrderFields.filter((x) => !currentOrders.find((y) => orderHash(x) === orderHash(y))),
						])
					);
				}

				yield put(actions.worklist.setDocumentFieldsAction({ fields: diagnoseFields }));
			}
		}
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.worklist.setApplyOrderSetIsLoading(false));
	}
}

export function* getBillingModifiersSaga() {
	try {
		const dictation: IDepartmentDictation = yield select((state: AppState) => state.worklist.currentDocument.dictation);

		yield put(actions.worklist.setBillingModifiersIsLoading(true));
		const result: IServiceResult<IBillingModifier[]> = yield call(getBillingModifiers, {
			providerId: dictation.providerUserInfoID,
		});
		if (result.success) {
			yield put(actions.worklist.setBillingModifiersSearchResult(result.data));
		}
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.worklist.setBillingModifiersIsLoading(false));
	}
}

export function* getProcedureDocumentationTemplatesSaga(action: IAction<{ documentId: string }>) {
	try {
		yield put(actions.worklist.setProcedureDocumentationTemplatesIsLoadingAction(true));

		const result: IServiceResult<IProcedureDocumentationTemplate[]> = yield call(getProcedureDocumentationTemplates, {
			documentId: action.payload.documentId,
		});

		if (result.success) {
			yield put(actions.worklist.setProcedureDocumentationTemplatesAction(result.data));
		}
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.worklist.setProcedureDocumentationTemplatesIsLoadingAction(false));
	}
}

export function* applyProcedureDocumentationTemplateSaga(
	action: IAction<IApplyProcedureDocumentationTemplateActionPayload>
) {
	try {
		yield put(actions.worklist.setApplyProcedureDocumentationTemplateIsLoadingAction(true));

		const { documentId, template, patientId } = action.payload;

		const result: IServiceResult<{ templateText: string }> = yield call(getProcedureDocumentation, {
			templateId: template.ehrTemplateId.toString(),
			documentId,
		});

		if (result.success) {
			const document: IDocumentDetails | null = yield select(
				(state: AppState) => state.worklist.currentDocument.document
			);
			if (document) {
				const procedureDocumentation: IProcedureDocumentationField = {
					procedureTemplateId: template.ehrTemplateId.toString(),
					procedureDocumentationText: template.templateText,
					procedureTemplateName: template.templateName,
					templateData: result.data.templateText,
					modified: 1,
				};

				const diagnoseFields = mapDocumentToFields(document);
				const updatedDiagnoseFields = {
					...diagnoseFields,
					procedureDocumentation: [...diagnoseFields.procedureDocumentation, procedureDocumentation],
				};

				yield put(actions.worklist.setDocumentFieldsAction({ fields: updatedDiagnoseFields }));
				yield put(
					actions.worklist.saveProcedureDocumentationAction({
						documentId,
						patientId,
						procedureDocumentation: updatedDiagnoseFields.procedureDocumentation,
					})
				);
			}
		}
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.worklist.setApplyProcedureDocumentationTemplateIsLoadingAction(false));
	}
}

export function* saveProcedureDocumentationSaga(action: IAction<ISaveProcedureDocumentationActionPayload>) {
	yield put(actions.worklist.setSaveProcedureDocumentationIsLoadingAction(true));

	try {
		const editProcedureDocumentation: boolean = yield select(
			(state: AppState) => state.user.settings.featureFlags.editProcedureDocumentation.enabled
		);

		if (!editProcedureDocumentation) {
			return;
		}

		const { documentId, patientId, procedureDocumentation } = action.payload;

		const procedureDocumentationRequest: IDocProcedureDocumentationRequest[] =
			mapProcedureDocumentationFieldsToResponse(procedureDocumentation);

		const result: IApiResponse = yield call(saveProcedureDocumentation, {
			patientId,
			documentId,
			procedureDocumentations: procedureDocumentationRequest,
		});

		if (result.Success) {
			const document: IDocumentDetails = yield select((state: AppState) => state.worklist.currentDocument.document);

			const diagnoseFields = mapDocumentToFields(document);
			const updatedDiagnoseFields = {
				...diagnoseFields,
				procedureDocumentation: [
					...diagnoseFields.procedureDocumentation
						.filter((procedure) => procedure.modified !== 2)
						.map((procedure) => {
							if (procedure.modified === 1 || procedure.modified === 3) {
								return { ...procedure, modified: 0 };
							}
							return procedure;
						}),
				],
			};

			yield put(actions.worklist.setDocumentFieldsAction({ fields: updatedDiagnoseFields }));
			const currentDictation: IDictation = yield select((state: AppState) => state.worklist.currentDocument.dictation);
			yield call(updateBillingServices, { blobId: currentDictation.blobID, documentId, patientId });
		}
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.worklist.setSaveProcedureDocumentationIsLoadingAction(false));
	}
}

export function* getDocumentProviderDotPhrasesSaga(action: IAction<IGetDocumentProviderDotPhrasesActionPayload>) {
	const { providerId } = action.payload;

	try {
		const result: IMacro[] = yield call(getProviderMacros, {
			providerId,
		});
		if (result) {
			yield put(
				actions.worklist.setDocumentProviderDotphrasesAction(
					result.filter((x) => x.shortCode && x.section && x.description)
				)
			);
		}
	} catch (e) {
		handleGlobalException(e);
	}
}

export function* getPatientPreviewDocument(action: IAction<IGetPatientPreviewDocumentActionPayload>) {
	const { documentId, patientId, documentTypeName } = action.payload;
	const departmentId: string = yield select((state: AppState) => state.worklist.departmentId);

	yield put(actions.worklist.setPatientPreviewDocumentIsLoading(true));
	try {
		const [document]: [document: IPreviewDocumentResponse] = yield all([
			call(getDocument, {
				DocumentID: documentId,
				PatientID: patientId,
				DepartmentID: departmentId,
				DocumentTypeName: documentTypeName.toUpperCase(),
			}),
		]);
		yield put(
			actions.worklist.setPatientPreviewDocument({
				base64: document.Baseimage64,
				type: 'pdf',
			})
		);
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.worklist.setPatientPreviewDocumentIsLoading(false));
	}
}

export function* requestAISummaryForPromptSaga(action: IAction<IRequestAISummaryForPromptActionPayload>) {
	const dictation: IDepartmentDictation | undefined = yield select(
		(state: AppState) => state.worklist.currentDocument.dictation
	);

	const errorMessage = 'Something went wrong during requesting AI summary for the prompt';

	if (dictation?.blobID !== action.payload.blobId) {
		toast.error(errorMessage);
	}

	const encounter: IPatientEncounter | undefined = yield select(
		(state: AppState) => state.worklist.currentDocument.encounter
	);

	const patientDemographic: IPatientDemographic | undefined = yield select(
		(state: AppState) => state.worklist.currentDocument.chart.patientDemographic
	);

	const data: IRequestAISummaryRequest = getAiSummaryRequest({
		blobId: action.payload.blobId,
		prompt: action.payload.prompt.prompt,
		instructions: action.payload.prompt.instructions,
		aiModel: action.payload.aiModel,
		encounter: encounter ?? null,
		dictation: dictation ?? null,
		patientDemographic: patientDemographic ?? null,
	});

	try {
		yield put(actions.worklist.setLastTimePromptSubmitted(new Date().getTime()));
		const result: IServiceResult<void> = yield call(requestAISummary, data);

		if (!result.success) {
			toast.error(errorMessage);
		}
	} catch (e) {
		toast.error(errorMessage);
		handleGlobalException(e);
	}
}

export function* updatePromptAISummarySaga(action: IAction<IUpdatePromptAISummaryActionPayload>) {
	const dictation: IDepartmentDictation | null = yield select(
		(state: AppState) => state.worklist.currentDocument.dictation
	);

	const errorMessage = 'Something went wrong during requesting AI summary for the prompt';

	if (dictation?.blobID !== action.payload.blobId) {
		return;
	}

	try {
		const aiSummaryResult: IServiceResult<{
			openAISummary: string | null;
			openAISummaryStructured: IOpenAISummary | null;
		}> = yield call(getOpenAISummary, action.payload.blobId);

		if (
			aiSummaryResult.success &&
			(aiSummaryResult.data.openAISummary || aiSummaryResult.data.openAISummaryStructured)
		) {
			yield put(
				actions.worklist.setPromptAISummary({
					openAISummary: aiSummaryResult.data.openAISummary,
					openAISummaryStructured: aiSummaryResult.data.openAISummaryStructured,
				})
			);
			yield put(actions.worklist.setLastTimePromptSubmitted(null));
			toast.success('AI summary updated');
		} else {
			toast.error(errorMessage);
		}
	} catch (e) {
		toast.error(errorMessage);
		handleGlobalException(e);
	}
}

export function* updateCustomPatientDemographicsSaga(action: IAction<IUpdateCustomPatientDemographicsActionPayload>) {
	const { blobId, data } = action.payload;

	try {
		const result: IServiceResult<void> = yield call(updateCustomPatientDemographics, {
			BlobID: blobId,
			FirstName: data.PatientDemographics.PatientFirstName || '',
			LastName: data.PatientDemographics.PatientLastName || '',
			MRN: data.PatientDemographics.MRN || '',
			DateOfBirth: data.PatientDemographics.DateOfBirth || '',
			Gender: data.PatientDemographics.Gender || '',
		});

		if (result.success) {
			yield put(actions.worklist.setCustomPatientDemographics(data));
			toast.success('Patient demographics updated');
		} else {
			toast.error('Something went wrong during updating patient demographics');
		}
	} catch (e) {
		handleGlobalException(e);
	}
}
