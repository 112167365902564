import { Grid, Box, Typography, FormControl, FormLabel, FormControlLabel, RadioGroup, Radio } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { namespaces } from '../../application/i18n.constants';

interface IBillingCodeOptionsProps {
	sectionLabel?: string;
	options: Array<{
		procedureCode: string;
		active: boolean;
	}>;
	handleAddBillingCode: (procedureCode: string) => void;
}

function BillingCodeOptions({ sectionLabel, options, handleAddBillingCode }: IBillingCodeOptionsProps) {
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		handleAddBillingCode((event.target as HTMLInputElement).value);
	};

	return (
		<FormControl>
			{sectionLabel && <FormLabel>{sectionLabel}</FormLabel>}
			<RadioGroup onChange={handleChange}>
				{options &&
					options.map((i) => (
						<FormControlLabel
							key={i.procedureCode}
							value={i.procedureCode}
							disabled={i.active}
							checked={i.active}
							control={<Radio />}
							label={i.procedureCode}
						/>
					))}
			</RadioGroup>
		</FormControl>
	);
}

interface ICommonBillingServiceItemListProps {
	activeCodes: Array<string | boolean>;
	onAddBillingCode: (procedureCode: string) => void;
}

const officeVisitsEstablishedCodes = ['99211', '99212', '99213', '99214', '99215'];
const officeVisitsNewCodes = ['99201', '99202', '99203', '99204', '99205'];
const postOpFollowUpCodes = ['99024'];
const otherCodes = ['99455', '99366'];

function CommonBillingServiceItemList({ activeCodes, onAddBillingCode }: ICommonBillingServiceItemListProps) {
	const { t } = useTranslation(namespaces.components.searchBillingServices);

	const codes = {
		officeVisits: {
			established: officeVisitsEstablishedCodes.map((code) => ({
				procedureCode: code,
				active: activeCodes.indexOf(code) > -1,
			})),
			new: officeVisitsNewCodes.map((code) => ({ procedureCode: code, active: activeCodes.indexOf(code) > -1 })),
		},
		postOpFollowUp: postOpFollowUpCodes.map((code) => ({
			procedureCode: code,
			active: activeCodes.indexOf(code) > -1,
		})),
		other: otherCodes.map((code) => ({
			procedureCode: code,
			active: activeCodes.indexOf(code) > -1,
		})),
	};

	const handleAddBillingCode = (procedureCode: string) => {
		onAddBillingCode(procedureCode);
	};

	return (
		<Box sx={{ padding: '1rem' }}>
			<Box>
				<Typography variant="body1">{t('officeVisits')}</Typography>
				<Grid container>
					<Grid item sx={{ marginRight: '2rem' }}>
						<BillingCodeOptions
							handleAddBillingCode={handleAddBillingCode}
							options={codes.officeVisits.established}
							sectionLabel={t('established')}
						/>
					</Grid>
					<Grid item>
						<BillingCodeOptions
							handleAddBillingCode={handleAddBillingCode}
							options={codes.officeVisits.new}
							sectionLabel={t('new')}
						/>
					</Grid>
				</Grid>
			</Box>
			<Box sx={{ marginTop: '1rem' }}>
				<Typography variant="body1">{t('postOp')}</Typography>
				<Grid container>
					<Grid item sx={{ marginRight: '2rem' }}>
						<BillingCodeOptions handleAddBillingCode={handleAddBillingCode} options={codes.postOpFollowUp} />
					</Grid>
				</Grid>
			</Box>
			<Box sx={{ marginTop: '1rem' }}>
				<Typography variant="body1">{t('other')}</Typography>
				<Grid container>
					<Grid item sx={{ marginRight: '2rem' }}>
						<BillingCodeOptions handleAddBillingCode={handleAddBillingCode} options={codes.other} />
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
}

export default CommonBillingServiceItemList;
