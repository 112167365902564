import * as React from 'react';
import { ReactNode, useEffect, useState } from 'react';
import { Box, BoxProps } from '@mui/material';
import ValidationTextField from './ValidationTextField';
import ValidationSelectField from './ValidationSelectField';

interface IValidationFormProps extends BoxProps {
	children: ReactNode;
	onValidationChange?: (validationResult: boolean) => void;
	onCompletenessChange?: (completeness: boolean) => void;
	disableValidation?: boolean;
}

function ValidationForm({
	children,
	onValidationChange,
	onCompletenessChange,
	disableValidation,
	...props
}: IValidationFormProps) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [validity, setValidity] = useState<{
		[key: string]: { message?: string; valid: boolean; isNotFilled?: boolean };
	}>({});

	useEffect(() => {
		if (onValidationChange) {
			const values: boolean[] = Object.values(validity).map((x) => x.valid);
			const result: boolean = values.length > 0 ? values.reduce((x, y) => x && y) : true;
			onValidationChange(result);
		}

		if (onCompletenessChange) {
			const values: boolean[] = Object.values(validity).map((x) => !x.isNotFilled);
			const result: boolean = values.length > 0 ? values.reduce((x, y) => x && y) : true;
			onCompletenessChange(result);
		}
	}, [validity]);

	const setFieldValidity = (key: string, validationResult: { message?: string; valid: boolean }) => {
		setValidity((current) => {
			const fields = { ...current };
			fields[key] = validationResult;
			return fields;
		});
	};

	const childrenWithProps = React.Children.map(children, (child, index) => {
		if (React.isValidElement(child)) {
			if (child.type === ValidationTextField || child.type === ValidationSelectField) {
				return React.cloneElement(child, {
					disableValidation,
					onValidationChange: (validationResult: { message?: string; valid: boolean }) =>
						setFieldValidity(index.toString(), validationResult),
				});
			}
			return child;
		}
		return child;
	});

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <Box {...props}>{childrenWithProps}</Box>;
}

export default ValidationForm;
