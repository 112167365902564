import { environment } from '../../environment/environment';

const { API_URL } = environment;

export const endpoints = {
	auth: {
		login: `${API_URL}/Auth/Login`,
		forgotPassword: `${API_URL}/Auth/ForgotPassword`,
		validatePIN: `${API_URL}/Auth/ValidatePIN`,
	},
	user: {
		validateEhrLogin: `${API_URL}/User/ValidateEhrLogin`,
		macro: `${API_URL}/User/Macro`,
		macros: `${API_URL}/User/Macros`,
		importMacros: `${API_URL}/User/Macro/Import`,
		replacement: `${API_URL}/User/WordReplacement`,
		replacements: `${API_URL}/User/WordReplacements`,
		users: `${API_URL}/User/v2/Users`,
	},
	customer: {
		root: `${API_URL}/Customer`,
		macro: `${API_URL}/Customer/Macro`,
		macros: `${API_URL}/Customer/Macros`,
		importMacros: `${API_URL}/Customer/Macro/Import`,
		replacement: `${API_URL}/Customer/WordReplacement`,
		replacements: `${API_URL}/Customer/WordReplacements`,
	},
	patients: {
		search: `${API_URL}/Patients/Search`,
		chart: `${API_URL}/Patients/Chart`,
		updateDemographics: `${API_URL}/Patients/UpdateDemographics`,
	},
	dictation: {
		openAISummary: `${API_URL}/Dictation/OpenAISummary`,
		uploadOpenAISummary: `${API_URL}/Dictation/UploadOpenAISummary`,
	},
	documents: {
		dictatedDocuments: `${API_URL}/Documents/DictatedDocuments`,
		dictatedDocumentsWorklist: `${API_URL}/Documents/DictatedDocumentsWorklist`,
		dictatedDocumentsWorklistData: `${API_URL}/Documents/v2/DictatedDocumentsWorklistData`,
		documentDetailsGet: `${API_URL}/Documents/DocumentDetailsGet`,
		documentGet: `${API_URL}/Documents/DocumentGet`,
		dictatedDocumentsWorklistCheckIn: `${API_URL}/Documents/DictatedDocumentsWorklistCheckIn`,
		dictatedDocumentsWorklistCheckOut: `${API_URL}/Documents/DictatedDocumentsWorklistCheckOut`,
		documentByAppointmentID: `${API_URL}/Documents/DocumentByAppointmentID`,
		encounter: `${API_URL}/Documents/Encounter`,
		dictatedDocumentsTracking: `${API_URL}/Documents/DictatedDocumentsTracking`,
	},
	clinical: {
		documentSave: `${API_URL}/Clinical/DocumentSave`,
		imoProblemSearch: `${API_URL}/Clinical/IMOProblemSearch`,
		orderSearch: `${API_URL}/Clinical/OrderSearch`,
		orderSets: `${API_URL}/Clinical/OrderSets`,
		orderSetDetails: `${API_URL}/Clinical/OrderSetDetails`,
	},
	general: {
		careProviderSearch: `${API_URL}/General/CareProviderSearch`,
	},
	billing: {
		detailsGet: `${API_URL}/Billing/DetailsGet`,
		detailsSave: `${API_URL}/Billing/DetailsSave`,
		modifiers: `${API_URL}/Billing/Modifiers`,
		procedureCodes: `${API_URL}/Billing/ProcedureCodes`,
	},
	template: {
		getProcedureDocumentations: `${API_URL}/Template/ProcedureDocumentations`,
		getProcedureDocumentation: `${API_URL}/Template/ProcedureDocumentation`,
	},
	procedureDocumentation: {
		save: `${API_URL}/ProcedureDocumentation/Save`,
	},
	system: {
		aiModels: `${API_URL}/System/AIModels`,
	},
};
