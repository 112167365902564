import { Box, Checkbox, FormControlLabel, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import * as React from 'react';
import OrderIcon from './instruct.png';
import LazyInput from '../../inputs/LazyInput';
import { IInterpretationField } from '../../../core/models/dictations/dictations.models';

function OrderItem({
	interpretation,
	description,
	onDelete,
	isEditOrderInterpretationEnabled,
	onInterpretationChange,
	canDeleteOrder,
}: {
	interpretation?: IInterpretationField;
	description: string;
	onDelete: () => void;
	isEditOrderInterpretationEnabled: boolean;
	onInterpretationChange: (interpretation: IInterpretationField) => void;
	canDeleteOrder: boolean;
}) {
	const [note, setNote] = React.useState(interpretation?.note ?? '');

	return (
		<Box
			sx={{
				display: 'flex',
				flex: 1,
				alignItems: 'center',
				justifyContent: 'space-between',
				width: '100%',
				// '&:hover': {
				// 	backgroundColor: canDeleteOrder ? grey['300'] : 'transparent',
				// },
			}}
		>
			<Box sx={{ display: 'flex', justifyContent: 'center', pl: 2, width: '100%' }}>
				<img alt="" src={OrderIcon} width="20" height="20" style={{ marginTop: 4 }} />
				<Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
					<Typography sx={{ pl: 2 }}>{description}</Typography>
					{interpretation && (
						<Box sx={{ pl: 2 }}>
							<Typography sx={{ mr: 1 }}>Interpretation:</Typography>
							<Box sx={{ pl: 2 }}>
								<LazyInput
									readOnly={!isEditOrderInterpretationEnabled}
									style={{ width: '100%', resize: 'none' }}
									maxRows={5}
									value={interpretation.note}
									onChange={(value) => setNote(value)}
									onLazyChange={(value) => onInterpretationChange({ ...interpretation, note: value })}
								/>
								<FormControlLabel
									control={
										<Checkbox
											disabled={!isEditOrderInterpretationEnabled}
											checked={interpretation.discussedWithPatient}
											onChange={(x) =>
												onInterpretationChange({ ...interpretation, note, discussedWithPatient: x.target.checked })
											}
										/>
									}
									label={<Typography>Discussed with patient</Typography>}
								/>
							</Box>
						</Box>
					)}
				</Box>
			</Box>
			{canDeleteOrder ? (
				<IconButton sx={{ width: 32, height: 32 }} onClick={onDelete}>
					<CloseIcon sx={{ fontSize: '1rem' }} />
				</IconButton>
			) : null}
		</Box>
	);
}

export default OrderItem;
