import * as React from 'react';
import { Box, List, ListItemButton, Typography } from '@mui/material';
import { ListItemButtonProps } from '@mui/material/ListItemButton/ListItemButton';
import CheckIcon from '@mui/icons-material/Check';
import Spinner from '../spinner/Spinner';
import ListContainer from './ListContainer';

export type IListItem<T> = T & {
	id: string;
	title: string;
};

export interface IItemsListProps<T> {
	items: IListItem<T>[];
	loading?: boolean;
	listHeader?: string;
	button?: true;
	isSelected?: (item: IListItem<T>) => boolean;
	canSelectSelected?: boolean;
	renderItem: (item: IListItem<T>) => React.ReactNode;
	onClick?: (item: IListItem<T>) => void;
}
function ItemButton({
	children,
	button,
	disabled,
	selected,
	onClick,
	...rest
}: ListItemButtonProps & { button?: boolean }) {
	return (
		<ListItemButton
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...rest}
			sx={{
				pl: 0,
				'&.Mui-disabled': {
					opacity: 1,
					backgroundColor: 'transparent',
				},
			}}
			disabled={disabled}
			onClick={button ? onClick : undefined}
			selected={selected}
		>
			<Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
				{children}
				<Box alignItems="center">{selected ? <CheckIcon sx={{ color: 'secondary.main' }} /> : null}</Box>
			</Box>
		</ListItemButton>
	);
}
function ItemsList<T>({
	loading,
	items,
	listHeader,
	button,
	renderItem,
	isSelected,
	canSelectSelected,
	onClick,
}: IItemsListProps<T>) {
	return (
		<ListContainer>
			{loading ? (
				<Box sx={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
					<Spinner />
				</Box>
			) : (
				<List sx={{ width: '100%' }}>
					{listHeader && (
						<Typography sx={{ fontWeight: 'bold', backgroundColor: 'secondary.light' }}>{listHeader}</Typography>
					)}
					{items.map((item) => (
						<ItemButton
							button={button}
							key={`${item.id}-${item.title}`}
							disabled={isSelected ? !canSelectSelected && isSelected(item) : !onClick}
							onClick={onClick ? () => onClick(item) : undefined}
							selected={isSelected ? isSelected(item) : undefined}
						>
							{renderItem(item)}
						</ItemButton>
					))}
				</List>
			)}
		</ListContainer>
	);
}

export default ItemsList;
