import * as React from 'react';
import {
	Checkbox,
	IconButton,
	Paper,
	SxProps,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableContainerProps,
	TableHead,
	TableRow,
	Theme,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { TableRowProps } from '@mui/material/TableRow/TableRow';
import { TableCellProps } from '@mui/material/TableCell/TableCell';

export type Align = 'inherit' | 'left' | 'center' | 'right' | 'justify';

export interface IDataTableProps {
	tableContainerProps?: TableContainerProps;
	columns: Array<{ name: string; sx?: SxProps<Theme>; align?: Align }>;
	dataHiddenColumnIndices?: number[];
	rows: Array<{
		selected?: boolean;
		data: IDataTableRow[];
		rowProps?: Partial<TableRowProps & { disabled?: boolean }>;
		cellProps?: Partial<TableCellProps>;
	}>;
	onRowClick?: (rowIndex: number) => void;
	onDeleteClick?: (rowIndex: number) => void;
	onCopyClick?: (rowIndex: number) => void;
	onSelectClick?: (rowIndex: number, value: boolean) => void;
	buildRowKey?: (rowIndex: number) => string;
}

export interface IDataTableRow {
	value: React.ReactNode;
	align?: Align;
	sx?: SxProps<Theme>;
}
export default function DataTable({
	tableContainerProps,
	columns,
	dataHiddenColumnIndices,
	rows,
	onRowClick,
	onDeleteClick,
	onCopyClick,
	onSelectClick,
	buildRowKey,
}: IDataTableProps) {
	const additionalProps = (index: number) =>
		dataHiddenColumnIndices && dataHiddenColumnIndices.find((x) => x === index) !== undefined
			? { 'data-private': true }
			: {};

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<TableContainer component={Paper} {...tableContainerProps}>
			<Table stickyHeader>
				<TableHead>
					<TableRow>
						{columns.length > 0 && <TableCell>{columns[0].name}</TableCell>}
						{columns.map(
							(x, index) =>
								index !== 0 && (
									<TableCell key={x.name} sx={x.sx} align={x.align || 'right'}>
										{columns[index].name}
									</TableCell>
								)
						)}
					</TableRow>
				</TableHead>
				<TableBody sx={{ overflowY: 'auto' }}>
					{rows.map((row, rowIndex) => (
						<TableRow
							// Alternative for computing key: row.map((x) => x.value).reduce((x, y) => `${x}${y}`)
							// eslint-disable-next-line react/no-array-index-key
							key={buildRowKey ? buildRowKey(rowIndex) : rowIndex}
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...row.rowProps}
							sx={{
								cursor: onRowClick && !row.rowProps?.disabled ? 'pointer' : undefined,
								...row.rowProps?.sx,
							}}
							hover={row.rowProps?.hover !== undefined ? row.rowProps?.hover : !!onRowClick}
						>
							{onSelectClick && (
								<TableCell align="left">
									<Checkbox
										size="small"
										checked={!!row.rowProps?.selected}
										onChange={(e) => onSelectClick(rowIndex, e.target.checked)}
									/>
								</TableCell>
							)}
							{row.data.map((cell, index) => (
								<TableCell
									// eslint-disable-next-line react/no-array-index-key
									key={index}
									sx={cell.sx}
									align={cell.align || (index > 0 ? 'right' : undefined)}
									// eslint-disable-next-line react/jsx-props-no-spreading
									{...row.cellProps}
									onClick={
										onRowClick && !row.rowProps?.disabled && !React.isValidElement(cell.value)
											? () => onRowClick(rowIndex)
											: undefined
									}
									// eslint-disable-next-line react/jsx-props-no-spreading
									{...additionalProps(index)}
								>
									{cell.value}
								</TableCell>
							))}
							<TableCell align="right">
								{onCopyClick && (
									<IconButton aria-label="delete" onClick={() => onCopyClick(rowIndex)}>
										<ContentCopyIcon sx={{ fontSize: '0.85rem' }} />
									</IconButton>
								)}
								{onDeleteClick && (
									<IconButton aria-label="delete" onClick={() => onDeleteClick(rowIndex)}>
										<DeleteIcon sx={{ fontSize: '0.85rem' }} />
									</IconButton>
								)}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

DataTable.defaultProps = {
	onRowClick: () => {},
};
