import { createAction } from '@reduxjs/toolkit';
import { appConstants } from './app.constsnts';
import { IUserSettings } from '../../models/users/user.models';
import { IAIModel } from '../../api/system/system.api.models';

// Init App
const initAppAction = createAction(appConstants.INIT_APP);

// Init user services

export interface IInitUserServicesActionPayload {
	userSettings: IUserSettings;
}

const initUserServicesAction = createAction<IInitUserServicesActionPayload>(appConstants.INIT_USER_SERVICES);

const getAiModels = createAction(appConstants.GET_AI_MODELS);
const setAiModels = createAction<IAIModel[]>(appConstants.SET_AI_MODELS);

export const appActions = {
	initAppAction,
	initUserServicesAction,

	getAiModels,
	setAiModels,
};
