const FONT_PRIMARY = 'Inter, sans-serif';

const typography = {
	fontFamily: FONT_PRIMARY,
	fontWeightRegular: 400,
	fontWeightMedium: 600,
	fontWeightBold: 700,
};

export default typography;
