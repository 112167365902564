import dayjs from 'dayjs';
import { dateFormat } from '../../core.constants';

function getPatientNameFromSearchQuery(query: string): { firstName?: string; lastName: string } {
	const names = query.indexOf(',') > 0 ? query.split(',') : query.split(' ');
	const namesLen = names.length;
	if (namesLen >= 3) {
		return {
			lastName: `${names[0]}`,
			firstName: `${names[2]}`,
		};
	}
	if (names[1]) {
		return {
			lastName: names[0],
			firstName: names[1],
		};
	}
	return {
		lastName: names[0],
	};
}

export function getQueryFromString(
	query: string
): { firstName?: string; lastName: string } | { DOB: string } | { patientID: string } {
	if (dayjs(query, dateFormat, true).isValid()) return { DOB: query };
	if (parseInt(query, 10)) return { patientID: query };
	return getPatientNameFromSearchQuery(query);
}

function appendStringIfNotEmpty(str: string, append: string): string {
	return append ? `${str} ${append}` : str;
}

export function getPatientName(name1: string, middleName: string, name2: string): string {
	let result = appendStringIfNotEmpty('', name1);
	result = appendStringIfNotEmpty(result, middleName);
	result = appendStringIfNotEmpty(result, name2);
	return result;
}
