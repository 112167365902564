import * as React from 'react';
import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { IPatientEncounter } from '../../core/models/patients/patients.models';
import SelectFiled from '../select/SelectField';
import { IPreviewDocument } from '../../core/models/dictations/document.models';
import Loading from '../spinner/Loading';

interface IDocumentPreviewProps {
	documents: IPatientEncounter[];
	document: IPreviewDocument | null;
	documentIsLoading: boolean;
	onDocumentSelect: (documentRef: { documentId: string; documentPatientId: string; documentTypeName: string }) => void;
}

function groupByDocumentType(documents: IPatientEncounter[]): {
	[key: string]: IPatientEncounter[];
} {
	const documentGroups: {
		[key: string]: IPatientEncounter[];
	} = {};

	documents.forEach((doc) => {
		const docKey = doc.documentTypeName.toLowerCase();

		if (documentGroups[docKey]) {
			documentGroups[docKey].push(doc);
		} else {
			documentGroups[docKey] = [doc];
		}
	});

	return documentGroups;
}

function DocumentPreview({ documents, document, documentIsLoading, onDocumentSelect }: IDocumentPreviewProps) {
	const documentsByType: { [key: string]: IPatientEncounter[] } = groupByDocumentType(documents);
	const [selectedType, setSelectedType] = useState<string | null>(null);
	const [selectedDocumentId, setSelectedDocumentId] = useState<string | null>();

	const previewDocument = documents.find((x) => x.documentID === selectedDocumentId) || null;

	const nameSanitizer = (type: string) => {
		switch (type.toLowerCase()) {
			case 'clinicaldocument':
				return 'clinical document';
			default:
				return type.toLowerCase();
		}
	};

	return (
		<Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
			<Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', pb: 2, pt: 2 }}>
				<SelectFiled
					sx={{ pr: 1 }}
					fullWidth
					size="small"
					disabled={Object.keys(documentsByType).length === 0}
					value={selectedType || ''}
					label="Document Type"
					data={Object.keys(documentsByType).map((x) => ({
						text: `${nameSanitizer(x).replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())} (${documentsByType[x].length})`,
						value: x,
					}))}
					onChange={(e) => {
						setSelectedType(e.target.value);
						setSelectedDocumentId(null);
					}}
				/>
				<SelectFiled
					sx={{ ml: 1 }}
					fullWidth
					size="small"
					disabled={!selectedType}
					value={selectedDocumentId || ''}
					label="Document"
					data={
						selectedType
							? documentsByType[selectedType].map((x) => ({
									text: `${dayjs(x.createDateTime).format('MM / DD / YYYY')} - ${x.title}`,
									value: x.documentID,
							  }))
							: []
					}
					onChange={(e) => {
						const documentId = e.target.value;
						const doc = documents.find((x) => x.documentID === documentId);
						if (doc) {
							setSelectedDocumentId(documentId);
							onDocumentSelect({
								documentId,
								documentPatientId: doc.patientID,
								documentTypeName: doc.documentTypeName,
							});
						}
					}}
				/>
			</Box>
			<Box
				sx={{ display: 'flex', flex: 1, overflowY: 'hidden', pb: 2, alignItems: 'center', justifyContent: 'center' }}
			>
				{documentIsLoading ? (
					<Loading />
				) : (
					<>
						{selectedDocumentId && document && previewDocument && (
							<embed
								style={{ width: '100%', height: '100%' }}
								src={`data:application/${document.type};base64,${document.base64}#toolbar=0`}
							/>
						)}
						{!selectedDocumentId && <Typography>Select a document</Typography>}
						{Object.keys(documentsByType).length === 0 && <Typography>There are no documents</Typography>}
					</>
				)}
			</Box>
		</Box>
	);
}

export default DocumentPreview;
