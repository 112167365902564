import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Dialog, DialogTitle, LinearProgress, Paper } from '@mui/material';
import dayjs from 'dayjs';
import { namespaces } from '../../application/i18n.constants';
import { dateFormat } from '../../core/core.constants';
import Spinner from '../spinner/Spinner';
import SearchBar from '../search-bar/SearchBar';
import DataTable from '../data-table/DataTable';
import { useAppDispatch, useAppSelector } from '../../core/core.types';
import { IPatientsSearchResult } from '../../core/models/patients/patients.models';
import { actions } from '../../core/state/actions';
import { getPatientName, getQueryFromString } from '../../core/services/patients/patients.services';

interface ISearchPatientProps {
	open: boolean;
	isLoading: boolean;
	onClose?: () => void;
	onSelectPatient: (patientID: string) => void;
}

interface IPatientsSearchResultsProps {
	patientsSearchResults: IPatientsSearchResult[];
	loading: boolean;
	onRowClick: (rowIndex: number) => void;
}

function PatientSearchResults({ loading, patientsSearchResults, onRowClick }: IPatientsSearchResultsProps) {
	const { t } = useTranslation(namespaces.components.searchPatient);

	const columns = [
		{
			name: t('patient'),
		},
		{
			name: t('patientId'),
		},
		{
			name: t('dateOfBirth'),
		},
	];

	const rows = patientsSearchResults.map((patient) => ({
		data: [
			{
				value: getPatientName(patient.lastName, patient.middleName, patient.firstName),
			},
			{
				value: patient.patientID,
			},
			{
				value: patient.dateOfBirth ? dayjs(patient.dateOfBirth).format(dateFormat) : '',
			},
		],
	}));

	return (
		<Box>{loading ? <LinearProgress /> : <DataTable columns={columns} rows={rows} onRowClick={onRowClick} />}</Box>
	);
}

function SearchPatient({ isLoading, open, onClose, onSelectPatient }: ISearchPatientProps) {
	const dispatch = useAppDispatch();
	const { t } = useTranslation(namespaces.components.searchPatient);
	const patientsSearchResults = useAppSelector<IPatientsSearchResult[]>(
		(state) => state.worklist.currentDocument.patients
	);
	const patientSearchIsLoading = useAppSelector<boolean>((state) => state.worklist.currentDocument.searchIsLoading);

	const handleSearch = (searchQuery: string) => {
		if (searchQuery && searchQuery.length > 0) {
			const parsedQuery = getQueryFromString(searchQuery);
			if (parsedQuery) {
				dispatch(actions.worklist.worklistPatientSearchAction(parsedQuery));
			}
		}
	};

	const handlePatientSelect = (rowId: number) => {
		const { patientID } = patientsSearchResults[rowId];
		onSelectPatient(patientID);
	};

	return (
		<Dialog fullWidth maxWidth="sm" onClose={onClose} open={open}>
			<DialogTitle textAlign="center">{t('title')}</DialogTitle>
			{!isLoading ? (
				<Paper
					sx={{
						overflow: 'auto',
					}}
				>
					<SearchBar onSearch={handleSearch} />
					<PatientSearchResults
						loading={patientSearchIsLoading}
						patientsSearchResults={patientsSearchResults}
						onRowClick={handlePatientSelect}
					/>
				</Paper>
			) : (
				<Box
					sx={{
						display: 'flex',
						flex: 1,
						minHeight: '10rem',
						overflow: 'auto',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<Spinner />
				</Box>
			)}
		</Dialog>
	);
}

SearchPatient.defaultProps = {
	onClose: undefined,
};

export default SearchPatient;
