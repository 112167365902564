import { cloneDeep } from 'lodash';
import { IBillingCode } from '../../models/dictations/dictations.models';

export const isBillingServiceSelected = (service: IBillingCode): boolean =>
	service.modified === 1 || service.modified === 3 || (!!service.serviceID && service.modified !== 2);

export const billingHash = (code: IBillingCode) => {
	const { procedureCode } = code;
	const defaultValue = 'null';

	const serviceType = code.serviceType.toLowerCase();
	const encounterObjectID = serviceType === 'emcode' ? defaultValue : `${code.encounterObjectID || defaultValue}`;
	const procedureDocumentationTemplateID =
		serviceType === 'emcode'
			? defaultValue
			: `procedureDocumentationTemplateID-${code.procedureDocumentationTemplateID || defaultValue}`;

	return `serviceType-${serviceType}_procedureCode-${procedureCode}_encounterObjectID-${encounterObjectID}procedureDocumentationTemplateID-${procedureDocumentationTemplateID}`;
};

export const addBillingServices = (billingCodes: IBillingCode[], icd10Codes: string[], procedureCode: string) => {
	const codes = cloneDeep(billingCodes);

	const newBillingCode: IBillingCode = {
		procedureCode,
		modifiers: [],
		iCD10Codes: [],
		billForService: false,
		serviceType: 'EMCODE',
		modified: 1,
		units: null,
	};

	if (icd10Codes.length === 1) {
		newBillingCode.iCD10Codes.push(icd10Codes[0]);
	}

	const currentBillingService = codes.find((x) => billingHash(x) === billingHash(newBillingCode));

	if (!currentBillingService || currentBillingService.modified === 2) {
		return [
			...codes.filter((x) => billingHash(x) !== billingHash(newBillingCode)),
			{
				...newBillingCode,
				serviceID: currentBillingService?.serviceID || null,
				modified: currentBillingService?.serviceID ? 3 : 1,
			},
		];
	}

	return billingCodes;
};
