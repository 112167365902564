import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { IOrderField } from '../../core/models/dictations/dictations.models';
import { useAppSelector } from '../../core/core.types';
import { namespaces } from '../../application/i18n.constants';
import { orderHash } from '../../core/services/documents/documents.services';
import ItemsList from '../list/ItemsList';

interface IOrderItemsListProps {
	searchQuery: string;
	selectedOrders: IOrderField[];
	isLoading: (waitingResult: boolean) => boolean;
	onSelectOrder: (order: IOrderField) => void;
}

function OrderItem({ order }: { order: IOrderField }) {
	return (
		<Box sx={{ flexDirection: 'column' }}>
			<Typography sx={{ fontWeight: 'bold' }}>{order.description}</Typography>
			<Typography variant="subtitle1">{order.orderTypeGroup}</Typography>
		</Box>
	);
}

function OrderItemsList({ searchQuery, selectedOrders, isLoading, onSelectOrder }: IOrderItemsListProps) {
	const searchOrdersResults = useAppSelector<IOrderField[]>((state) => state.worklist.ordersSearchResult);
	const searchOrdersIsLoading = useAppSelector<boolean>((state) => state.worklist.ordersSearchIsLoading);
	const { t } = useTranslation(namespaces.components.diagnosesAndOrdersDialog);

	const loading = isLoading(searchOrdersIsLoading);
	return searchQuery.length > 0 ? (
		<ItemsList
			button
			loading={loading}
			listHeader={
				searchOrdersResults && searchOrdersResults.length > 0 && !loading ? t('searchResults') : t('noSearchResults')
			}
			items={searchOrdersResults.map((x) => ({ id: orderHash(x), title: x.description, ...x }))}
			renderItem={(order: IOrderField) => <OrderItem order={order} />}
			isSelected={(order) =>
				!!selectedOrders.filter((x) => x.modified !== 2).find((x) => orderHash(x) === orderHash(order))
			}
			onClick={onSelectOrder}
		/>
	) : (
		<ItemsList
			listHeader={t('addedItems')}
			items={selectedOrders
				.filter((x) => x.modified !== 2)
				.map((x) => ({ id: orderHash(x), title: x.description, ...x }))}
			isSelected={(order) =>
				!!selectedOrders.filter((x) => x.modified !== 2).find((x) => orderHash(x) === orderHash(order))
			}
			renderItem={(order: IOrderField) => <OrderItem order={order} />}
		/>
	);
}

export default OrderItemsList;
