import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../core/core.types';
import DictationPage from './DictationPage';
import { actions } from '../../core/state/actions';
import {
	ICustomPatientDemographicsData,
	IPatientDemographic,
	IPatientEncounter,
} from '../../core/models/patients/patients.models';
import {
	IBillingCode,
	IBillingModifier,
	IDepartmentDictation,
	IDiagnoseField,
	IDiagnoseFields,
	IDictation,
	IOpenAISummary,
	IOrderField,
	IProcedureCode,
	IProcedureDocumentationField,
	IProcedureDocumentationTemplate,
	IQANote,
	ITimestampedTranscription,
} from '../../core/models/dictations/dictations.models';
import { getDictationCache, getEncounterCache } from '../../core/services/dictations/dictations-cache.service';
import {
	CodedErrorType,
	IDocumentDetails,
	IPreviewDocument,
	JobStatus,
} from '../../core/models/dictations/document.models';
import { IFeatureFlags, IScriberPermissions } from '../../core/models/users/user.models';
import { trackEvent } from '../../application/app-insights';
import { IMacro } from '../../core/models/macros/macros.models';
import { EHRType } from '../../core/models/ehr.models';
import { canJobHaveEncounters } from '../../core/services/documents/documents.services';
import { AIModelType } from '../../core/api/dictations/dictations.api.models';

function Dictation() {
	const dispatch = useAppDispatch();

	const [searchParams] = useSearchParams();
	const blobID = searchParams.get('blobId');

	const ehr = useAppSelector<EHRType | null>((state) => state.user.settings.system);

	const edited = useAppSelector<boolean>((state) => state.worklist.currentDocument.edited);
	const isDocumentPullLoading = useAppSelector<boolean>((state) => state.worklist.currentDocument.pullIsLoading);
	const document = useAppSelector<IDocumentDetails | undefined>((state) => state.worklist.currentDocument.document);
	const isDocumentLoading = useAppSelector<boolean>((state) => state.worklist.currentDocument.isDocumentLoading);
	const patientDemographic = useAppSelector<IPatientDemographic | undefined>(
		(state) => state.worklist.currentDocument.chart.patientDemographic
	);
	const patientDemographicIsLoading = useAppSelector<boolean>(
		(state) => state.worklist.currentDocument.chart.patientDemographicIsLoading
	);
	const patientDocuments = useAppSelector<IPatientEncounter[] | undefined>(
		(state) => state.worklist.currentDocument.chart.patientEncounters
	);
	const [documentLoaded, setDocumentLoaded] = useState<boolean>(false);
	const patientDocumentsIsLoading = useAppSelector<boolean>(
		(state) => state.worklist.currentDocument.chart.patientEncountersIsLoading
	);

	const previewDocument = useAppSelector<IPreviewDocument | undefined>(
		(state) => state.worklist.currentDocument.previewDocument
	);
	const previewDocumentIsLoading = useAppSelector<boolean>(
		(state) => state.worklist.currentDocument.previewDocumentIsLoading
	);

	const dictation = useAppSelector<IDictation | undefined>((state) => state.worklist.currentDocument.dictation);
	const currentEncounterIsLoading = useAppSelector<boolean>(
		(state) => state.worklist.currentDocument.encounterIsLoading
	);
	const currentEncounter = useAppSelector<IPatientEncounter | undefined>(
		(state) => state.worklist.currentDocument.encounter
	);
	const unassignedOrders = useAppSelector<IOrderField[]>((state) => state.worklist.currentDocument.unassignedOrders);
	const pulledDiagnoses = useAppSelector<IDiagnoseField[] | null>(
		(state) => state.worklist.currentDocument.pullAssessmentPlanDiagnoses
	);
	const billingLoaded = useAppSelector<boolean>((state) => state.worklist.currentDocument.billingLoaded);
	const billingClaimCreated = useAppSelector<boolean>((state) => state.worklist.currentDocument.billingClaimCreated);
	const billingCodes = useAppSelector<IBillingCode[]>((state) => state.worklist.currentDocument.billingCodes);
	const qaNotes = useAppSelector<IQANote[]>((state) => state.worklist.currentDocument.qaNotes);
	const username = useAppSelector<string>((state) => state.user.settings.username);
	const permissions = useAppSelector<IScriberPermissions>((state) => state.user.settings.permissions);
	const featureFlags = useAppSelector<IFeatureFlags>((state) => state.user.settings.featureFlags);

	const saveProcedureDocumentationIsLoading = useAppSelector<boolean>(
		(state) => state.worklist.currentDocument.saveProcedureDocumentationIsLoading
	);

	const applyProcedureTemplateIsLoading: boolean = useAppSelector(
		(state) => state.worklist.currentDocument.applyProcedureDocumentationTemplateIsLoading
	);

	const billingModifiersLoading = useAppSelector<boolean>((state) => state.worklist.billingModifiersIsLoading);
	const billingModifiers = useAppSelector<IBillingModifier[]>((state) => state.worklist.billingModifiersSearchResult);

	const codeSearchIsLoading = useAppSelector<boolean>((state) => state.worklist.emCodeSearchIsLoading);
	const procedureCodeSearchResults = useAppSelector<IProcedureCode[]>(
		(state) => state.worklist.procedureCodeSearchResult
	);

	const isDocumentSaveLoading = useAppSelector<boolean>(
		(state) => state.worklist.currentDocument.isDocumentSaveLoading
	);

	const saveErrors = useAppSelector<CodedErrorType[] | undefined>((state) => state.worklist.currentDocument.saveErrors);

	const providerDotPhrases = useAppSelector<IMacro[]>((state) => state.worklist.currentDocument.providerDotPhrases);

	const transcription = useAppSelector<{
		azureASR: { text: string | null; timestampedText: ITimestampedTranscription[] | null };
		mModalASR: string | null;
		nuanceASR: string | null;
		openAISummary: string | null;
		openAISummaryStructured: IOpenAISummary | null;
		prompt: string | null;
		customOpenAISummary: string | null;
		customOpenAISummaryStructured: IOpenAISummary | null;
	}>((state) => state.worklist.currentDocument.transcription);

	const providerNote = useAppSelector<string>((state) => state.worklist.currentDocument.providerNote);
	const customPatientDemographics = useAppSelector<ICustomPatientDemographicsData | null>(
		(state) => state.worklist.currentDocument.customPatientDemographics
	);

	const aiPrompt = useAppSelector<{
		prompt: string | null;
		instructions: string | null;
	}>((state) => {
		const trns = state.worklist.currentDocument.transcription;
		return {
			prompt: trns.prompt,
			instructions: trns.instructions,
		};
	});
	const lastTimePromptSubmitted = useAppSelector<number | null>(
		(state) => state.worklist.currentDocument.transcription.lastTimePromptSubmitted
	);

	const flags = useAppSelector<IFeatureFlags>((state) => state.user.settings.featureFlags);
	const patientId = dictation?.patientID;

	React.useEffect(() => {
		if (!dictation && blobID) {
			const currentDictationCache: IDepartmentDictation | null = getDictationCache(blobID);
			if (currentDictationCache) {
				dispatch(actions.worklist.setCurrentDictation({ dictation: currentDictationCache }));
			}
			if (currentDictationCache && !currentEncounter) {
				const currentEncounterCache: IPatientEncounter | null = getEncounterCache(blobID);
				if (currentEncounterCache) {
					dispatch(
						actions.worklist.updateDictationEncounterAction({
							blobId: blobID,
							encounter: currentEncounterCache,
						})
					);
				}
			}
		}
	}, []);

	const canHaveEncounters = canJobHaveEncounters(dictation?.documentType);

	useEffect(() => {
		if (blobID && dictation && !canHaveEncounters) {
			dispatch(
				actions.worklist.getCurrentDocumentAction({
					blobId: blobID,
					documentTypeName: dictation.documentType,
					metadata: {
						providerId: dictation.providerUserInfoID,
					},
				})
			);
		}
	}, []);

	useEffect(() => {
		if (blobID && dictation && canHaveEncounters && !documentLoaded) {
			if (!dictation.patientID || (patientDocuments && !currentEncounter)) {
				setDocumentLoaded(true);
				dispatch(
					actions.worklist.getCurrentDocumentAction({
						blobId: blobID,
						documentTypeName: dictation.documentType,
						metadata: {
							providerId: dictation.providerUserInfoID,
						},
					})
				);
			}
		}
	}, [patientDocuments]);

	React.useEffect(() => {
		const providerId = dictation?.providerUserInfoID;
		if (providerId) {
			dispatch(actions.worklist.getDocumentProviderDotphrasesAction({ providerId }));
		}
	}, [currentEncounter?.providerId]);

	React.useEffect(() => {
		if (patientId && blobID) {
			dispatch(actions.worklist.openPatientDocumentAction({ blobId: blobID, patientId }));
		}
	}, [patientId]);

	React.useEffect(() => {
		dispatch(actions.worklist.getOrderSets());
	}, []);

	React.useEffect(() => {
		dispatch(actions.worklist.getBillingModifiers());
	}, []);

	const onCloseDictationPage = () => {
		if (blobID) {
			trackEvent('1202001', 'JobClose', { blobId: blobID });
			dispatch(actions.worklist.closeDictation({ blobID, unlock: true, async: true }));
		}
	};

	const onEditedChange = (isEdited: boolean) => {
		dispatch(actions.worklist.setEditedDocument(isEdited));
	};

	const onPullDocumentField = (fieldId: string, documentId: string) => {
		if (patientId) {
			dispatch(actions.worklist.pullCurrentDocumentFiledAction({ documentId, patientId, fieldId }));
		}
	};

	const onPreviewDocumentSelect = useCallback(
		({
			documentId,
			documentPatientId,
			documentTypeName,
		}: {
			documentId: string;
			documentPatientId: string;
			documentTypeName: string;
		}) => {
			dispatch(
				actions.worklist.getPatientPreviewDocument({
					patientId: documentPatientId,
					documentId,
					documentTypeName,
				})
			);
		},
		[dispatch]
	);

	const onCloseDiagnosesPull = () => {
		dispatch(actions.worklist.setPullAssessmentPlanDiagnosesAction(null));
	};

	const onMergePulledAssessmentPlan = (diagnoses: IDiagnoseField[]) => {
		dispatch(actions.worklist.mergePulledAssessmentPlanAction(diagnoses));
	};

	const onFieldsUpdate = (fields: IDiagnoseFields) => {
		dispatch(
			actions.worklist.setDocumentFieldsAction({
				fields,
			})
		);
	};

	const onDiagnosisRankingChanged = () => {
		dispatch(actions.worklist.setReorderDiagnoses(true));
	};

	const onApplyOrderSet = (orderSet: { orderSetId: number; diagnoseHash?: string }) => {
		dispatch(actions.worklist.applyOrderSet(orderSet));
	};

	const onApplyProcedureDocumentationTemplate = (template: IProcedureDocumentationTemplate) => {
		if (currentEncounter?.patientID && currentEncounter?.documentID) {
			dispatch(
				actions.worklist.applyProcedureDocumentationTemplateAction({
					patientId: currentEncounter.patientID,
					documentId: currentEncounter.documentID,
					template,
				})
			);
		}
	};

	const onSaveProcedureDocumentations = (procedureDocumentation: IProcedureDocumentationField[]) => {
		if (currentEncounter?.patientID && currentEncounter?.documentID) {
			dispatch(
				actions.worklist.saveProcedureDocumentationAction({
					patientId: currentEncounter.patientID,
					documentId: currentEncounter.documentID,
					procedureDocumentation,
				})
			);
		}
	};

	const onBillingCodesUpdate = (codes: IBillingCode[]) => {
		dispatch(actions.worklist.setBillingCodes({ claimCreated: billingClaimCreated, billingCodes: codes }));
	};

	const onQANotesUpdate = (notes: IQANote[]) => {
		dispatch(actions.worklist.setQANotes(notes));
	};

	const onSaveFields = (options: {
		markTranscriptionComplete: boolean;
		sendToQA: boolean;
		forceSave: boolean;
		forceToQA: boolean;
	}) => {
		const blobId = blobID || undefined;
		if (options.markTranscriptionComplete) {
			trackEvent('1201001', 'JobComplete', { blobId });
		} else if (options.sendToQA) {
			trackEvent('1201002', 'JobSendToQA', { blobId, forceToQA: options.forceToQA });
		} else {
			trackEvent('1201003', 'JobSave', { blobId, forceToQA: options.forceToQA, onTimeOut: false });
		}

		dispatch(actions.worklist.saveDocumentAction(options));
	};

	const onMarkAsComplete = () => {
		if (dictation) {
			dispatch(
				actions.jobs.updateJob({
					blobId: dictation.blobID,
					job: dictation,
					update: { documentStatus: JobStatus.Completed },
				})
			);
			onCloseDictationPage();
		}
	};

	const onSelectPatient = (selectedPatientId: string) => {
		dispatch(actions.worklist.setDictationPatientAction({ patientId: selectedPatientId, resetEncounter: true }));
	};

	const onSelectPatientEncounter = (encounter: IPatientEncounter) => {
		if (dictation && blobID) {
			dispatch(
				actions.worklist.updateDictationEncounterAction({
					blobId: blobID,
					encounter,
					updateEncounter: true,
					ignoreStagedEncounterData: true,
				})
			);
		}
	};

	const onUnassignedOrdersChange = (orders: IOrderField[]) => {
		dispatch(actions.worklist.setUnassignedOrders(orders));
	};

	const onProviderNoteChange = (note: string) => {
		dispatch(actions.worklist.setProviderNote(note));
	};

	const onAIPromptChange = (prompt: string) => {
		dispatch(actions.worklist.setAIPrompt(prompt));
	};

	const onAIInstructionsChange = (instructions: string) => {
		dispatch(actions.worklist.setAIInstructions(instructions));
	};

	const onAIPromptSubmit = (aiModel: AIModelType | null, prompt: { prompt: string; instructions: string }) => {
		if (dictation?.blobID) {
			dispatch(actions.worklist.requestAISummaryForPrompt({ blobId: dictation?.blobID, prompt, aiModel }));
		}
	};

	const updateCustomPatientDemographics = (data: ICustomPatientDemographicsData) => {
		dispatch(
			actions.worklist.updateCustomPatientDemographics({
				blobId: blobID || '',
				data,
			})
		);
	};

	return (
		<DictationPage
			ehr={ehr as EHRType}
			edited={edited}
			permissions={permissions}
			featureFlags={featureFlags}
			onCloseDictation={onCloseDictationPage}
			onEditedChange={onEditedChange}
			isDocumentLoading={isDocumentLoading}
			saveErrors={saveErrors}
			document={document}
			transcription={transcription}
			username={username}
			patientDemographic={patientDemographic}
			patientDemographicIsLoading={patientDemographicIsLoading}
			patientDocumentsIsLoading={patientDocumentsIsLoading}
			patientDocuments={patientDocuments}
			currentEncounterIsLoading={currentEncounterIsLoading}
			currentEncounter={currentEncounter}
			previewDocument={previewDocument}
			previewDocumentIsLoading={previewDocumentIsLoading}
			onPreviewDocumentSelect={onPreviewDocumentSelect}
			dictationStat={dictation?.stat}
			dictation={dictation}
			isDocumentPullLoading={isDocumentPullLoading}
			onPullDocumentField={onPullDocumentField}
			pulledDiagnoses={pulledDiagnoses}
			isDocumentSaveLoading={isDocumentSaveLoading}
			onApplyOrderSet={onApplyOrderSet}
			onFieldsUpdate={onFieldsUpdate}
			onDiagnosisRankingChanged={onDiagnosisRankingChanged}
			onSaveFields={onSaveFields}
			onMarkAsComplete={onMarkAsComplete}
			onSelectPatient={onSelectPatient}
			onSelectPatientEncounter={onSelectPatientEncounter}
			unassignedOrders={unassignedOrders}
			onUnassignedOrdersChange={onUnassignedOrdersChange}
			onCloseDiagnosesPull={onCloseDiagnosesPull}
			onMergePulledAssessmentPlan={onMergePulledAssessmentPlan}
			saveProcedureDocumentationIsLoading={saveProcedureDocumentationIsLoading}
			applyProcedureTemplateIsLoading={applyProcedureTemplateIsLoading}
			onApplyProcedureDocumentationTemplate={onApplyProcedureDocumentationTemplate}
			onSaveProcedureDocumentations={onSaveProcedureDocumentations}
			billingLoaded={billingLoaded}
			billingClaimCreated={billingClaimCreated}
			billingCodes={billingCodes}
			onBillingCodesChange={onBillingCodesUpdate}
			billingModifiersLoading={billingModifiersLoading}
			billingModifiers={billingModifiers}
			codeSearchIsLoading={codeSearchIsLoading}
			procedureCodeSearchResults={procedureCodeSearchResults}
			qaNotes={qaNotes}
			onQANotesChange={onQANotesUpdate}
			providerNote={providerNote}
			customPatientDemographics={customPatientDemographics}
			onUpdateCustomPatientDemographics={updateCustomPatientDemographics}
			onChangeProviderNote={onProviderNoteChange}
			aiPrompt={{
				prompt: aiPrompt.prompt || '',
				instructions: aiPrompt.instructions || '',
				canUpdate: permissions.aiAccess && featureFlags.retrieveOpenAISummary.enabled,
				onChangePrompt: onAIPromptChange,
				onChangeInstructions: onAIInstructionsChange,
				lastTimePromptSubmitted,
				onPromptSubmit: onAIPromptSubmit,
			}}
			providerDotPhrases={providerDotPhrases}
			settings={{
				viewBillingServicesEnabled: flags.viewBillingServices.enabled,
				editBillingServicesEnabled: flags.editBillingServices.enabled,
				editProcedureDocumentationEnabled: flags.editProcedureDocumentation.enabled,
				saveOrderInterpretationsEnabled: flags.saveOrderInterpretations.enabled,
			}}
		/>
	);
}

export default Dictation;
