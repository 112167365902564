import { useEffect, useState } from 'react';
import { useBearerToken } from '../shared/hooks/useBearerToken';
import { environment } from '../environment/environment';
import { AppDispatch } from '../core/core.types';

const { SIGNALR_HUB_URL } = environment;

// You may need to adjust these types based on the actual structure of your SignalR messages
export interface SignalRProxy {
	on: (event: string, callback: (...args: unknown[]) => void) => void;
	invoke: (event: string, ...args: unknown[]) => void;
}

export interface SignalRConnection {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	on: (event: string, callback: (...args: any[]) => void) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	qs: Record<string, any>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	start: () => any;
	stop: () => void;
	createHubProxy: (hubName: string) => SignalRProxy;
	logging: boolean;
}

export interface ISignalREventHandler {
	event: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	callback: (dispatch: AppDispatch, ...args: any[]) => void;
}

const getSignalRConnection = (
	token: string,
	dispatch: AppDispatch,
	eventHandlers: ISignalREventHandler[]
): { connection: SignalRConnection; proxy: SignalRProxy } => {
	const hubName = 'ClientUpdateHub'; // Update to match your hub name
	const clientType = 'VSS'; // Update as needed
	const headers = { Authorization: encodeURIComponent(token), ClientType: clientType, HubName: hubName };
	// Setup connection
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const connection = $.hubConnection(SIGNALR_HUB_URL, headers) as unknown as SignalRConnection;

	connection.logging = true;
	connection.qs = headers;
	const proxy = connection.createHubProxy(hubName);
	eventHandlers.forEach((x) => {
		proxy.on(x.event, (...args) => {
			// eslint-disable-next-line no-console
			console.log('SignalR Event: ', x.event, args);
			x.callback(dispatch, ...args);
		});
	});

	return { connection, proxy };
};

/* eslint-disable no-console */
const useSignalRjQuery = (
	dispatch: AppDispatch,
	eventHandler: ISignalREventHandler[]
): { connection: SignalRConnection; proxy: SignalRProxy } | null => {
	const [connRef, setConnRef] = useState<{ connection: SignalRConnection; proxy: SignalRProxy } | null>(null);
	const authenticationToken = useBearerToken();

	const startConnection = (
		connection: SignalRConnection,
		onSuccess: (conn: unknown) => void,
		onFail: (err: unknown) => void
	) => {
		connection.start().done(onSuccess).fail(onFail);
	};

	const closeConnection = () => {
		if (connRef) {
			connRef.connection.stop();
			console.log('SignalR Connection stopped!');
		}
	};

	useEffect(() => {
		if (authenticationToken) {
			closeConnection(); // Close existing connRef if open
			const signalR = getSignalRConnection(authenticationToken, dispatch, eventHandler);
			startConnection(
				signalR.connection,
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				(conn) => {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					console.log('SignalR Connected!', conn?.id);
					setConnRef(signalR);
				},
				(err: unknown) => console.error('SignalR Connection Error: ', err)
			);
		} else {
			closeConnection();
			setConnRef(null);
		}

		return () => {
			closeConnection();
			setConnRef(null);
		};
	}, [authenticationToken]);

	return connRef;
};
/* eslint-enable no-console */

export default useSignalRjQuery;
