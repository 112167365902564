import * as React from 'react';
import { ForwardedRef, forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DiagnosisField, { DiagnoseTextFieldType, IField, IFieldProps } from './DiagnosisField';
import { namespaces } from '../../application/i18n.constants';
import LazyInput, { ILazyInput } from '../inputs/LazyInput';
import { IAutocompleteProps } from '../dot-phrases-popper/autocomplete.utils';
import AutocompletePopper from '../dot-phrases-popper/AutocompletePopper';

export interface ITextField extends IField {
	focusWithCursorAtEnd: () => void;
	getText: () => string | undefined;
}

export interface ITextFieldProps extends IFieldProps {
	text: string;
	onChange?: (text: string) => void;
	onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>, fieldId: string) => void;
	onFocus?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>, fieldId: string) => void;
	onFocusChange?: (
		event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
		fieldId: DiagnoseTextFieldType,
		focus: boolean
	) => void;
	autocomplete?: IAutocompleteProps;
}

const DiagnosisTextField: React.ForwardRefRenderFunction<ITextField, ITextFieldProps> = function DiagnosisTextFieldFunc(
	{ id, name, text, autocomplete, onPull, onAdd, onAppend, onCopy, onChange, onFocus, onBlur, onFocusChange },
	forwardedRef: ForwardedRef<ITextField>
) {
	const { t } = useTranslation(namespaces.components.diagnosis);
	const diagnoseFieldRef = useRef<ITextField>(null);
	const lazyInputRef = useRef<ILazyInput>(null);
	const inputRef = useRef<HTMLTextAreaElement>(null);

	const [hoveredField, setHoveredField] = useState<boolean>(false);
	const [focusedFiled, setFocusedFiled] = useState<boolean>(false);

	const setSelection = (length?: number) => {
		const input = inputRef?.current;
		if (input && (length || length === 0)) {
			input.focus();
			input.setSelectionRange(length, length);
		}
	};

	useImperativeHandle(forwardedRef, () => ({
		focusWithCursorAtEnd() {
			setSelection(inputRef?.current?.value?.length);
		},
		getText() {
			return inputRef?.current?.value;
		},
	}));

	return (
		<AutocompletePopper
			id={id}
			type="input"
			inputRef={inputRef.current}
			autocomplete={{
				separator: autocomplete?.separator || ' ',
				trigger: autocomplete?.trigger || '.',
				options: autocomplete?.options || [],
				sections: autocomplete?.sections,
			}}
		>
			<DiagnosisField
				ref={diagnoseFieldRef}
				id={id}
				childrenHovered={hoveredField}
				childrenFocused={focusedFiled}
				name={name}
				onPull={onPull}
				onAdd={onAdd}
				onAppend={onAppend}
				onCopy={onCopy}
			>
				<LazyInput
					ref={lazyInputRef}
					style={{ width: '100%', resize: 'none' }}
					inputRef={inputRef}
					// eslint-disable-next-line react/jsx-props-no-spreading
					maxRows={50}
					readOnly={!onChange}
					placeholder={t('textPlaceholderNote')}
					onMouseEnter={() => setHoveredField(true)}
					onMouseLeave={() => setHoveredField(false)}
					id={`${id}-input`}
					value={text}
					onBlur={(e) => {
						if (onBlur) {
							onBlur(e, id);
						}
						if (onFocusChange) {
							onFocusChange(e, id as DiagnoseTextFieldType, false);
						}
						setFocusedFiled(false);
					}}
					onFocus={(e) => {
						if (onFocus) {
							onFocus(e, id);
						}
						if (onFocusChange) {
							onFocusChange(e, id as DiagnoseTextFieldType, true);
						}
						setFocusedFiled(true);
					}}
					onLazyChange={(value) => {
						if (onChange) {
							onChange(value);
						}
					}}
				/>
			</DiagnosisField>
		</AutocompletePopper>
	);
};

export default forwardRef(DiagnosisTextField);
