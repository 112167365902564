import { createAction } from '@reduxjs/toolkit';
import { jobsConstants } from './jobs.constants';
import {
	IDictation,
	IDocumentVersion,
	IJobDictation,
	IJobsSearchFilters,
	IOpenAISummary,
	IQANote,
	ITimestampedTranscription,
} from '../../models/dictations/dictations.models';
import {
	ICustomPatientDemographicsData,
	IPatientDemographic,
	IPatientEncounter,
} from '../../models/patients/patients.models';
import { IUserDisplayName } from '../../models/users/user.models';
import { DocumentType, JobStatus } from '../../models/dictations/document.models';
import { AIModelType } from '../../api/dictations/dictations.api.models';

// const openDictation = createAction<{ blobId: string }>(jobsConstants.OPEN_DICTATION);
const openDictation = createAction<{ job: IJobDictation }>(jobsConstants.OPEN_DICTATION);

export interface ISetDictationDataActionPayload {
	dictation: IDictation;
	encounter: IPatientEncounter;
	demographic: IPatientDemographic;
	versions: IDocumentVersion[];
	qaNotes: IQANote[];
	transcription: {
		azureASR: { text: string | null; timestampedText: ITimestampedTranscription[] | null };
		mModalASR: string | null;
		nuanceASR: string | null;
		openAISummary: string | null;
		openAISummaryStructured: IOpenAISummary | null;
		customOpenAISummary: string | null;
		customOpenAISummaryStructured: IOpenAISummary | null;
		prompt: string | null;
		instructions: string | null;
		lastTimePromptSubmitted: number | null;
		patientDemographics: ICustomPatientDemographicsData | null;
	};
	providerNote: string;
}
const setDictationData = createAction<ISetDictationDataActionPayload | null>(jobsConstants.SET_DICTATION_DATA);
const setDictationDataIsLoading = createAction<boolean>(jobsConstants.SET_DICTATION_DATA_IS_LOADING);

// Jobs search
const jobsSearchAction = createAction<IJobsSearchFilters>(jobsConstants.JOBS_SEARCH_REQUEST);
const jobsSearchIsLoadingAction = createAction<boolean>(jobsConstants.JOBS_SEARCH_IS_LOADING);

interface ISetJobsSearchResultActionPayload {
	jobs: IJobDictation[];
}

const setJobsSearchResultAction = createAction<ISetJobsSearchResultActionPayload>(jobsConstants.JOBS_SET_RESULTS);

// Users search
const getUsersAction = createAction(jobsConstants.USERS_GET_REQUEST);
const getUsersIsLoadingAction = createAction<boolean>(jobsConstants.USERS_GET_IS_LOADING);

interface ISetUsersResultActionPayload {
	users: IUserDisplayName[];
}

const setUsersResultAction = createAction<ISetUsersResultActionPayload>(jobsConstants.USERS_SET_RESULTS);

export interface IChangeDocumentStatusActionPayload {
	blobId: string;
	job: IJobDictation;
	update: {
		documentStatus?: JobStatus;
		documentTypeName?: DocumentType;
		stat?: boolean;
	};
}
const updateJob = createAction<IChangeDocumentStatusActionPayload>(jobsConstants.UPDATE_JOB);

// AI Prompt
const setAIPrompt = createAction<string>(jobsConstants.SET_AI_PROMPT);
const setAIInstructions = createAction<string>(jobsConstants.SET_AI_INSTRUCTIONS);
const setLastTimePromptSubmitted = createAction<number | null>(jobsConstants.SET_LAST_TIME_PROMPT_SUBMITTED);

export interface IRequestAISummaryForPromptActionPayload {
	blobId: string;
	aiModel: AIModelType | null;
	prompt: { prompt: string; instructions: string };
}
const requestAISummaryForPrompt = createAction<IRequestAISummaryForPromptActionPayload>(
	jobsConstants.REQUEST_AI_SUMMARY_FOR_PROMPT
);

export interface IUpdatePromptAISummaryActionPayload {
	blobId: string;
}
const updatePromptAISummary = createAction<IUpdatePromptAISummaryActionPayload>(jobsConstants.UPDATE_PROMPT_AI_SUMMARY);

export interface ISetPromptAISummaryActionPayload {
	openAISummary: string | null;
	openAISummaryStructured: IOpenAISummary | null;
}
const setPromptAISummary = createAction<ISetPromptAISummaryActionPayload>(jobsConstants.SET_PROMPT_AI_SUMMARY);

export const jobsActions = {
	openDictation,
	setDictationData,
	setDictationDataIsLoading,
	jobsSearchAction,
	jobsSearchIsLoadingAction,
	setJobsSearchResultAction,
	getUsersAction,
	getUsersIsLoadingAction,
	setUsersResultAction,
	updateJob,
	setAIPrompt,
	setAIInstructions,
	setLastTimePromptSubmitted,
	requestAISummaryForPrompt,
	updatePromptAISummary,
	setPromptAISummary,
};
