export const adminConstants = {
	GET_PROVIDER_MACROS: 'ADMIN/GET_PROVIDER_MACROS',
	SET_PROVIDER_MACROS_IS_LOADING: 'ADMIN/SET_PROVIDER_MACROS_IS_LOADING',
	SET_PROVIDER_MACROS: 'ADMIN/SET_PROVIDER_MACROS',
	SAVE_PROVIDER_MACRO: 'ADMIN/SAVE_PROVIDER_MACRO',
	SET_SAVE_PROVIDER_MACRO_IS_LOADING: 'ADMIN/SET_SAVE_PROVIDER_MACRO_IS_LOADING',
	UPDATE_PROVIDER_MACRO: 'ADMIN/UPDATE_PROVIDER_MACRO',
	DELETE_PROVIDER_MACRO: 'ADMIN/DELETE_PROVIDER_MACRO',
	IMPORT_PROVIDER_MACRO: 'ADMIN/IMPORT_PROVIDER_MACRO',
	COPY_PROVIDER_MACROS: 'ADMIN/COPY_PROVIDER_MACROS',

	GET_CUSTOMER_MACROS: 'ADMIN/GET_CUSTOMER_MACROS',
	SET_CUSTOMER_MACROS_IS_LOADING: 'ADMIN/SET_CUSTOMER_MACROS_IS_LOADING',
	SET_CUSTOMER_MACROS: 'ADMIN/SET_CUSTOMER_MACROS',
	SAVE_CUSTOMER_MACRO: 'ADMIN/SAVE_CUSTOMER_MACRO',
	SET_SAVE_CUSTOMER_MACRO_IS_LOADING: 'ADMIN/SET_SAVE_CUSTOMER_MACRO_IS_LOADING',
	UPDATE_CUSTOMER_MACRO: 'ADMIN/UPDATE_CUSTOMER_MACRO',
	DELETE_CUSTOMER_MACRO: 'ADMIN/DELETE_CUSTOMER_MACRO',
	IMPORT_CUSTOMER_MACRO: 'ADMIN/IMPORT_CUSTOMER_MACRO',

	GET_PROVIDERS: 'ADMIN/GET_PROVIDERS',
	SET_PROVIDERS_IS_LOADING: 'ADMIN/SET_PROVIDERS_IS_LOADING',
	SET_PROVIDERS: 'ADMIN/SET_PROVIDERS',

	GET_PROVIDER_WORD_REPLACEMENTS: 'ADMIN/GET_PROVIDER_WORD_REPLACEMENTS',
	SET_PROVIDER_WORD_REPLACEMENTS_IS_LOADING: 'ADMIN/SET_PROVIDER_WORD_REPLACEMENTS_IS_LOADING',
	SET_PROVIDER_WORD_REPLACEMENTS: 'ADMIN/SET_PROVIDER_WORD_REPLACEMENTS',
	SAVE_PROVIDER_WORD_REPLACEMENT: 'ADMIN/SAVE_PROVIDER_WORD_REPLACEMENT',
	UPDATE_PROVIDER_WORD_REPLACEMENT: 'ADMIN/UPDATE_PROVIDER_WORD_REPLACEMENT',
	SET_SAVE_PROVIDER_WORD_REPLACEMENT_IS_LOADING: 'ADMIN/SET_SAVE_PROVIDER_WORD_REPLACEMENT_IS_LOADING',
	DELETE_PROVIDER_WORD_REPLACEMENT: 'ADMIN/DELETE_PROVIDER_WORD_REPLACEMENT',

	GET_CUSTOMER_WORD_REPLACEMENTS: 'ADMIN/GET_CUSTOMER_WORD_REPLACEMENTS',
	SET_CUSTOMER_WORD_REPLACEMENTS_IS_LOADING: 'ADMIN/SET_CUSTOMER_WORD_REPLACEMENTS_IS_LOADING',
	SET_CUSTOMER_WORD_REPLACEMENTS: 'ADMIN/SET_CUSTOMER_WORD_REPLACEMENTS',
	SAVE_CUSTOMER_WORD_REPLACEMENT: 'ADMIN/SAVE_CUSTOMER_WORD_REPLACEMENT',
	UPDATE_CUSTOMER_WORD_REPLACEMENT: 'ADMIN/UPDATE_CUSTOMER_WORD_REPLACEMENT',
	SET_SAVE_CUSTOMER_WORD_REPLACEMENT_IS_LOADING: 'ADMIN/SET_SAVE_CUSTOMER_WORD_REPLACEMENT_IS_LOADING',
	DELETE_CUSTOMER_WORD_REPLACEMENT: 'ADMIN/DELETE_CUSTOMER_WORD_REPLACEMENT',
};
