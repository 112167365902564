import { createAction } from '@reduxjs/toolkit';
import { worklistConstants } from './worklist.constants';
import { IProvider } from '../../models/providers/providers.models';
import { IUserSettings } from '../../models/users/user.models';
import {
	ICustomPatientDemographicsData,
	IPatientDemographic,
	IPatientEncounter,
	IPatientsAdvancedSearchFilters,
	IPatientsChartSearch,
	IPatientsSearchResult,
} from '../../models/patients/patients.models';
import {
	IBillingCode,
	IBillingModifier,
	IDepartmentDictation,
	IDiagnoseField,
	IDiagnoseFields,
	IDictation,
	IOpenAISummary,
	IOrderField,
	IOrderSet,
	IOrdersSearchQuery,
	IProcedureCode,
	IProcedureDocumentationField,
	IProcedureDocumentationTemplate,
	IQANote,
	ITimestampedTranscription,
} from '../../models/dictations/dictations.models';
import { CodedErrorType, IDocumentDetails, IPreviewDocument } from '../../models/dictations/document.models';
import { IMacro } from '../../models/macros/macros.models';
import { AIModelType } from '../../api/dictations/dictations.api.models';

// General

const setSettingsAction = createAction<{ departmentId: string }>(worklistConstants.SET_SETTINGS);

const initWorkListAction = createAction<{ userSettings: IUserSettings }>(worklistConstants.INIT_WORKLIST);

export interface ISetClinicActionPayload {
	customerId: string;
}
const setClinic = createAction<ISetClinicActionPayload>(worklistConstants.SET_CLINIC);

const initDictations = createAction<{ userSettings: IUserSettings }>(worklistConstants.INIT_DICTATIONS);

// Providers

const getProvidersAction = createAction(worklistConstants.GET_PROVIDERS);
const setProvidersIsLoadingAction = createAction<boolean>(worklistConstants.SET_PROVIDERS_IS_LOADING);

interface ISetProvidersActionPayload {
	providers: IProvider[];
}

const setProvidersAction = createAction<ISetProvidersActionPayload>(worklistConstants.SET_PROVIDERS);

export interface ISetSelectedProvidersActionPayload {
	username: string;
	customerId: string;
	providers: IProvider[];
	cache?: boolean;
}

const setSelectedProvidersAction = createAction<ISetSelectedProvidersActionPayload>(
	worklistConstants.SET_SELECTED_PROVIDERS
);

// Procedure Documentation

const getProcedureDocumentationTemplatesAction = createAction<{ documentId: string }>(
	worklistConstants.GET_PROCEDURE_DOCUMENTATION_TEMPLATES
);
const setProcedureDocumentationTemplatesIsLoadingAction = createAction<boolean>(
	worklistConstants.SET_PROCEDURE_DOCUMENTATION_TEMPLATES_IS_LOADING
);
const setProcedureDocumentationTemplatesAction = createAction<IProcedureDocumentationTemplate[]>(
	worklistConstants.SET_PROCEDURE_DOCUMENTATION_TEMPLATES
);

export interface ISaveProcedureDocumentationActionPayload {
	documentId: string;
	patientId: string;
	procedureDocumentation: IProcedureDocumentationField[];
}
const saveProcedureDocumentationAction = createAction<ISaveProcedureDocumentationActionPayload>(
	worklistConstants.SAVE_PROCEDURE_DOCUMENTATION
);

const setSaveProcedureDocumentationIsLoadingAction = createAction<boolean>(
	worklistConstants.SET_SAVE_PROCEDURE_DOCUMENTATION_IS_LOADING
);

export interface IApplyProcedureDocumentationTemplateActionPayload {
	documentId: string;
	patientId: string;
	template: IProcedureDocumentationTemplate;
}

const applyProcedureDocumentationTemplateAction = createAction<IApplyProcedureDocumentationTemplateActionPayload>(
	worklistConstants.APPLY_PROCEDURE_DOCUMENTATION_TEMPLATE
);
const setApplyProcedureDocumentationTemplateIsLoadingAction = createAction<boolean>(
	worklistConstants.SET_APPLY_PROCEDURE_DOCUMENTATION_TEMPLATE_IS_LOADING
);

// Dictations

const setUnlockDocumentIsLoading = createAction<boolean>(worklistConstants.SET_UNLOCK_DOCUMENT_IS_LOADING);

const setDictationsIsLoadingAction = createAction<boolean>(worklistConstants.SET_DICTATIONS_IS_LOADING);

const getDictationsAction = createAction<{ disableLoading?: boolean }>(worklistConstants.GET_DICTATIONS);

interface ISetDictationsActionPayload {
	dictations: IDictation[];
}

const setDictationsAction = createAction<ISetDictationsActionPayload>(worklistConstants.SET_DICTATIONS);

// Current dictation
export interface ISetCurrentDictationActionPayload {
	dictation: IDepartmentDictation;
	// encounter?: IPatientEncounter;
	resetEncounter?: boolean;
}

const setCurrentDictation = createAction<ISetCurrentDictationActionPayload>(worklistConstants.SET_CURRENT_DICTATION);

const closeDictation = createAction<{ blobID: string; unlock: boolean; async?: boolean }>(
	worklistConstants.CLOSE_DICTATION
);
const unlockAndOpenDictation = createAction<IDictation>(worklistConstants.UNLOCK_AND_OPEN_DICTATION);
const setEditedDocument = createAction<boolean>(worklistConstants.SET_EDITED_DOCUMENT);
const setReorderDiagnoses = createAction<boolean>(worklistConstants.SET_REORDER_DIAGNOSES);

const openPatientDocumentAction = createAction<IPatientsChartSearch>(worklistConstants.OPEN_DICTATION_DOCUMENT);

const setPatientDemographicIsLoadingAction = createAction<boolean>(
	worklistConstants.SET_PATIENT_DEMOGRAPHIC_IS_LOADING
);
const setPatientDemographic = createAction<IPatientDemographic>(worklistConstants.SET_PATIENT_DEMOGRAPHIC);

const setPatientEncountersIsLoadingAction = createAction<boolean>(worklistConstants.SET_PATIENT_ENCOUNTERS_IS_LOADING);
const setPatientEncountersAction = createAction<IPatientEncounter[]>(worklistConstants.SET_PATIENT_ENCOUNTERS);

export interface IGetPatientPreviewDocumentActionPayload {
	documentId: string;
	patientId: string;
	documentTypeName: string;
}
const getPatientPreviewDocument = createAction<IGetPatientPreviewDocumentActionPayload>(
	worklistConstants.GET_PATIENT_PREVIEW_DOCUMENT
);

const setPatientPreviewDocumentIsLoading = createAction<boolean>(
	worklistConstants.SET_PATIENT_PREVIEW_DOCUMENT_IS_LOADING
);

const setPatientPreviewDocument = createAction<IPreviewDocument>(worklistConstants.SET_PATIENT_PREVIEW_DOCUMENT);

export interface IGetDocumentDetailsActionPayload {
	blobId: string;
	documentId?: string;
	patientId?: string;
	documentTypeName: string;
	metadata: {
		providerId: string | null;
	};
	departmentId?: string;
	ignoreStagedEncounterData?: boolean;
}

const openDictationAction = createAction<IDictation>(worklistConstants.OPEN_DICTATION);
const getCurrentDocumentAction = createAction<IGetDocumentDetailsActionPayload>(worklistConstants.GET_DOCUMENT_DETAILS);
const setCurrentDocumentIsLoadingAction = createAction<boolean>(worklistConstants.SET_DOCUMENT_LOADING);
const setCurrentDocumentAction = createAction<IDocumentDetails>(worklistConstants.SET_DOCUMENT_DETAILS);

export interface IPullCurrentDocumentFiledActionPayload {
	documentId: string;
	patientId: string;
	fieldId: string;
}

const pullCurrentDocumentFiledAction = createAction<IPullCurrentDocumentFiledActionPayload>(
	worklistConstants.PULL_DOCUMENT_FILED
);

const pullCurrentDocumentFiledIsLoadingAction = createAction<boolean>(worklistConstants.PULL_DOCUMENT_FILED_IS_LOADING);

const setPullAssessmentPlanDiagnosesAction = createAction<IDiagnoseField[] | null>(
	worklistConstants.SET_PULL_ASSESSMENT_PLAN_DIAGNOSES
);

const mergePulledAssessmentPlanAction = createAction<IDiagnoseField[]>(worklistConstants.MERGE_PULLED_ASSESSMENT_PLAN);

export interface ISaveDocumentActionPayload {
	markTranscriptionComplete: boolean;
	sendToQA: boolean;
	forceSave: boolean;
	forceToQA: boolean;
	timedOutSave?: boolean;
}

const saveDocumentAction = createAction<ISaveDocumentActionPayload>(worklistConstants.SAVE_DOCUMENT_DETAILS);
const setSaveDocumentLoadingAction = createAction<boolean>(worklistConstants.SET_DOCUMENT_SAVE_LOADING);
const setSaveDocumentErrorsAction = createAction<{ errors?: CodedErrorType[] }>(
	worklistConstants.SET_DOCUMENT_SAVE_ERROR
);

export interface ISetDocumentFieldsAction {
	fields: IDiagnoseFields;
}

const setDocumentFieldsAction = createAction<ISetDocumentFieldsAction>(worklistConstants.SET_DOCUMENT_FIELDS);

const openPatientSearchAction = createAction<null | string>(worklistConstants.OPEN_PATIENT_SEARCH);
const worklistPatientSearchAction = createAction<IPatientsAdvancedSearchFilters>(
	worklistConstants.WORKLIST_PATIENT_SEARCH_REQUEST
);

const worklistPatientSearchIsLoadingAction = createAction<boolean>(
	worklistConstants.WORKLIST_PATIENT_SEARCH_IS_LOADING
);

interface ISetPatientSearchResultActionPayload {
	patients: IPatientsSearchResult[];
}

const setWorklistPatientSearchResultAction = createAction<ISetPatientSearchResultActionPayload>(
	worklistConstants.WORKLIST_PATIENT_SET_RESULTS
);

export interface ISetDictationPatientActionPayload {
	patientId: string;
	resetEncounter?: boolean;
}

const setDictationPatientAction = createAction<ISetDictationPatientActionPayload>(
	worklistConstants.SET_DICTATION_PATIENT
);

export interface ISetCurrentEncounterActionPayload {
	encounter: IPatientEncounter;
}

const setCurrentEncounterAction = createAction<ISetCurrentEncounterActionPayload>(
	worklistConstants.SET_CURRENT_ENCOUNTER
);

export interface ISetDictationEncounterActionPayload {
	blobId: string;
	encounter: IPatientEncounter;
	updateEncounter?: boolean;
	ignoreStagedEncounterData?: boolean;
}

const updateDictationEncounterAction = createAction<ISetDictationEncounterActionPayload>(
	worklistConstants.UPDATE_DICTATION_ENCOUNTER
);

const setDictationEncounterIsLoadingAction = createAction<boolean>(
	worklistConstants.SET_DICTATION_ENCOUNTER_IS_LOADING
);

// Diagnosis

const searchDiagnoses = createAction<string>(worklistConstants.SEARCH_DIAGNOSES);
const setDiagnosesSearchResult = createAction<IDiagnoseField[]>(worklistConstants.SET_SEARCH_DIAGNOSES_RESULT);
const setDiagnosesSearchIsLoading = createAction<boolean>(worklistConstants.SET_SEARCH_DIAGNOSES_IS_LOADING);

// Orders

const searchOrders = createAction<IOrdersSearchQuery>(worklistConstants.SEARCH_ORDERS);
const setOrdersSearchResult = createAction<IOrderField[]>(worklistConstants.SET_SEARCH_ORDERS_RESULT);
const setOrdersSearchIsLoading = createAction<boolean>(worklistConstants.SET_SEARCH_ORDERS_IS_LOADING);
const setUnassignedOrders = createAction<IOrderField[]>(worklistConstants.SET_UNASSIGNED_ORDERS);

// Order Sets
const getOrderSets = createAction(worklistConstants.GET_ORDER_SETS);
const setOrderSetsResult = createAction<IOrderSet[]>(worklistConstants.SET_ORDER_SETS_RESULT);
const setOrderSetsIsLoading = createAction<boolean>(worklistConstants.SET_ORDER_SETS_IS_LOADING);

const applyOrderSet = createAction<{ orderSetId: number; diagnoseHash?: string }>(worklistConstants.APPLY_ORDER_SET);
const setApplyOrderSetIsLoading = createAction<boolean>(worklistConstants.SET_APPLY_ORDER_SET_IS_LOADING);

// Billing Service

export interface ISetEmCodeActionPayload {
	procedureCodeSearchResult: IProcedureCode[];
}

const searchEMCode = createAction<string>(worklistConstants.SEARCH_PROCEDURE_CODE);
const setProcedureCodeSearchResult = createAction<ISetEmCodeActionPayload>(
	worklistConstants.SET_SEARCH_PROCEDURE_CODE_RESULT
);
const setProcedureCodeSearchIsLoading = createAction<boolean>(worklistConstants.SET_SEARCH_PROCEDURE_CODE_IS_LOADING);

const setBillingCodes = createAction<{ claimCreated: boolean; billingCodes: IBillingCode[] }>(
	worklistConstants.SET_BILLING_CODES
);

const getBillingModifiers = createAction(worklistConstants.GET_BILLING_MODIFIERS);
const setBillingModifiersSearchResult = createAction<IBillingModifier[]>(
	worklistConstants.SET_BILLING_MODIFIERS_SEARCH_RESULT
);
const setBillingModifiersIsLoading = createAction<boolean>(worklistConstants.SET_BILLING_MODIFIERS_IS_LOADING);

// QA Notes

const setQANotes = createAction<IQANote[]>(worklistConstants.SET_QA_NOTES);

// Transcript
interface ISetTranscriptActionPayload {
	azureASR: { text: string | null; timestampedText: ITimestampedTranscription[] | null };
	mModalASR: string | null;
	nuanceASR: string | null;
	openAISummary: string | null;
	openAISummaryStructured: IOpenAISummary | null;
	prompt: string | null;
	instructions: string | null;
	customOpenAISummary: string | null;
	customOpenAISummaryStructured: IOpenAISummary | null;
}
const setTranscript = createAction<ISetTranscriptActionPayload>(worklistConstants.SET_TRANSCRIPT);

// Provider Note
const setProviderNote = createAction<string>(worklistConstants.SET_PROVIDER_NOTE);

// Custom patient demographic
const setCustomPatientDemographics = createAction<ICustomPatientDemographicsData | null>(
	worklistConstants.SET_CUSTOM_PATIENT_DEMOGRAPHICS
);

export interface IUpdateCustomPatientDemographicsActionPayload {
	blobId: string;
	data: ICustomPatientDemographicsData;
}

const updateCustomPatientDemographics = createAction<IUpdateCustomPatientDemographicsActionPayload>(
	worklistConstants.UPDATE_CUSTOM_PATIENT_DEMOGRAPHICS
);

// AI Prompt
const setAIPrompt = createAction<string>(worklistConstants.SET_AI_PROMPT);
const setAIInstructions = createAction<string>(worklistConstants.SET_AI_INSTRUCTIONS);
const setLastTimePromptSubmitted = createAction<number | null>(worklistConstants.SET_LAST_TIME_PROMPT_SUBMITTED);

export interface IRequestAISummaryForPromptActionPayload {
	blobId: string;
	aiModel: AIModelType | null;
	prompt: { prompt: string; instructions: string };
}
const requestAISummaryForPrompt = createAction<IRequestAISummaryForPromptActionPayload>(
	worklistConstants.REQUEST_AI_SUMMARY_FOR_PROMPT
);

export interface IUpdatePromptAISummaryActionPayload {
	blobId: string;
}
const updatePromptAISummary = createAction<IUpdatePromptAISummaryActionPayload>(
	worklistConstants.UPDATE_PROMPT_AI_SUMMARY
);

export interface ISetPromptAISummaryActionPayload {
	openAISummary: string | null;
	openAISummaryStructured: IOpenAISummary | null;
}
const setPromptAISummary = createAction<ISetPromptAISummaryActionPayload>(worklistConstants.SET_PROMPT_AI_SUMMARY);

// Provider Dot Phrases

export interface IGetDocumentProviderDotPhrasesActionPayload {
	providerId: string;
}
const getDocumentProviderDotphrasesAction = createAction<IGetDocumentProviderDotPhrasesActionPayload>(
	worklistConstants.GET_DOCUMENT_PROVIDER_DOTPHRASES
);

export interface ISetDocumentProviderDotphrasesActionPayload extends IMacro {}
const setDocumentProviderDotphrasesAction = createAction<ISetDocumentProviderDotphrasesActionPayload[]>(
	worklistConstants.SET_DOCUMENT_PROVIDER_DOTPHRASES
);

export const worklistActions = {
	initWorkListAction,

	setClinic,
	initDictations,

	setSettingsAction,

	getProvidersAction,
	setProvidersIsLoadingAction,
	setProvidersAction,
	setSelectedProvidersAction,

	getProcedureDocumentationTemplatesAction,
	setProcedureDocumentationTemplatesIsLoadingAction,
	setProcedureDocumentationTemplatesAction,
	applyProcedureDocumentationTemplateAction,
	setApplyProcedureDocumentationTemplateIsLoadingAction,
	saveProcedureDocumentationAction,
	setSaveProcedureDocumentationIsLoadingAction,

	setUnlockDocumentIsLoading,
	setDictationsIsLoadingAction,
	getDictationsAction,
	setDictationsAction,

	setCurrentDictation,

	closeDictation,
	unlockAndOpenDictation,
	setEditedDocument,
	setReorderDiagnoses,

	openPatientDocumentAction,

	setPatientDemographicIsLoadingAction,
	setPatientDemographic,

	setPatientEncountersIsLoadingAction,
	setPatientEncountersAction,

	getPatientPreviewDocument,
	setPatientPreviewDocumentIsLoading,
	setPatientPreviewDocument,

	openDictationAction,
	getCurrentDocumentAction,
	setCurrentDocumentIsLoadingAction,
	setCurrentDocumentAction,

	pullCurrentDocumentFiledIsLoadingAction,
	pullCurrentDocumentFiledAction,

	setPullAssessmentPlanDiagnosesAction,
	mergePulledAssessmentPlanAction,

	setSaveDocumentLoadingAction,
	setSaveDocumentErrorsAction,
	setDocumentFieldsAction,
	saveDocumentAction,

	openPatientSearchAction,
	worklistPatientSearchAction,
	worklistPatientSearchIsLoadingAction,
	setWorklistPatientSearchResultAction,
	setDictationPatientAction,

	setCurrentEncounterAction,
	updateDictationEncounterAction,
	setDictationEncounterIsLoadingAction,

	searchDiagnoses,
	setDiagnosesSearchResult,
	setDiagnosesSearchIsLoading,

	searchOrders,
	setOrdersSearchResult,
	setOrdersSearchIsLoading,
	setUnassignedOrders,

	getOrderSets,
	setOrderSetsResult,
	setOrderSetsIsLoading,
	applyOrderSet,
	setApplyOrderSetIsLoading,

	searchEMCode,
	setProcedureCodeSearchResult,
	setProcedureCodeSearchIsLoading,

	setBillingCodes,
	getBillingModifiers,
	setBillingModifiersSearchResult,
	setBillingModifiersIsLoading,

	setQANotes,
	setTranscript,
	setProviderNote,
	setCustomPatientDemographics,
	updateCustomPatientDemographics,

	setAIPrompt,
	setAIInstructions,
	setLastTimePromptSubmitted,
	requestAISummaryForPrompt,
	updatePromptAISummary,
	setPromptAISummary,

	getDocumentProviderDotphrasesAction,
	setDocumentProviderDotphrasesAction,
};
