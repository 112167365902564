import { call, put } from 'redux-saga/effects';
import { actions, IAction } from '../actions';
import { handleGlobalException } from '../../../application/exception-handlers';
import { IInitUserServicesActionPayload } from './app.actions';
import { getUserSettingsCache } from '../../../system/local-storage';
import { getAIModels } from '../../api/system/system.api';
import { IServiceResult } from '../../models/service.models';
import { IAIModel } from '../../api/system/system.api.models';

export function* initAppSaga() {
	const userSettings = getUserSettingsCache();

	if (userSettings) {
		yield put(actions.app.initUserServicesAction({ userSettings }));
	}
}

export function* initUserServicesSaga(action: IAction<IInitUserServicesActionPayload>) {
	try {
		const { userSettings } = action.payload;
		yield put(actions.app.getAiModels());
		yield put(actions.user.initUserAction({ userSettings }));
		yield put(actions.worklist.initWorkListAction({ userSettings }));
	} catch (e) {
		handleGlobalException(e);
	}
}

export function* getAiModelsSaga() {
	try {
		const aiModels: IServiceResult<IAIModel[]> = yield call(getAIModels);
		yield put(actions.app.setAiModels(aiModels.data || []));
	} catch (e) {
		handleGlobalException(e);
	}
}
