import { createReducer } from '@reduxjs/toolkit';
import { worklistActions } from './worklist.actions';
import { IProvider } from '../../models/providers/providers.models';
import {
	IBillingCode,
	IBillingModifier,
	IDepartmentDictation,
	IDiagnoseField,
	IDictation,
	IOpenAISummary,
	IOrderField,
	IOrderSet,
	IProcedureCode,
	IProcedureDocumentationTemplate,
	IQANote,
	ITimestampedTranscription,
} from '../../models/dictations/dictations.models';
import {
	ICustomPatientDemographicsData,
	IPatientDemographic,
	IPatientEncounter,
	IPatientsAdvancedSearchFilters,
	IPatientsSearchResult,
} from '../../models/patients/patients.models';
import { IOrderResponse } from '../../api/dictations/dictations.api.models';
import { mapProcedureDocumentationFieldsToResponse } from '../../services/documents/documents.services';
import { CodedErrorType, IDocumentDetails, IPreviewDocument } from '../../models/dictations/document.models';
import { IMacro } from '../../models/macros/macros.models';

export interface IWorklistState {
	allProviders: IProvider[];
	selectedProviders: IProvider[];
	providersIsLoading: boolean;
	departmentId: string;
	isDictationsLoading: boolean;
	dictations: IDictation[];
	unlockDocumentIsLoading: boolean;
	currentDocument: {
		dictation?: IDepartmentDictation;

		document?: IDocumentDetails;
		isDocumentLoading: boolean;
		isDocumentSaveLoading: boolean;

		saveErrors?: CodedErrorType[];

		edited: boolean;
		reorderDiagnoses: boolean;

		chart: {
			patientDemographicIsLoading: boolean;
			patientDemographic?: IPatientDemographic;

			patientEncountersIsLoading: boolean;
			patientEncounters?: IPatientEncounter[];
			patientEncountersLoaded: boolean;
		};
		pullIsLoading: boolean;

		pullAssessmentPlanDiagnoses: IDiagnoseField[] | null;

		searchIsLoading: boolean;
		patients: IPatientsSearchResult[];
		advancedSearchFilters: IPatientsAdvancedSearchFilters;

		encounter?: IPatientEncounter;
		encounterIsLoading: boolean;

		previewDocument?: IPreviewDocument;
		previewDocumentIsLoading: boolean;

		unassignedOrders: IOrderField[];

		procedureDocumentationTemplatesIsLoading: boolean;
		procedureDocumentationTemplates: IProcedureDocumentationTemplate[];
		applyProcedureDocumentationTemplateIsLoading: boolean;
		saveProcedureDocumentationIsLoading: boolean;

		billingLoaded: boolean;
		billingClaimCreated: boolean;
		billingCodes: IBillingCode[];

		qaNotes: IQANote[];
		transcription: {
			azureASR: {
				text: string | null;
				timestampedText: ITimestampedTranscription[] | null;
			};
			mModalASR: string | null;
			nuanceASR: string | null;
			openAISummary: string | null;
			openAISummaryStructured: IOpenAISummary | null;
			instructions: string | null;
			prompt: string | null;
			customOpenAISummary: string | null;
			customOpenAISummaryStructured: IOpenAISummary | null;
			lastTimePromptSubmitted: number | null;
		};
		providerNote: string;
		customPatientDemographics: ICustomPatientDemographicsData | null;

		providerDotPhrases: IMacro[];
	};
	diagnoseSearchIsLoading: boolean;
	diagnosesSearchResult: IDiagnoseField[];
	ordersSearchIsLoading: boolean;
	ordersSearchResult: IOrderField[];
	ordersSetsIsLoading: boolean;
	applyOrderSetIsLoading: boolean;
	ordersSetsResult: IOrderSet[];
	emCodeSearchIsLoading: boolean;
	billingModifiersSearchResult: IBillingModifier[];
	billingModifiersIsLoading: boolean;
	procedureCodeSearchResult: IProcedureCode[];
}

const initCurrentDocument = {
	dictation: undefined,

	document: undefined,
	isDocumentLoading: false,
	isDocumentSaveLoading: false,

	saveErrors: [],

	edited: false,
	reorderDiagnoses: false,

	chart: {
		patientDemographicIsLoading: false,
		patientDemographic: undefined,
		patientEncountersIsLoading: false,
		encounters: undefined,
		patientEncountersLoaded: false,
	},

	pullIsLoading: false,
	pullAssessmentPlanDiagnoses: null,

	searchIsLoading: false,
	patients: [],
	advancedSearchFilters: {},

	encounter: undefined,
	encounterIsLoading: false,

	previewDocument: undefined,
	previewDocumentIsLoading: false,

	unassignedOrders: [],

	procedureDocumentationTemplatesIsLoading: false,
	applyProcedureDocumentationTemplateIsLoading: false,
	saveProcedureDocumentationIsLoading: false,
	procedureDocumentationTemplates: [],

	billingLoaded: false,
	billingClaimCreated: false,
	billingCodes: [],

	qaNotes: [],
	transcription: {
		azureASR: { text: null, timestampedText: null },
		mModalASR: null,
		nuanceASR: null,
		openAISummary: null,
		openAISummaryStructured: null,
		instructions: null,
		prompt: null,
		customOpenAISummary: null,
		customOpenAISummaryStructured: null,
		lastTimePromptSubmitted: null,
	},
	providerNote: '',
	customPatientDemographics: null,

	providerDotPhrases: [],
};

const initialState: IWorklistState = {
	providersIsLoading: false,
	allProviders: [],
	selectedProviders: [],
	departmentId: '',
	isDictationsLoading: false,
	unlockDocumentIsLoading: false,
	dictations: [],
	currentDocument: initCurrentDocument,
	diagnoseSearchIsLoading: false,
	diagnosesSearchResult: [],
	ordersSearchIsLoading: false,
	ordersSearchResult: [],
	ordersSetsIsLoading: false,
	applyOrderSetIsLoading: false,
	ordersSetsResult: [],
	emCodeSearchIsLoading: false,
	billingModifiersSearchResult: [],
	billingModifiersIsLoading: false,
	procedureCodeSearchResult: [],
};

const worklistReducer = createReducer<IWorklistState>(initialState, (builder) => {
	builder
		.addCase(worklistActions.setProvidersAction, (state, action) => {
			state.allProviders = action.payload.providers;
		})
		.addCase(worklistActions.setSelectedProvidersAction, (state, action) => {
			state.selectedProviders = action.payload.providers;
		})
		.addCase(worklistActions.setProvidersIsLoadingAction, (state, action) => {
			state.providersIsLoading = action.payload;
		})

		.addCase(worklistActions.setProcedureDocumentationTemplatesAction, (state, action) => {
			state.currentDocument.procedureDocumentationTemplates = action.payload;
		})

		.addCase(worklistActions.setProcedureDocumentationTemplatesIsLoadingAction, (state, action) => {
			state.currentDocument.procedureDocumentationTemplatesIsLoading = action.payload;
		})

		.addCase(worklistActions.setApplyProcedureDocumentationTemplateIsLoadingAction, (state, action) => {
			state.currentDocument.applyProcedureDocumentationTemplateIsLoading = action.payload;
		})

		.addCase(worklistActions.setSaveProcedureDocumentationIsLoadingAction, (state, action) => {
			state.currentDocument.saveProcedureDocumentationIsLoading = action.payload;
		})

		.addCase(worklistActions.setSettingsAction, (state, action) => {
			state.departmentId = action.payload.departmentId;
		})

		.addCase(worklistActions.setUnlockDocumentIsLoading, (state, action) => {
			state.unlockDocumentIsLoading = action.payload;
		})

		.addCase(worklistActions.setDictationsIsLoadingAction, (state, action) => {
			state.isDictationsLoading = action.payload;
		})

		.addCase(worklistActions.setDictationsAction, (state, action) => {
			state.dictations = action.payload.dictations;
		})

		.addCase(worklistActions.setCurrentDictation, (state, action) => {
			if (action.payload.resetEncounter) {
				state.currentDocument = {
					...initCurrentDocument,
					dictation: action.payload.dictation,
				};
			} else {
				state.currentDocument = {
					...initCurrentDocument,
					dictation: action.payload.dictation,
					encounter: state.currentDocument.encounter,
					chart: state.currentDocument.encounter ? state.currentDocument.chart : initCurrentDocument.chart,
					providerDotPhrases: state.currentDocument.providerDotPhrases,
					transcription: state.currentDocument.transcription,
				};
			}
		})

		.addCase(worklistActions.setPatientDemographicIsLoadingAction, (state, action) => {
			state.currentDocument.chart.patientDemographicIsLoading = action.payload;
		})
		.addCase(worklistActions.setPatientDemographic, (state, action) => {
			state.currentDocument.chart.patientDemographic = action.payload;
		})

		.addCase(worklistActions.setPatientEncountersIsLoadingAction, (state, action) => {
			state.currentDocument.chart.patientEncountersIsLoading = action.payload;
		})
		.addCase(worklistActions.setPatientEncountersAction, (state, action) => {
			state.currentDocument.chart.patientEncounters = action.payload;
			state.currentDocument.chart.patientEncountersLoaded = true;
		})

		.addCase(worklistActions.setEditedDocument, (state, action) => {
			state.currentDocument.edited = action.payload;
		})

		.addCase(worklistActions.setReorderDiagnoses, (state, action) => {
			state.currentDocument.reorderDiagnoses = action.payload;
		})

		.addCase(worklistActions.setDocumentFieldsAction, (state, action) => {
			if (state.currentDocument.document) {
				const updateFields = action.payload.fields;
				state.currentDocument.edited = true;
				state.currentDocument.document = {
					...state.currentDocument.document,
					hpiSketchPad: updateFields.hpiSketchPad,
					rosSketchPad: updateFields.rosSketchPad,
					peSketchPad: updateFields.peSketchPad,
					PatientGoals: updateFields.patientGoals,
					PatientInstructions: updateFields.patientInstructions,
					DiscussionNotes: updateFields.discussionNotes,
					AssessmentPlanSketchPad: updateFields.assessmentPlan,
					assSketchPad: updateFields.assessment,
					planSketchPad: updateFields.plan,
					Diagnoses: updateFields.diagnoses.map((diagnose) => ({
						DiagnosisID: diagnose.diagnosisId,
						Ranking: diagnose.ranking,
						Selected: diagnose.selected,
						Caption: diagnose.caption,
						ICDCodes: diagnose.icdCodes.map((y) => ({
							Code: y.code,
							Codeset: y.codeset,
							Description: y.description,
						})),
						LexicalID: diagnose.lexicalId,
						Note: diagnose.note,
						Laterality: diagnose.laterality,
						SNOMEDCode: diagnose.SNOMEDCode,
						SNOMEDName: diagnose.SNOMEDName,
						Modified: diagnose.modified,
						Problem: diagnose.problem,
						ProblemStatus: diagnose.problemStatus,
						AddToProblemList: diagnose.addToProblemList,
						CanPostCoordinate: diagnose.canPostCoordinate,
						Orders: diagnose.orders.map((order) => {
							const orderField: Omit<
								IOrderResponse,
								| 'LastModifiedBy'
								| 'LastModifiedDate'
								| 'AssignedTo'
								| 'Status'
								| 'CreatedBy'
								| 'OrderedBy'
								| 'OrderedDate'
								| 'Class'
							> = {
								OrderID: order.orderId as string,
								Selected: order.selected,
								OrderItemID: order.orderItemId as string,
								OrderTypeID: order.orderTypeId,
								OrderTypeGroup: order.orderTypeGroup,
								Description: order.description,
								Interpretation: order.interpretation
									? {
											Note: order.interpretation.note || null,
											OrderID: order.interpretation.orderId,
											InterpretationID: order.interpretation.interpretationId,
											DiscussedWithPatient: order.interpretation.discussedWithPatient,
									  }
									: undefined,
								Modified: order.modified,
								Procedure: {
									Selected: order.procedure.selected,
									ProcedureLexicalCode: order.procedure.procedureLexicalCode,
									ProcedureDescription: order.procedure.procedureDescription,
								},
							};

							return orderField as IOrderResponse;
						}),
					})),
					ProcedureDocumentations: mapProcedureDocumentationFieldsToResponse(updateFields.procedureDocumentation),
				};
			}
		})

		.addCase(worklistActions.pullCurrentDocumentFiledIsLoadingAction, (state, action) => {
			state.currentDocument.pullIsLoading = action.payload;
		})

		.addCase(worklistActions.setPullAssessmentPlanDiagnosesAction, (state, action) => {
			state.currentDocument.pullAssessmentPlanDiagnoses = action.payload;
		})
		.addCase(worklistActions.setCurrentDocumentAction, (state, action) => {
			state.currentDocument.document = action.payload;
		})

		.addCase(worklistActions.setCurrentDocumentIsLoadingAction, (state, action) => {
			state.currentDocument.isDocumentLoading = action.payload;
		})
		.addCase(worklistActions.setSaveDocumentLoadingAction, (state, action) => {
			state.currentDocument.isDocumentSaveLoading = action.payload;
		})
		.addCase(worklistActions.setSaveDocumentErrorsAction, (state, action) => {
			state.currentDocument.saveErrors = action.payload.errors;
		})

		.addCase(worklistActions.setDictationPatientAction, (state, action) => {
			if (state.currentDocument.dictation) {
				state.currentDocument = {
					...initCurrentDocument,
					dictation: { ...state.currentDocument.dictation, patientID: action.payload.patientId },
					providerDotPhrases: state.currentDocument.providerDotPhrases,
					transcription: state.currentDocument.transcription,
				};
			}
			if (action.payload.resetEncounter && state.currentDocument.dictation) {
				state.currentDocument.dictation.documentID = null;
			}
		})

		.addCase(worklistActions.worklistPatientSearchIsLoadingAction, (state, action) => {
			state.currentDocument.searchIsLoading = action.payload;
		})
		.addCase(worklistActions.worklistPatientSearchAction, (state, action) => {
			state.currentDocument.advancedSearchFilters = action.payload;
		})
		.addCase(worklistActions.setWorklistPatientSearchResultAction, (state, action) => {
			state.currentDocument.patients = action.payload.patients;
		})

		.addCase(worklistActions.setCurrentEncounterAction, (state, action) => {
			state.currentDocument.encounter = action.payload.encounter;
		})
		.addCase(worklistActions.setDictationEncounterIsLoadingAction, (state, action) => {
			state.currentDocument.encounterIsLoading = action.payload;
		})

		.addCase(worklistActions.setPatientPreviewDocument, (state, action) => {
			state.currentDocument.previewDocument = action.payload;
		})
		.addCase(worklistActions.setPatientPreviewDocumentIsLoading, (state, action) => {
			state.currentDocument.previewDocumentIsLoading = action.payload;
		})

		.addCase(worklistActions.setDiagnosesSearchResult, (state, action) => {
			state.diagnosesSearchResult = action.payload;
		})
		.addCase(worklistActions.setDiagnosesSearchIsLoading, (state, action) => {
			state.diagnoseSearchIsLoading = action.payload;
		})

		.addCase(worklistActions.setOrdersSearchResult, (state, action) => {
			state.ordersSearchResult = action.payload;
		})
		.addCase(worklistActions.setOrdersSearchIsLoading, (state, action) => {
			state.ordersSearchIsLoading = action.payload;
		})
		.addCase(worklistActions.setUnassignedOrders, (state, action) => {
			state.currentDocument.unassignedOrders = action.payload;
		})

		.addCase(worklistActions.setOrderSetsResult, (state, action) => {
			state.ordersSetsResult = action.payload;
		})
		.addCase(worklistActions.setOrderSetsIsLoading, (state, action) => {
			state.ordersSetsIsLoading = action.payload;
		})
		.addCase(worklistActions.setApplyOrderSetIsLoading, (state, action) => {
			state.applyOrderSetIsLoading = action.payload;
		})

		.addCase(worklistActions.setProcedureCodeSearchResult, (state, action) => {
			state.procedureCodeSearchResult = action.payload.procedureCodeSearchResult;
		})
		.addCase(worklistActions.setProcedureCodeSearchIsLoading, (state, action) => {
			state.emCodeSearchIsLoading = action.payload;
		})

		.addCase(worklistActions.setBillingCodes, (state, action) => {
			state.currentDocument.billingLoaded = true;
			state.currentDocument.billingCodes = action.payload.billingCodes;
			state.currentDocument.billingClaimCreated = action.payload.claimCreated;
		})

		.addCase(worklistActions.setBillingModifiersSearchResult, (state, action) => {
			state.billingModifiersSearchResult = action.payload;
		})
		.addCase(worklistActions.setBillingModifiersIsLoading, (state, action) => {
			state.billingModifiersIsLoading = action.payload;
		})

		.addCase(worklistActions.setQANotes, (state, action) => {
			state.currentDocument.qaNotes = action.payload;
		})
		.addCase(worklistActions.setTranscript, (state, action) => {
			state.currentDocument.transcription = { ...action.payload, lastTimePromptSubmitted: null };
		})

		.addCase(worklistActions.setProviderNote, (state, action) => {
			state.currentDocument.providerNote = action.payload;
		})

		.addCase(worklistActions.setCustomPatientDemographics, (state, action) => {
			state.currentDocument.customPatientDemographics = action.payload;
		})

		.addCase(worklistActions.setAIPrompt, (state, action) => {
			state.currentDocument.transcription.prompt = action.payload;
		})

		.addCase(worklistActions.setAIInstructions, (state, action) => {
			state.currentDocument.transcription.instructions = action.payload;
		})

		.addCase(worklistActions.setLastTimePromptSubmitted, (state, action) => {
			state.currentDocument.transcription.lastTimePromptSubmitted = action.payload;
		})

		.addCase(worklistActions.setPromptAISummary, (state, action) => {
			state.currentDocument.transcription.customOpenAISummary = action.payload.openAISummary;
			state.currentDocument.transcription.customOpenAISummaryStructured = action.payload.openAISummaryStructured;
		})

		.addCase(worklistActions.setDocumentProviderDotphrasesAction, (state, action) => {
			state.currentDocument.providerDotPhrases = action.payload;
		});
});

export default worklistReducer;
