import * as React from 'react';
import {
	createRef,
	ForwardedRef,
	forwardRef,
	RefObject,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from 'react';
import { Box, Button, Checkbox, Grid, Input, SxProps, Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import { namespaces } from '../../application/i18n.constants';
import { IQANote } from '../../core/models/dictations/dictations.models';

interface INote {
	focusInput: () => void;
}

interface INoteProps {
	noteData: IQANote;
	isDone: boolean;
	canEditText: boolean;
	canEditIsDone: boolean;
	onNoteChange: (note: IQANote) => void;
	onNoteFocus: (change: boolean) => void;
	onNoteClick: (timestamp: number) => void;
	onSubmit: (note: IQANote) => void;
}

const NoteItem: React.ForwardRefRenderFunction<INote, INoteProps> = function NoteItemFunc(
	{ noteData, isDone, canEditText, canEditIsDone, onNoteClick, onNoteChange, onNoteFocus, onSubmit }: INoteProps,
	forwardedRef: ForwardedRef<INote>
) {
	const inputRef = useRef<HTMLInputElement>(null);

	const { audioTimeMarker, note, createdDateTime, username } = noteData;
	const [noteText, setNoteText] = useState(note);
	const [noteFocus, setNoteFocus] = useState(false);
	const timestamp = Number(audioTimeMarker);
	const timestampToSeconds = (seconds: number) => new Date(seconds * 1000).toISOString().substring(14, 19);

	const handleNoteFocusChanged = (focused: boolean) => {
		onNoteFocus(focused);
		setNoteFocus(focused);
	};

	useImperativeHandle(forwardedRef, () => ({
		focusInput() {
			const input = inputRef?.current;
			if (input) {
				handleNoteFocusChanged(true);
				input.focus();
			}
		},
	}));

	const handleNoteChange = () => {
		handleNoteFocusChanged(false);
		onNoteChange({
			...noteData,
			note: noteText,
		});
	};

	const handleNoteClick = () => {
		onNoteClick(timestamp);
	};

	const handleNoteStatusChanged = (checked: boolean) => {
		onNoteChange({
			...noteData,
			isDone: checked,
		});
	};

	const handleNoteKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			handleNoteChange();
			onSubmit(noteData);
		}
	};

	return (
		<Grid item>
			<Box sx={{ pb: 1, display: 'flex' }}>
				<Box sx={{ pr: '1rem', display: 'flex', alignItems: 'center' }}>
					<Button sx={{ fontWeight: 'normal' }} onClick={handleNoteClick}>
						{timestampToSeconds(timestamp)}
					</Button>
				</Box>
				<Input
					ref={inputRef}
					readOnly={!canEditText}
					multiline={noteFocus}
					autoFocus={noteFocus}
					fullWidth
					value={noteText}
					onBlur={handleNoteChange}
					onFocus={() => handleNoteFocusChanged(true)}
					onKeyPress={(e) => handleNoteKeyPress(e)}
					onChange={(e) => setNoteText(e.target.value)}
				/>
				<Box sx={{ alignItems: 'center', display: 'flex' }}>
					<Checkbox
						disabled={!canEditIsDone}
						onChange={(e) => handleNoteStatusChanged(e.target.checked)}
						checked={isDone}
						inputProps={{ 'aria-label': 'controlled' }}
					/>
				</Box>
			</Box>
			<Box sx={{ display: 'flex', justifyContent: 'right' }}>
				<Typography variant="caption" sx={{ mr: '0.25rem' }}>
					{username.split('@')[0]}
				</Typography>
				<Typography variant="caption">{dayjs(createdDateTime).format('MM/DD/YYYY HH:mm A')}</Typography>
			</Box>
		</Grid>
	);
};

const QANote = forwardRef(NoteItem);

export interface IQaNotes {
	addQaNote: () => void;
}

export interface IQANotesProps {
	containerSx?: SxProps<Theme>;
	qaNotes: IQANote[];
	disableAddNewNote?: boolean;
	canEditNoteIsDone: boolean;
	qaNotesIsEditing: boolean;
	handleQANoteAdd: () => void;
	handleQANoteClick: (timestamp: number) => void;
	handleQANoteActive: (state: boolean) => void;
	handleQANotesChange: (qaNotes: IQANote[]) => void;
	handleANoteSubmit: (note: IQANote) => void;
}

const QaNotes: React.ForwardRefRenderFunction<IQaNotes, IQANotesProps> = function QaNotesFunc(
	{
		containerSx,
		qaNotes,
		canEditNoteIsDone,
		qaNotesIsEditing,
		disableAddNewNote,
		handleQANoteAdd,
		handleQANoteClick,
		handleQANotesChange,
		handleQANoteActive,
		handleANoteSubmit,
	}: IQANotesProps,
	forwardedRef: ForwardedRef<IQaNotes>
) {
	const { t } = useTranslation(namespaces.components.qaNotes);

	const [noteRefs, setNoteRefs] = React.useState<Array<{ ref: RefObject<INote>; note: IQANote }>>([]);

	const [shouldNoteBeFocused, setShouldNoteBeFocused] = useState<{ notesCount: number } | null>(null);

	const isEqualNotes = (note1: IQANote, note2: IQANote) =>
		note1.id || note2.id ? note1.id === note2.id : note1.createdDateTime === note2.createdDateTime;

	const onAddNote = () => {
		setShouldNoteBeFocused({ notesCount: qaNotes.length });
		handleQANoteAdd();
	};

	useImperativeHandle(forwardedRef, () => ({
		addQaNote() {
			if (!qaNotesIsEditing) {
				onAddNote();
			}
		},
	}));

	React.useEffect(() => {
		setNoteRefs((notes: Array<{ ref: RefObject<INote>; note: IQANote }>) =>
			Array(qaNotes.length)
				.fill(undefined)
				.map((_, i) => {
					const existingRef: { ref: RefObject<INote>; note: IQANote } | undefined = notes.find((x) =>
						isEqualNotes(x.note, qaNotes[i])
					);
					if (!existingRef) {
						return { ref: createRef(), note: qaNotes[i] };
					}
					return { ref: existingRef.ref, note: qaNotes[i] };
				})
		);
	}, [qaNotes.length]);

	useEffect(() => {
		if (shouldNoteBeFocused && noteRefs.length > shouldNoteBeFocused.notesCount) {
			const inputRef = noteRefs[noteRefs.length - 1];
			if (inputRef) {
				inputRef.ref.current?.focusInput();
			}
			setShouldNoteBeFocused(null);
		}
	}, [noteRefs.length, shouldNoteBeFocused]);

	const onNoteChange = (note: IQANote) => {
		const notes = cloneDeep(qaNotes);
		if (note.note.length === 0) {
			const index = notes.findIndex((x) => isEqualNotes(x, note));
			notes.splice(index, 1);
			handleQANotesChange(notes);
		} else {
			const editedNote = notes.find((x) => isEqualNotes(x, note));
			if (editedNote) {
				editedNote.isDone = note.isDone;
				editedNote.note = note.note;
				handleQANotesChange(notes);
			}
		}
	};

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', ...containerSx }}>
			{!disableAddNewNote && (
				<Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
					<Button startIcon={<AddIcon />} disabled={qaNotesIsEditing} size="small" onClick={onAddNote}>
						{t('newNote')}
					</Button>
				</Box>
			)}
			<Box sx={{ px: 2, overflow: 'auto' }}>
				{qaNotes && qaNotes.length > 0 ? (
					qaNotes.map((note) => (
						<QANote
							ref={noteRefs.find((x) => isEqualNotes(x.note, note))?.ref}
							onNoteChange={onNoteChange}
							noteData={note}
							isDone={note.isDone}
							canEditText={!note.id || note.id === 0}
							canEditIsDone={canEditNoteIsDone && !note.originalIsDone}
							key={note.id || note.createdDateTime}
							onNoteFocus={handleQANoteActive}
							onNoteClick={handleQANoteClick}
							onSubmit={handleANoteSubmit}
						/>
					))
				) : (
					<Typography sx={{ py: 2 }}>There are no QA notes.</Typography>
				)}
			</Box>
		</Box>
	);
};

export default forwardRef(QaNotes);
