import * as React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import ResetPasswordPage from './ResetPasswordPage';
import { useAppDispatch, useAppSelector } from '../../core/core.types';
import { actions } from '../../core/state/actions';

function ResetPassword() {
	const dispatch = useAppDispatch();
	const loading = useAppSelector<boolean>((state) => state.auth.resetPassword.loading);
	const success = useAppSelector<boolean>((state) => state.auth.resetPassword.success);
	const error = useAppSelector<string | null>((state) => state.auth.resetPassword.error);
	const codeRequested = useAppSelector<boolean>((state) => state.auth.resetPassword.codeRequested);
	const codeConfirmed = useAppSelector<boolean | null>((state) => state.auth.resetPassword.codeConfirmed);

	useEffect(() => {
		dispatch(actions.auth.initResetPasswordRequest());
	}, []);

	const onPasswordRestRequest = (username: string, email: string) => {
		dispatch(actions.auth.resetPasswordRequest({ username, email }));
	};

	const onPasswordRest = (username: string, email: string, newPassword: string, confirmPassword: string) => {
		dispatch(actions.auth.resetPasswordConfirmNewPassword({ username, email, newPassword, confirmPassword }));
	};

	const onConfirmCode = (username: string, email: string, confirmationCode: string) => {
		dispatch(actions.auth.resetPasswordSendConfirmCode({ username, email, confirmationCode }));
	};

	return (
		<ResetPasswordPage
			loading={loading}
			success={success}
			error={error}
			codeRequested={codeRequested}
			codeConfirmed={codeConfirmed}
			onPasswordRestRequest={onPasswordRestRequest}
			onPasswordReset={onPasswordRest}
			onConfirmCode={onConfirmCode}
		/>
	);
}

export default connect()(ResetPassword);
