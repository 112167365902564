import { endpoints } from '../endpoints';
import { getRequest } from '../api-service';
import { IProvider } from '../../models/providers/providers.models';
import { IGetUsersResponse } from '../user/user.api.models';

export async function getProvidersV2(name?: string): Promise<IProvider[]> {
	const endpoint = endpoints.user.users;
	const dictators = await getRequest<IGetUsersResponse[]>(endpoint);

	const providers = dictators
		.filter((x) => x.UserInfoID)
		.map((x: IGetUsersResponse) => {
			return {
				userInfoId: x.UserInfoID.toString(),
				userKey: x.UserKey,
				displayName: x.DisplayName || x.UserInfoID.toString(),
			};
		})
		.map((x) => ({ name: x.displayName, id: x.userInfoId }));

	return name ? providers.filter((x) => x.name.toLowerCase().includes(name.toLowerCase())) : providers;
}
