import React from 'react';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { IPatientsAdvancedSearchFilters, IPatientsSearchResult } from '../../core/models/patients/patients.models';
import { useAppDispatch, useAppSelector } from '../../core/core.types';
import { actions } from '../../core/state/actions';
import PatientsSearchPage from './PatientsSearchPage';
import { navigateToWorkListTab } from '../../system/navigator';
import { routes } from '../App/routes';

function PatientsSearch() {
	const dispatch = useAppDispatch();
	const [searchParams] = useSearchParams();

	const patientID: string | null = searchParams.get('patientID');
	const firstName: string | null = searchParams.get('firstName');
	const lastName: string | null = searchParams.get('lastName');
	const DOB: string | null = searchParams.get('DOB');

	const advancedSearchFilters = {
		firstName: firstName || undefined,
		lastName: lastName || undefined,
		patientID: patientID || undefined,
		DOB: DOB || undefined,
	};

	const patientsSearchResults = useAppSelector<IPatientsSearchResult[]>((state) => state.patients.patients);
	const patientSearchIsLoading = useAppSelector<boolean>((state) => state.patients.searchIsLoading);

	const onSearch = (filters: IPatientsAdvancedSearchFilters) => {
		navigateToWorkListTab({ to: routes.searchTabs.patients, filters });
	};

	React.useEffect(() => {
		if (
			Object.keys(advancedSearchFilters).filter(
				(x) => !!advancedSearchFilters[x as keyof IPatientsAdvancedSearchFilters]
			).length > 0
		) {
			dispatch(actions.patients.patientSearchAction(advancedSearchFilters));
		} else {
			dispatch(actions.patients.setPatientSearchResultAction({ patients: [] }));
		}
	}, [patientID, firstName, lastName, DOB]);

	return (
		<PatientsSearchPage
			patientsSearchResults={patientsSearchResults}
			advancedSearchFilters={advancedSearchFilters}
			loading={patientSearchIsLoading}
			onSearch={onSearch}
		/>
	);
}

export default connect()(PatientsSearch);
