import * as React from 'react';
import { useRoutes } from 'react-router-dom';
import SignIn from '../SingIn/SingIn';
import { getBearerCache } from '../../system/local-storage';
import AccountSettings from '../AccountSettings/AccountSettings';
import ProtectedRoute from '../../components/router/ProtectedRoute';
import { routes } from './routes';
import Support from '../Support/Support';
import Search from '../Search/Search';
import Jobs from '../Jobs/Jobs';
import Dictation from '../Dictation/Dictation';
import Admin from '../Admin/Admin';
import ReadOnlyDictation from '../JobsSearch/ReadOnlyDictation';
import ResetPassword from '../ResetPassword/ResetPassword';

export default function Router() {
	const token = getBearerCache();

	return useRoutes([
		{
			path: routes.login,
			element: <SignIn />,
		},
		{
			path: routes.resetPassword,
			element: <ResetPassword />,
		},
		{
			path: routes.accountSettings,
			element: (
				<ProtectedRoute token={token}>
					<AccountSettings />
				</ProtectedRoute>
			),
		},
		{
			path: routes.home,
			element: (
				<ProtectedRoute token={token}>
					<Jobs />
				</ProtectedRoute>
			),
		},
		{
			path: routes.support,
			element: (
				<ProtectedRoute token={token}>
					<Support />
				</ProtectedRoute>
			),
		},
		{
			path: routes.dictation,
			element: (
				<ProtectedRoute token={token}>
					<Dictation />
				</ProtectedRoute>
			),
		},
		{
			path: `${routes.admin}/:tab`,
			element: (
				<ProtectedRoute token={token}>
					<Admin />
				</ProtectedRoute>
			),
		},
		{
			path: `${routes.search}/:tab`,
			element: (
				<ProtectedRoute token={token}>
					<Search />
				</ProtectedRoute>
			),
		},
		{
			path: routes.dictationView,
			element: (
				<ProtectedRoute token={token}>
					<ReadOnlyDictation />
				</ProtectedRoute>
			),
		},
	]);
}
