import * as React from 'react';
import { Typography } from '@mui/material';
import PageContainer from '../../components/page-container/PageContainer';

function SupportPage() {
	return (
		<PageContainer
			sx={{
				flexDirection: 'column',
				alignItems: 'center',
			}}
		>
			<Typography>Support</Typography>
		</PageContainer>
	);
}

export default SupportPage;
