import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import { getIsAuthenticatedCache, getLastActivityCache, setLastActivityCache } from '../system/local-storage';
import { ISignOutActionPayload } from '../core/state/auth/auth.actions';
import { ISaveDocumentActionPayload } from '../core/state/worklist/worklist.actions';

type IUseAuthProps = {
	onSignOut: (args: ISignOutActionPayload) => void;
	onSaveDocument: (args: ISaveDocumentActionPayload) => void;
	routes: {
		login: string;
		dictation: string;
	};
	authTimeout: number;
	promptBeforeIdle: number;
	cacheActivityInterval: number;
	eventsThrottle: number;
};

type IUseAuthReturn = {
	openTimeoutModal: boolean;
	isAuthenticated: boolean;
	sessionTimeRemaining: number;
	onIdle: () => void;
	handleSessionContinue: () => void;
};

export const useAuth = ({
	onSignOut,
	onSaveDocument,
	routes,
	authTimeout,
	promptBeforeIdle,
	cacheActivityInterval,
	eventsThrottle,
}: IUseAuthProps): IUseAuthReturn => {
	const location = useLocation();
	const [initiated, setInitiated] = useState<boolean>(false);
	const [isAuthenticated, setIsAuthenticated] = useState<boolean>(getIsAuthenticatedCache());
	const [openTimeoutModal, setOpenTimeoutModal] = useState<boolean>(false);
	const [userActivityState, setUserActivityState] = useState<'inactive' | 'active' | 'prompted'>('inactive');
	const [sessionTimeRemaining, setSessionTimeRemaining] = useState<number>(0);

	const setTimestampToCache = () => {
		setLastActivityCache(new Date().getTime());
	};

	const sessionExpired = (lastActivity: number | null): boolean => {
		if (!lastActivity) {
			return false;
		}
		const currentTime = new Date().getTime();
		const millisecondsDiff = Math.abs(currentTime - lastActivity);
		return millisecondsDiff >= authTimeout;
	};

	const deactivateModal = () => {
		setOpenTimeoutModal(false);
		setSessionTimeRemaining(0);
	};

	const activateModal = () => {
		setOpenTimeoutModal(true);
		setSessionTimeRemaining(promptBeforeIdle / 1000);
	};

	const onIdle = () => {
		deactivateModal();
		if (location.pathname === routes.dictation) {
			onSaveDocument({
				markTranscriptionComplete: false,
				sendToQA: false,
				forceSave: false,
				forceToQA: false,
				timedOutSave: true,
			});
		} else {
			onSignOut({
				notRedirect: false,
				onTimeOut: true,
			});
		}
	};

	const onPrompt = () => {
		setUserActivityState('prompted');
		activateModal();
	};

	const onActive = () => {
		if (initiated && isAuthenticated) {
			setTimestampToCache();
		}
		setUserActivityState('active');
		deactivateModal();
	};

	const onAction = () => {
		if (initiated && isAuthenticated && userActivityState === 'active') {
			setTimestampToCache();
		}
	};

	const { getRemainingTime, isPrompted, activate, pause } = useIdleTimer({
		onActive,
		onPrompt,
		onIdle,
		timeout: authTimeout,
		promptBeforeIdle,
		eventsThrottle,
		onAction,
		throttle: cacheActivityInterval,
	});

	const onPause = () => {
		setUserActivityState('inactive');
		pause();
		deactivateModal();
	};

	const handleSessionContinue = () => {
		activate();
	};

	useEffect(() => {
		const authenticated = getIsAuthenticatedCache();
		if (authenticated) {
			if (sessionExpired(getLastActivityCache())) {
				onSignOut({
					notRedirect: location.pathname === routes.login,
					onSessionExpired: true,
				});
			} else {
				setTimestampToCache();
			}
		}
		setInitiated(true);
	}, []);

	useEffect(() => {
		if (isAuthenticated) {
			activate();
			onActive();
		} else {
			onPause();
		}
	}, [isAuthenticated]);

	useEffect(() => {
		const interval = setInterval(() => {
			if (isPrompted()) {
				setSessionTimeRemaining(Math.ceil(getRemainingTime() / 1000));
			}
		}, 1000);
		return () => {
			clearInterval(interval);
		};
	}, [getRemainingTime, isPrompted]);

	useEffect(() => {
		const authenticated = getIsAuthenticatedCache();
		if (location.pathname === routes.login) {
			if (!authenticated) {
				onSignOut({ notRedirect: true, onUnauthorized: true });
			}
		}

		setIsAuthenticated(authenticated);
	}, [location]);

	return { isAuthenticated, openTimeoutModal, onIdle, sessionTimeRemaining, handleSessionContinue };
};
