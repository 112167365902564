import { Alert, Box, Button, Link, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { namespaces } from '../../application/i18n.constants';
import { routes } from '../../pages/App/routes';

export interface ISignInFromProps {
	onSingIn: (username: string, password: string) => void;
	signInError?: string;
	loading: boolean;
	onCredentialsChange?: (username: string, password: string) => void;
}

function SignInForm({ onSingIn, signInError, loading, onCredentialsChange }: ISignInFromProps) {
	const { t } = useTranslation(namespaces.pages.signIn);

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');

	const onUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setUsername(event.target.value);
		onCredentialsChange?.(event.target.value, password);
	};

	const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
		onCredentialsChange?.(username, event.target.value);
	};

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 320 }}>
			{signInError && (
				<Alert sx={{ width: '100%' }} severity="error">
					{signInError}
				</Alert>
			)}
			<TextField
				margin="normal"
				required
				fullWidth
				id="username"
				label={t('username')}
				error={Boolean(signInError)}
				name="username"
				autoComplete="username"
				autoFocus
				onChange={onUsernameChange}
			/>
			<TextField
				margin="normal"
				required
				fullWidth
				name="password"
				error={Boolean(signInError)}
				label={t('password')}
				type="password"
				id="password"
				autoComplete="current-password"
				onChange={onPasswordChange}
			/>

			{loading ? (
				<LoadingButton loading variant="contained" fullWidth sx={{ mt: 3, mb: 2 }}>
					{t('signInBtnLoading')}
				</LoadingButton>
			) : (
				<Button
					type="submit"
					disabled={username.length < 1 || password.length < 1}
					fullWidth
					variant="contained"
					sx={{ mt: 3, mb: 2 }}
					onClick={() => onSingIn(username, password)}
				>
					{t('signInBtn')}
				</Button>
			)}
			<Link fontSize="0.75rem" href={routes.resetPassword}>
				{t('forgotPassword')}
			</Link>
		</Box>
	);
}

SignInForm.defaultProps = {
	signInError: undefined,
};

export default SignInForm;
