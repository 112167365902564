import * as React from 'react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import JobsPage from './JobsPage';
import { useAppDispatch, useAppSelector } from '../../core/core.types';
import { IDictation } from '../../core/models/dictations/dictations.models';
import { IProvider } from '../../core/models/providers/providers.models';
import { actions } from '../../core/state/actions';
import { routes } from '../App/routes';
import { IScriberPermissions, IUserEHRVerification } from '../../core/models/users/user.models';
import { trackEvent } from '../../application/app-insights';
import { DocumentType, JobStatus } from '../../core/models/dictations/document.models';

function Jobs() {
	const dispatch = useAppDispatch();
	const location = useLocation();

	const [initialized, setInitialized] = useState<boolean>(false);

	const username = useAppSelector<string>((state) => state.user.settings.shortname);
	const userId = useAppSelector<string>((state) => state.user.settings.userInfoId);
	const permissions = useAppSelector<IScriberPermissions>((state) => state.user.settings.permissions);
	const isLoading = useAppSelector<boolean>((state) => state.worklist.isDictationsLoading);
	const isUnlocking = useAppSelector<boolean>((state) => state.worklist.unlockDocumentIsLoading);
	const dictations = useAppSelector<IDictation[]>((state) => state.worklist.dictations);
	const selectedProviders = useAppSelector<IProvider[]>((state) => state.worklist.selectedProviders);
	const ehrVerification = useAppSelector<IUserEHRVerification>((state) => state.user.ehrVerification);

	useEffect(() => {
		const interval = setInterval(() => {
			if (initialized) {
				dispatch(actions.worklist.getDictationsAction({ disableLoading: true }));
			}
		}, 60 * 1000);

		return () => clearInterval(interval);
	}, [initialized]);

	useEffect(() => {
		if (initialized) {
			if (location.pathname === routes.workList) {
				dispatch(actions.worklist.getDictationsAction({}));
			}
		} else {
			setInitialized(true);
		}
	}, [location]);

	const onJobOpen = (dictation: IDictation) => {
		trackEvent('1101001', 'JobOpen', { blobId: dictation.blobID });
		dispatch(actions.worklist.openDictationAction(dictation));
	};

	const onJobUnlock = (dictation: IDictation) => {
		dispatch(actions.worklist.closeDictation({ blobID: dictation.blobID, unlock: true }));
	};

	const onJobUnlockAndEdit = (dictation: IDictation) => {
		dispatch(actions.worklist.unlockAndOpenDictation(dictation));
	};

	const canOpenJob = (job: IDictation): boolean => {
		return !!(
			job.documentType &&
			(job.documentType.toLowerCase() === DocumentType.Visit.toLowerCase() ||
				job.documentType.toLowerCase() === DocumentType.DictationNote.toLowerCase() ||
				job.documentType.toLowerCase() === DocumentType.DictationLetter.toLowerCase() ||
				job.documentType.toLowerCase() === DocumentType.OperativeNote.toLowerCase() ||
				job.documentType.toLowerCase() === DocumentType.Scribe.toLowerCase() ||
				job.documentType.toLowerCase().replace(' ', '') === DocumentType.NonPatient.toLowerCase().replace(' ', ''))
		);
	};

	const onUpdateJob = (
		job: IDictation,
		update: {
			documentStatus?: JobStatus;
			documentTypeName?: DocumentType;
			stat?: boolean;
		}
	) => {
		dispatch(actions.jobs.updateJob({ blobId: job.blobID, job, update }));
	};

	return (
		<JobsPage
			permissions={permissions}
			isLoading={isLoading}
			isUnlocking={isUnlocking}
			currentUsername={username}
			dictations={dictations
				.filter((x) => permissions.admin || x.providerUserInfoID === userId)
				.filter((x) => permissions.qa || !x.markedForQA)
				.filter((x) => x.documentStatus !== JobStatus.Removed)}
			canOpenJob={canOpenJob}
			onJobOpen={onJobOpen}
			onJobUnlock={onJobUnlock}
			onJobUnlockAndEdit={onJobUnlockAndEdit}
			userHasSelectedProviders={selectedProviders.length > 0}
			ehrVerification={ehrVerification}
			onUpdateJob={onUpdateJob}
		/>
	);
}

export default Jobs;
