import * as React from 'react';
import { ChangeEvent, useEffect, useState } from 'react';
import { BaseTextFieldProps } from '@mui/material/TextField/TextField';
import { useTranslation } from 'react-i18next';
import { ValidationMessage, ValidationResult } from '../../core/validators/types';
import { IValidationField } from './validationField.types';
import { namespaces } from '../../application/i18n.constants';
import SelectField, { IItem } from '../select/SelectField';

interface IValidationSelectFieldProps<T extends string | ReadonlyArray<string> | number | undefined>
	extends Omit<BaseTextFieldProps, 'error'>,
		IValidationField {
	data: IItem<T>[];
	disabledValues?: T[];
	validation?: (value: string) => ValidationResult;
	onChange?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}

function ValidationSelectField<T extends string | ReadonlyArray<string> | number | undefined>({
	validation,
	value,
	data,
	disabledValues,
	required,
	helperText,
	onChange,
	onValidationChange,
	disableValidation,
	...props
}: IValidationSelectFieldProps<T>) {
	const { t } = useTranslation(namespaces.common.validations);

	const check = (inputValue: unknown): { message?: ValidationMessage; valid: boolean; isNotFilled?: boolean } => {
		if (required && (!inputValue || inputValue === '')) {
			return { valid: false, message: t('fieldIsRequired'), isNotFilled: true };
		}

		return { valid: true };
	};

	const [validationResult, setValidationResult] = useState<{ message?: string; valid: boolean }>(check(value));

	useEffect(() => {
		if (onValidationChange) {
			onValidationChange(validationResult);
		}
	}, []);

	useEffect(() => {
		if (required) {
			const checkResult = check(value);
			setValidationResult(checkResult);
			if (onValidationChange) {
				onValidationChange(checkResult);
			}
		}
	}, [value]);

	const onValueChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		if (onChange) {
			onChange(event);
		}
	};

	return (
		<SelectField
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
			required={required}
			value={value}
			data={data}
			disabledValues={disabledValues}
			onChange={onValueChange}
			error={!validationResult.valid && !disableValidation}
			helperText={
				validationResult.valid || disableValidation ? helperText : validationResult.message || t('inputIsNotValid')
			}
		/>
	);
}

export default ValidationSelectField;
