import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { red } from '@mui/material/colors';
import { namespaces } from '../../application/i18n.constants';

interface ISessionTimeoutDialogProps {
	open: boolean;
	remainingTime: number;
	onSessionContinue: () => void;
}

export default function SessionTimeoutDialog({ open, remainingTime, onSessionContinue }: ISessionTimeoutDialogProps) {
	const { t } = useTranslation(namespaces.components.sessionTimeoutDialog);

	return (
		<Dialog open={open} aria-labelledby="session-timeout-dialog" aria-describedby="session-timeout-dialog">
			<DialogTitle id="session-timeout-dialog-title" sx={{ textAlign: 'center', color: red[500] }}>
				{t('title')}
			</DialogTitle>
			<DialogContent sx={{ pb: '0.25rem' }}>
				<Typography variant="body1" sx={{ mb: '1rem' }}>{`${t('currentSession')}. ${t(
					'loggedOut'
				)} ${remainingTime} ${t('seconds')}`}</Typography>
				<Typography variant="body1">{t('inquireExtendSession')}?</Typography>
			</DialogContent>
			<DialogActions sx={{ display: 'flex', justifyContent: 'center', pb: '1rem', mt: '0.2rem' }}>
				<Button
					onClick={onSessionContinue}
					color="primary"
					variant="contained"
					size="large"
					disabled={remainingTime <= 0}
				>
					{t('continueSession')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
