import * as React from 'react';
import { useEffect, useState } from 'react';
import { Alert, Autocomplete, Box, Grid, TextField, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { namespaces } from '../../application/i18n.constants';
import { IProvider } from '../../core/models/providers/providers.models';
import { ICreateMacroPayload, IMacro, IUpdateMacroPayload } from '../../core/models/macros/macros.models';
import MacrosSearch from '../../components/macros/MacrosSearch';
import Spinner from '../../components/spinner/Spinner';
import MacrosTable from '../../components/macros/MacrosTable';
import AddMacrosDialog from '../../components/macros/AddMacrosDialog';
import UpdateMacrosDialog from '../../components/macros/UpdateMacrosDialog';
import DeleteMacrosDialog from '../../components/macros/DeleteMacrosDialog';
import UploadCsvDialog from '../../components/macros/UploadCsvDialog';
import CopyMacrosDialog from '../../components/macros/CopyMacrosDialog';

interface IProviderDotPhrasesPageProps {
	shortCodeDisabled?: boolean;

	macros: IMacro[];
	macrosIsLoading: boolean;
	saveMacroIsLoading: boolean;

	onMacroAdd: (macro: ICreateMacroPayload) => void;
	onMacroUpdate: (macro: IUpdateMacroPayload) => void;
	onMacroDelete: (macroId: number) => void;
	onMacroImport: (params: { file: File; providerId: string }) => void;

	providers?: {
		disabled?: boolean;
		selectedProvider?: string;
		allProviders: IProvider[];
		providersAreLoading: boolean;
		onSelectProvider: (providerId: string) => void;
		onCopyMacros: (providerId: string, macros: IMacro[]) => void;
	};
}

interface IProviderAutocompleteOptions {
	firstLetter: string;
	value: string;
	text: string;
}

export default function MacrosPage({
	providers,
	shortCodeDisabled,
	macros,
	onMacroAdd,
	onMacroUpdate,
	onMacroDelete,
	onMacroImport,
	saveMacroIsLoading,
	macrosIsLoading,
}: IProviderDotPhrasesPageProps) {
	const { t } = useTranslation(namespaces.components.dotPhrases);
	const smallScreen = useMediaQuery((th: Theme) => th.breakpoints.down('sm'));
	const isLoading = macrosIsLoading || saveMacroIsLoading;

	const [openedDotPhrase, setOpenedDotPhrase] = useState<IMacro | null>(null);
	const [macroDialogOpen, setMacroDialogOpen] = useState(false);
	const [macroImportDialogOpen, setMacroImportDialogOpen] = useState(false);
	const [copyDialogOpen, setCopyDialogOpen] = useState(false);
	const [deleteDotPhraseDialogOpen, setDeleteDotPhraseDialogOpen] = useState(false);
	const [deleteMacroId, setDeleteMacroId] = useState<number | null>(null);
	const [searchQuery, setSearchQuery] = useState('');
	const [selectedSection, setSelectedSection] = useState('');
	const [selectedProvider, setSelectedProvider] = useState<IProviderAutocompleteOptions>({
		firstLetter: '',
		value: '',
		text: '',
	});

	const [macroToCopy, setMacroToCopy] = useState<IMacro | null>(null);

	const [selectedRows, setSelectedRows] = React.useState<number[]>([]);

	useEffect(() => {
		const provider = providers?.selectedProvider
			? providers?.allProviders.find((p) => p.id === providers.selectedProvider)
			: undefined;

		setSelectedProvider({
			firstLetter: provider?.name[0].toUpperCase() || '',
			value: provider?.id || '',
			text: provider?.name || '',
		});
		setSearchQuery('');
		setSelectedSection('');
	}, [providers?.allProviders, providers?.selectedProvider]);

	const filteredMacros = macros.filter((macro) => {
		const queryType = searchQuery[0] === '.' ? 'shortCode' : 'description';
		const parsedSelectedSection = selectedSection !== 'all' ? selectedSection : '';
		if (parsedSelectedSection && macro.section !== parsedSelectedSection) {
			return false;
		}

		return !(searchQuery && !macro[queryType].toLowerCase().includes(searchQuery.replace('.', '').toLowerCase()));
	});

	const handleMacroSearch = (query: string, section: string) => {
		setSearchQuery(query);
		setSelectedSection(section);
	};

	const handleSetProvider = (provider: IProviderAutocompleteOptions) => {
		setSelectedProvider(provider);
		providers?.onSelectProvider?.(provider.value);
	};

	const handleOpenMacro = (idx: number) => {
		setOpenedDotPhrase(filteredMacros[idx]);
	};

	const handleOpenDeleteMacro = (idx: number) => {
		setDeleteDotPhraseDialogOpen(true);
		setDeleteMacroId(filteredMacros[idx].macroId);
	};

	const handleDeleteDotPhrase = () => {
		if (deleteMacroId) {
			onMacroDelete(deleteMacroId);
			setDeleteDotPhraseDialogOpen(false);
		}
	};

	const handleMacroImport = ({ file, providerId }: { file: File; providerId: string }) => {
		onMacroImport({ file, providerId });
		setMacroImportDialogOpen(false);
	};

	const cacheMacroToCopy = (idx: number) => {
		const macro = filteredMacros[idx];
		setMacroToCopy(macro);
		setCopyDialogOpen(true);
	};

	const handleMacrosCopy = (providerId: string) => {
		if (macroToCopy) {
			providers?.onCopyMacros(providerId, [macroToCopy]);
			setMacroToCopy(null);
		} else {
			const macrosToCopy = selectedRows.map((idx) => filteredMacros[idx]);
			if (providers) {
				providers.onCopyMacros(providerId, macrosToCopy);
				setSelectedRows([]);
			}
		}
		setCopyDialogOpen(false);
	};

	if (providers?.providersAreLoading) {
		return (
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<Spinner />
			</Box>
		);
	}

	const providerOptions = providers?.allProviders
		? providers.allProviders.map((provider) => {
				const firstLetter = provider.name[0].toUpperCase();
				return {
					firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
					value: provider.id,
					text: provider.name,
				};
		  })
		: [];

	const providersList = !providers?.disabled ? providerOptions : [selectedProvider];

	const copyEnabled = !!providers && !providers?.disabled;

	return (
		<Grid sx={{ flexDirection: 'column', minWidth: 720 }}>
			<Grid
				sx={{
					display: 'flex',
					flexDirection: smallScreen ? 'column' : 'row',
					height: smallScreen ? 'initial' : '3rem',
					alignItems: 'center',
					mt: 4,
				}}
				item
			>
				{/* eslint-disable react/jsx-props-no-spreading */}
				{providers ? (
					<Autocomplete
						sx={{ width: '30%' }}
						autoHighlight
						id="providerSelect"
						getOptionLabel={(option) => option.text}
						options={providersList.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
						groupBy={(option) => option.firstLetter}
						onChange={(e, inputValue) => {
							if (inputValue) {
								handleSetProvider(inputValue);
							} else {
								handleSetProvider({ firstLetter: '', value: '', text: '' });
							}
						}}
						fullWidth
						disabled={providers?.disabled}
						value={selectedProvider}
						size="medium"
						renderInput={(params) => <TextField {...params} label={t('selectProvider')} />}
					/>
				) : null}
				<MacrosSearch
					sx={{ width: providers ? '70%' : '100%' }}
					onSearch={handleMacroSearch}
					disabled={providers && !selectedProvider.value}
					onAdd={() => setMacroDialogOpen(true)}
					onImport={() => setMacroImportDialogOpen(true)}
					onCopy={selectedRows.length > 0 ? () => setCopyDialogOpen(true) : undefined}
					copyEnabled={copyEnabled}
				/>
			</Grid>
			{providers && !selectedProvider.value ? (
				<Alert sx={{ mt: 1 }} severity="info">
					{t('noProviderSelected')}
				</Alert>
			) : (
				<Box sx={{ mt: 1 }}>
					{isLoading ? (
						<Box
							sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '3rem' }}
						>
							<Spinner />
						</Box>
					) : (
						filteredMacros && (
							<MacrosTable
								macros={filteredMacros}
								onRowClick={handleOpenMacro}
								onDeleteClick={handleOpenDeleteMacro}
								onCopyClick={copyEnabled ? (rowId) => cacheMacroToCopy(rowId) : undefined}
								onSelectionChange={providers ? (rows) => setSelectedRows(rows) : undefined}
							/>
						)
					)}
				</Box>
			)}
			<AddMacrosDialog
				open={macroDialogOpen}
				title={t('addNewMacro')}
				shortCodeDisabled={shortCodeDisabled}
				providers={
					providers
						? {
								allProviders: providers.allProviders,
								preSelectedProvider: selectedProvider.value,
								disabled: providers?.disabled,
						  }
						: undefined
				}
				add={{
					action: ({ providerId, section, shortCode, description, macro, spokenForm }) => {
						onMacroAdd({ providerId, section, shortCode, description, macro, spokenForm });
						setMacroDialogOpen(false);
					},
					text: t('submit'),
				}}
				cancel={{
					action: () => {
						setMacroDialogOpen(false);
					},
					text: t('cancel'),
				}}
			/>
			{openedDotPhrase && (
				<UpdateMacrosDialog
					title={t('updateMacro')}
					shortCodeDisabled={shortCodeDisabled}
					openedDotPhrase={openedDotPhrase}
					update={{
						action: ({ section, macro, spokenForm }) => {
							onMacroUpdate({
								macroId: openedDotPhrase?.macroId,
								providerId: selectedProvider.value || 'N/A',
								section,
								macro,
								spokenForm,
							});
							setOpenedDotPhrase(null);
						},
						text: t('update'),
					}}
					cancel={{
						action: () => {
							setOpenedDotPhrase(null);
						},
						text: t('cancel'),
					}}
				/>
			)}
			<DeleteMacrosDialog
				text={t('deleteMacro')}
				open={deleteDotPhraseDialogOpen}
				deletePhrase={{ action: handleDeleteDotPhrase, text: t('confirm') }}
				cancel={{
					action: () => {
						setDeleteDotPhraseDialogOpen(false);
					},
					text: t('cancel'),
				}}
			/>
			<UploadCsvDialog
				open={macroImportDialogOpen}
				providers={
					providers
						? {
								allProviders: providers.allProviders,
								preSelectedProvider: selectedProvider.value,
								disabled: providers?.disabled,
						  }
						: undefined
				}
				onClose={() => setMacroImportDialogOpen(false)}
				onUpload={({ file, providerId }) => handleMacroImport({ file, providerId })}
			/>
			{providers && (
				<CopyMacrosDialog
					open={copyDialogOpen}
					providers={{
						allProviders: providers.allProviders,
						preSelectedProvider: selectedProvider.value,
					}}
					onClose={() => setCopyDialogOpen(false)}
					onCopy={({ providerId }) => handleMacrosCopy(providerId)}
				/>
			)}
		</Grid>
	);
}
