import * as React from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material';
import { Theme, useTheme } from '@mui/system';
import { useLocation } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import {
	ICustomPatientDemographicsData,
	IPatientDemographic,
	IPatientEncounter,
} from '../../core/models/patients/patients.models';
import {
	IDictation,
	IDocumentVersion,
	IOpenAISummary,
	IQANote,
	ITimestampedTranscription,
} from '../../core/models/dictations/dictations.models';
import Loading from '../../components/spinner/Loading';
import DictationSideBar, { IDictationSideBar } from '../../components/dictation/dictation-side-bar/DictationSideBar';
import DictationPageHeader from '../../components/dictation/DictationPageHeader';
import { mapDocumentToFields } from '../../core/services/documents/documents.services';
import SelectFiled from '../../components/select/SelectField';
import { goBack, push } from '../../system/navigator';
import { routes } from '../App/routes';
import ExpandableMenu, { IExpandableMenuItem } from '../../components/expandable-menu/ExpandableMenu';
import OpenAiSummary from '../../components/transcription/openai-summary/OpenAiSummary';
import { EHRType } from '../../core/models/ehr.models';
import EhrDiagnosis from '../../components/diagnosis/EhrDiagnosis';
import PageContainer from '../../components/page-container/PageContainer';
import { DictationSidebarTabType } from '../../components/dictation/dictation-sidebar-tabs/DictationSidebarTabs';
import { AIModelType } from '../../core/api/dictations/dictations.api.models';
import NotePdf, { getNotePatientDemographics, handleDownloadPdf } from '../../components/pdfTemplates/NotePdf';
import { IScriberPermissions } from '../../core/models/users/user.models';

function DiagnosisVersion({
	ehr,
	isLoading,
	encounter,
	versions,
}: {
	ehr: EHRType;
	isLoading: boolean;
	encounter: IPatientEncounter;
	versions: IDocumentVersion[];
}) {
	const [versionId, setVersionId] = useState<number>(versions[0].versionId);
	const documentVersion = versions.find((x) => x.versionId === versionId) as IDocumentVersion;

	const noHandler = () => {};
	const noDataList: [] = [];
	const noDataNull = null;
	const noDataBooleanFalse = false;
	const noDataBooleanTrue = true;

	const fields = mapDocumentToFields(documentVersion.document);

	const version = versions.find((x) => x.versionId === versionId);

	return (
		<Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center' }}>
			<SelectFiled
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				InputProps={{
					disableUnderline: true,
				}}
				size="small"
				variant="standard"
				value={versionId}
				data={versions.map((x) => ({ value: x.versionId, text: `version ${x.versionId}` }))}
				onChange={(e) => setVersionId(Number(e.target.value))}
			/>
			<Typography color="text.secondary" sx={{ mb: 2 }}>
				{version?.displayName || version?.username || ''}
			</Typography>
			<EhrDiagnosis
				ehr={ehr}
				encounters={noDataList}
				encountersIsLoading={isLoading}
				currentEncounter={encounter}
				fields={fields}
				onChange={noHandler}
				onDiagnosisRankingChanged={noHandler}
				handleBillingCodesChange={noHandler}
				onApplyOrderSet={noHandler}
				isPullLoading={noDataBooleanFalse}
				onPull={noHandler}
				pulledDiagnoses={noDataNull}
				onCloseDiagnosesPull={noHandler}
				onMergePulledAssessmentPlan={noHandler}
				saveProcedureDocumentationIsLoading={noDataBooleanFalse}
				applyProcedureTemplateIsLoading={noDataBooleanFalse}
				onApplyProcedureDocumentationTemplate={noHandler}
				onSaveProcedureDocumentations={noHandler}
				unassignedOrders={noDataList}
				onUnassignedOrdersChange={noHandler}
				billingLoaded={noDataBooleanTrue}
				billingClaimCreated={documentVersion.billingClaimCreated}
				billingCodes={documentVersion.billing}
				billingModifiersLoading={noDataBooleanFalse}
				billingModifiers={noDataList}
				codeSearchIsLoading={noDataBooleanFalse}
				procedureCodeSearchResults={noDataList}
				onTextFieldFocusChanged={noHandler}
				providerDotPhrases={noDataList}
				settings={{
					editProcedureDocumentationEnabled: false,
					editBillingServicesEnabled: false,
					viewBillingServicesEnabled: true,
					saveOrderInterpretationsEnabled: false,
				}}
			/>
		</Box>
	);
}
interface IReadOnlyDictationPageProps {
	ehr: EHRType;
	isDocumentLoading: boolean;
	permissions: IScriberPermissions;
	document: {
		dictation: IDictation;
		encounter: IPatientEncounter;
		versions: IDocumentVersion[];
		demographic: IPatientDemographic;
		qaNotes: IQANote[];
		transcription: {
			azureASR: { text: string | null; timestampedText: ITimestampedTranscription[] | null };
			mModalASR: string | null;
			nuanceASR: string | null;
			openAISummary: string | null;
			openAISummaryStructured: IOpenAISummary | null;
			customOpenAISummary: string | null;
			customOpenAISummaryStructured: IOpenAISummary | null;
			patientDemographics: ICustomPatientDemographicsData | null;
		};
		providerNote: string;
	};
	aiPrompt?: {
		prompt: string;
		instructions: string;
		canUpdate: boolean;
		onChangePrompt: (aiPrompt: string) => void;
		onChangeInstructions: (aiInstructions: string) => void;
		lastTimePromptSubmitted: number | null;
		onPromptSubmit: (aiModel: AIModelType | null, prompt: { prompt: string; instructions: string }) => void;
	};
}

function ReadOnlyDictationPage({
	ehr,
	permissions,
	isDocumentLoading,
	document,
	aiPrompt,
}: IReadOnlyDictationPageProps) {
	const theme = useTheme();
	const smallScreen = useMediaQuery((th: Theme) => th.breakpoints.down('sm'));
	const location = useLocation();

	const dictationSideBarRef = useRef<IDictationSideBar>(null);

	const [qaNotesIsEditing, setQANotesIsEditing] = React.useState(false);
	const [currentTab, setCurrentTab] = useState<string | null>(null);

	const documentWithEncounterLoaded = document;

	const headerHeight = 8;
	const border = !smallScreen ? '0.1rem solid darkgray' : 0;
	const minPageHeight = `calc(100vh - ${theme.spacing(headerHeight)})`;

	const sideBarSize = {
		sm: 5,
		md: qaNotesIsEditing ? 6 : 5,
		lg: qaNotesIsEditing ? 4 : 3,
	};

	const noHandler = () => {};
	const noDataBooleanFalse = false;
	const noDateEmptyList: [] = [];

	const hasAiSummary = () => document.transcription.openAISummaryStructured || document.transcription.openAISummary;
	const hasCustomAiSummary = () =>
		!!document.transcription.customOpenAISummaryStructured || !!document.transcription.customOpenAISummary;

	const expandableMenuItems = useMemo(() => {
		const items: IExpandableMenuItem[] = [];

		const splitAISummary = hasCustomAiSummary();

		if (document.transcription.openAISummary || document.transcription.openAISummaryStructured) {
			items.push({
				title: 'summary',
				Item: (
					<Box sx={{ height: '100%', overflowY: 'hidden' }} display="flex" flexDirection="row">
						{hasAiSummary() && (
							<OpenAiSummary
								ehr={ehr}
								summaryTitle={splitAISummary ? 'Default' : undefined}
								addDisabled
								summary={document.transcription.openAISummaryStructured}
								text={document.transcription.openAISummary}
								selectedDiagnoses={noDateEmptyList}
							/>
						)}
						{hasCustomAiSummary() && (
							<OpenAiSummary
								ehr={ehr}
								summaryTitle={splitAISummary ? 'Custom' : undefined}
								addDisabled
								summary={document.transcription.customOpenAISummaryStructured}
								text={document.transcription.customOpenAISummary}
								selectedDiagnoses={noDateEmptyList}
							/>
						)}
					</Box>
				),
			});
		}

		return items;
	}, [
		ehr,
		document.transcription.openAISummaryStructured,
		document.transcription.openAISummary,
		document.transcription.customOpenAISummaryStructured,
		document.transcription.customOpenAISummary,
	]);

	const canHaveEncounters = document.dictation?.documentType?.toLowerCase() === 'visit';
	const customPatientDemographic = document?.transcription?.patientDemographics;
	const patientDemographic = document?.demographic;

	const canDownloadPdf = useMemo(
		() =>
			permissions.downloadNotes &&
			(document.transcription.customOpenAISummaryStructured || document.transcription.openAISummaryStructured),
		[
			permissions.downloadNotes,
			document.transcription.customOpenAISummaryStructured,
			document.transcription.openAISummaryStructured,
		]
	);
	const downloadPdf = useCallback(
		() =>
			handleDownloadPdf(
				<NotePdf
					note={document.transcription.customOpenAISummaryStructured || document.transcription.openAISummaryStructured}
					patientDemographics={getNotePatientDemographics(
						patientDemographic,
						customPatientDemographic,
						document?.dictation
					)}
					text={null}
				/>,
				document?.dictation?.documentID ? document.dictation.documentID : ''
			),
		[
			document.transcription.customOpenAISummaryStructured,
			document.transcription.openAISummaryStructured,
			patientDemographic,
			customPatientDemographic,
			document?.dictation,
		]
	);

	return (
		<PageContainer navbar={false} disablePadding sx={{ display: 'flex', flexDirection: 'column' }}>
			<DictationPageHeader logo>
				<Box display="flex" flexDirection="row">
					{canDownloadPdf ? (
						<LoadingButton
							sx={{
								color: 'white',
							}}
							color="primary"
							onClick={downloadPdf}
						>
							DOWNLOAD
						</LoadingButton>
					) : null}
					<Button
						variant="contained"
						disableElevation
						onClick={() => {
							if (location.key) {
								goBack();
							} else {
								push(routes.searchTabs.jobs);
							}
						}}
					>
						CANCEL
					</Button>
				</Box>
			</DictationPageHeader>
			<Box>
				<Box>
					<Grid container direction="row">
						{document.dictation ? (
							<Grid
								item
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...sideBarSize}
								justifyContent="center"
								sx={{
									flex: 1,
									minHeight: minPageHeight,
									borderRight: !smallScreen ? '0.1rem solid darkgray' : 0,
									transition: (th) => th.transitions.create('all', { easing: th.transitions.easing.easeIn }),
								}}
							>
								<DictationSideBar
									ref={dictationSideBarRef}
									containerSx={{
										display: 'flex',
										flex: 1,
										flexDirection: 'column',
										maxHeight: minPageHeight,
										transition: (th) => th.transitions.create('all', { easing: th.transitions.easing.easeIn }),
									}}
									audioUrl={document.dictation.blobUrl}
									patientId={document.dictation.patientID}
									isEncountersLoading={noDataBooleanFalse}
									isPatientLoading={isDocumentLoading}
									disableEncounter={!canHaveEncounters}
									changeEncounterDisabled
									patient={{
										demographic: document.demographic,
										encounter: document.encounter,
										stat: document.dictation.stat,
									}}
									onPatientSelect={noHandler}
									onPatientUpdate={noHandler}
									sidebar={{
										qaNotes: document.qaNotes,
										disabledTabs: canHaveEncounters
											? []
											: [DictationSidebarTabType.PROVIDER_NOTE, DictationSidebarTabType.QA_NOTES],
										disableAddNewNote: true,
										canEditNoteIsDone: noDataBooleanFalse,
										qaNotesIsEditing,
										handleQANoteIsEditing: setQANotesIsEditing,
										handleQANoteAdd: noHandler,
										handleQANotesChange: noHandler,
										handleQANoteSubmit: noHandler,
										transcription: {
											azure: {
												text: document.transcription.azureASR.text,
												timestampedText: document.transcription.azureASR.timestampedText,
											},
											mModal: document.transcription.mModalASR,
											nuance: document.transcription.nuanceASR,
										},
										providerNote: document.providerNote,
										aiPrompt,
									}}
								/>
							</Grid>
						) : null}
						<Grid
							item
							sm={7}
							md={qaNotesIsEditing ? 6 : 7}
							lg={qaNotesIsEditing ? 8 : 9}
							sx={{
								transition: (thm) => thm.transitions.create('all', { easing: thm.transitions.easing.easeIn }),
							}}
						>
							<Box
								sx={{
									minHeight: minPageHeight,
									maxHeight: minPageHeight,
									display: 'flex',
									flexDirection: 'row',
									flex: 1,
								}}
							>
								<ExpandableMenu
									containerSx={{
										display: 'flex',
										flex: 1,
										overflowY: 'auto',
									}}
									childSx={{
										borderLeft: border,
									}}
									items={expandableMenuItems}
									onTabChanged={(tab) => setCurrentTab(tab)}
								/>
								{currentTab == null || document.versions.length !== 0 ? (
									<Box
										sx={{
											minHeight: minPageHeight,
											flex: 1,
											display: 'flex',
											flexDirection: 'row',
											overflowY: 'auto',
											borderLeft: border,
											px: 2,
											pt: 2,
										}}
									>
										{/* eslint-disable-next-line no-nested-ternary */}
										{documentWithEncounterLoaded ? (
											document.versions.length > 0 ? (
												<>
													<Box sx={{ display: 'flex', flex: 1, p: 1, overflowY: 'auto' }}>
														<DiagnosisVersion
															ehr={ehr}
															isLoading={isDocumentLoading}
															encounter={document.encounter}
															versions={document.versions}
														/>
													</Box>
													<Box sx={{ display: 'flex', flex: 1, p: 1, overflowY: 'auto' }}>
														<DiagnosisVersion
															ehr={ehr}
															isLoading={isDocumentLoading}
															encounter={document.encounter}
															versions={document.versions}
														/>
													</Box>
												</>
											) : (
												<Box sx={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
													<Typography variant="h5">There are no saved versions</Typography>
												</Box>
											)
										) : (
											isDocumentLoading && <Loading />
										)}
									</Box>
								) : null}
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</PageContainer>
	);
}

export default ReadOnlyDictationPage;
