import * as React from 'react';
import { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { Box, Button, IconButton, Typography } from '@mui/material';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { trackError, trackEvent } from '../../application/app-insights';
import { IAudioPlayer, IAudioPlayerProps } from './audioPlayer.interfaces';

interface IProgress {
	played: number;
	playedSeconds: number;
	loaded: number;
	loadedSeconds: number;
}

function SeekIcon({ type, onClick }: { type: 'back' | 'forward'; onClick: () => void }) {
	return (
		<IconButton
			aria-label="back"
			sx={{ transform: type === 'forward' ? 'rotate(180deg) scale(1, -1)' : '', mt: '0.2rem' }}
			onClick={onClick}
		>
			<SettingsBackupRestoreIcon sx={{ fontSize: '1.75rem' }} />
		</IconButton>
	);
}

const BrowserAudioPlayer: React.ForwardRefRenderFunction<IAudioPlayer, IAudioPlayerProps> = function AudioPlayerFunc(
	{ url, onProgress }: IAudioPlayerProps,
	forwardedRef: ForwardedRef<IAudioPlayer>
) {
	const player = useRef<ReactPlayer>(null);

	const [reloadState, setReloadState] = useState<{ reloaded: boolean; startTime: Date } | null>(null);
	const [audioUrl, setAudioUrl] = useState<string>(url);
	const [setbackTime, setSetbackTime] = useState<number | null>(null);

	const [seeking] = useState<boolean>(false);
	const [progress, setProgress] = useState<IProgress | null>(null);
	const [nonZeroProgress, setNonZeroProgress] = useState<IProgress | null>(null);
	const [playing, setPlaying] = useState<boolean>(false);
	const [playbackRate, setPlaybackRate] = useState<number>(1);

	const [reloadRetryCountdown, setReloadRetryCountdown] = useState<number>(10);

	const reloadAudio = () => {
		trackEvent('2101003', 'ReloadAudio');

		if (player.current) {
			const nativePlayerRef = player.current.getInternalPlayer();
			setSetbackTime(nativePlayerRef.currentTime);

			setReloadRetryCountdown(10);
			setPlaying(false);
			setReloadState({ startTime: new Date(), reloaded: false });

			const newUrl =
				url.lastIndexOf('?') > 0 ? `${url}&key=${new Date().getTime()}` : `${url}?key=${new Date().getTime()}`;
			setAudioUrl(newUrl);
		}
	};

	useEffect(() => {
		const intervalId = setInterval(() => {
			if (reloadState?.reloaded === false) {
				if (reloadRetryCountdown > 0) {
					setReloadRetryCountdown((t) => t - 1);
				} else {
					reloadAudio();
				}
			}
		}, 1000);

		return () => clearInterval(intervalId);
	}, [reloadState, reloadRetryCountdown]);

	useEffect(() => {
		if (player.current?.getInternalPlayer()) {
			const internalPlayer = player.current.getInternalPlayer();
			internalPlayer.addEventListener('error', (e: { currentTarget: { error: { [key: string]: string } } }) =>
				trackError('2101002', 'Audio Player error', {
					error: `Media error occurred with code: ${e?.currentTarget?.error?.code || 'unknown'}`,
				})
			);
		}
	}, [player.current]);

	useEffect(() => {
		setAudioUrl(url);
	}, [url]);

	useEffect(() => {
		if (reloadState?.reloaded) {
			if (setbackTime && player.current) {
				player.current.seekTo(setbackTime || nonZeroProgress?.playedSeconds || 0, 'seconds');
			}
			setPlaying(true);
		}
	}, [reloadState]);

	useEffect(() => {
		if (playing) {
			setReloadState(null);
			setSetbackTime(null);
		}
	}, [playing]);

	const onLoad = () => {
		if (reloadState?.reloaded === false) {
			setReloadState((state) => (state ? { ...state, reloaded: true } : state));
		}
	};

	const onSeek = (seconds: number) => {
		if (player.current) {
			let seek: number = player.current.getCurrentTime() + seconds;

			const duration = player.current.getDuration();

			if (seek > duration) {
				seek = duration - 0.1;
			} else if (seek < 0) {
				seek = 0;
			}

			player.current.seekTo(seek, 'seconds');
		}
	};

	const getTime = () => progress?.playedSeconds || 0;
	const setTime = (timeStamp: number) => {
		if (player.current) {
			player.current.seekTo(timeStamp, 'seconds');
		}
	};
	const toggleAudio = () => {
		setPlaying(!playing);
	};
	const jumpBack = () => {
		onSeek(-5);
	};
	const increasePlaybackSpeed = () => {
		if (playbackRate < 2) {
			setPlaybackRate(playbackRate + 0.25);
		}
	};
	const decreasePlaybackSpeed = () => {
		if (playbackRate > 0.25) {
			setPlaybackRate(playbackRate - 0.25);
		}
	};

	useImperativeHandle(forwardedRef, () => ({
		getTime() {
			return getTime();
		},
		setTime(timeStamp) {
			setTime(timeStamp);
		},
		toggleAudio() {
			toggleAudio();
		},
		jumpBack() {
			jumpBack();
		},
		increasePlaybackSpeed() {
			increasePlaybackSpeed();
		},
		decreasePlaybackSpeed() {
			decreasePlaybackSpeed();
		},
	}));

	const handleProgress = (progressState: IProgress) => {
		// We only want to update time slider if we are not currently seeking
		if (!seeking) {
			if (onProgress) {
				onProgress(progressState.playedSeconds || 0);
			}
			if (progressState.playedSeconds) {
				setNonZeroProgress(progressState);
			}
			setProgress(progressState);
		}
	};

	const handlePlaybackRateChange = (speed: number) => {
		setPlaybackRate(speed);
	};

	const isLoading = reloadState?.reloaded === false;

	return (
		<Box>
			<Box sx={{ height: '3rem' }}>
				<ReactPlayer
					ref={player}
					key={audioUrl}
					url={audioUrl}
					controls={!isLoading}
					playing={playing}
					playbackRate={playbackRate}
					muted={false}
					width="100%"
					height="100%"
					config={{ file: { attributes: { controlsList: 'nodownload', preload: 'auto' } } }}
					onProgress={handleProgress}
					onReady={onLoad}
					onPlaybackRateChange={handlePlaybackRateChange}
					onPlay={() => setPlaying(true)}
					onPause={() => setPlaying(false)}
					onError={(error, data, hlsInstance, hlsGlobal) =>
						trackError('2101001', 'Audio Player error', {
							error: error ? JSON.stringify(error, Object.getOwnPropertyNames(error)) : '',
							data,
							hlsInstance,
							hlsGlobal,
							duration: player.current?.getDuration(),
							secondsLoaded: player.current?.getSecondsLoaded(),
						})
					}
				/>
			</Box>
			<Box sx={{ display: 'flex' }}>
				<Box sx={{ flex: 1 }} />
				<Box sx={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
					<SeekIcon type="back" onClick={() => onSeek(-5)} />
					<SeekIcon type="forward" onClick={() => onSeek(5)} />
				</Box>
				<Box sx={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'flex-end' }}>
					<Button variant="text" onClick={reloadAudio} disabled={isLoading}>
						<Typography textTransform="none" fontSize="0.75rem" color="#AA4A44">
							{isLoading ? 'Loading...' : 'Reload Audio'}
						</Typography>
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

export default forwardRef(BrowserAudioPlayer);
