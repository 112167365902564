import { IApiResponse, mapData, mapHttpResponse, patchRequestV2, postRequest } from '../api-service';
import { endpoints } from '../endpoints';
import {
	IPatientChartSearchQuery,
	IPatientChartSearchResponse,
	IPatientSearchQuery,
	IPatientSearchResponse,
} from './patients.api.models';
import { IServiceResult } from '../../models/service.models';
import { IPatientChartDocuments, IPatientsSearchResult } from '../../models/patients/patients.models';

export async function patientSearch(
	query: IPatientSearchQuery,
	metadata?: { providerId: string }
): Promise<IServiceResult<IPatientsSearchResult[]>> {
	const body = {
		PatientID: query.patientID,
		FirstName: query.firstName,
		LastName: query.lastName,
		PatientName: query.patientName,
		DOB: query.DOB,
		Gender: query.gender,
		SSN: query.SSN,
		PageSize: query.pageSize,
		PageStart: query.pageStart,
		SearchCriteria: query.searchCriteria,
		MedicalRecordNumber: query.medicalRecordNumber,
	};

	const headers = metadata?.providerId ? { ProviderUserInfoID: metadata.providerId } : {};
	const endpoint = endpoints.patients.search;
	const result = await postRequest<IPatientSearchResponse>(endpoint, body, headers);

	return mapData<IPatientSearchResponse, IPatientsSearchResult[]>(result, (res) =>
		res.Patients.map((x) => {
			return {
				firstName: x.FirstName,
				lastName: x.LastName,
				middleName: x.MiddleName,
				dateOfBirth: x.DateOfBirth,
				patientID: x.PatientID,
				ssn: x.SSN,
			};
		})
	);
}

export async function patientChartSearch(
	query: IPatientChartSearchQuery,
	metadata?: { providerId: string }
): Promise<IServiceResult<IPatientChartDocuments>> {
	const body = {
		PatientID: query.PatientID,
		Filters: query.Filters,
	};

	const headers = metadata?.providerId ? { ProviderUserInfoID: metadata.providerId } : {};
	const endpoint = endpoints.patients.chart;
	const result = await postRequest<IPatientChartSearchResponse>(endpoint, body, headers);

	return mapData<IPatientChartSearchResponse, IPatientChartDocuments>(result, (res) => {
		return {
			demographic: res.PatientChart?.Demographic
				? {
						genderText: res.PatientChart.Demographic.GenderText,
						firstName: res.PatientChart.Demographic.FirstName,
						middleName: res.PatientChart.Demographic.MiddleName,
						lastName: res.PatientChart.Demographic.LastName,
						dateOfBirth: res.PatientChart.Demographic.DateOfBirth,
						patientID: res.PatientChart.Demographic.PatientID,
						medicalRecordNumber: res.PatientChart.Demographic.MedicalRecordNumber,
				  }
				: null,
			documents: res.PatientChart?.IscribeDocuments
				? res.PatientChart.IscribeDocuments.map((x) => ({
						createDateTime: x.CreateDateTime,
						createByFullName: x.CreateByFullName,
						documentID: x.DocumentID,
						documentStatus: x.DocumentStatus,
						documentTypeName: x.DocumentTypeName,
						lastModifiedDateTime: x.LastModifiedDateTime,
						patientID: x.PatientID,
						title: x.Title,
						iScribeDocumentStatus: x.IScribeDocumentStatus,
						renderingProviderFullName: x.RenderingProvider?.FullName,
						providerId: x.RenderingProvider?.CareProviderID,
						sequenceNumber: x.SequenceNumber,
				  }))
				: null,
		};
	});
}
export async function updateCustomPatientDemographics(request: {
	BlobID: string;
	FirstName: string;
	LastName: string;
	MRN: string;
	DateOfBirth: string;
	Gender: string;
}): Promise<IServiceResult<void>> {
	const body = {
		...request,
	};

	const endpoint = endpoints.patients.updateDemographics;
	const result = await patchRequestV2<IApiResponse>(endpoint, body);

	return mapHttpResponse(result);
}
