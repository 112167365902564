import * as React from 'react';
import { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import TextMessage, { ITextMessage, ITextMessageData } from './timestamped-transcription/TextMessage';

export interface ITranscriptProps {
	transcript: ITextMessageData[];
	handleMessageClick: (timeStamp: number) => void;
}

export interface ITranscriptChat {
	seek: (timestamp: number) => void;
}

const TimestampedTranscription: React.ForwardRefRenderFunction<ITranscriptChat, ITranscriptProps> =
	function TimestampedTranscriptionFunc(
		{ transcript, handleMessageClick }: ITranscriptProps,
		forwardedRef: ForwardedRef<ITranscriptChat>
	) {
		const messagesRefs = useRef<ITextMessage[]>([]);

		const [messages] = useState<{ message: ITextMessageData; index: number }[]>(
			transcript.map((message, index) => ({
				index,
				message,
			}))
		);

		useImperativeHandle(forwardedRef, () => ({
			seek(timestamp: number) {
				const currentMessageIndex = messages.findIndex((x) => x.message.timestamp >= timestamp);

				let refMessage: { message: ITextMessageData; index: number } | undefined;

				if (currentMessageIndex === 0) {
					[refMessage] = messages;
				} else if (currentMessageIndex === -1) {
					refMessage = messages[messages.length - 1];
				} else {
					refMessage = messages[currentMessageIndex - 1];
				}

				// const refMessage = currentMessageIndex > 0 ? messages[currentMessageIndex - 1] : messages[currentMessageIndex];
				if (refMessage) {
					const messageRef: ITextMessage = messagesRefs.current[refMessage.index];
					if (messageRef) {
						messageRef.scrollMessageIntoView();
					}
				}
			},
		}));

		useEffect(() => {
			messagesRefs.current = messagesRefs.current.slice(0, messages.length);
		}, []);

		return (
			<div>
				{messages.map((x, index) => (
					<TextMessage
						key={x.index}
						ref={(el) => {
							if (el) {
								messagesRefs.current[index] = el;
							}
						}}
						active={false}
						message={{
							timestamp: x.message.timestamp,
							text: x.message.text,
							speaker: x.message.speaker,
						}}
						handleMessageClick={handleMessageClick}
					/>
				))}
			</div>
		);
	};

export default forwardRef(TimestampedTranscription);
