import { Box, Button, List, ListItem, Paper, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { orderHash } from '../../../core/services/documents/documents.services';
import AddOrderIcon from './add_order_semibold.png';
import { IInterpretationField, IOrderField } from '../../../core/models/dictations/dictations.models';
import OrderItem from './OrderItem';
import { namespaces } from '../../../application/i18n.constants';

function OrderList({
	orders,
	onDelete,
	onAddDiagnose,
	isEditOrderInterpretationEnabled,
	onInterpretationChange,
}: {
	orders: IOrderField[];
	onDelete: (oHash: string) => void;
	onAddDiagnose: (oHash: string) => void;
	isEditOrderInterpretationEnabled: boolean;
	onInterpretationChange: (oHash: string, interpretation: IInterpretationField) => void;
}) {
	const { t } = useTranslation(namespaces.components.diagnosis);
	return (
		<Box sx={{ width: '100%' }}>
			<List sx={{ width: '100%' }}>
				{orders.map((x) => (
					<ListItem key={`${orderHash(x)}-${x.description.toLowerCase()}`} sx={{ pl: 0 }}>
						<Paper sx={{ flex: 1, flexDirection: 'column', p: 2 }}>
							<Button onClick={() => onAddDiagnose(orderHash(x))}>
								<img alt="" src={AddOrderIcon} width="20" height="20" />
								<Typography sx={{ pl: 1, textTransform: 'none' }} variant="subtitle1">
									{t('selectDiagnosis')}
								</Typography>
							</Button>
							<Box sx={{ pl: 1 }}>
								<OrderItem
									interpretation={x.interpretation}
									description={x.description}
									canDeleteOrder
									onDelete={() => onDelete(orderHash(x))}
									isEditOrderInterpretationEnabled={isEditOrderInterpretationEnabled}
									onInterpretationChange={(interpretation) => onInterpretationChange(orderHash(x), interpretation)}
								/>
							</Box>
						</Paper>
					</ListItem>
				))}
			</List>
		</Box>
	);
}

export default OrderList;
