import { createAction } from '@reduxjs/toolkit';
import { IPatientsAdvancedSearchFilters, IPatientsSearchResult } from '../../models/patients/patients.models';
import { patientsConstants } from './patients.constants';
// Patient search

const patientSearchAction = createAction<IPatientsAdvancedSearchFilters>(patientsConstants.PATIENT_SEARCH_REQUEST);

const patientSearchIsLoadingAction = createAction<boolean>(patientsConstants.PATIENT_SEARCH_IS_LOADING);

interface ISetPatientSearchResultActionPayload {
	patients: IPatientsSearchResult[];
}

const setPatientSearchResultAction = createAction<ISetPatientSearchResultActionPayload>(
	patientsConstants.PATIENT_SET_RESULTS
);

export const patientsActions = {
	patientSearchAction,
	patientSearchIsLoadingAction,
	setPatientSearchResultAction,
};
