import { put } from 'redux-saga/effects';
import { actions, IAction } from '../actions';
import { handleGlobalException } from '../../../application/exception-handlers';
import { IInitUserActionPayload } from './user.actions';
import { setAppInsightsUser } from '../../../application/app-insights';
import { identifyLogRocketUser } from '../../../application/log-rocket';

export function* initUserSaga(action: IAction<IInitUserActionPayload>) {
	try {
		const { userSettings } = action.payload;
		yield put(actions.user.setUserSettingsAction(userSettings));

		setAppInsightsUser({ username: userSettings.username });
		identifyLogRocketUser({ username: userSettings.username });
	} catch (e) {
		handleGlobalException(e);
	}
}
