import { createReducer } from '@reduxjs/toolkit';
import { ISetDictationDataActionPayload, jobsActions } from './jobs.actions';
import { IJobDictation } from '../../models/dictations/dictations.models';
import { IUserDisplayName } from '../../models/users/user.models';

export interface IJobsState {
	isCurrentDictationLoading: boolean;
	currentDictation: ISetDictationDataActionPayload | null;

	jobs: IJobDictation[];
	searchIsLoading: boolean;

	getUsersIsLoading: boolean;
	users: IUserDisplayName[];
}

const initialState: IJobsState = {
	isCurrentDictationLoading: false,
	currentDictation: null,

	jobs: [],
	searchIsLoading: false,

	getUsersIsLoading: false,
	users: [],
};

const jobsReducer = createReducer<IJobsState>(initialState, (builder) => {
	builder
		.addCase(jobsActions.setDictationData, (state, action) => {
			state.currentDictation = action.payload;
		})
		.addCase(jobsActions.setDictationDataIsLoading, (state, action) => {
			state.isCurrentDictationLoading = action.payload;
		})
		.addCase(jobsActions.jobsSearchIsLoadingAction, (state, action) => {
			state.searchIsLoading = action.payload;
		})
		.addCase(jobsActions.setJobsSearchResultAction, (state, action) => {
			state.jobs = action.payload.jobs;
		})
		.addCase(jobsActions.getUsersIsLoadingAction, (state, action) => {
			state.getUsersIsLoading = action.payload;
		})
		.addCase(jobsActions.setUsersResultAction, (state, action) => {
			state.users = action.payload.users;
		})

		.addCase(jobsActions.setAIPrompt, (state, action) => {
			if (state.currentDictation) {
				state.currentDictation.transcription.prompt = action.payload;
			}
		})

		.addCase(jobsActions.setAIInstructions, (state, action) => {
			if (state.currentDictation) {
				state.currentDictation.transcription.instructions = action.payload;
			}
		})

		.addCase(jobsActions.setLastTimePromptSubmitted, (state, action) => {
			if (state.currentDictation) {
				state.currentDictation.transcription.lastTimePromptSubmitted = action.payload;
			}
		})

		.addCase(jobsActions.setPromptAISummary, (state, action) => {
			if (state.currentDictation) {
				state.currentDictation.transcription.customOpenAISummary = action.payload.openAISummary;
				state.currentDictation.transcription.customOpenAISummaryStructured = action.payload.openAISummaryStructured;
			}
		});
});

export default jobsReducer;
