import * as React from 'react';
import { Box, Chip } from '@mui/material';

interface IItem {
	id: string;
	title: string;
}

interface IChipListProps<T> {
	items: Array<T & IItem>;
	onDelete?: (id: string) => void;
}

function ChipList<T>({ items, onDelete }: IChipListProps<T>) {
	return (
		<Box>
			{items.map((x: IItem) => {
				return (
					<Chip
						sx={{
							m: 0.5,
						}}
						color="secondary"
						key={x.id}
						label={x.title}
						onDelete={onDelete ? () => onDelete(x.id) : undefined}
					/>
				);
			})}
		</Box>
	);
}

ChipList.defaultProps = {
	onDelete: undefined,
};

export default ChipList;
