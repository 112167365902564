import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Howl } from 'howler';
import { Pause, PlayArrow, VolumeDown, VolumeUp } from '@mui/icons-material';
import { Box, IconButton, Slider, Typography } from '@mui/material';
import { IAudioPlayer, IAudioPlayerProps } from './audioPlayer.interfaces';
import NumericInput from '../numeric-input/NumericInput';
import SeekIcon from './SeekIcon';
import { trackError } from '../../application/app-insights';

interface IHowlerAudioPlayerProps extends IAudioPlayerProps {
	html5?: boolean;
}

const HowlerAudioPlayer: React.ForwardRefRenderFunction<IAudioPlayer, IHowlerAudioPlayerProps> =
	function HowlerAudioPlayerFunc(
		{ url, onProgress, html5 }: IHowlerAudioPlayerProps,
		forwardedRef: ForwardedRef<IAudioPlayer>
	) {
		const minRate = 0.5;
		const maxRate = 2.5;
		const step = 0.25;

		const [isPlaying, setIsPlaying] = useState<boolean>(false);
		const [duration, setDuration] = useState<number>(0);
		const [volume, setVolume] = useState<number>(1);
		const [rate, setRate] = useState<number>(1);
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const [position, setPosition] = useState<number>(0);
		const [sliding, setSliding] = useState<boolean>(false);
		const [slidingPosition, setSlidingPosition] = useState<number>(0);

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const [sound, setSound] = useState<null | any>(null);

		// Howler abstractions

		// const hasEnded = (): boolean => !!sound && (!sound.playing() || sound.seek() >= sound.duration());

		const getTime = () => (sound ? sound.seek() : 0);
		const setTime = (timeStamp: number) => {
			if (sound) {
				sound.seek(timeStamp);
			}
		};

		const onPlay = () => {
			if (sound) {
				sound.play();
			}
		};

		const onPause = () => {
			if (sound) {
				sound.pause();
			}
		};

		// Howler event handlers

		const onLoad = () => {
			setDuration(sound.duration());
			setRate(1);
			setPosition(0);
			setSlidingPosition(0);
		};

		const onPositionUpdated = (timestamp: number) => {
			setPosition(timestamp);
			setSlidingPosition(timestamp);
		};

		const onSeek = (value: number) => {
			if (!duration) {
				return;
			}
			const newValue = duration - 0.1 < value ? duration - 0.1 : value;

			setTime(newValue);
			setPosition(newValue);
		};

		useEffect(() => {
			if (sound) {
				setIsPlaying(false);
				sound.on('load', () => onLoad());
				sound.on('play', () => setIsPlaying(true));
				sound.on('pause', () => setIsPlaying(false));
				sound.on('seek', () => onPositionUpdated(getTime()));
				sound.on('loaderror', (error: unknown) =>
					trackError('2201001', 'Audio Player error', {
						error: `Loading error occurred: ${JSON.stringify(error) || 'no data'}`,
					})
				);
				sound.on('playerror', (error: unknown) =>
					trackError('2201002', 'Audio Player error', {
						error: `Playing error occurred: ${JSON.stringify(error) || 'no data'}`,
					})
				);
				sound.on('end', () => {
					setIsPlaying(false);
					onPositionUpdated(sound.duration());
				});
			}
			return () => {
				if (sound) {
					sound.unload();
				}
			};
		}, [sound]);

		useEffect(() => {
			if (sound) {
				sound.rate(rate);
			}
		}, [rate, sound]);

		// Player methods

		const onJump = (seconds: number) => {
			let seek: number = getTime() + seconds;

			if (seek > duration) {
				seek = duration;
			} else if (seek < 0) {
				seek = 0;
			}

			onSeek(seek);
		};

		const toggleAudio = () => {
			if (isPlaying) {
				onPause();
			} else {
				onPlay();
			}
		};

		useImperativeHandle(forwardedRef, () => ({
			getTime() {
				return getTime();
			},
			setTime(timestamp: number) {
				setTime(timestamp);
			},
			toggleAudio() {
				toggleAudio();
			},
			jumpBack() {
				onJump(-5);
			},
			increasePlaybackSpeed() {
				if (rate < maxRate) {
					setRate((r) => r + step);
				}
			},
			decreasePlaybackSpeed() {
				if (rate > minRate) {
					setRate((r) => r - step);
				}
			},
		}));

		// Init player

		useEffect(() => {
			const xhr = new XMLHttpRequest();
			xhr.open('GET', url, true);
			xhr.responseType = 'blob';
			xhr.onload = function OnLoadFunc() {
				const audioUrl = URL.createObjectURL(xhr.response);
				setSound(
					new Howl({
						src: [audioUrl],
						format: 'wav',
						html5: html5 !== false,
						xhr: {
							retry: 5,
							timeout: 60,
						},
					})
				);
			};

			xhr.send();
		}, [url]);

		// Update progress

		useEffect(() => {
			const intervalId = setInterval(() => {
				if (isPlaying && !sliding) {
					onPositionUpdated(getTime());
				}
			}, 250);

			return () => clearInterval(intervalId);
		}, [isPlaying, sliding]);

		useEffect(() => {
			// Update position every 100ms while audio is playing
			let intervalId: NodeJS.Timer;
			if (isPlaying && !sliding && onProgress) {
				intervalId = setInterval(() => {
					onProgress(getTime());
				}, 1000);
			}

			return () => clearInterval(intervalId);
		}, [isPlaying, sliding, onProgress]);

		// UI handlers
		const handleSlideChangeCommitted = (value: number) => {
			onSeek(value);
			setSliding(false);
		};

		const handleVolumeChange = (event: Event, newValue: number) => {
			setVolume(newValue);
			sound.volume(newValue);
		};

		const handleRateChange = (newValue: number) => {
			setRate(newValue);
		};

		// UI
		const formatPosition = (timestamp: number) => {
			const minutes = Math.floor(timestamp / 60);
			const seconds = Math.floor(timestamp % 60);

			return `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
		};

		const isLoading = !duration;

		return (
			<Box sx={{ display: 'flex', flexDirection: 'column', px: 2 }}>
				<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
					<Typography noWrap overflow="visible" fontSize="0.90rem">{`${formatPosition(slidingPosition)}`}</Typography>
					<Slider
						disabled={isLoading}
						sx={{
							'& .MuiSlider-thumb': {
								width: 10,
								height: 10,
								borderRadius: 5,
							},
							mr: 2,
							ml: 2,
						}}
						aria-labelledby="audio-seek"
						value={slidingPosition}
						step={0.01}
						max={duration}
						onChange={(e, value) => setSlidingPosition(value as number)}
						onChangeCommitted={(e, value) => handleSlideChangeCommitted(value as number)}
						onMouseUp={() => setSliding(false)}
						onMouseDown={() => setSliding(true)}
					/>
					<Typography noWrap overflow="visible" fontSize="0.90rem">{`${formatPosition(duration)}`}</Typography>
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between',
						minHeight: 40,
					}}
				>
					<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: 100 }}>
						<VolumeDown fontSize="small" />
						<Slider
							sx={{
								'& .MuiSlider-thumb': {
									width: 5,
									height: 5,
									borderRadius: 2.5,
								},
								mx: 1,
							}}
							value={volume}
							min={0}
							max={1}
							step={0.1}
							onChange={(e, value) => handleVolumeChange(e, value as number)}
							aria-labelledby="audio-volume"
							size="small"
						/>
						<VolumeUp fontSize="small" />
					</Box>
					<Box sx={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
						{!isLoading ? (
							<>
								<SeekIcon type="back" onClick={() => onJump(-5)} />
								<IconButton onClick={isPlaying ? onPause : onPlay}>{isPlaying ? <Pause /> : <PlayArrow />}</IconButton>
								<SeekIcon type="forward" onClick={() => onJump(5)} />
							</>
						) : (
							<Typography fontSize="0.85rem"> Loading audio...</Typography>
						)}
					</Box>
					<Box sx={{ minWidth: 70 }}>
						<NumericInput
							step={step}
							value={rate}
							minValue={minRate}
							maxValue={maxRate}
							format={(x) => `${x.toFixed(2)}X`}
							onChange={(x) => handleRateChange(x)}
						/>
					</Box>
				</Box>
			</Box>
		);
	};

export default forwardRef(HowlerAudioPlayer);
