import { Box, Button, Checkbox, FormControlLabel, FormGroup, List, ListItem, Paper, Typography } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IDiagnoseField } from '../../core/models/dictations/dictations.models';
import Dialog from '../dialog/Dialog';
import {
	addOrderToDiagnoseArray,
	deleteDiagnoseOrderFromArray,
	diagHash,
	mergeDiagnoses,
	orderHash,
} from '../../core/services/documents/documents.services';
import { namespaces } from '../../application/i18n.constants';

interface IPullAssessmentPlanDialogProps {
	open: boolean;
	onClose: () => void;
	currentDiagnoses: IDiagnoseField[];
	pulledDiagnoses: IDiagnoseField[] | null;
	onMergeDiagnoses: (diagnoses: IDiagnoseField[]) => void;
}

function PullAssessmentPlanDialog({
	open,
	onClose,
	currentDiagnoses,
	pulledDiagnoses,
	onMergeDiagnoses,
}: IPullAssessmentPlanDialogProps) {
	const { t } = useTranslation(namespaces.components.pullAssessmentPlanDialog);

	const [result, setResult] = useState<IDiagnoseField[]>([]);

	const pulled: IDiagnoseField[] = pulledDiagnoses || [];

	useEffect(() => {
		setResult(mergeDiagnoses(currentDiagnoses, pulled, true));
	}, [currentDiagnoses, pulledDiagnoses]);

	const orderAlreadyExists = (diagList: IDiagnoseField[], dHash: string, oHash: string): boolean => {
		const diag = diagList.filter((x) => x.modified !== 2).find((x) => diagHash(x) === dHash);

		if (!diag) {
			return false;
		}

		const order = diag.orders.filter((x) => x.modified !== 2).find((x) => orderHash(x) === oHash);

		return !!order;
	};

	const onMerge = ({ includeOrders }: { includeOrders?: boolean }) => {
		if (includeOrders) {
			onMergeDiagnoses(result);
		} else {
			const res = mergeDiagnoses(currentDiagnoses, pulled, false);
			onMergeDiagnoses(res);
		}
	};

	const notAddedDiagOrders = (diag: IDiagnoseField) =>
		diag.orders.filter((order) => !orderAlreadyExists(currentDiagnoses, diagHash(diag), orderHash(order)));

	const ordersToPull = pulled.map((x) => notAddedDiagOrders(x)).flat(1);

	return (
		<Dialog
			title={t('pullForwardDiagnosesTitle')}
			fullWidth
			maxWidth="sm"
			onClose={onClose}
			onCloseTitle="cancel"
			open={open}
		>
			<Paper sx={{ overflow: 'auto' }}>
				{pulled.length === 0 ? (
					<Typography sx={{ p: 2 }} fontWeight="bold" align="center">
						{t('noDiagnosesToPull')}
					</Typography>
				) : (
					<List>
						{pulled.map((diag) => (
							<ListItem key={diagHash(diag)} component="div">
								<Box flexDirection="column">
									<Typography fontWeight="bold">{diag.caption}</Typography>
									{diag.orders && notAddedDiagOrders(diag).length > 0 ? (
										<List>
											{notAddedDiagOrders(diag).map((order) => (
												<ListItem key={orderHash(order)}>
													<FormGroup>
														<FormControlLabel
															control={
																<Checkbox
																	checked={orderAlreadyExists(result, diagHash(diag), orderHash(order))}
																	onChange={(event) => {
																		if (event.target.checked) {
																			setResult((x) => addOrderToDiagnoseArray(x, diagHash(diag), order));
																		} else {
																			setResult((x) =>
																				deleteDiagnoseOrderFromArray(x, diagHash(diag), orderHash(order))
																			);
																		}
																	}}
																/>
															}
															label={order.description}
														/>
													</FormGroup>
												</ListItem>
											))}
										</List>
									) : (
										<ListItem>
											<Typography>{t('noOrdersToPull')}</Typography>
										</ListItem>
									)}
								</Box>
							</ListItem>
						))}
					</List>
				)}
			</Paper>
			{result.length > 0 ? (
				<>
					{pulled.length > 0 ? (
						<Button variant="contained" sx={{ m: 0.5 }} onClick={() => onMerge({ includeOrders: true })}>
							{t('pullForwardDiagnoses')}
						</Button>
					) : null}
					{ordersToPull.length > 0 ? (
						<Button variant="contained" sx={{ m: 0.5 }} onClick={() => onMerge({ includeOrders: false })}>
							{t('pullForwardWithoutOrders')}
						</Button>
					) : null}
				</>
			) : null}
			<Button variant="contained" sx={{ m: 0.5 }} onClick={onClose}>
				{t('cancel')}
			</Button>
		</Dialog>
	);
}

export default PullAssessmentPlanDialog;
