import { AnyObject } from '../../../shared/types';

export interface IDocProcedureDocumentationData {
	procedureTemplateID: string;
	proceduretemplatename: string;
	proceduredocumentationtext: string;
	TemplateData: string;
	Modified: number;
}

export interface IDocumentTemplateData {
	TemplateType: string;
	TemplateData: Array<{
		sentences: Array<{
			findings: Array<{ selected: boolean | string }>;
		}>;
	}>;
}

export interface IOrderData {
	Procedure?: {
		ProcedureDescription: string;
		ProcedureLexicalCode: string;
		Selected: boolean;
	};
	Selected: boolean;
	LastModifiedDate: string;
	Status: 'REVIEW';
	CreatedBy: string;
	Description: string;
	Interpretation?: {
		Note: string | null;
		InterpretationID: string;
		OrderID: string;
		DiscussedWithPatient: boolean;
	};
	OrderID: string;
	OrderedBy: string;
	OrderedDate: string;
	Class: {
		ClassDescription: 'order';
		Class: 'ORDER';
	};
	LastModifiedBy: string;
	OrderTypeID: number;
	OrderTypeGroup: string;
	AssignedTo: string;
	Modified: number;
	OrderItemID: string | null;
}

export enum DiagnoseLaterality {
	left = 'LEFT',
	right = 'RIGHT',
	bilateral = 'BILATERAL',
}

export interface IDiagnoseData {
	Problem: boolean;
	CanPostCoordinate: boolean;
	ProblemStatus: number;
	Note: string;
	ICDCodes: Array<{
		Description: string;
		Code: string;
		Codeset: string;
	}>;
	DiagnosisID: string | null;
	SNOMEDCode: string;
	SNOMEDName: string | null;
	AddToProblemList: boolean;
	Caption: string;
	LexicalID: string;
	Ranking: number;
	Laterality?: DiagnoseLaterality;
	Orders: IOrderData[];
	Modified: number;
	Selected: boolean;
}
export interface IDocumentDetails {
	Diagnoses: IDiagnoseData[];
	Instructions: AnyObject[];
	ChiefComplaints: Array<{ ID: number; Selected: boolean; Value: string }>;
	ccSketchPad: string;
	hpiSketchPad: string;
	pfshSketchPad: string | null;
	rosSketchPad: string;
	peSketchPad: string;
	ProcedureDocumentations: IDocProcedureDocumentationData[];
	PatientGoals: string;
	PatientInstructions: string;
	DiscussionNotes: string;
	iopSketchPad: string | null;
	assSketchPad: string | null;
	rosNoteID: string | null;
	rfvNoteID: string | null;
	peNoteID: string | null;
	apNoteID: string | null;
	AssessmentPlanSketchPad: string | null;
	planSketchPad: string | null;
	PatientID: number;
	DocumentName: string | null;
	DocumentTypeID: number;
	SiteID: string | null;
	DocumentID: number;
	MiscNoteTitle: string | null;
	MiscNoteCaption: string | null;
	ROS: AnyObject[] | null;
	hpi: AnyObject[] | null;
	PFSH: AnyObject[] | null;
	PESystems: AnyObject[] | null;
	EMCoding: AnyObject[] | null;
	CheckedOut: boolean;
	CheckedOutBy: string | null;
	sequenceNumber: number;
	Medications: AnyObject[] | null;
	HistoricalMedications: AnyObject[] | null;
	Vitals: AnyObject[] | null;
	UndiagnosedOrders: AnyObject[];
	Templates: IDocumentTemplateData[];
	EncounterWellChildRosTemplates: AnyObject[];
	EncounterWellChildRos: AnyObject[];
	Status: {
		ChiefComplaint: boolean;
		Hpi: boolean;
		Ros: boolean;
		Pe: boolean;
		ProcedureDocumentation: boolean;
		AssessmentPlan: boolean;
		Goals: boolean;
		Diagnoses: boolean;
		Interpretations: boolean;
		EhrLogin: string | null;
	};
	IScribeDocumentStatus: string;
	PhysicalExamDocumentFiles: AnyObject[];
	ProcedureDocumentationFiles: AnyObject[];
}

export interface IEncounterDocumentData extends IDocumentDetails {}

export interface ICodedError {
	code: string | 'diagnoseLateralityRequired';
	message: string;
}

export type DocumentErrorType = Record<string, number | string | string[] | number[]>;
export interface IDocumentSaveDataError<T extends DocumentErrorType> extends ICodedError {
	data: T;
}

export type CodedErrorType = ICodedError | IDocumentSaveDataError<DocumentErrorType>;

export interface IPreviewDocument {
	base64: string;
	type: 'pdf';
}

export enum JobStatus {
	Completed = 'Complete',
	NotCompleted = 'Sent',
	Removed = 'Removed',
	Sending = 'Sending',
}

export const JobStatusSectionsList = [
	JobStatus.Completed,
	JobStatus.NotCompleted,
	JobStatus.Removed,
	JobStatus.Sending,
];

export function getJobStatusText(status: JobStatus | '') {
	if (status === JobStatus.NotCompleted) {
		return 'Not Completed';
	}
	return status;
}

export enum DocumentType {
	Visit = 'Visit',
	DictationNote = 'Dictation Note',
	DictationLetter = 'Dictation Letter',
	OperativeNote = 'Operative Note',
	Scribe = 'Scribe',
	NonPatient = 'Non Patient',
}

export const DocumentTypeSectionsList = [
	DocumentType.Visit,
	DocumentType.DictationLetter,
	DocumentType.DictationNote,
	DocumentType.OperativeNote,
	DocumentType.Scribe,
	DocumentType.NonPatient,
];
