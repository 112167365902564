import { createBrowserHistory } from 'history';
import { IPatientsAdvancedSearchFilters } from '../core/models/patients/patients.models';
import { routes, SearchTab } from '../pages/App/routes';
import { setIsAuthenticatedCache } from './local-storage';
import { IJobsSearchFilters } from '../core/models/dictations/dictations.models';

export const navigator = createBrowserHistory();

export const push = (to: string) => {
	navigator.push(to);
};

export const replace = (to: string) => {
	navigator.replace(to);
};

export const forcePushLogin = () => {
	setIsAuthenticatedCache(false);
	navigator.replace(routes.login);
};

export const goBack = () => {
	navigator.back();
};

type SearchFiltersType = IPatientsAdvancedSearchFilters | IJobsSearchFilters;
export const navigateToWorkListTab = ({
	to,
	filters,
}:
	| {
			to: '/search/jobs';
			filters?: IJobsSearchFilters;
	  }
	| {
			to: '/search/patients';
			filters?: IPatientsAdvancedSearchFilters;
	  }) => {
	const currentPath = navigator.location.pathname;
	const isWorkListTab = Object.keys(routes.searchTabs).find((key: string) => {
		const tabKey = key as SearchTab;
		const navigationRoute = routes.searchTabs[tabKey];
		return currentPath === navigationRoute || currentPath === routes.workList;
	});
	const queryString =
		filters &&
		Object.keys(filters)
			.filter(
				(key: string) => !!filters[key as keyof SearchFiltersType] && filters[key as keyof SearchFiltersType] !== ''
			)
			.map((key: string) => `${key}=${filters[key as keyof SearchFiltersType]}`)
			.join('&');

	const query = queryString ? `?${queryString}` : '';

	if (isWorkListTab) {
		replace(`${to}${query}`);
	} else {
		push(`${to}${query}`);
	}
};

export const navigateToDictation = (blobId: string) => {
	push(`${routes.dictation}?blobId=${blobId}`);
};
