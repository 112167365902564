import { IMacro } from '../../models/macros/macros.models';
import {
	deleteRequestV2,
	getRequest,
	getRequestV2,
	IApiHttpResponse,
	IApiSimpleHttpResponse,
	mapHttpData,
	mapHttpResponse,
	patchRequestV2,
	postFormRequestV2,
	postRequestV2,
} from '../api-service';
import { endpoints } from '../endpoints';
import { IServiceResult } from '../../models/service.models';
import { ICustomerMacroResponseItem, ICustomerWordReplacementResponseItem } from './customer.api.models';
import { IUserCustomerPermissionsItem } from '../../models/users/user.models';
import { IApiUserCustomerPermissionsItemResponse, IMacroCreateResult } from '../user/user.api.models';
import { IWordReplacement } from '../../models/replacements/replacements.models';

export async function getCustomerMacros(): Promise<IMacro[]> {
	const endpoint = endpoints.customer.macros;
	const result = await getRequest<ICustomerMacroResponseItem[]>(endpoint);

	return result.map((x: ICustomerMacroResponseItem) => {
		return {
			macroId: x.ID,
			description: x.Description,
			shortCode: x.ShortCode,
			section: x.Section,
			macro: x.Macro,
			spokenForm: x.SpokenForm || '',
		};
	});
}

export async function saveCustomerMacro(request: {
	shortCode: string;
	section: string;
	description: string;
	macro: string;
	spokenForm: string;
}): Promise<IServiceResult<number>> {
	const body = {
		Section: request.section,
		ShortCode: request.shortCode,

		Description: request.description,
		Macro: request.macro,
		SpokenForm: request.spokenForm,
	};
	const endpoint = endpoints.customer.macro;
	const response: IApiHttpResponse<number> = await postRequestV2<number>(endpoint, body);
	return mapHttpData<number, number>(response, (r) => r);
}

export async function updateCustomerMacro(request: {
	macroId: number;
	macro: string;
	section: string;
	spokenForm: string;
}): Promise<IServiceResult<void>> {
	const body = {
		ID: request.macroId,
		Section: request.section,
		Macro: request.macro,
		SpokenForm: request.spokenForm,
	};
	const endpoint = endpoints.customer.macro;
	const response: IApiSimpleHttpResponse = await patchRequestV2(endpoint, body);
	return mapHttpResponse(response);
}

export async function deleteCustomerMacro(request: { macroId: number }): Promise<IServiceResult<void>> {
	const body = {
		customerMacroID: request.macroId,
	};
	const endpoint = endpoints.customer.macro;
	const result: IApiSimpleHttpResponse = await deleteRequestV2(endpoint, body);
	return mapHttpResponse(result);
}

export async function importCustomerMacros(request: {
	customerId: string;
	file: File;
}): Promise<IServiceResult<IMacroCreateResult>> {
	const body = new FormData();
	body.append('file', request.file);
	body.append('customerId', request.customerId);
	const endpoint = endpoints.customer.importMacros;
	const result = await postFormRequestV2<IMacroCreateResult>(endpoint, body);
	return mapHttpData(result, (r) => ({
		Success: r.Success,
		MacrosCount: r.MacrosCount,
		MacrosCreated: r.MacrosCreated,
	}));
}

export const getEhrCustomers = async (ehrId?: string): Promise<IServiceResult<IUserCustomerPermissionsItem[]>> => {
	const body = ehrId ? { ehrId } : {};
	const endpoint = endpoints.customer.root;
	const result: IApiHttpResponse<IApiUserCustomerPermissionsItemResponse> =
		await getRequestV2<IApiUserCustomerPermissionsItemResponse>(endpoint, body);

	return mapHttpData<IApiUserCustomerPermissionsItemResponse, IUserCustomerPermissionsItem[]>(result, (r) =>
		r.UserCustomerPermission.map((x) => ({
			customerId: x.CustomerID,
			customerName: x.CustomerName,
			ehrUserId: x.EhrUserID,
			ehrUsername: x.EhrUsername,
			userCustomerProviderPermissions: x.UserCustomerProviderPermissions.map((userCustomerProviderPermission) => ({
				providerId: userCustomerProviderPermission.ProviderID,
				userInfoId: userCustomerProviderPermission.ProviderUserInfoID,
				providerUsername: userCustomerProviderPermission.ProviderUsername,
			})),
		}))
	);
};

// Customer Word Replacements

export async function saveCustomerWordReplacement(request: {
	word: string;
	wordReplacement: string;
	customerId: string;
}): Promise<IServiceResult<number>> {
	const body = {
		OriginalText: request.word,
		ReplacementText: request.wordReplacement,
		CustomerID: request.customerId,
	};
	const endpoint = endpoints.customer.replacement;
	const response: IApiHttpResponse<number> = await postRequestV2<number>(endpoint, body);
	return mapHttpData<number, number>(response, (r) => r);
}

export async function updateCustomerWordReplacement(request: {
	id: string;
	wordReplacement: string;
	word: string;
}): Promise<IServiceResult<void>> {
	const body = {
		ReplacementText: request.wordReplacement,
		ID: request.id,
	};
	const endpoint = endpoints.customer.replacement;
	const response: IApiSimpleHttpResponse = await patchRequestV2(endpoint, body);
	return mapHttpResponse(response);
}

export async function getCustomerWordReplacements({ customerId }: { customerId: string }): Promise<IWordReplacement[]> {
	const endpoint = endpoints.customer.replacements;
	const result = await getRequest<ICustomerWordReplacementResponseItem[]>(endpoint, { customerId });

	return result.map((x: ICustomerWordReplacementResponseItem) => {
		return {
			id: x.ID,
			text: x.OriginalText,
			replacement: x.ReplacementText,
		};
	});
}

export async function deleteCustomerWordReplacement(request: { id: string }): Promise<IServiceResult<void>> {
	const body = {
		customerWorldReplacementId: request.id,
	};
	const endpoint = endpoints.customer.replacement;
	const result: IApiSimpleHttpResponse = await deleteRequestV2(endpoint, body);
	return mapHttpResponse(result);
}
