import { authConstants } from './auth/auth.constants';
import { userConstants } from './user/user.constants';
import { appConstants } from './app/app.constsnts';
import { worklistConstants } from './worklist/worklist.constants';
import { patientsConstants } from './patients/patients.constants';
import { adminConstants } from './admin/admin.constants';
import { jobsConstants } from './jobs/jobs.constants';

export const constants = {
	app: appConstants,
	auth: authConstants,
	user: userConstants,
	worklist: worklistConstants,
	jobs: jobsConstants,
	patients: patientsConstants,
	admin: adminConstants,
};
