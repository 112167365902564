import React, { useEffect, useState } from 'react';
import { Box, Button, LinearProgress, TextField, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import DataTable from '../../components/data-table/DataTable';
import { namespaces } from '../../application/i18n.constants';
import { IPatientsAdvancedSearchFilters, IPatientsSearchResult } from '../../core/models/patients/patients.models';
import { dateFormat } from '../../core/core.constants';
import { getPatientName } from '../../core/services/patients/patients.services';

interface IPatientsSearchPageProps {
	patientsSearchResults: IPatientsSearchResult[];
	advancedSearchFilters: IPatientsAdvancedSearchFilters;
	onSearch: (filters: IPatientsAdvancedSearchFilters) => void;
	loading: boolean;
}

const StyledTextField = styled(TextField)(() => ({
	marginRight: '0.5rem',
}));

function PatientsSearchPage({
	patientsSearchResults,
	advancedSearchFilters,
	loading,
	onSearch,
}: IPatientsSearchPageProps) {
	const { t } = useTranslation(namespaces.pages.patientSearch);

	const [firstName, setFirstName] = useState<string>('');
	const [lastName, setLastName] = useState<string>('');
	const [patientID, setPatientID] = useState<string>('');
	const [DOB, setPatientDOB] = useState<string>('');

	useEffect(() => {
		setFirstName(advancedSearchFilters.firstName || '');
		setLastName(advancedSearchFilters.lastName || '');
		setPatientID(advancedSearchFilters.patientID || '');
		setPatientDOB(advancedSearchFilters.DOB || '');
	}, [advancedSearchFilters]);

	const onFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFirstName(event.target.value);
	};

	const onLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLastName(event.target.value);
	};

	const onPatientIDChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPatientID(event.target.value);
	};

	const onPatientDOBChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPatientDOB(event.target.value);
	};

	const handleAdvancedSearch = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		onSearch({
			firstName,
			lastName,
			patientID,
			DOB,
		});
	};

	const columns = [
		{
			name: t('patient'),
		},
		{
			name: t('patientId'),
		},
		{
			name: t('dateOfBirth'),
		},
	];

	const rows = patientsSearchResults.map((patient) => ({
		data: [
			{
				value: getPatientName(patient.lastName, patient.middleName, patient.firstName),
			},
			{
				value: patient.patientID,
			},
			{
				value: dayjs(patient.dateOfBirth).format(dateFormat),
			},
		],
	}));

	return (
		<Box>
			<Typography align="left" sx={{ py: 2, color: 'primary.main' }} variant="h5">
				{t('title')}
			</Typography>
			<form onSubmit={handleAdvancedSearch}>
				<Box
					sx={{
						display: 'flex',
					}}
				>
					<StyledTextField id="first-name" label={t('firstName')} value={firstName} onChange={onFirstNameChange} />
					<StyledTextField id="last-name" label={t('lastName')} value={lastName} onChange={onLastNameChange} />
					<StyledTextField id="dob" label={t('dateOfBirth')} value={DOB} onChange={onPatientDOBChange} />
					<StyledTextField id="patient-id" label={t('patientId')} value={patientID} onChange={onPatientIDChange} />
					{loading ? (
						<LoadingButton loading variant="contained">
							{t('searchBtnLoading')}
						</LoadingButton>
					) : (
						<Button type="submit" variant="contained">
							{t('searchBtn')}
						</Button>
					)}
				</Box>
			</form>
			<Typography fontWeight="bold" sx={{ fontSize: '1rem', padding: 1.5 }} variant="subtitle1" color="primary">
				{t('searchResults')}
			</Typography>
			{loading ? (
				<LinearProgress />
			) : (
				<DataTable columns={columns} rows={rows} tableContainerProps={{ sx: { height: '50vh', overflowY: 'auto' } }} />
			)}
		</Box>
	);
}

export default PatientsSearchPage;
