export interface IShortCode {
	shortCode: string;
	description: string;
	text: string;
}

export interface IShortCodeSection {
	title: string;
	options: IShortCode[];
}

export interface IAutocompleteProps {
	trigger: string;
	separator: string;
	options: IShortCode[];
	sections?: IShortCodeSection[];
}

export const getAutocompleteOptions = ({
	inputText,
	caret,
	config,
	minLength,
}: {
	inputText: string;
	caret: number;
	config: IAutocompleteProps;
	minLength: number;
}): { shortCodes: IShortCode[]; sections?: IShortCodeSection[]; minimumReached: boolean } => {
	const substring = inputText.slice(0, caret);

	let wordStart: number = Math.max(
		substring.lastIndexOf(config.separator),
		substring.lastIndexOf(`\n`),
		substring.lastIndexOf(`\r`)
	);

	let wordStartOffset: number = config.separator.length;

	if (wordStart === -1) {
		if (substring.startsWith(config.trigger)) {
			wordStart = 0;
			wordStartOffset = 0;
		} else {
			return { shortCodes: [], minimumReached: false };
		}
	}

	const lastWord = substring.slice(wordStart + wordStartOffset, substring.length);

	const minimumReached = lastWord.length >= config.trigger.length + minLength;

	const filterOption = (option: IShortCode) => `${config.trigger}${option.shortCode}`.startsWith(lastWord);

	if (lastWord.slice(0, config.trigger.length) === config.trigger) {
		return {
			shortCodes: config.options.filter((x) => filterOption(x)),
			sections: config.sections
				? config.sections
						.map((sec) => ({ ...sec, options: sec.options.filter((x) => filterOption(x)) }))
						.filter((sec) => sec.options.length > 0)
				: undefined,
			minimumReached,
		};
	}

	return { shortCodes: [], minimumReached };
};

export const getReplacedShortCode = ({
	inputText,
	caret,
	option,
	separator,
}: {
	inputText: string;
	caret: number;
	option: IShortCode;
	separator: string;
}): { text: string; offset: number } => {
	const substring = inputText.slice(0, caret);
	const lastWordStartIndex = Math.max(
		substring.lastIndexOf(separator),
		substring.lastIndexOf(`\n`),
		substring.lastIndexOf(`\r`)
	);

	const prefix = inputText.slice(0, lastWordStartIndex + 1);
	const suffix = inputText.slice(caret, inputText.length);

	const space = ' ';

	const start = `${prefix}${option.text}${suffix.startsWith(space) ? '' : space}`;
	const upToCaret = inputText.slice(0, caret);
	const offset = start.length - upToCaret.length;

	return { text: `${start}${suffix}`, offset };
};
