import * as React from 'react';
import { Paper } from '@mui/material';

function ListContainer({ children }: { children: React.ReactNode }) {
	return (
		<Paper
			sx={{
				overflow: 'auto',
				minHeight: 100,
				px: 2,
			}}
		>
			{children}
		</Paper>
	);
}

export default ListContainer;
