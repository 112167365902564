import * as React from 'react';
import DiagnosesAndOrdersDialog, { IDiagnosesAndOrdersDialogProps } from './DiagnosesAndOrdersDialog';
import { EHRType } from '../../core/models/ehr.models';

interface IEhrDiagnosisLis extends Omit<IDiagnosesAndOrdersDialogProps, 'enabledTabs'> {
	ehr: EHRType;
}

function EhrDiagnosesAndOrdersDialog({ ehr, ...props }: IEhrDiagnosisLis) {
	if (ehr === EHRType.NextGen) {
		return (
			<DiagnosesAndOrdersDialog
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
				enabledTabs={['diagnosis']}
			/>
		);
	}

	if (ehr === EHRType.AllscriptsTW || ehr === EHRType.AllscriptsPro) {
		return (
			<DiagnosesAndOrdersDialog
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
				enabledTabs={['diagnosis', 'orders']}
			/>
		);
	}

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <DiagnosesAndOrdersDialog {...props} enabledTabs={['diagnosis', 'orders', 'orderSets']} />;
}

export default EhrDiagnosesAndOrdersDialog;
