import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { IOrderSet } from '../../core/models/dictations/dictations.models';
import { useAppSelector } from '../../core/core.types';
import { namespaces } from '../../application/i18n.constants';
import ItemsList from '../list/ItemsList';

interface IOrderSetsItemsListProps {
	searchQuery: string;
	isLoading: (waitingResult: boolean) => boolean;
	onSelectOrderSet: (order: IOrderSet) => void;
}

function OrderSetItem({ orderSet }: { orderSet: IOrderSet }) {
	return (
		<Box sx={{ flexDirection: 'column' }}>
			<Typography sx={{ fontWeight: 'bold' }}>{orderSet.orderSetName}</Typography>
		</Box>
	);
}

function OrderSetsItemsList({ searchQuery, isLoading, onSelectOrderSet }: IOrderSetsItemsListProps) {
	const orderSetsResults = useAppSelector<IOrderSet[]>((state) => state.worklist.ordersSetsResult);
	const ordersSetsIsLoading = useAppSelector<boolean>((state) => state.worklist.ordersSetsIsLoading);
	const { t } = useTranslation(namespaces.components.diagnosesAndOrdersDialog);

	const loading = isLoading(ordersSetsIsLoading);

	const orderSets =
		searchQuery.length > 0
			? orderSetsResults.filter((x) => x.orderSetName.toLowerCase().includes(searchQuery.toLowerCase()))
			: orderSetsResults;

	return (
		<ItemsList
			button
			loading={loading}
			listHeader={orderSets && orderSets.length > 0 && !loading ? t('searchResults') : t('noSearchResults')}
			items={orderSets.map((x) => ({ id: x.orderSetId.toString(), title: x.orderSetName, ...x }))}
			renderItem={(orderSet: IOrderSet) => <OrderSetItem orderSet={orderSet} />}
			onClick={onSelectOrderSet}
		/>
	);
}

export default OrderSetsItemsList;
