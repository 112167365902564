import * as React from 'react';
import { useEffect } from 'react';
import WordReplacementsPage from './WordReplacementsPage';
import { useAppDispatch, useAppSelector } from '../../../core/core.types';
import { IProvider } from '../../../core/models/providers/providers.models';
import { actions } from '../../../core/state/actions';
import { IScriberPermissions } from '../../../core/models/users/user.models';

function WordReplacements() {
	const dispatch = useAppDispatch();
	const currentClinicId = useAppSelector<string>((state) => state.user.settings.customerId);
	const currentCustomerId = useAppSelector((state) => state.user.settings.customerId);
	const permissions = useAppSelector<IScriberPermissions>((state) => state.user.settings.permissions);
	const userInfoId = useAppSelector<string>((state) => state.user.settings.userInfoId);

	const providersAreLoading = useAppSelector<boolean>((state) => state.admin.providersIsLoading);

	const [selectedCustomerId, setSelectedCustomerId] = React.useState<string | null>(null);
	const customers = useAppSelector((state) => state.user.settings.userCustomerPermissions);
	const customerReplacements = useAppSelector((state) => state.admin.wordReplacements.customerWordReplacements);
	const customerReplacementsLoading = useAppSelector(
		(state) =>
			state.admin.wordReplacements.customerWordReplacementsIsLoading ||
			state.admin.wordReplacements.saveCustomerWordReplacementsIsLoading
	);

	const [selectedProviderId, setSelectedProviderId] = React.useState<string | null>(null);
	const allProviders = useAppSelector<IProvider[]>((state) => state.admin.providers);
	const userReplacements = useAppSelector((state) => state.admin.wordReplacements.providerWordReplacements);
	const userReplacementsLoading = useAppSelector(
		(state) =>
			state.admin.wordReplacements.providerWordReplacementsIsLoading ||
			state.admin.wordReplacements.saveProviderWordReplacementsIsLoading
	);

	useEffect(() => {
		if (!providersAreLoading) {
			dispatch(actions.admin.getProvidersAction());
		}
	}, [currentClinicId]);

	const onSelectProvider = (providerId: string | null) => {
		setSelectedProviderId(providerId);
		if (providerId) {
			dispatch(actions.admin.getProviderWordReplacements({ providerId }));
		} else {
			dispatch(actions.admin.setProviderWordReplacements([]));
		}
	};

	useEffect(() => {
		if (!permissions.admin && !providersAreLoading && userInfoId) {
			onSelectProvider(userInfoId);
		}
	}, [userInfoId, permissions, providersAreLoading]);

	const onSelectCustomer = (customerId: string | null) => {
		setSelectedCustomerId(customerId);
		if (customerId) {
			dispatch(actions.admin.getCustomerWordReplacements({ customerId }));
		} else {
			dispatch(actions.admin.setCustomerWordReplacements([]));
		}
	};

	const onAddReplacement = ({
		providerId,
		customerId,
		word,
		wordReplacement,
	}: {
		providerId: string | null;
		customerId: string | null;
		word: string;
		wordReplacement: string;
	}) => {
		if (providerId) {
			dispatch(actions.admin.saveProviderWordReplacement({ providerId, word, wordReplacement }));
		} else if (customerId) {
			dispatch(actions.admin.saveCustomerWordReplacement({ customerId, word, wordReplacement }));
		}
	};

	const onUpdateReplacement = ({
		id,
		providerId,
		customerId,
		word,
		wordReplacement,
	}: {
		id: string;
		providerId: string | null;
		customerId: string | null;
		word: string;
		wordReplacement: string;
	}) => {
		if (providerId) {
			dispatch(actions.admin.updateProviderWordReplacement({ id, providerId, word, wordReplacement }));
		} else if (customerId) {
			dispatch(actions.admin.updateCustomerWordReplacement({ id, customerId, word, wordReplacement }));
		}
	};

	const onDeleteReplacement = ({
		id,
		providerId,
		customerId,
	}: {
		id: string;
		providerId: string | null;
		customerId: string | null;
	}) => {
		if (providerId) {
			dispatch(actions.admin.deleteProviderWordReplacement({ id }));
		} else if (customerId) {
			dispatch(actions.admin.deleteCustomerWordReplacement({ id }));
		}
	};

	return (
		<WordReplacementsPage
			currentCustomer={currentCustomerId}
			customer={{
				onAdd: onAddReplacement,
				onUpdate: onUpdateReplacement,
				onDelete: onDeleteReplacement,
				replacements: customerReplacements,
				loading: customerReplacementsLoading,
			}}
			user={{
				onAdd: onAddReplacement,
				onUpdate: onUpdateReplacement,
				onDelete: onDeleteReplacement,
				replacements: userReplacements,
				loading: userReplacementsLoading,
			}}
			providers={{
				canView: permissions.admin,
				allProviders,
				providerId: selectedProviderId,
				onSelectProvider,
				providersAreLoading,
			}}
			customers={{
				canView: permissions.admin,
				allCustomers: customers.map((x) => ({
					id: x.customerId.toString(),
					name: x.customerName,
				})),
				customerId: selectedCustomerId,
				customersAreLoading: false,
				onSelectCustomer,
			}}
		/>
	);
}

export default WordReplacements;
