import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchBar from '../search-bar/SearchBar';
import { actions } from '../../core/state/actions';
import { useAppDispatch, useAppSelector } from '../../core/core.types';
import {
	IProcedureDocumentationField,
	IProcedureDocumentationTemplate,
} from '../../core/models/dictations/dictations.models';
import { namespaces } from '../../application/i18n.constants';
import Dialog from '../dialog/Dialog';
import ChipItemsList from '../list/ChipItemsList';

interface IProcedureDocumentationDialogProps {
	documentId: string;
	selectedProcedureDocumentations: IProcedureDocumentationField[];
	open: boolean;
	onClose?: () => void;
	onSelectProcedureDocumentation: (template: IProcedureDocumentationTemplate) => void;
	onDeleteProcedureDocumentation: (templateId: string) => void;
}

function ProcedureDocumentationTemplateItem({ template }: { template: IProcedureDocumentationTemplate }) {
	return (
		<Box sx={{ flexDirection: 'column' }}>
			<Typography sx={{ fontWeight: 'bold' }}>{template.templateName}</Typography>
		</Box>
	);
}

function ProcedureDocumentationDialog({
	documentId,
	selectedProcedureDocumentations,
	open,
	onClose,
	onSelectProcedureDocumentation,
	onDeleteProcedureDocumentation,
}: IProcedureDocumentationDialogProps) {
	const dispatch = useAppDispatch();

	const { t } = useTranslation(namespaces.components.procedureDocumentationDialog);

	const procedureTemplates: IProcedureDocumentationTemplate[] = useAppSelector(
		(state) => state.worklist.currentDocument.procedureDocumentationTemplates
	);
	const templatesIsLoading: boolean = useAppSelector(
		(state) => state.worklist.currentDocument.procedureDocumentationTemplatesIsLoading
	);

	const [templates, setTemplates] = useState<IProcedureDocumentationTemplate[]>(procedureTemplates);
	const [templatesFetchContext, setTemplatesFetchContext] = useState<{ documentId: string } | null>(null);
	const [searchQuery, setSearchQuery] = useState<string>('');

	useEffect(() => {
		if (
			open &&
			(!templatesFetchContext || templatesFetchContext.documentId !== documentId || procedureTemplates.length === 0)
		) {
			dispatch(actions.worklist.getProcedureDocumentationTemplatesAction({ documentId }));
			setTemplatesFetchContext({ documentId });
		}
	}, [open, documentId]);

	useEffect(() => {
		setTemplates(procedureTemplates.filter((x) => x.templateName.toLowerCase().includes(searchQuery.toLowerCase())));
	}, [searchQuery, procedureTemplates]);

	const onCloseDialog = () => {
		setSearchQuery('');
		if (onClose) {
			onClose();
		}
	};

	const onSelect = (template: IProcedureDocumentationTemplate) => {
		onSelectProcedureDocumentation(template);
		onCloseDialog();
	};

	return (
		<Dialog fullWidth maxWidth="sm" onClose={onCloseDialog} title={t('procedureDocumentationTemplates')} open={open}>
			<SearchBar placeholder={t('search')} value={searchQuery} onInputChange={(value) => setSearchQuery(value)} />
			<ChipItemsList
				button
				loading={templatesIsLoading}
				items={templates.map((x) => ({ id: x.ehrTemplateId.toString(), title: x.templateName, ...x }))}
				selected={selectedProcedureDocumentations.map((x) => ({
					id: x.procedureTemplateId,
					title: x.procedureTemplateName,
				}))}
				deletable={selectedProcedureDocumentations.map((x) => ({
					id: x.procedureTemplateId,
					title: x.procedureTemplateName,
				}))}
				onDelete={(id) => onDeleteProcedureDocumentation(id)}
				onClick={(item) => onSelect(item)}
				renderItem={(item) => <ProcedureDocumentationTemplateItem template={item} />}
			/>
		</Dialog>
	);
}

export default ProcedureDocumentationDialog;
