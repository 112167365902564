import { IServiceResult } from '../../models/service.models';
import { endpoints } from '../endpoints';
import { getRequestV2, mapHttpData } from '../api-service';
import { IAIModel } from './system.api.models';

export async function getAIModels(): Promise<IServiceResult<IAIModel[]>> {
	const endpoint = endpoints.system.aiModels;
	const result = await getRequestV2<IAIModel[]>(endpoint);

	return mapHttpData(result, (data) => data);
}
