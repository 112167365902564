export enum MacroSection {
	HPI = 'HPI',
	ROS = 'ROS',
	PE = 'PE',
	Procedures = 'Procedures',
	Imaging = 'Imaging',
	AP = 'AAndP',
	Discussion = 'Discussion',
}

export interface IDeleteMacroPayload {
	macroId: number;
}

export interface ICreateMacroPayload {
	providerId: string;

	section: string;
	shortCode: string;

	description: string;
	spokenForm: string;
	macro: string;
}

export interface IUpdateMacroPayload {
	macroId: number;
	providerId: string;

	section: string;
	spokenForm: string;
	macro: string;
}

export interface IMacro {
	macroId: number;
	section: MacroSection;
	description: string;
	shortCode: string;
	macro: string;
	spokenForm: string;
}

export const MacroSectionsList = [
	MacroSection.HPI,
	MacroSection.ROS,
	MacroSection.PE,
	// MacroSection.Procedures,
	MacroSection.Imaging,
	MacroSection.AP,
	// MacroSection.Discussion,
];
