import * as React from 'react';
import { Box, Button, Dialog as MUIDialog, DialogProps, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { namespaces } from '../../application/i18n.constants';
import Spinner from '../spinner/Spinner';

interface IDialogProps extends DialogProps {
	title?: string;
	loading?: boolean;
	children?: React.ReactNode;
	onClose: () => void;
	onCloseTitle?: 'done' | 'cancel';
}

function Dialog({ title, onClose, onCloseTitle, loading, children, ...props }: IDialogProps) {
	const { t } = useTranslation(namespaces.components.dialog);

	return (
		// eslint-disable-next-line
		<MUIDialog {...props} onClose={onClose}>
			<DialogTitle textAlign="center">
				{title || null}
				{onClose ? (
					<Button
						onClick={onClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
						}}
					>
						{onCloseTitle === 'cancel' ? t('cancel') : t('done')}
					</Button>
				) : null}
			</DialogTitle>
			{loading ? (
				<Box
					sx={{
						display: 'flex',
						flex: 1,
						minHeight: '10rem',
						overflow: 'auto',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<Spinner />
				</Box>
			) : (
				children
			)}
		</MUIDialog>
	);
}

export default Dialog;
