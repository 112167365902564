import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IProvider } from '../../core/models/providers/providers.models';
import ValidationSelectField from '../validators/ValidationSelectField';
import { namespaces } from '../../application/i18n.constants';

interface IUploadCsvDialogProps {
	open: boolean;
	providers?: {
		allProviders: IProvider[];
		preSelectedProvider: string;
		disabled?: boolean;
	};
	onClose: () => void;
	onUpload: (params: { file: File; providerId: string }) => void;
}
function UploadCsvDialog({ open, providers, onUpload, onClose }: IUploadCsvDialogProps) {
	const { t } = useTranslation(namespaces.components.dotPhrases);
	const [file, setFile] = React.useState<File | null>(null);
	const [selectedProvider, setSelectedProvider] = useState<string | null>(providers?.preSelectedProvider || null);

	const handleSetProvider = (providerId: string) => {
		setSelectedProvider(providerId);
	};

	useEffect(() => {
		if (providers?.preSelectedProvider) {
			setSelectedProvider(providers.preSelectedProvider);
		}
	}, [providers?.preSelectedProvider]);

	useEffect(() => {
		if (open) {
			setFile(null);
		}
	}, [open]);

	return (
		<Dialog onClose={onClose} open={open}>
			<Box flexDirection="column" aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title">Upload CSV</DialogTitle>
				<DialogContent
					sx={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					{providers ? (
						<ValidationSelectField
							sx={{ minWidth: 166, my: 2, mr: 1 }}
							id="providerSelect"
							type="text"
							required={!!providers}
							value={selectedProvider}
							data={providers.allProviders.map((x) => ({ value: x.id, text: x.name }))}
							label={t('selectProvider')}
							disabled={providers.disabled}
							onChange={(e) => handleSetProvider(e.target.value)}
						/>
					) : null}
					<input
						type="file"
						accept=".csv"
						onChange={(e) => {
							if (e.target.files) {
								setFile(e.target.files[0]);
							}
						}}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose} color="primary">
						{t('cancel')}
					</Button>
					<Button
						onClick={() => {
							if (file) {
								onUpload({ file, providerId: selectedProvider || 'N/A' });
							}
						}}
						color="primary"
						autoFocus
						disabled={!file || (!!providers && !selectedProvider)}
					>
						{t('upload')}
					</Button>
				</DialogActions>
			</Box>
		</Dialog>
	);
}

export default UploadCsvDialog;
