import { createReducer } from '@reduxjs/toolkit';
import { appActions } from './app.actions';
import { IAIModel } from '../../api/system/system.api.models';

export interface IAppState {
	aiModels: IAIModel[];
}

const initialState: IAppState = {
	aiModels: [],
};

const appReducer = createReducer<IAppState>(initialState, (builder) => {
	builder.addCase(appActions.setAiModels, (state, action) => {
		state.aiModels = action.payload;
	});
});

export default appReducer;
