import { AnyObject } from '../shared/types';
import { trackException } from './app-insights';

export const handleException = (key: string, error: Error | unknown, payload?: AnyObject) => {
	trackException(key, error);
	// eslint-disable-next-line no-console
	console.warn(error, `payload: ${payload ? JSON.stringify(payload) : ''}`);
};

export const handleGlobalException = (error: Error | unknown, payload?: AnyObject) => {
	trackException('global', error);
	// eslint-disable-next-line no-console
	console.warn(error, `payload: ${payload ? JSON.stringify(payload) : ''}`);
};
