import * as React from 'react';
import styled from '@emotion/styled';
import ContentContainer, { IContentContainerProps } from '../content-container/ContentContainer';
import Navbar from '../navbar/Navbar';

interface IPageContainerProps extends IContentContainerProps {
	navbar?: boolean;
}

export const Container = styled.div`
	display: flex;
	height: 100vh;
	flex-direction: column;
`;

function PageContainer({ children, sx, loading, disablePadding, error, navbar }: IPageContainerProps) {
	return (
		<Container>
			{navbar ? <Navbar /> : null}
			<ContentContainer
				sx={{
					...sx,
					flex: 1,
					height: '100%',
					overflowY: 'auto',
				}}
				disablePadding={disablePadding}
				error={error}
				loading={loading}
			>
				{children}
			</ContentContainer>
		</Container>
	);
}

PageContainer.defaultProps = {
	navbar: true,
};

export default PageContainer;
