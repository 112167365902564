import { Box, SxProps, Theme } from '@mui/material';
import * as React from 'react';
import Spinner from './Spinner';

function Loading({ sx }: { sx?: SxProps<Theme> }) {
	return (
		<Box sx={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', ...sx }}>
			<Spinner />
		</Box>
	);
}

export default Loading;
