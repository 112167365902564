import { CodedErrorType, DocumentErrorType } from '../../models/dictations/document.models';

interface IApiErrorResponse {
	Code: null;
	Message: string;
	Data: DocumentErrorType;
}

export function mapCodedErrors(errors: string | IApiErrorResponse[] | undefined): CodedErrorType[] {
	if (!errors) {
		return [];
	}

	let codedErrors: IApiErrorResponse[] = [];

	if (typeof errors === 'string') {
		codedErrors = JSON.parse(errors);
	} else {
		codedErrors = errors;
	}

	return codedErrors.map((x) => ({
		code: 'diagnoseLateralityRequired',
		data: x.Data,
		message: x.Message,
	}));
}
