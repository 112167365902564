export function getInnerTextCaretPosition(element) {
	let caretOffset = 0;
	const doc = element.ownerDocument || element.document;
	const win = doc.defaultView || doc.parentWindow;
	let sel;
	if (typeof win.getSelection !== 'undefined') {
		sel = win.getSelection();
		if (sel.rangeCount > 0) {
			const range = win.getSelection().getRangeAt(0);
			const preCaretRange = range.cloneRange();
			preCaretRange.selectNodeContents(element);
			preCaretRange.setEnd(range.endContainer, range.endOffset);
			caretOffset = preCaretRange.toString().length;
		}
	} else {
		sel = doc.selection;
		if (sel && sel.type !== 'Control') {
			const textRange = sel.createRange();
			const preCaretTextRange = doc.body.createTextRange();
			preCaretTextRange.moveToElementText(element);
			preCaretTextRange.setEndPoint('EndToEnd', textRange);
			caretOffset = preCaretTextRange.text.length;
		}
	}
	return caretOffset;
}

export function setInputCaretPosition(elem, caretPos) {
	if (elem != null) {
		if (elem.createTextRange) {
			const range = elem.createTextRange();
			range.move('character', caretPos);
			range.select();
		} else if (elem.selectionStart) {
			elem.focus();
			elem.setSelectionRange(caretPos, caretPos);
		} else elem.focus();
	}
}

export function setNativeValue(element, value) {
	const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set;
	const prototype = Object.getPrototypeOf(element);
	const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set;

	if (valueSetter && valueSetter !== prototypeValueSetter) {
		prototypeValueSetter.call(element, value);
	} else {
		valueSetter.call(element, value);
	}
}
