import { createAction } from '@reduxjs/toolkit';
import { authConstants } from './auth.constants';

// Authentication
export interface IUserLoginActionPayload {
	username: string;
	password: string;
	pin?: string;
}

interface IUserLoginErrorActionPayload {
	error: string;
	success: boolean;
	emailAddress?: string;
	mfaRequired?: boolean;
	invalidMfaPin?: boolean;
}

interface ISignInIsLoadingActionPayload {
	loading: boolean;
}

export interface ISignOutActionPayload {
	notRedirect?: boolean;
	onTimeOut?: boolean;
	onDocument?: boolean;
	onUnauthorized?: boolean;
	onSessionExpired?: boolean;
}

const signInRequestAction = createAction<IUserLoginActionPayload>(authConstants.SIGN_IN_REQUEST);
const signInResultAction = createAction<IUserLoginErrorActionPayload>(authConstants.SIGN_IN_RESULT);
const signInIsLoadingAction = createAction<ISignInIsLoadingActionPayload>(authConstants.SIGN_IN_IS_LOADING);
const signOutAction = createAction<ISignOutActionPayload | undefined>(authConstants.SIGN_OUT);

const setMfaPinIsInvalid = createAction<boolean>(authConstants.SET_MFA_PIN_IS_INVALID);

const initResetPasswordRequest = createAction(authConstants.INIT_PASSWORD_REQUEST);

export interface IResetPasswordRequestActionPayload {
	username: string;
	email: string;
}
const resetPasswordRequest = createAction<IResetPasswordRequestActionPayload>(authConstants.RESET_PASSWORD_REQUEST);

export interface IResetPasswordConfirmNewPasswordActionPayload extends IResetPasswordRequestActionPayload {
	newPassword: string;
	confirmPassword: string;
}
const resetPasswordConfirmNewPassword = createAction<IResetPasswordConfirmNewPasswordActionPayload>(
	authConstants.RESET_PASSWORD_CONFIRM_NEW_PASSWORD
);

const setResetPasswordIsLoading = createAction<boolean>(authConstants.SET_RESET_PASSWORD_IS_LOADING);

export interface IResetPasswordSendConfirmCodeActionPayload extends IResetPasswordRequestActionPayload {
	confirmationCode: string;
}
const resetPasswordSendConfirmCode = createAction<IResetPasswordSendConfirmCodeActionPayload>(
	authConstants.RESET_PASSWORD_CONFIRM_NEW_PASSWORD
);

export interface ISetResetPasswordRequestResultActionPayload {
	codeRequested?: boolean;
	codeConfirmed?: boolean | null;
	code?: string | null;
	error?: string | null;
	success?: boolean;
}
const setResetPasswordRequestResult = createAction<ISetResetPasswordRequestResultActionPayload>(
	authConstants.SET_RESET_PASSWORD_REQUEST_RESULT
);

export const authActions = {
	signInRequestAction,
	signInResultAction,
	setMfaPinIsInvalid,
	signInIsLoadingAction,
	signOutAction,

	initResetPasswordRequest,
	resetPasswordRequest,
	resetPasswordConfirmNewPassword,
	resetPasswordSendConfirmCode,
	setResetPasswordIsLoading,
	setResetPasswordRequestResult,
};
