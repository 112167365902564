import { all, call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { actions, IAction } from '../actions';
import {
	getDictatedDocumentsTracking,
	getEncounter,
	getOpenAISummary,
	jobsSearch,
	requestAISummary,
	updateDocument,
} from '../../api/dictations/dictations.api';
import { handleGlobalException } from '../../../application/exception-handlers';
import { IServiceResult } from '../../models/service.models';
import {
	IDepartmentDictation,
	IDictation,
	IDocumentVersions,
	IJobDictation,
	IJobsSearchFilters,
	IOpenAISummary,
} from '../../models/dictations/dictations.models';
import { IPatientChartDocuments, IPatientDemographic, IPatientEncounter } from '../../models/patients/patients.models';
import { patientChartSearch } from '../../api/patients/patients.api';
import { IUserDisplayName } from '../../models/users/user.models';
import { getUsers } from '../../api/user/user.api';
import { IChangeDocumentStatusActionPayload } from './jobs.actions';
import { JobStatus } from '../../models/dictations/document.models';
import { AppState } from '../../core.types';
import {
	IRequestAISummaryForPromptActionPayload,
	IUpdatePromptAISummaryActionPayload,
} from '../worklist/worklist.actions';
import { IRequestAISummaryRequest } from '../../api/dictations/dictations.api.models';
import { getAiSummaryRequest } from '../../services/dictations/dictations-services';

export function* searchJobsSaga(action: IAction<IJobsSearchFilters>) {
	try {
		yield put(actions.jobs.jobsSearchIsLoadingAction(true));

		const result: IJobDictation[] = yield call(jobsSearch, {
			patientID: action.payload.patientID || '',
			dictatedBy: action.payload.dictatedBy || '',
			transcribedBy: action.payload.transcribedBy || '',
			qaBy: action.payload.qaBy || '',
			dictatedEndDate: action.payload.dictatedEndDate || '',
			dictatedStartDate: action.payload.dictatedStartDate || '',
			sentStartDate: action.payload.sentStartDate || '',
			sentEndDate: action.payload.sentEndDate || '',
			documentType: action.payload.documentType || '',
			documentStatus: action.payload.documentStatus || '',
		});
		if (result) {
			yield put(actions.jobs.setJobsSearchResultAction({ jobs: result }));
		}
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.jobs.jobsSearchIsLoadingAction(false));
	}
}

export function* openJobSaga(action: IAction<{ job: IJobDictation }>) {
	// const { blobId } = action.payload;
	const { job } = action.payload;
	try {
		yield put(actions.jobs.setDictationData(null));
		yield put(actions.jobs.setDictationDataIsLoading(true));

		// const dictation: IServiceResult<IDictation> = yield call(getDictation, blobId);

		const dictation: IDictation = {
			stat: job.stat,
			patientID: job.patientID,
			providerUserInfoID: job.providerUserInfoID,
			blobID: job.blobID,
			documentID: job.documentID,
			blobUrl: job.blobUrl,
			documentType: job.documentType,
			documentStatus: job.documentStatus,
			appointmentDateTime: job.appointmentDateTime,
			careProviderName: job.careProviderName,
			patientFirstName: job.patientFirstName,
			patientLastName: job.patientLastName,
			markedForQA: false,
			isHeld: false,
			isCheckedOut: false,
			checkedOutBy: '',
			appointmentID: '',
			createdDateTime: job.createdDateTime,
			documentSaveStatus: '',
		};

		const [patientEncounter, patientChart, versions]: [
			patientEncounter: IPatientEncounter,
			patientChart: IServiceResult<IPatientChartDocuments>,
			versions: IServiceResult<IDocumentVersions>
		] = yield all([
			call(function* getEncounterSaga(encounterId: string | null) {
				let encounter: IPatientEncounter | null = null;
				if (encounterId) {
					encounter = yield call(
						getEncounter,
						{
							encounterId,
							patientId: dictation.patientID,
						},
						{
							providerId: dictation.providerUserInfoID,
						}
					);
				}

				if (encounter) {
					return encounter;
				}

				return {
					documentID: '',
					patientID: '',
					createByFullName: '',
					createDateTime: '',
					documentStatus: 'CLOSED',
					documentTypeName: '',
					iScribeDocumentStatus: 'Unsigned',
					lastModifiedDateTime: '',
					providerId: dictation.providerUserInfoID?.toString() || '',
					renderingProviderFullName: dictation.careProviderName,
					sequenceNumber: 0,
					title: '',
				};
			}, dictation.documentID),
			call(
				patientChartSearch,
				{
					PatientID: dictation.patientID,
					Filters: {
						Documents: false,
						Demographic: true,
					},
				},
				dictation?.providerUserInfoID ? { providerId: dictation.providerUserInfoID } : undefined
			),
			call(getDictatedDocumentsTracking, dictation.blobID),
		]);

		yield put(
			actions.jobs.setDictationData({
				dictation,
				encounter: patientEncounter,
				demographic: patientChart.data.demographic as IPatientDemographic,
				versions: versions.data.versions,
				qaNotes: versions.data.qaNotes,
				transcription: {
					azureASR: {
						text: versions.data.transcription.azureASR.text,
						timestampedText: versions.data.transcription.azureASR.timestampedText,
					},
					mModalASR: versions.data.transcription.mModalASR,
					nuanceASR: versions.data.transcription.nuanceASR,
					openAISummary: versions.data.transcription.openAISummary,
					openAISummaryStructured: versions.data.transcription.openAISummaryStructured,
					customOpenAISummary: null,
					customOpenAISummaryStructured: null,
					prompt: versions.data.transcription.prompt ?? null,
					instructions: versions.data.transcription.instructions ?? null,
					lastTimePromptSubmitted: null,
					patientDemographics: versions.data.transcription.patientDemographics,
				},
				providerNote: versions.data.providerNote,
			})
		);
	} catch (e) {
		toast.error('The document could not be loaded');
		handleGlobalException(e);
	} finally {
		yield put(actions.jobs.setDictationDataIsLoading(false));
	}
}

export function* getUsersSaga() {
	try {
		yield put(actions.jobs.getUsersIsLoadingAction(true));
		const users: IUserDisplayName[] = yield call(getUsers);

		if (users) {
			yield put(
				actions.jobs.setUsersResultAction({
					users: users.filter((x) => x.displayName),
				})
			);
		}
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.jobs.getUsersIsLoadingAction(false));
	}
}

function updateJobProps(
	jobs: IDictation[],
	blobId: string,
	newProps: {
		documentStatus?: '' | JobStatus;
		documentType?: string;
		stat: boolean;
	}
): IDictation[] {
	const editedJobIndex = jobs.findIndex((x) => x.blobID === blobId);
	if (editedJobIndex !== -1) {
		const editedJob = { ...jobs[editedJobIndex] };
		if (newProps.documentStatus) {
			editedJob.documentStatus = newProps.documentStatus;
		}
		if (newProps.documentType) {
			editedJob.documentType = newProps.documentType;
		}
		editedJob.stat = newProps.stat;

		return [...jobs.map((x, index) => (index === editedJobIndex ? editedJob : x))];
	}

	return jobs;
}
export function* changeDocumentStatusSaga(action: IAction<IChangeDocumentStatusActionPayload>) {
	try {
		const {
			blobId,
			job,
			update: { documentStatus, documentTypeName, stat },
		} = action.payload;

		const jobs: IJobDictation[] = yield select((state: AppState) => state.jobs.jobs) || [];
		const dictations: IDictation[] = yield select((state: AppState) => state.worklist.dictations) || [];

		const getApiStatus = (sts: JobStatus) => {
			if (sts === JobStatus.Removed) {
				return JobStatus.Removed;
			}
			if (sts === JobStatus.NotCompleted) {
				return 'Not Completed';
			}
			if (sts === JobStatus.Completed) {
				return JobStatus.Completed;
			}
			if (sts === JobStatus.Sending) {
				return JobStatus.Sending;
			}

			return null;
		};

		const newStatus = documentStatus ? getApiStatus(documentStatus) : null;
		const newStat = stat !== undefined ? stat : job.stat;

		const result: IServiceResult<void> = yield call(updateDocument, {
			blobId,
			documentStatus: newStatus || null,
			documentTypeName: documentTypeName || null,
			stat: newStat,
		});

		if (result.success) {
			const newProps = {
				documentStatus,
				documentType: documentTypeName,
				stat: newStat,
			};

			yield put(
				actions.jobs.setJobsSearchResultAction({
					jobs: updateJobProps(jobs as IDictation[], blobId, newProps),
				})
			);

			yield put(
				actions.worklist.setDictationsAction({
					dictations: updateJobProps(dictations, blobId, newProps),
				})
			);

			toast.success(`Document successfully updated`);
		} else {
			toast.error(`Document could not be updated${result.error ? `: ${result.error}` : ''}`);
		}
	} catch (e) {
		handleGlobalException(e);
	}
}

export function* requestJobAISummaryForPromptSaga(action: IAction<IRequestAISummaryForPromptActionPayload>) {
	const dictation: IDepartmentDictation | undefined = yield select(
		(state: AppState) => state.jobs.currentDictation?.dictation
	);

	const errorMessage = 'Something went wrong during requesting AI summary for the prompt';

	if (dictation?.blobID !== action.payload.blobId) {
		toast.error(errorMessage);
	}

	const encounter: IPatientEncounter | undefined = yield select(
		(state: AppState) => state.jobs.currentDictation?.encounter
	);

	const patientDemographic: IPatientDemographic | undefined = yield select(
		(state: AppState) => state.jobs.currentDictation?.demographic
	);

	const data: IRequestAISummaryRequest = getAiSummaryRequest({
		blobId: action.payload.blobId,
		prompt: action.payload.prompt.prompt,
		instructions: action.payload.prompt.instructions,
		aiModel: action.payload.aiModel,
		encounter: encounter ?? null,
		dictation: dictation ?? null,
		patientDemographic: patientDemographic ?? null,
	});

	try {
		yield put(actions.jobs.setLastTimePromptSubmitted(new Date().getTime()));
		const result: IServiceResult<void> = yield call(requestAISummary, data);

		if (!result.success) {
			toast.error(errorMessage);
		}
	} catch (e) {
		toast.error(errorMessage);
		handleGlobalException(e);
	}
}

export function* updateJobPromptAISummarySaga(action: IAction<IUpdatePromptAISummaryActionPayload>) {
	const dictation: IDepartmentDictation | undefined = yield select(
		(state: AppState) => state.jobs.currentDictation?.dictation
	);

	const errorMessage = 'Something went wrong during requesting AI summary for the prompt';

	if (dictation?.blobID !== action.payload.blobId) {
		return;
	}

	try {
		const aiSummaryResult: IServiceResult<{
			openAISummary: string | null;
			openAISummaryStructured: IOpenAISummary | null;
		}> = yield call(getOpenAISummary, action.payload.blobId);

		if (
			aiSummaryResult.success &&
			(aiSummaryResult.data.openAISummary || aiSummaryResult.data.openAISummaryStructured)
		) {
			yield put(
				actions.jobs.setPromptAISummary({
					openAISummary: aiSummaryResult.data.openAISummary,
					openAISummaryStructured: aiSummaryResult.data.openAISummaryStructured,
				})
			);
			yield put(actions.jobs.setLastTimePromptSubmitted(null));
			toast.success('AI summary updated');
		} else {
			toast.error(errorMessage);
		}
	} catch (e) {
		toast.error(errorMessage);
		handleGlobalException(e);
	}
}
