import * as React from 'react';
import { ForwardedRef, forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Box, IconButton, SxProps, Theme, Tooltip, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ITimestampedTranscription, TranscriptionOption } from '../../core/models/dictations/dictations.models';
import SelectFiled from '../select/SelectField';
import { ITranscriptChat } from './timestamped-transcription/abridge-transcription/AbridgeTranscription';
import { getTranscriptionOptionCache, setTranscriptionOptionCache } from '../../system/local-storage';
import TimestampedTranscription from './TimestampedTranscription';

interface ITranscriptionProps {
	containerSx?: SxProps<Theme>;
	azureASR: { text: string | null; timestampedText: ITimestampedTranscription[] | null };
	mModalASR?: string | null;
	nuanceASR?: string | null;
	handleMessageClick: (timestamp: number) => void;
}

export interface ITranscription {
	seek: (timestamp: number) => void;
}

const Transcription = function TranscriptionFunc(
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	{ containerSx, azureASR, mModalASR, nuanceASR, handleMessageClick }: ITranscriptionProps,
	forwardedRef: ForwardedRef<ITranscription>
) {
	const transcriptionRef = useRef<ITranscriptChat>(null);

	const options = useMemo(() => {
		const opts: { text: string; value: TranscriptionOption }[] = [];
		if (azureASR.timestampedText || azureASR.text) {
			opts.push({ text: 'Azure', value: TranscriptionOption.azure });
		}
		if (mModalASR) {
			opts.push({ text: '3M - mModal', value: TranscriptionOption.mModal });
		}
		if (nuanceASR) {
			opts.push({ text: 'Nuance', value: TranscriptionOption.nuance });
		}
		return opts;
	}, [azureASR, mModalASR, nuanceASR]);

	const getDefaultOption = (): TranscriptionOption => {
		const cacheOption = getTranscriptionOptionCache();
		if (cacheOption) {
			const defaultOption = options.find((x) => x.value === cacheOption);
			if (defaultOption) {
				return defaultOption.value;
			}
			return options[0].value;
		}
		return options[0].value;
	};
	const [transcription, setTranscription] = useState<TranscriptionOption>(getDefaultOption());

	useImperativeHandle(forwardedRef, () => ({
		seek(timestamp: number) {
			transcriptionRef.current?.seek(timestamp);
		},
	}));

	const onChangeOption = (option: TranscriptionOption) => {
		setTranscription(option);
		setTranscriptionOptionCache(option);
	};

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', ...containerSx }}>
			<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
				<Tooltip title="Copy text to clipboard">
					<IconButton size="small" onClick={() => navigator.clipboard.writeText(azureASR.text ?? '')}>
						{transcription === TranscriptionOption.azure && azureASR?.text && <ContentCopyIcon />}
					</IconButton>
				</Tooltip>
				<SelectFiled
					sx={{ p: 1 }}
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					InputProps={{
						disableUnderline: true,
					}}
					size="small"
					variant="standard"
					disabled={options.length <= 1}
					data={options}
					value={transcription}
					onChange={(e) => onChangeOption(e.target.value as TranscriptionOption)}
				/>
			</Box>
			<Box sx={{ overflow: 'auto', px: 2 }}>
				{transcription === TranscriptionOption.azure &&
					((azureASR?.timestampedText && azureASR.timestampedText.length > 0) || azureASR?.text) &&
					(azureASR?.timestampedText ? (
						<TimestampedTranscription
							ref={transcriptionRef}
							transcript={azureASR.timestampedText.map((x) => ({
								text: x.DisplayText,
								timestamp: x.Timestamp,
								speaker: 0,
							}))}
							handleMessageClick={handleMessageClick}
						/>
					) : (
						<Typography>{azureASR.text}</Typography>
					))}
				{transcription === TranscriptionOption.mModal && mModalASR && <Typography>{mModalASR}</Typography>}
				{transcription === TranscriptionOption.nuance && nuanceASR && <Typography>{nuanceASR}</Typography>}
			</Box>
		</Box>
	);
};

export default forwardRef(Transcription);
