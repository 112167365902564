import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, FormControl, InputLabel, MenuItem, Select, SxProps, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { namespaces } from '../../application/i18n.constants';
import SearchBar from '../search-bar/SearchBar';
import { MacroSectionsList } from '../../core/models/macros/macros.models';
import { getNameForDotPhraseSection } from '../../core/services/macros/macros.services';

interface IMacrosSearchProps {
	sx?: SxProps<Theme>;
	value?: string;
	disabled?: boolean;
	onSearch: (query: string, section: string) => void;
	onAdd: () => void;
	onImport: () => void;
	copyEnabled?: boolean;
	onCopy?: () => void;
}

function MacrosSearch({ sx, value, disabled, onSearch, onAdd, onImport, copyEnabled, onCopy }: IMacrosSearchProps) {
	const { t } = useTranslation(namespaces.components.dotPhrases);
	const [searchQuery, setSearchQuery] = useState<string>(value !== undefined ? value : '');
	const [section, setSection] = useState<string>('all');

	useEffect(() => {
		onSearch(searchQuery, section);
	}, [searchQuery, section]);

	return (
		<FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: 56, ...sx }}>
			<InputLabel htmlFor="sectionSearch">{t('section')}</InputLabel>
			<Select
				sx={{ flex: 1 }}
				id="sectionSearch"
				size="medium"
				required
				disabled={disabled}
				value={section}
				label={t('section')}
				variant="outlined"
				onChange={(e) => setSection(e.target.value)}
			>
				<MenuItem value="all">{t('all')}</MenuItem>
				{MacroSectionsList.map((x) => (
					<MenuItem value={x} key={x}>
						{getNameForDotPhraseSection(x)}
					</MenuItem>
				))}
			</Select>
			<SearchBar
				value={searchQuery}
				disabled={disabled}
				onInputChange={setSearchQuery}
				sx={{ p: 0, flex: 2 }}
				textFieldProps={{
					variant: 'outlined',
				}}
				placeholder={t('phraseOrDescription')}
			/>
			<Button variant="contained" size="small" onClick={onAdd} sx={{ height: 56, ml: 1 }}>
				{t('add')}
			</Button>
			<Button variant="contained" size="small" onClick={onImport} sx={{ height: 56, ml: 1 }}>
				{t('import')}
			</Button>
			{copyEnabled && (
				<Button disabled={!onCopy} variant="contained" size="small" onClick={onCopy} sx={{ height: 56, ml: 1 }}>
					{t('copy')}
				</Button>
			)}
		</FormControl>
	);
}

export default MacrosSearch;
