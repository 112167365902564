import { createAction } from '@reduxjs/toolkit';
import { userConstants } from './user.constants';
import { IUserSettings } from '../../models/users/user.models';

// Init User
export interface IInitUserActionPayload {
	userSettings: IUserSettings;
}

const initUserAction = createAction<IInitUserActionPayload>(userConstants.INIT_USER);

// User Settings
interface IUserSettingsActionPayload extends IUserSettings {}

const setUserSettingsAction = createAction<IUserSettingsActionPayload>(userConstants.SET_USER_SETTINGS);

interface ISetVerifyEhrResultActionPayload {
	daysUntilExpiration: number | null;
	error: string | null;
}

const verifyEhrAction = createAction(userConstants.VERIFY_EHR);
const setVerifyEhrLoadingAction = createAction<boolean>(userConstants.SET_VERIFY_EHR_LOADING);
const setVerifyEhrResultAction = createAction<ISetVerifyEhrResultActionPayload>(userConstants.SET_VERIFY_EHR_RESULT);

const setProvidersAreEditableAction = createAction<boolean>(userConstants.SET_PROVIDERS_ARE_EDITABLE);

export const userActions = {
	initUserAction,
	setUserSettingsAction,

	verifyEhrAction,
	setVerifyEhrLoadingAction,
	setVerifyEhrResultAction,

	setProvidersAreEditableAction,
};
