import { createReducer } from '@reduxjs/toolkit';
import { IMacro } from '../../models/macros/macros.models';
import { adminActions } from './admin.actions';
import { IProvider } from '../../models/providers/providers.models';
import { IWordReplacement } from '../../models/replacements/replacements.models';

export interface IAdminState {
	providers: IProvider[];
	providersIsLoading: boolean;
	macros: {
		customerMacros: IMacro[];
		customerMacrosIsLoading: boolean;
		saveCustomerMacrosIsLoading: boolean;

		providerMacros: IMacro[];
		providerMacrosIsLoading: boolean;
		saveProviderMacrosIsLoading: boolean;
	};
	wordReplacements: {
		customerWordReplacements: IWordReplacement[];
		customerWordReplacementsIsLoading: boolean;
		saveCustomerWordReplacementsIsLoading: boolean;

		providerWordReplacements: IWordReplacement[];
		providerWordReplacementsIsLoading: boolean;
		saveProviderWordReplacementsIsLoading: boolean;
	};
}

const initialState: IAdminState = {
	providers: [],
	providersIsLoading: false,
	macros: {
		customerMacros: [],
		customerMacrosIsLoading: false,
		saveCustomerMacrosIsLoading: false,

		providerMacros: [],
		providerMacrosIsLoading: false,
		saveProviderMacrosIsLoading: false,
	},
	wordReplacements: {
		customerWordReplacements: [],
		customerWordReplacementsIsLoading: false,
		saveCustomerWordReplacementsIsLoading: false,

		providerWordReplacements: [],
		providerWordReplacementsIsLoading: false,
		saveProviderWordReplacementsIsLoading: false,
	},
};

const adminReducer = createReducer<IAdminState>(initialState, (builder) => {
	builder
		.addCase(adminActions.setCustomerMacros, (state, action) => {
			state.macros.customerMacros = action.payload;
		})
		.addCase(adminActions.setCustomerMacrosIsLoading, (state, action) => {
			state.macros.customerMacrosIsLoading = action.payload;
		})
		.addCase(adminActions.setSaveCustomerMacroIsLoading, (state, action) => {
			state.macros.saveCustomerMacrosIsLoading = action.payload;
		})

		.addCase(adminActions.setProviderMacros, (state, action) => {
			state.macros.providerMacros = action.payload;
		})
		.addCase(adminActions.setProviderMacrosIsLoading, (state, action) => {
			state.macros.providerMacrosIsLoading = action.payload;
		})
		.addCase(adminActions.setSaveProviderMacroIsLoading, (state, action) => {
			state.macros.saveProviderMacrosIsLoading = action.payload;
		})

		.addCase(adminActions.setProvidersAction, (state, action) => {
			state.providers = action.payload.providers;
		})

		.addCase(adminActions.setProvidersIsLoadingAction, (state, action) => {
			state.providersIsLoading = action.payload;
		})

		.addCase(adminActions.setCustomerWordReplacements, (state, action) => {
			state.wordReplacements.customerWordReplacements = action.payload;
		})
		.addCase(adminActions.setCustomerWordReplacementsIsLoading, (state, action) => {
			state.wordReplacements.customerWordReplacementsIsLoading = action.payload;
		})
		.addCase(adminActions.setSaveCustomerWordReplacementIsLoading, (state, action) => {
			state.wordReplacements.saveCustomerWordReplacementsIsLoading = action.payload;
		})

		.addCase(adminActions.setProviderWordReplacements, (state, action) => {
			state.wordReplacements.providerWordReplacements = action.payload;
		})
		.addCase(adminActions.setProviderWordReplacementsIsLoading, (state, action) => {
			state.wordReplacements.providerWordReplacementsIsLoading = action.payload;
		})
		.addCase(adminActions.setSaveProviderWordReplacementIsLoading, (state, action) => {
			state.wordReplacements.saveProviderWordReplacementsIsLoading = action.payload;
		});
});

export default adminReducer;
