import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { Box, IconButton, List, ListItem, Paper, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { cloneDeep } from 'lodash';
import { IProcedureDocumentationField } from '../../core/models/dictations/dictations.models';
import { IAutocompleteProps } from '../dot-phrases-popper/autocomplete.utils';
import AutocompletePopper from '../dot-phrases-popper/AutocompletePopper';

function SelectWord({
	words,
	disabled,
	templateName,
	listKey,
	selectedWord,
	onChange,
}: {
	templateName: string;
	disabled: boolean;
	listKey: string;
	words: string[];
	selectedWord: string;
	onChange: () => void;
}) {
	const theme = useTheme();
	const [edited, setEdited] = useState<boolean>(false);

	const getWidth = (options: string[], text: string) => {
		const FONT_SIZE = theme.typography.fontSize * 0.75;
		const size: number = options.map((x) => x.length).reduce((x, y) => (x > y ? x : y)) * FONT_SIZE;
		const MIN_SIZE = size > 50 ? size : 50;

		if (text.length * FONT_SIZE > MIN_SIZE) {
			return (text.length + 1) * FONT_SIZE;
		}
		return MIN_SIZE;
	};

	const getDefaultText = () => selectedWord;
	const [textValue, setTextValue] = useState<string>(getDefaultText());
	const [inputWidth, setInputWidth] = useState<number>(getWidth(words, textValue));

	const onChangeWord = (text: string, forceChange?: boolean) => {
		if (text !== selectedWord || edited || forceChange) {
			onChange();
		}
	};

	useEffect(() => {
		setTextValue(getDefaultText());
	}, [selectedWord]);

	useEffect(() => {
		setInputWidth(getWidth(words, textValue));

		const delayDebounceFn = setTimeout(() => {
			onChangeWord(textValue);
		}, 500);

		return () => clearTimeout(delayDebounceFn);
	}, [textValue]);

	const data = {
		options: words,
		selected: textValue,
	};

	const template = templateName.toLowerCase().replace(' ', '_');

	return (
		<div contentEditable={false} style={{ display: 'inline-flex' }} data-options={JSON.stringify(data)}>
			<input
				style={{ width: `${inputWidth}px`, textAlign: 'center' }}
				type="text"
				disabled={disabled}
				id={`${template}_options_list_input_${listKey}`}
				list={`${template}_options_list_${listKey}`}
				value={textValue}
				onChange={(e) => {
					setEdited(true);
					setTextValue(e.target.value);
				}}
			/>
			<datalist id={`${template}_options_list_${listKey}`}>
				{words.map((x) => (
					// eslint-disable-next-line jsx-a11y/control-has-associated-label
					<option key={x} value={x} />
				))}
			</datalist>
		</div>
	);
}

function getNodeById(parentNode: Element, id: string): Element | null {
	if (parentNode.id === id) {
		return parentNode;
	}

	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < parentNode.childNodes.length; i++) {
		const childNode = parentNode.childNodes[i];
		if (childNode.nodeType === Node.ELEMENT_NODE) {
			const foundNode = getNodeById(childNode as Element, id);
			if (foundNode) {
				return foundNode;
			}
		}
	}

	return null;
}

function transformInnerHtml(
	div: HTMLDivElement,
	transform: (params: { options: string[]; selected: string }) => string
): void {
	/* eslint-disable @typescript-eslint/ban-ts-comment */
	div.childNodes.forEach((node) => {
		if (
			node.nodeType === 1 &&
			(node.nodeName.toLowerCase() === 'div' || node.nodeName.toLowerCase() === 'span') &&
			// @ts-ignore
			node.dataset.options
		) {
			// @ts-ignore
			const { options, selected }: { options: []; selected: string } = JSON.parse(node.dataset.options);
			if (options.find((x) => x === selected) || selected === '') {
				const newValue = transform({ options, selected });
				div.replaceChild(document.createTextNode(newValue), node);
			} else {
				div.replaceChild(document.createTextNode(selected), node);
			}
		} else if (node.childNodes?.length > 0) {
			transformInnerHtml(node as HTMLDivElement, transform);
		}
	});
	/* eslint-enable @typescript-eslint/ban-ts-comment */
}

function parseProcedureDocumentationFromHtml(content: HTMLElement): {
	templateData: string;
	templateText: string;
} {
	const templateDataDiv = document.createElement('div');
	templateDataDiv.innerHTML = content.innerHTML;
	transformInnerHtml(templateDataDiv, ({ options, selected }) => {
		const optionsWithSelected = options.map((x) => (x === selected ? `${x}*` : x));
		return `{{${optionsWithSelected.join('|')}}}`;
	});

	const templateTextDiv = document.createElement('div');
	templateTextDiv.innerHTML = content.innerHTML;
	transformInnerHtml(templateTextDiv, ({ options, selected }) => {
		const selectedOption = options.find((x) => x === selected);
		return selectedOption ? selected : `____`;
	});

	return {
		// // remove temporary 'non-breaking space' if needed
		// templateData: templateDataDiv.innerHTML.replace(/^(&nbsp;)+/, ''),
		// templateText: templateTextDiv.innerText.replace(/^(&nbsp;)+/, ''),
		templateData: templateDataDiv.innerHTML,
		templateText: templateTextDiv.innerText,
	};
}

function findWords(text: string): string[] {
	const words = text.split(/(?={{|}})|(?<={{|}})/g);
	const results: string[] = [];
	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < words.length; i++) {
		if (words[i] === '{{') {
			const closingIndex = words.findIndex((x, index) => x === '}}' && index > i);
			if (closingIndex > 0) {
				const newResult = words.slice(i, closingIndex + 1).reduce((x, y) => `${x}${y}`);
				results.push(newResult);
				i = closingIndex;
			} else {
				results.push(words[i]);
			}
		} else {
			results.push(words[i]);
		}
	}

	return results;
}

const replaceOptions = (templateName: string, templateData: string, editable: boolean) => {
	const regex = /\{\{([^}]+)}}/g;
	let wordIndex = 0;
	return templateData.replace(regex, (match, options) => {
		const tokenWords: string[] = options.split('|');

		const selectedIndex: number = tokenWords.findIndex((x) => x.endsWith('*'));
		if (selectedIndex >= 0) {
			tokenWords[selectedIndex] = tokenWords[selectedIndex].substring(0, tokenWords[selectedIndex].length - 1);
		}

		const words = templateData ? findWords(templateData) : [];

		const key = `${tokenWords.join(',')}_${words.slice(0, wordIndex).filter((x) => x.startsWith('{{')).length}`;
		const select = (
			<SelectWord
				key={key}
				disabled={!editable}
				templateName={templateName}
				listKey={wordIndex.toString()}
				words={tokenWords}
				selectedWord={selectedIndex >= 0 ? tokenWords[selectedIndex] : ''}
				onChange={() => {
					// has no effect
				}}
			/>
		);
		wordIndex += 1;
		return renderToString(select);
	});
};

interface IProcedureDocumentationItemProps {
	editable: boolean;
	initProcedureDocumentation: IProcedureDocumentationField;
	onDelete?: () => void;
	onChange: (templateData: string, templateText: string) => void;
	autocomplete?: IAutocompleteProps;
}

function ProcedureDocumentationItem({
	editable,
	initProcedureDocumentation,
	onChange,
	onDelete,
	autocomplete,
}: IProcedureDocumentationItemProps) {
	const inputRef = useRef(null);

	const [procedureDocumentation] = useState(cloneDeep(initProcedureDocumentation));

	const [edited, setEdited] = useState<boolean>(false);

	const contentEditable =
		editable && procedureDocumentation.templateData !== null && procedureDocumentation.templateData !== undefined;

	const onChangeTokenizedText = (html: HTMLDivElement, forceChange?: boolean) => {
		const parsedData = parseProcedureDocumentationFromHtml(html);
		if (procedureDocumentation.templateData !== parsedData.templateData || edited || forceChange) {
			onChange(parsedData.templateData, parsedData.templateText);
		}
	};

	const [innerHTML, setInnerHTML] = useState<HTMLDivElement>();

	useEffect(() => {
		/* eslint-disable @typescript-eslint/ban-ts-comment */
		// @ts-ignore
		if (inputRef.current && !inputRef.current.getAttribute('data-initialized')) {
			// @ts-ignore
			inputRef.current.setAttribute('data-initialized', 'true');
			// @ts-ignore
			inputRef.current.innerHTML = replaceOptions(
				initProcedureDocumentation.procedureTemplateName,
				contentEditable ? procedureDocumentation.templateData : procedureDocumentation.procedureDocumentationText,
				contentEditable
			);
		}
		/* eslint-enable @typescript-eslint/ban-ts-comment */
	}, [procedureDocumentation.templateData]);

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			if (innerHTML) {
				onChangeTokenizedText(innerHTML);
			}
		}, 500);

		return () => clearTimeout(delayDebounceFn);
	}, [innerHTML]);

	// useEffect(() => {
	// 	/* eslint-disable @typescript-eslint/ban-ts-comment */
	// 	// @ts-ignore
	// 	if (inputRef?.current?.innerHTML !== undefined && inputRef?.current?.innerHTML !== null) {
	// 		// @ts-ignore
	// 		const html = inputRef?.current?.innerHTML;
	// 		if (!html.toString().trim()) {
	// 			// @ts-ignore
	// 			// prevent cases when contentEditable div is Empty, because it would not be editable anymore, so we have to add a 'non-breaking space' character
	// 			inputRef.current.innerHTML = `&nbsp;`;
	// 		}
	// 	}
	// 	/* eslint-enable @typescript-eslint/ban-ts-comment */
	// }, [innerHTML]);

	const onEdit = (target: EventTarget) => {
		/* eslint-disable @typescript-eslint/ban-ts-comment */
		// @ts-ignore
		if (contentEditable && inputRef.current) {
			setEdited(true);
			// @ts-ignore
			const nodeId = target.id;
			const changedNode = getNodeById(inputRef.current, nodeId);
			if (changedNode?.parentNode) {
				// @ts-ignore
				const dataOptions = changedNode.parentNode.dataset.options;
				if (dataOptions) {
					const parsedDataOptions = JSON.parse(dataOptions);
					// @ts-ignore
					changedNode.parentNode.setAttribute(
						'data-options',
						// @ts-ignore
						JSON.stringify({ ...parsedDataOptions, selected: target.value })
					);
				}
			}

			const newElem = document.createElement('div');
			// @ts-ignore
			newElem.innerHTML = inputRef.current.innerHTML;
			setInnerHTML(newElem);
		}
		/* eslint-enable @typescript-eslint/ban-ts-comment */
	};

	return (
		<Paper sx={{ display: 'flex', flex: 1, flexDirection: 'column', py: 2, pr: 2 }}>
			<Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<Typography fontWeight="bold" sx={{ pl: 2 }}>
						{procedureDocumentation.procedureTemplateName}
					</Typography>
				</Box>
				{onDelete && editable && (
					<IconButton onClick={() => onDelete()}>
						<CloseIcon sx={{ fontSize: '1rem' }} />
					</IconButton>
				)}
			</Box>
			<AutocompletePopper
				id="procedureDocumentation"
				type="contentEditable"
				inputRef={inputRef.current}
				autocomplete={{
					separator: autocomplete?.separator || ' ',
					trigger: autocomplete?.trigger || '.',
					options: autocomplete?.options || [],
				}}
			>
				<Box
					ref={inputRef}
					sx={{ p: 2 }}
					contentEditable={contentEditable}
					suppressContentEditableWarning
					// onChange={(e) => {
					// 	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// 	// @ts-ignore
					// 	const html = e.target.innerHTML;
					// 	if (!html.toString().trim()) {
					// 		onEdit(e.target);
					// 	}
					// }}
					onInput={(e) => {
						onEdit(e.target);
					}}
					onBlur={(e) => {
						onEdit(e.target);
					}}
				/>
			</AutocompletePopper>
		</Paper>
	);
}

interface IProcedureDocumentationListProps {
	editable: boolean;
	procedureDocumentations: IProcedureDocumentationField[];
	onChangeProcedureDocumentation: (id: string, templateData: string, templateText: string) => void;
	onDeleteProcedureDocumentation?: (id: string) => void;
	autocomplete?: IAutocompleteProps;
}

function ProcedureDocumentationList({
	editable,
	procedureDocumentations,
	onChangeProcedureDocumentation,
	onDeleteProcedureDocumentation,
	autocomplete,
}: IProcedureDocumentationListProps) {
	return (
		<List sx={{ width: '100%', overflowX: 'auto' }}>
			{procedureDocumentations.map((procedure) => (
				<ListItem
					sx={{ px: 0, width: '100%', overflowX: 'auto' }}
					key={
						procedure.procedureTemplateId
							? `id_${procedure.procedureTemplateId}`
							: `name_${procedure.procedureTemplateName}`
					}
				>
					<ProcedureDocumentationItem
						editable={editable}
						initProcedureDocumentation={procedure}
						onDelete={
							onDeleteProcedureDocumentation
								? () => onDeleteProcedureDocumentation(procedure.procedureTemplateId)
								: undefined
						}
						onChange={(templateData, templateText) => {
							onChangeProcedureDocumentation(procedure.procedureTemplateId, templateData, templateText);
						}}
						autocomplete={autocomplete}
					/>
				</ListItem>
			))}
		</List>
	);
}

export default ProcedureDocumentationList;
