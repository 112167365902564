import * as React from 'react';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../core/core.types';
import {
	ICreateMacroPayload,
	IMacro,
	IUpdateMacroPayload,
	MacroSectionsList,
} from '../../../core/models/macros/macros.models';
import { actions } from '../../../core/state/actions';
import MacrosPage from '../MacrosPage';

function CustomerMacros() {
	const dispatch = useAppDispatch();

	const currentClinicId = useAppSelector<string>((state) => state.user.settings.customerId);
	const customerMacros = useAppSelector<IMacro[]>((state) => state.admin.macros.customerMacros);
	const customerMacrosIsLoading = useAppSelector<boolean>((state) => state.admin.macros.customerMacrosIsLoading);
	const saveCustomerMacroIsLoading = useAppSelector<boolean>((state) => state.admin.macros.saveCustomerMacrosIsLoading);

	const onProviderDotPhraseAdd = (macro: ICreateMacroPayload) => {
		dispatch(actions.admin.saveCustomerMacro(macro));
	};

	const onDotPhraseUpdate = (macro: IUpdateMacroPayload) => {
		dispatch(actions.admin.updateCustomerMacro(macro));
	};

	const onDotPhraseDelete = (macroId: number) => {
		dispatch(actions.admin.deleteCustomerMacro({ macroId }));
	};
	const onMacroImport = (params: { file: File; providerId?: string }) => {
		dispatch(actions.admin.importCustomerMacrosAction({ customerId: currentClinicId, file: params.file }));
	};

	useEffect(() => {
		dispatch(actions.admin.getCustomerMacrosAction());
	}, [currentClinicId]);

	return (
		<MacrosPage
			macros={customerMacros.filter((macro) => MacroSectionsList.includes(macro.section))}
			macrosIsLoading={customerMacrosIsLoading}
			saveMacroIsLoading={saveCustomerMacroIsLoading}
			onMacroAdd={onProviderDotPhraseAdd}
			onMacroDelete={onDotPhraseDelete}
			onMacroUpdate={onDotPhraseUpdate}
			onMacroImport={onMacroImport}
		/>
	);
}

export default CustomerMacros;
