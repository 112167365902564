import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Box } from '@mui/material';
import { namespaces } from '../../application/i18n.constants';
import HorizontalTabPanel from '../tab-panel/HorizontalTabPanel';
import { useAppDispatch } from '../../core/core.types';
import { actions } from '../../core/state/actions';
import SearchBar from '../search-bar/SearchBar';
import Dialog from '../dialog/Dialog';
import { IDiagnoseField, IOrderField, IOrderSet } from '../../core/models/dictations/dictations.models';
import DiagnoseItemsList from './DiagnoseItemsList';
import OrderItemsList from './OrdersItemsList';
import { diagHash, orderHash } from '../../core/services/documents/documents.services';
import OrderSetsItemsList from './OrderSetsItemsList';
import ChipList from '../chip-list/ProvidersChipList';
import { distinct } from '../../utils/array.utils';

type TabType = 'diagnosis' | 'orders' | 'orderSets';

function DiagnoseChipList({
	currentTab,
	contextDiagnose,
	contextOrder,
	selectedDiagnoses,
	onDeleteDiagnose,
	unassignedOrders,
	onDeleteOrder,
	onDeleteDiagnoseOrder,
}: {
	currentTab: TabType;
	contextDiagnose?: IDiagnoseField | null;
	contextOrder?: IOrderField | null;
	onDeleteDiagnose: (dHash: string) => void;
	selectedDiagnoses: IDiagnoseField[];
	unassignedOrders: IOrderField[];
	onDeleteOrder: (oHash: string) => void;
	onDeleteDiagnoseOrder: (dHash: string, oHash: string) => void;
}) {
	if (currentTab === 'diagnosis') {
		if (!contextOrder) {
			const items = selectedDiagnoses.filter((x) => !x.diagnosisId);
			return (
				<Box sx={{ p: items.length === 0 ? 0 : 1 }}>
					<ChipList
						items={items.map((x) => ({ id: diagHash(x), title: x.caption, ...x }))}
						onDelete={onDeleteDiagnose}
					/>
				</Box>
			);
		}
	}
	if (currentTab === 'orders') {
		if (contextDiagnose) {
			const items = (
				selectedDiagnoses.find((x) => diagHash(x) === diagHash(contextDiagnose)) as IDiagnoseField
			).orders.filter((x) => !x.orderId);
			return (
				<Box sx={{ p: items.length === 0 ? 0 : 1 }}>
					<ChipList
						items={items.map((x) => ({ id: orderHash(x), title: x.description, ...x }))}
						onDelete={(oHash) => onDeleteDiagnoseOrder(diagHash(contextDiagnose), oHash)}
					/>
				</Box>
			);
		}
		return (
			<Box sx={{ p: unassignedOrders.length === 0 ? 0 : 1 }}>
				<ChipList
					items={unassignedOrders.map((x) => ({ id: orderHash(x), title: x.description, ...x }))}
					onDelete={(oHash) => onDeleteOrder(oHash)}
				/>
			</Box>
		);
	}

	return null;
}

export interface IDiagnosesAndOrdersDialogProps {
	searchContext: {
		encounterId: string;
		patientId: string;
	};
	contextDiagnose?: IDiagnoseField | null;
	contextOrder?: IOrderField | null;
	selectedDiagnoses: IDiagnoseField[];
	unassignedOrders: IOrderField[];
	open: boolean;
	onClose?: () => void;
	onSelectDiagnose: (item: IDiagnoseField) => void;
	onDeleteDiagnose: (dHash: string) => void;
	onSelectDiagnoseOrder: (dHash: string, item: IOrderField) => void;
	onSelectOrder: (item: IOrderField) => void;

	onSelectDiagnoseForOrder: (oHash: string, item: IDiagnoseField) => void;
	onSelectOrderSet: (orderSet: IOrderSet) => void;
	onDeleteDiagnoseOrder: (dHash: string, oHash: string) => void;
	onDeleteOrder: (oHash: string) => void;

	enabledTabs: TabType[];
}

function DiagnosesAndOrdersDialog({
	searchContext,
	contextDiagnose,
	contextOrder,
	selectedDiagnoses,
	unassignedOrders,
	onDeleteDiagnose,
	open,
	onClose,
	onSelectDiagnose,
	onSelectDiagnoseOrder,
	onSelectOrder,
	onSelectDiagnoseForOrder,
	onSelectOrderSet,
	onDeleteDiagnoseOrder,
	onDeleteOrder,
	enabledTabs,
}: IDiagnosesAndOrdersDialogProps) {
	const dispatch = useAppDispatch();
	const { t } = useTranslation(namespaces.components.diagnosesAndOrdersDialog);

	const [isTyping, setIsTyping] = useState<boolean>(false);
	const [searchQuery, setSearchQuery] = useState<string>('');

	const defaultTab: TabType = 'diagnosis';
	let initialTab: TabType = contextDiagnose ? 'orders' : defaultTab;
	initialTab = contextOrder ? 'diagnosis' : initialTab;

	const noContext = !contextOrder && !contextDiagnose;

	const [currenTab, setCurrentTab] = useState<TabType>(initialTab);

	// Moved to Dictation.tsx
	// useEffect(() => {
	// 	dispatch(actions.worklist.getOrderSets());
	// }, []);

	useEffect(() => {
		if (contextDiagnose && currenTab !== 'orders' && currenTab !== 'orderSets') {
			setCurrentTab('orders');
		}
		if (contextOrder) {
			setCurrentTab('diagnosis');
		}
	}, [contextDiagnose, contextOrder]);

	useEffect(() => {
		const defaultSearchQuery = '';
		setSearchQuery(defaultSearchQuery);
	}, [currenTab]);

	const onSearch = () => {
		if (currenTab === 'diagnosis') {
			dispatch(actions.worklist.searchDiagnoses(searchQuery));
		} else if (currenTab === 'orders') {
			dispatch(
				actions.worklist.searchOrders({
					searchPhrase: searchQuery,
					encounterId: searchContext.encounterId,
					patientId: searchContext.patientId,
					SNOMEDCode: contextDiagnose?.SNOMEDCode || '',
				})
			);
		}
	};

	const onSelectDiagnoseField = (diagnose: IDiagnoseField) => {
		if (contextOrder) {
			onSelectDiagnoseForOrder(orderHash(contextOrder), diagnose);
		} else {
			onSelectDiagnose(diagnose);
		}
	};

	const onSelectOrderField = (order: IOrderField) => {
		if (contextDiagnose) {
			onSelectDiagnoseOrder(diagHash(contextDiagnose), order);
		} else {
			onSelectOrder(order);
		}
	};

	const onCloseDialog = () => {
		setSearchQuery('');
		dispatch(actions.worklist.setDiagnosesSearchResult([]));
		if (onClose) {
			onClose();
		}
	};

	const isLoading = (isWaitingResult: boolean) => searchQuery.length > 0 && (isTyping || isWaitingResult);

	return (
		<Dialog fullWidth maxWidth="sm" onClose={onCloseDialog} open={open}>
			<SearchBar
				placeholder={t('search')}
				value={searchQuery}
				onInputChange={setSearchQuery}
				onTyping={setIsTyping}
				onSearch={onSearch}
			/>
			<HorizontalTabPanel
				initTab={currenTab}
				current={currenTab}
				renderHeader={(currentTab) => (
					<DiagnoseChipList
						currentTab={currentTab as TabType}
						contextDiagnose={contextDiagnose}
						contextOrder={contextOrder}
						selectedDiagnoses={selectedDiagnoses}
						onDeleteDiagnose={onDeleteDiagnose}
						unassignedOrders={unassignedOrders}
						onDeleteOrder={onDeleteOrder}
						onDeleteDiagnoseOrder={onDeleteDiagnoseOrder}
					/>
				)}
				tabs={[
					{
						id: 'diagnosis',
						name: t('diagnosis'),
						enabled: !!contextOrder || noContext,
						component: (
							<DiagnoseItemsList
								searchQuery={searchQuery}
								selectedDiagnoses={selectedDiagnoses}
								isLoading={isLoading}
								onSelectDiagnose={onSelectDiagnoseField}
							/>
						),
						onTabPress: (tab: string) => setCurrentTab(tab as TabType),
					},
					{
						id: 'orders',
						enabled: !!contextDiagnose || noContext,
						name: t('orders'),
						component: (
							<OrderItemsList
								searchQuery={searchQuery}
								selectedOrders={distinct(
									[...selectedDiagnoses.map((x) => x.orders), ...unassignedOrders]
										.flat(1)
										.filter((x) => x.modified !== 2),
									(order) => orderHash(order)
								)}
								isLoading={isLoading}
								onSelectOrder={onSelectOrderField}
							/>
						),
						onTabPress: (tab: string) => setCurrentTab(tab as TabType),
					},
					{
						id: 'orderSets',
						enabled: !!contextDiagnose || noContext,
						name: t('orderSets'),
						component: (
							<OrderSetsItemsList searchQuery={searchQuery} isLoading={isLoading} onSelectOrderSet={onSelectOrderSet} />
						),
						onTabPress: (tab: string) => setCurrentTab(tab as TabType),
					},
				].filter((tab) => enabledTabs.find((x) => x === tab.id))}
			/>
		</Dialog>
	);
}

DiagnosesAndOrdersDialog.defaultProps = {
	onClose: undefined,
};

export default connect()(DiagnosesAndOrdersDialog);
