import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Dialog, DialogTitle, Divider, List, ListItem, ListItemText, Paper, Typography } from '@mui/material';
import dayjs from 'dayjs';
import CheckIcon from '@mui/icons-material/Check';
import { namespaces } from '../../application/i18n.constants';
import { dateFormat } from '../../core/core.constants';
import { IPatientEncounter } from '../../core/models/patients/patients.models';
import Spinner from '../spinner/Spinner';

interface ISelectEncountersProps {
	open: boolean;
	isLoading: boolean;
	selectedId?: string;
	onSelect: (encounter: IPatientEncounter) => void;
	onClose?: () => void;
	encounters: IPatientEncounter[];
}

function SelectEncounters({ encounters, isLoading, selectedId, onSelect, open, onClose }: ISelectEncountersProps) {
	const { t } = useTranslation(namespaces.components.selectEncounters);

	return (
		<Dialog fullWidth maxWidth="sm" onClose={onClose} open={open}>
			<DialogTitle textAlign="center">{t('selectEncounters')}</DialogTitle>
			{!isLoading ? (
				<Paper
					sx={{
						overflow: 'auto',
					}}
				>
					<List>
						{encounters.length > 0 ? (
							encounters.map((encounter: IPatientEncounter) => (
								<ListItem
									button
									key={encounter.documentID}
									onClick={() => onSelect(encounter)}
									selected={encounter.documentID === selectedId}
									disabled={encounter.documentID === selectedId}
								>
									<ListItemText
										primary={encounter.title}
										secondary={
											<>
												Created On: {dayjs(encounter.createDateTime).format(dateFormat)} <br />
												Created By: {encounter.renderingProviderFullName} <br />
												{encounter.createByFullName}
												{encounter.documentStatus}
											</>
										}
									/>
									<Box alignItems="center">
										{encounter.documentID === selectedId ? <CheckIcon sx={{ color: 'secondary.main' }} /> : null}
									</Box>
									<Divider />
								</ListItem>
							))
						) : (
							<Box
								sx={{
									overflow: 'auto',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									padding: '5rem',
								}}
							>
								<Typography>{t('noEncountersFound')}</Typography>
							</Box>
						)}
					</List>
				</Paper>
			) : (
				<Box
					sx={{
						display: 'flex',
						flex: 1,
						minHeight: '10rem',
						overflow: 'auto',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<Spinner />
				</Box>
			)}
		</Dialog>
	);
}

SelectEncounters.defaultProps = {
	onClose: undefined,
};

export default SelectEncounters;
