import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import VerticalTabPanel from '../../components/tab-panel/VerticalTabPanel';
import Patients from '../PatientsSearch/PatientsSearch';
import Jobs from '../JobsSearch/JobsSearch';
import { routes, SearchTab, searchTabs } from '../App/routes';
import { navigateToWorkListTab } from '../../system/navigator';
import { namespaces } from '../../application/i18n.constants';
import PageContainer from '../../components/page-container/PageContainer';

function Search() {
	const { t } = useTranslation(namespaces.components.verticalTabBar);
	const { tab } = useParams();

	const currentTab: SearchTab =
		tab && Object.values(searchTabs).find((x: SearchTab) => x === tab) ? (tab as SearchTab) : searchTabs.patients;

	return (
		<PageContainer sx={{ pt: 0 }} disablePadding>
			<VerticalTabPanel
				currentTab={currentTab}
				tabs={[
					{
						id: 'patients',
						name: t('patients'),
						component: <Patients />,
						onPress: () => {
							navigateToWorkListTab({ to: routes.searchTabs.patients });
						},
					},
					{
						id: 'jobs',
						name: t('jobs'),
						component: <Jobs />,
						onPress: () => {
							navigateToWorkListTab({ to: routes.searchTabs.jobs });
						},
					},
				]}
			/>
		</PageContainer>
	);
}

export default Search;
