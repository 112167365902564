import { call, select, takeLatest } from 'redux-saga/effects';
import { Action } from 'redux';
import { EHRType } from './models/ehr.models';
import { AppState } from './core.types';
import { IAction } from './state/actions';

interface IActionCriteria {
	EHR: EHRType[];
}

export function* watchActionSaga(params: {
	action: Action;
	executors: Array<{ criteria: IActionCriteria; processor: (action: Action) => void }>;
}) {
	const ehr: EHRType | undefined = yield select((state: AppState) => state.user.settings.system);

	yield* params.executors.map(function* processor(e) {
		if (ehr && e.criteria.EHR.includes(ehr)) {
			yield call(e.processor, params.action);
		}
	});
}

export function takeLatestGeneric<T>(
	actionType: string,
	executors: Array<{ criteria: IActionCriteria; processor: (action: IAction<T>) => void }>
) {
	return takeLatest(actionType, function* watcher(action: Action) {
		yield call(watchActionSaga as never, {
			action,
			executors,
		});
	});
}
