export interface IOpenAiSummaryDiagnose {
	description: string;
	'ICD10 Code': string;
	'SNOMED Code': string;
}

export function textTransformer(text: string) {
	// numbered lists from new line
	const regex = /(\d+\.\s|\s-\s)\S+/g;
	return text.replace(regex, '\n$&').replace(/^\n+/, '');
}

export function buildAssessmentAndPlan(assessment?: string, plan?: string) {
	let result = '';
	if (assessment) {
		// result = `${result}\n\nAssessment:\n${assessment}`;
		result = `${result}\n\n${assessment}`;
	}
	if (plan) {
		// result = `${result}\n\nPlan:\n${plan}`;
		result = `${result}\n\n${plan}`;
	}

	return result.replace(/^\n+/, '');
}

export function buildPE(pe?: string, radiology?: string, imaging?: string) {
	let result = '';
	if (pe) {
		result = `${result}\n\n${pe}`;
	}
	if (radiology) {
		// result = `${result}\n\nRadiology:\n${radiology}`;
		result = `${result}\n\n${radiology}`;
	}
	if (imaging) {
		// result = `${result}\n\nImaging:\n${imaging}`;
		result = `${result}\n\n${imaging}`;
	}

	return result.replace(/^\n+/, '');
}

export function hasHeader(header: string) {
	const match = header.match(/^[^\n:]+:/);
	return match && match.length > 0;
}

export function parseJsonSting<T>(str: string) {
	try {
		return JSON.parse(str) as T;
	} catch (e) {
		return null;
	}
}

export function parseDiagnoses(
	diagnoses?: string
): Array<{ SNOMEDCode: string; ICD10Code: string; Description: string }> | string | null {
	if (!diagnoses || typeof diagnoses !== 'string') {
		return null;
	}
	const parsedDiagnoses = parseJsonSting<IOpenAiSummaryDiagnose[]>(diagnoses);

	if (parsedDiagnoses && Array.isArray(parsedDiagnoses)) {
		return parsedDiagnoses.map((x) => ({
			Description: x.description,
			ICD10Code: x['ICD10 Code'],
			SNOMEDCode: x['SNOMED Code'],
		}));
	}
	return diagnoses;
}
