import { Alert, Box, Stack, Switch, SxProps, Typography, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/system';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sortBy } from 'lodash';
import LoadingButton from '@mui/lab/LoadingButton';
import DataTable, { Align, IDataTableRow } from '../../../components/data-table/DataTable';
import { namespaces } from '../../../application/i18n.constants';
import { IWordReplacement } from '../../../core/models/replacements/replacements.models';
import SearchBar from '../../../components/search-bar/SearchBar';
import AutocompleteSelectField from '../../../components/select/AutocompleteSelectField';
import { IProvider } from '../../../core/models/providers/providers.models';
import SaveWordReplacementDialog from '../../../components/word-replacements/SaveWordReplacementDialog';
import DeleteMacrosDialog from '../../../components/macros/DeleteMacrosDialog';

interface IWordReplacementsPageLevelProps {
	onDelete?: (params: { id: string; providerId: string | null; customerId: string | null }) => void;
	onAdd?: (params: {
		providerId: string | null;
		customerId: string | null;
		word: string;
		wordReplacement: string;
	}) => void;
	onUpdate?: (params: {
		id: string;
		providerId: string | null;
		customerId: string | null;
		word: string;
		wordReplacement: string;
	}) => void;
	replacements: IWordReplacement[];
	loading: boolean;
}

interface IWordReplacementsPageProps {
	currentCustomer: string;
	user: IWordReplacementsPageLevelProps;
	customer: IWordReplacementsPageLevelProps;
	providers: {
		canView: boolean;
		providerId: string | null;
		allProviders: IProvider[];
		providersAreLoading: boolean;
		onSelectProvider: (providerId: string | null) => void;
	};
	customers: {
		canView: boolean;
		customerId: string | null;
		allCustomers: { id: string; name: string }[];
		customersAreLoading: boolean;
		onSelectCustomer: (customerId: string | null) => void;
	};
}

function WordReplacementsPage({ currentCustomer, user, customer, providers, customers }: IWordReplacementsPageProps) {
	const { t } = useTranslation(namespaces.pages.wordReplacements);
	const smallScreen = useMediaQuery((th: Theme) => th.breakpoints.down('sm'));

	const [saveReplacementDialogOpen, setSaveReplacementDialogOpen] = useState(false);
	const [deleteWordReplacementDialogOpen, setDeleteWordReplacementDialogOpen] = useState(false);
	const [deleteWordReplacement, setDeleteWordReplacement] = useState<IWordReplacement | null>(null);
	const [currentReplacement, setCurrentReplacement] = useState<IWordReplacement | null>(null);

	const [level, setLevel] = useState<'user' | 'customer'>('user');
	const { loading, onDelete, onAdd, onUpdate, replacements } = level === 'user' ? user : customer;
	const [searchQuery, setSearchQuery] = useState<string>('');

	const columns: Array<{ name: string; sx?: SxProps<Theme>; align?: Align }> = [
		{
			name: t('word'),
		},
		{
			name: t('replacement'),
			align: 'left',
		},
	];

	if (onDelete) {
		columns.push({
			name: '',
		});
	}
	const filteredReplacements = useMemo(
		() =>
			sortBy(
				replacements.filter(
					(x) =>
						x.text.toLowerCase().includes(searchQuery.toLowerCase()) ||
						x.replacement.toLowerCase().includes(searchQuery.toLowerCase())
				),
				(value) => value.text.toLowerCase()[0]
			),
		[replacements]
	);

	const rows: Array<{ data: IDataTableRow[] }> = filteredReplacements.map((word: IWordReplacement) => {
		const row: { data: IDataTableRow[] } = {
			data: [
				{
					value: word.text,
					align: 'left',
				},
				{
					value: word.replacement,
					align: 'left',
				},
			],
		};

		return row;
	});

	const onDeleteClick = (rowIndex: number) => {
		const replacement = filteredReplacements[rowIndex];
		if (replacement) {
			setDeleteWordReplacementDialogOpen(true);
			setDeleteWordReplacement(replacement);
		}
	};

	const handleDeleteDotPhrase = () => {
		if (deleteWordReplacement) {
			onDelete?.({
				id: deleteWordReplacement.id,
				providerId: providers?.providerId || null,
				customerId: customers?.customerId || null,
			});
			setDeleteWordReplacementDialogOpen(false);
		}
	};

	const onEditClick = (rowIndex: number) => {
		const replacement = filteredReplacements[rowIndex];
		setCurrentReplacement(replacement);
		setSaveReplacementDialogOpen(true);
	};

	const onAddClick = () => {
		setSaveReplacementDialogOpen(true);
	};

	const onAddReplacement = (params: {
		providerId: string | null;
		customerId: string | null;
		word: string;
		wordReplacement: string;
	}) => {
		onAdd?.(params);
	};

	const onUpdateReplacement = (params: {
		id: string;
		providerId: string | null;
		customerId: string | null;
		word: string;
		wordReplacement: string;
	}) => {
		onUpdate?.(params);
	};

	const providerSelected = level === 'user' && !!providers?.providerId;
	const providerNotSelected = level === 'user' && !providers?.providerId;
	const customerSelected = level === 'customer' && !!customers?.customerId;
	const customerNotSelected = level === 'customer' && !customers?.customerId;

	useEffect(() => {
		if (customers?.canView) {
			if (level === 'user') {
				providers?.onSelectProvider(null);
			} else {
				providers?.onSelectProvider(null);
				customers?.onSelectCustomer(currentCustomer);
			}
		}
	}, [level]);

	const currentProvider = providers.allProviders.find((x) => x.id === providers.providerId) || {
		id: '',
		name: '',
	};

	const providersList = (providers?.canView ? providers.allProviders : [currentProvider]).map((x) => ({
		value: x.id,
		text: x.name,
	}));

	return (
		<Box sx={{ flexDirection: 'column', minWidth: 640 }}>
			{customers?.canView && (
				<Stack sx={{ mt: 2 }} direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
					<Typography>{t('user')}</Typography>
					<Switch
						checked={level === 'customer'}
						inputProps={{ 'aria-label': 'ant design' }}
						onChange={(e, value) => setLevel(value ? 'customer' : 'user')}
					/>
					<Typography>{t('customer')}</Typography>
				</Stack>
			)}
			{providerNotSelected ? (
				<Alert sx={{ mt: 1 }} severity="info">
					{t('noProviderSelected')}
				</Alert>
			) : null}
			{customerNotSelected ? (
				<Alert sx={{ mt: 1 }} severity="info">
					{t('noCustomerSelected')}
				</Alert>
			) : null}
			<Box
				sx={{
					display: 'flex',
					flexDirection: smallScreen ? 'column' : 'row',
					height: smallScreen ? 'initial' : '3rem',
					alignItems: 'center',
					mt: 2,
				}}
			>
				{level === 'user' && providers ? (
					<AutocompleteSelectField
						textFieldProps={{
							required: true,
							placeholder: t('selectProvider'),
						}}
						sx={{ width: 220, mr: 2 }}
						fullWidth
						disabled={!providers?.canView}
						onChange={(e, value) => providers?.onSelectProvider(value)}
						// groupBy={(option) => option.firstLetter}
						disableClearable
						value={currentProvider?.id}
						data={sortBy(providersList, [(o) => o.text.toLowerCase()])}
						size="medium"
					/>
				) : null}
				{level === 'customer' && customers ? (
					<AutocompleteSelectField
						textFieldProps={{
							required: true,
							placeholder: t('selectCustomer'),
						}}
						sx={{ width: 220, mr: 2 }}
						fullWidth
						disabled={!customers?.canView}
						onChange={(e, value) => customers?.onSelectCustomer(value)}
						// groupBy={(option) => option.firstLetter}
						disableClearable
						value={customers.customerId || ''}
						data={[
							...sortBy([...customers.allCustomers], [(o) => o.name.toLowerCase()]).map((x) => ({
								value: x.id,
								text: x.name,
							})),
						]}
						size="medium"
					/>
				) : null}
				<SearchBar
					value={searchQuery}
					disabled={replacements.length === 0}
					onInputChange={setSearchQuery}
					sx={{ p: 0, flex: 2 }}
					textFieldProps={{
						variant: 'outlined',
					}}
					placeholder={t('searchWordReplacements')}
				/>
				<LoadingButton
					loading={loading}
					variant="contained"
					size="small"
					onClick={onAddClick}
					sx={{ height: 56, ml: 2 }}
				>
					{t('add')}
				</LoadingButton>
			</Box>
			{providerSelected || customerSelected ? (
				<Box sx={{ mt: 2 }}>
					<DataTable
						columns={columns}
						rows={rows}
						onRowClick={onEditClick}
						onDeleteClick={onDeleteClick}
						tableContainerProps={{ sx: { height: '70vh', overflowY: 'auto' } }}
					/>
				</Box>
			) : null}
			<SaveWordReplacementDialog
				providerDisabled={!providers?.canView}
				open={saveReplacementDialogOpen}
				replacement={currentReplacement || undefined}
				providers={
					providers && level === 'user'
						? { allProviders: providers.allProviders, preSelectedProvider: providers.providerId || undefined }
						: undefined
				}
				customers={
					customers && level === 'customer'
						? { allCustomers: customers.allCustomers, preSelectedCustomer: customers.customerId || undefined }
						: undefined
				}
				add={{
					action: (params) => {
						onAddReplacement(params);
						setSaveReplacementDialogOpen(false);
						setCurrentReplacement(null);
					},
					text: t('submit'),
				}}
				update={{
					action: (params) => {
						onUpdateReplacement(params);
						setSaveReplacementDialogOpen(false);
						setCurrentReplacement(null);
					},
					text: t('submit'),
				}}
				cancel={{
					action: () => {
						setSaveReplacementDialogOpen(false);
						setCurrentReplacement(null);
					},
					text: t('cancel'),
				}}
			/>
			<DeleteMacrosDialog
				text={t('deleteReplacementText', {
					word: deleteWordReplacement?.text,
					replacement: deleteWordReplacement?.replacement,
				})}
				open={deleteWordReplacementDialogOpen}
				deletePhrase={{ action: handleDeleteDotPhrase, text: t('confirm') }}
				cancel={{
					action: () => {
						setDeleteWordReplacementDialogOpen(false);
						setDeleteWordReplacement(null);
					},
					text: t('cancel'),
				}}
			/>
		</Box>
	);
}

export default WordReplacementsPage;
