import * as React from 'react';
import { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Box, Tab, TabProps, Tooltip, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import MicIcon from '@mui/icons-material/Mic';
import EditIcon from '@mui/icons-material/Edit';
import FeedbackIcon from '@mui/icons-material/Feedback';
import AdbIcon from '@mui/icons-material/Adb';
import { IQANote, ITimestampedTranscription } from '../../../core/models/dictations/dictations.models';
import { namespaces } from '../../../application/i18n.constants';
import QaNotes, { IQaNotes } from '../../qa-notes/QaNotes';
import Transcription, { ITranscription } from '../../transcription/Transcription';
import LazyInput from '../../inputs/LazyInput';
import AIPrompt from '../../ai-prompt/AIPrompt';
import { AIModelType } from '../../../core/api/dictations/dictations.api.models';

export interface IDictationSidebarTabs {
	addQaNote: () => void;
	seekTranscription: (timestamp: number) => void;
}

function MenuTab({
	value,
	tabName,
	icon,
	...props
}: { value: string; tabName: string; icon: React.ReactNode } & TabProps) {
	return (
		<Tab
			value={value}
			icon={
				<Tooltip title={tabName}>
					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
						{icon}
						<Typography fontWeight="bold" variant="caption" style={{ fontSize: '0.7rem' }}>
							{tabName}
						</Typography>
					</div>
				</Tooltip>
			}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
			sx={{ p: 0, ...props?.sx }}
		/>
	);
}

export interface IDictationSidebarTabsProps {
	qaNotes: IQANote[];
	disabledTabs?: DictationSidebarTabType[];
	disableAddNewNote?: boolean;
	canEditNoteIsDone: boolean;
	qaNotesIsEditing: boolean;
	handleQANoteIsEditing: (notesIsEditing: boolean) => void;
	handleQANoteAdd: () => void;
	handleQANotesChange: (notes: IQANote[]) => void;
	handleQANoteSubmit: (note: IQANote) => void;
	handleQANoteMessageClick: (timestamp: number) => void;
	transcription: {
		azure: { text: string | null; timestampedText: ITimestampedTranscription[] | null };
		mModal: string | null;
		nuance: string | null;
	};
	handleTranscriptMessageClick: (timestamp: number) => void;
	providerNote: string;
	onChangeProviderNote?: (providerNote: string) => void;
	aiPrompt?: {
		prompt: string;
		instructions: string;
		canUpdate: boolean;
		onChangePrompt: (aiPrompt: string) => void;
		onChangeInstructions: (aiInstructions: string) => void;
		lastTimePromptSubmitted: number | null;
		onPromptSubmit: (aiModel: AIModelType | null, prompt: { prompt: string; instructions: string }) => void;
	};
}

export enum DictationSidebarTabType {
	TRANSCRIPT = 'transcript',
	QA_NOTES = 'qaNotes',
	PROVIDER_NOTE = 'providerNote',
	AI_PROMPT = 'aiPrompt',
}

const DictationSidebarTabs: React.ForwardRefRenderFunction<IDictationSidebarTabs, IDictationSidebarTabsProps> =
	function DictationSidebarTabsFunc(
		{
			qaNotes,
			disabledTabs,
			disableAddNewNote,
			canEditNoteIsDone,
			qaNotesIsEditing,
			handleQANoteIsEditing,
			handleQANoteAdd,
			handleQANotesChange,
			handleQANoteSubmit,
			handleQANoteMessageClick,
			transcription,
			handleTranscriptMessageClick,
			providerNote,
			onChangeProviderNote,
			aiPrompt,
		}: IDictationSidebarTabsProps,
		forwardedRef: ForwardedRef<IDictationSidebarTabs>
	) {
		const { t } = useTranslation(namespaces.pages.dictation);

		const qaNotesRef = useRef<IQaNotes>(null);
		const transcriptionRef = useRef<ITranscription>(null);

		const hasTranscription = transcription.azure?.text || transcription.mModal || transcription.nuance;

		const isTabEnabled = (tab: DictationSidebarTabType) => {
			if (!disabledTabs) {
				return true;
			}
			return !disabledTabs.find((x) => x === tab);
		};

		const initialTab: DictationSidebarTabType =
			hasTranscription || !isTabEnabled(DictationSidebarTabType.PROVIDER_NOTE)
				? DictationSidebarTabType.TRANSCRIPT
				: DictationSidebarTabType.PROVIDER_NOTE;

		const [sidebarMenuTab, setSidebarMenuTab] = useState<DictationSidebarTabType>(initialTab);
		const [shouldAddANote, setShouldAddANote] = useState<boolean>(false);

		useEffect(() => {
			if (hasTranscription) {
				setSidebarMenuTab(initialTab);
			}
		}, [hasTranscription]);

		useImperativeHandle(forwardedRef, () => ({
			addQaNote() {
				if (sidebarMenuTab !== DictationSidebarTabType.QA_NOTES) {
					setShouldAddANote(true);
					setSidebarMenuTab(DictationSidebarTabType.QA_NOTES);
				} else if (qaNotesRef?.current) {
					qaNotesRef.current.addQaNote();
				}
			},
			seekTranscription(timestamp: number) {
				transcriptionRef.current?.seek(timestamp);
			},
		}));

		useEffect(() => {
			if (shouldAddANote && sidebarMenuTab === DictationSidebarTabType.QA_NOTES) {
				if (qaNotesRef?.current) {
					qaNotesRef.current.addQaNote();
				}
				setShouldAddANote(false);
			}
		}, [sidebarMenuTab, shouldAddANote]);

		const handleSidebarTabChange = (event: React.SyntheticEvent, newValue: DictationSidebarTabType) => {
			setSidebarMenuTab(newValue);
		};

		const tabSx = { padding: 0, height: '100%' };

		const updateAIPromptEnabled = !!aiPrompt?.canUpdate;

		return (
			<TabContext value={sidebarMenuTab}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<TabList onChange={handleSidebarTabChange}>
						{hasTranscription && isTabEnabled(DictationSidebarTabType.TRANSCRIPT) && (
							<MenuTab value={DictationSidebarTabType.TRANSCRIPT} tabName={t('transcript')} icon={<MicIcon />} />
						)}
						{isTabEnabled(DictationSidebarTabType.PROVIDER_NOTE) && (
							<MenuTab
								sx={{ ml: 1 }}
								value={DictationSidebarTabType.PROVIDER_NOTE}
								tabName={t('providerNote')}
								icon={<EditIcon />}
							/>
						)}
						{isTabEnabled(DictationSidebarTabType.QA_NOTES) && (
							<MenuTab value={DictationSidebarTabType.QA_NOTES} tabName={t('qaNotes')} icon={<FeedbackIcon />} />
						)}
						{updateAIPromptEnabled && isTabEnabled(DictationSidebarTabType.AI_PROMPT) && (
							<MenuTab value={DictationSidebarTabType.AI_PROMPT} tabName={t('aiPrompt')} icon={<AdbIcon />} />
						)}
					</TabList>
				</Box>
				<Box sx={{ overflowY: 'auto', height: 'calc(80vh)' }}>
					<TabPanel sx={tabSx} value={DictationSidebarTabType.TRANSCRIPT}>
						{hasTranscription && (
							<Transcription
								containerSx={{ height: '100%' }}
								ref={transcriptionRef}
								azureASR={transcription.azure}
								mModalASR={transcription.mModal}
								nuanceASR={transcription.nuance}
								handleMessageClick={handleTranscriptMessageClick}
							/>
						)}
					</TabPanel>
					<TabPanel sx={tabSx} value={DictationSidebarTabType.PROVIDER_NOTE}>
						{isTabEnabled(DictationSidebarTabType.PROVIDER_NOTE) && (
							<Box sx={{ height: '100%', p: 2 }}>
								<LazyInput
									style={
										{
											width: '100%',
											maxHeight: '100%',
											resize: 'none',
											alignItems: 'flex-start',
										} as React.CSSProperties
									}
									placeholder={onChangeProviderNote ? t('providerNotePlaceholder') : t('noProviderNote')}
									// multiline
									// disableUnderline
									disabled={!onChangeProviderNote}
									value={providerNote}
									onChange={onChangeProviderNote ? (value) => onChangeProviderNote(value) : undefined}
								/>
							</Box>
						)}
					</TabPanel>
					<TabPanel sx={tabSx} value={DictationSidebarTabType.QA_NOTES}>
						{isTabEnabled(DictationSidebarTabType.QA_NOTES) && (
							<QaNotes
								containerSx={{ height: '100%' }}
								ref={qaNotesRef}
								qaNotes={qaNotes}
								disableAddNewNote={disableAddNewNote}
								canEditNoteIsDone={canEditNoteIsDone}
								qaNotesIsEditing={qaNotesIsEditing}
								handleQANoteClick={handleQANoteMessageClick}
								handleQANoteActive={handleQANoteIsEditing}
								handleQANoteAdd={handleQANoteAdd}
								handleQANotesChange={handleQANotesChange}
								handleANoteSubmit={handleQANoteSubmit}
							/>
						)}
					</TabPanel>
					<TabPanel sx={tabSx} value={DictationSidebarTabType.AI_PROMPT}>
						{updateAIPromptEnabled && isTabEnabled(DictationSidebarTabType.AI_PROMPT) && (
							<AIPrompt
								containerSx={{ height: '100%' }}
								lastTimePromptSubmitted={aiPrompt?.lastTimePromptSubmitted}
								prompt={aiPrompt?.prompt}
								instructions={aiPrompt?.instructions}
								onChangePrompt={aiPrompt?.onChangePrompt}
								onChangeInstructions={aiPrompt?.onChangeInstructions}
								onSubmit={aiPrompt?.onPromptSubmit}
							/>
						)}
					</TabPanel>
				</Box>
			</TabContext>
		);
	};

export default forwardRef(DictationSidebarTabs);
