import LogrocketFuzzySanitizer from 'logrocket-fuzzy-search-sanitizer';
import LogRocket from 'logrocket';
import { isEmpty } from 'lodash';
import { environment } from '../environment/environment';
import { endpoints } from '../core/api/endpoints';
import { commonPrivateFields } from './object-sanitizer';

const { ENV, LOGROCKET_APP_ID } = environment;

interface IRequest {
	reqId: string;
	url: string;
	headers: { [key: string]: string | undefined };
	body?: string;
	method: string;
	referrer?: string;
	mode?: string;
	credentials?: string;
}

interface IResponse {
	reqId: string;
	status?: number;
	headers: { [key: string]: string | undefined };
	body?: string;
	method: string;
}

interface INetworkRequestResponse {
	body: unknown;
	method: string;
	headers: object;
}

type TrackEventProperties = {
	revenue?: number;
	[key: string]: string | number | boolean | string[] | number[] | boolean[] | undefined;
};

const requestUrl = (url1: string, url2: string): boolean => url1.toLowerCase().includes(url2.toLowerCase());

export const sanitizeEncounterDataJson = (
	request: {
		headers: { [key: string]: string | undefined };
		body?: string;
		method: string;
	},
	sanitizer: (request: INetworkRequestResponse) => { body: string }
) => {
	try {
		if (!request?.body || isEmpty(request.body)) {
			return request;
		}
		const jsonRequest = JSON.parse(request.body || '');
		if (jsonRequest.EncounterData) {
			const encounterData = jsonRequest.EncounterData;

			const sanitizedEncounterData = sanitizer({
				headers: request.headers,
				method: request.method,
				body: encounterData,
			});

			const sanitizedResult = { ...jsonRequest, EncounterData: JSON.stringify(sanitizedEncounterData.body) };
			return { ...request, body: JSON.stringify(sanitizedResult) };
		}
		return request;
	} catch (ex) {
		// eslint-disable-next-line no-console
		console.warn(ex);
		return request;
	}
};

const sanitizeCheckInRequest = (
	request: IRequest,
	sanitizer: (request: INetworkRequestResponse) => { body: string }
) => {
	const newRequest = { ...request };

	if (requestUrl(request.url, endpoints.documents.dictatedDocumentsWorklistCheckIn)) {
		return { ...newRequest, body: sanitizeEncounterDataJson(newRequest, sanitizer).body };
	}

	return newRequest;
};

const sanitizeCheckOutResponse = (
	response: IResponse,
	sanitizer: (response: INetworkRequestResponse) => { body: string }
) => {
	const newResponse = { ...response };

	return { ...newResponse, body: sanitizeEncounterDataJson(newResponse, sanitizer).body };
};

const logRocketRequestSanitizer = (request: IRequest): IRequest => {
	const privateFieldNames = [...commonPrivateFields];
	const { requestSanitizer } = LogrocketFuzzySanitizer.setup([...privateFieldNames]);

	const newRequest = sanitizeCheckInRequest(request, requestSanitizer);

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	return requestSanitizer(newRequest);
};

const logRocketResponseSanitizer = (response: IResponse): IResponse => {
	const privateFieldNames = [...commonPrivateFields];
	const { responseSanitizer } = LogrocketFuzzySanitizer.setup([...privateFieldNames]);

	const newRequest = sanitizeCheckOutResponse(response, responseSanitizer);

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	return responseSanitizer(newRequest);
};

const logRocketURLSanitizer = (url: string): string => {
	const privateFieldNames = [...commonPrivateFields];
	let sanitizedUrl = url;

	privateFieldNames.forEach((param) => {
		const regex = new RegExp(`${param}=([^&]*)`, 'i');
		const globalRegex = new RegExp(`${param}=([^&]*)`, 'gi');
		const matches = sanitizedUrl.matchAll(globalRegex);
		const matchedValues = Array.from(matches, (res) => res[0]);

		matchedValues.forEach((match) => {
			const matchValues = match.split('=');
			if (matchValues.length === 2) {
				const paramName = matchValues[0];
				sanitizedUrl = sanitizedUrl.replace(regex, `${paramName}=*`);
			}
		});
	});

	return sanitizedUrl;
};

const enabled = (env: string): boolean => {
	const enabledEnvs = ['prod'];
	return !!enabledEnvs.find((x) => x === env);
};

export const initLogRocket = () => {
	if (enabled(ENV)) {
		LogRocket.init(LOGROCKET_APP_ID, {
			shouldCaptureIP: false,
			network: {
				requestSanitizer: logRocketRequestSanitizer,
				responseSanitizer: logRocketResponseSanitizer,
			},
			browser: {
				urlSanitizer: logRocketURLSanitizer,
			},
		});
	}
};

export const identifyLogRocketUser = ({ username }: { username: string }) => {
	if (enabled(ENV)) {
		LogRocket.identify(username, {
			username,
		});
	}
};

export const logRocketTrackEvent = (name: string, payload?: TrackEventProperties) => {
	if (enabled(ENV)) {
		LogRocket.track(name, payload);
	}
};
