import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, Button, Container, IconButton, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { sortBy } from 'lodash';
import { namespaces } from '../../application/i18n.constants';
import { actions } from '../../core/state/actions';
import { AdminTab, routes } from '../../pages/App/routes';
import IScribeLogo from './iscribe-logo.svg';
import { useAppDispatch, useAppSelector } from '../../core/core.types';
import { getQueryFromString } from '../../core/services/patients/patients.services';
import NavbarSearch from './NavbarSearch';
import { IScriberPermissions, IUserCustomerPermissionsItem } from '../../core/models/users/user.models';
import { navigateToWorkListTab } from '../../system/navigator';
import { IJobsSearchFilters } from '../../core/models/dictations/dictations.models';
import { IPatientsAdvancedSearchFilters } from '../../core/models/patients/patients.models';
import AutocompleteSelectField from '../select/AutocompleteSelectField';
import AdminPages from '../../pages/Admin/adminPages';

interface Page {
	page: string;
	route: string;
	subPages?: Page[];
	requiredPermissions?: string;
}

const pages: Page[] = AdminPages;

function Navbar() {
	const { t } = useTranslation(namespaces.components.navbar);

	const history = useNavigate();
	const location = useLocation();
	const dispatch = useAppDispatch();

	const permissions = useAppSelector<IScriberPermissions>((state) => state.user.settings.permissions);

	const userCustomerPermissions = useAppSelector<IUserCustomerPermissionsItem[]>(
		(state) => state.user.settings.userCustomerPermissions
	);

	const currentClinicId = useAppSelector<string>((state) => state.user.settings.customerId);
	const currentClinic = userCustomerPermissions.find((x) => x.customerId.toString() === currentClinicId);

	const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
	const [anchorElDropdown, setAnchorElDropdown] = React.useState<null | HTMLElement>(null);

	const onSignOut = () => {
		dispatch(actions.auth.signOutAction());
	};

	const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleOpenDropdownMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElDropdown(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleCloseDropdownMenu = () => {
		setAnchorElDropdown(null);
	};

	const changeRoute = (route: string) => {
		handleCloseDropdownMenu();
		history(route);
	};

	const handlePatientSearch = (searchQuery: string) => {
		navigateToWorkListTab({
			to: routes.searchTabs.patients,
			filters: getQueryFromString(searchQuery) as IPatientsAdvancedSearchFilters,
		});
	};

	const handleJobsSearch = (searchQuery: string) => {
		navigateToWorkListTab({
			to: routes.searchTabs.jobs,
			filters: getQueryFromString(searchQuery) as IJobsSearchFilters,
		});
	};

	const handleSwitchClinic = (clinicId: string) => {
		dispatch(actions.worklist.setClinic({ customerId: clinicId }));
	};

	const getCustomerOptionGroup = useCallback((option: string) => {
		const customer = userCustomerPermissions.find((x) => x.customerId.toString() === option);
		const group = customer?.internalType;
		return group === 'default' ? 'Default' : 'Other';
	}, []);

	const renderMobileMenuPages = (page: Page) => {
		if (page.requiredPermissions && !(page.requiredPermissions.toLowerCase() in permissions)) {
			return null;
		}

		if (page.subPages) {
			return page.subPages
				.filter((x) => x.requiredPermissions !== 'admin' || permissions.admin)
				.map((subPage: Page) => (
					<MenuItem key={subPage.page} onClick={handleCloseNavMenu}>
						<Button key={subPage.page} onClick={() => changeRoute(subPage.route)}>
							{t(subPage.page)}
						</Button>
					</MenuItem>
				));
		}

		return (
			<MenuItem key={page.page} onClick={handleCloseNavMenu}>
				<Button key={page.page} onClick={() => changeRoute(page.route)}>
					{t(page.page)}
				</Button>
			</MenuItem>
		);
	};

	const renderMenuPages = (page: Page) => {
		if (page.requiredPermissions && !(page.requiredPermissions.toLowerCase() in permissions)) {
			return null;
		}

		if (page.subPages) {
			// WIP
			return null;
		}

		return (
			<Button key={page.page} sx={{ my: 2, color: 'white', display: 'block' }} onClick={() => changeRoute(page.route)}>
				{t(page.page)}
			</Button>
		);
	};

	const adminPages = pages
		.find((x) => x.page === 'admin')
		?.subPages?.filter((x) => x.requiredPermissions !== 'admin' || permissions.admin);
	const adminSubpagesRoutes = adminPages?.map((x) => x.page);

	return (
		<AppBar position="sticky">
			<Container maxWidth="xl">
				<Toolbar disableGutters>
					<Typography
						variant="h6"
						noWrap
						component="a"
						href="/"
						sx={{
							mr: 2,
							display: { xs: 'none', md: 'flex' },
							fontFamily: 'monospace',
							fontWeight: 700,
							letterSpacing: '.3rem',
							color: 'inherit',
							textDecoration: 'none',
						}}
					>
						<img src={IScribeLogo} alt="iScribe logo" />
					</Typography>
					<Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
						<IconButton
							size="large"
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleOpenNavMenu}
							color="inherit"
						>
							<MenuIcon />
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}
							open={Boolean(anchorElNav)}
							onClose={handleCloseNavMenu}
							sx={{
								display: { xs: 'block', md: 'none' },
							}}
						>
							{pages.map((page) => renderMobileMenuPages(page))}
							<MenuItem>
								<Button onClick={onSignOut}>{t('logOut')}</Button>
							</MenuItem>
						</Menu>
						<Typography
							variant="h6"
							noWrap
							component="a"
							href=""
							sx={{
								mr: 2,
								alignItems: 'center',
								display: { xs: 'flex', md: 'none' },
								flexGrow: 1,
								fontWeight: 700,
								color: 'inherit',
								textDecoration: 'none',
							}}
						>
							IScribe
						</Typography>
					</Box>
					<Box sx={{ flexGrow: 1, height: 64, display: { xs: 'none', md: 'flex' } }}>
						{pages.map((page) => renderMenuPages(page))}
						<Button
							sx={{ my: 2, color: 'white', display: 'block' }}
							aria-controls={anchorElDropdown ? 'basic-menu' : undefined}
							aria-haspopup="true"
							aria-expanded={anchorElDropdown ? 'true' : undefined}
							onClick={handleOpenDropdownMenu}
						>
							{t('admin')}
						</Button>
						<Menu anchorEl={anchorElDropdown} open={!!anchorElDropdown} onClose={handleCloseDropdownMenu}>
							{adminSubpagesRoutes?.map((x: string) => (
								<MenuItem key={x} onClick={() => changeRoute(routes.adminTabs[x as AdminTab])}>
									{t(x)}
								</MenuItem>
							))}
						</Menu>
						<Button onClick={onSignOut} sx={{ my: 2, color: 'white', display: 'block' }}>
							{t('logOut')}
						</Button>
					</Box>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						{location.pathname === routes.workList ||
						location.pathname === routes.adminTabs.customerMacros ||
						location.pathname === routes.adminTabs.providerMacros ||
						location.pathname === routes.accountSettings ? (
							<AutocompleteSelectField
								sx={{ width: 220, mr: 2 }}
								fullWidth
								componentsProps={{
									popper: {
										style: {
											// eslint-disable-next-line @typescript-eslint/ban-ts-comment
											// @ts-ignore
											width: 'fit-content',
										},
									},
								}}
								textFieldProps={{
									// eslint-disable-next-line @typescript-eslint/ban-ts-comment
									// @ts-ignore
									InputProps: {
										sx: {
											color: 'white',
											'.MuiOutlinedInput-notchedOutline': {
												borderColor: '#4b5c6a',
											},
											'&:hover .MuiOutlinedInput-notchedOutline': {
												borderColor: 'white', // Change border color when hovering
											},
											'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
												borderColor: 'white', // Change border color when the TextField is focused
											},
											'.MuiSvgIcon-root': {
												fill: 'white',
												'&.Mui-disabled': {
													fill: 'transparent',
												},
											},
											'.MuiPopover-paper': {
												maxHeight: 180,
											},
											'.MuiInput-input:focus': {
												backgroundColor: 'transparent',
											},
											'& .MuiSelect-select': {
												color: 'white !important',
												WebkitTextFillColor: 'white !important',
											},
										},
									},
								}}
								onChange={(e, value) => handleSwitchClinic(value)}
								groupBy={(option) => getCustomerOptionGroup(option)}
								disableClearable
								value={currentClinic?.customerId.toString() || ''}
								data={sortBy(
									[...userCustomerPermissions],
									[(o) => getCustomerOptionGroup(o.customerId.toString()), (o) => o.customerName?.toLowerCase()]
								).map((x) => ({
									value: x.customerId.toString(),
									text: x.customerName,
								}))}
							/>
						) : null}

						<NavbarSearch onPatientSearch={handlePatientSearch} onJobsSearch={handleJobsSearch} />
					</Box>
				</Toolbar>
			</Container>
		</AppBar>
	);
}

export default connect()(Navbar);
