import * as React from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';

interface IVerticalTabsProps {
	currentTab: string;
	tabs: {
		id: string;
		name: string;
		component: React.ReactNode;
		onPress: () => void;
	}[];
}

export default function VerticalTabPanel({ currentTab, tabs }: IVerticalTabsProps) {
	return (
		<TabContext value={currentTab}>
			<Tabs
				orientation="vertical"
				variant="scrollable"
				value={currentTab}
				onChange={(event: React.SyntheticEvent, value: string) => {
					event.preventDefault();
					const handler = tabs.find((x) => x.id === value)?.onPress;
					if (handler) {
						handler();
					}
				}}
				aria-label="tab"
				sx={{ borderRight: 1, borderColor: 'divider' }}
			>
				{tabs.map((x) => (
					<Tab key={`tab-${x.id}`} label={x.name} id={x.id} value={x.id} aria-controls={`vertical-tabpanel-${x.id}`} />
				))}
			</Tabs>
			<Box
				sx={{
					display: 'flex',
					flex: 1,
					justifyContent: 'center',
				}}
			>
				{tabs.map((x) => (
					<TabPanel key={`panel-${x.id}`} value={x.id}>
						{x.component}
					</TabPanel>
				))}
			</Box>
		</TabContext>
	);
}
