import * as React from 'react';
import { CSSProperties } from 'react';
import html2pdf from 'html2pdf.js';
import { createRoot } from 'react-dom/client';
import dayjs from 'dayjs';
import { IDictation, IOpenAISummary } from '../../core/models/dictations/dictations.models';
import { parseDiagnoses, textTransformer } from '../../utils/textTransformers.utils';
import { dateFormat } from '../../core/core.constants';
import { ICustomPatientDemographicsData, IPatientDemographic } from '../../core/models/patients/patients.models';

interface INote {
	hpi?: string;
	ros?: string;
	pe?: string;
	radiology?: string;
	imaging?: string;
	discussion?: string;
	assessment?: string;
	plan?: string;
	diagnoses?: string | Array<{ SNOMEDCode: string; ICD10Code: string; Description: string }>;
	emCode?: string;
	note?: string;
}

export interface INotePatientDemographics {
	name?: string;
	dateOfBirth?: string;
	mrn?: string;
	dictationDate?: string;
	gender?: string;
}

export const getNotePatientDemographics = (
	patientDemographic?: IPatientDemographic | null,
	customPatientDemographic?: ICustomPatientDemographicsData | null,
	dictation?: IDictation | null
): INotePatientDemographics => {
	return {
		// eslint-disable-next-line no-nested-ternary
		name: customPatientDemographic?.PatientDemographics?.PatientFullName
			? customPatientDemographic.PatientDemographics.PatientFullName
			: // eslint-disable-next-line no-nested-ternary
			patientDemographic?.firstName && patientDemographic?.lastName
			? `${patientDemographic.firstName} ${patientDemographic.lastName}`
			: dictation?.patientFirstName && dictation?.patientLastName
			? `${dictation.patientFirstName} ${dictation.patientLastName}`
			: undefined,
		dateOfBirth: customPatientDemographic?.PatientDemographics?.DateOfBirth
			? customPatientDemographic.PatientDemographics.DateOfBirth
			: patientDemographic?.dateOfBirth,
		mrn: customPatientDemographic?.PatientDemographics?.MRN
			? customPatientDemographic.PatientDemographics.MRN
			: patientDemographic?.medicalRecordNumber || undefined,
		dictationDate: dictation?.createdDateTime,
		gender: customPatientDemographic?.PatientDemographics?.Gender
			? customPatientDemographic.PatientDemographics.Gender
			: patientDemographic?.genderText || undefined,
	};
};

const styles: { [key: string]: CSSProperties } = {
	page: {
		display: 'flex',
		flexDirection: 'column',
		paddingLeft: 40,
		paddingRight: 40,
		fontSize: 12,
	},
	section: {
		margin: '5px 0',
		borderBottom: '1px solid black',
		paddingBottom: 5,
	},
	patientDemographics: {
		marginBottom: 5,
	},
	header: {
		fontSize: 18,
		marginBottom: 5,
		fontWeight: 'bold',
		color: '#2C3F50',
	},
	text: {
		marginBottom: 5,
		lineHeight: 1.5,
		whiteSpace: 'pre-line',
	},
	highlightedText: {
		lineHeight: 1.5,
		whiteSpace: 'pre-line',
		color: '#2C3F50',
		fontWeight: 'bold',
	},
	ul: {
		margin: 0,
		padding: 0,
		listStyle: 'none',
	},
	bullet: {
		display: 'inline-block',
		width: 6,
		height: 6,
		borderRadius: 3,
		backgroundColor: 'black',
		marginRight: 10,
	},
	li: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: 5,
	},
	liText: {
		fontSize: 12,
	},
};

function renderText(header: string, text?: string) {
	return (
		text && (
			<div style={styles.section}>
				<p style={styles.header}>{header}</p>
				<p style={styles.text}>{text}</p>
			</div>
		)
	);
}

function renderPatientDemographics(patientDemographics: INotePatientDemographics) {
	const { name, dateOfBirth, gender, mrn, dictationDate } = patientDemographics;

	const formattedDate = (dob?: string) => (dob ? `${dayjs(dob).format(dateFormat)}` : '');

	const age = (dob?: string) => dayjs().diff(dob, 'years');
	const formattedAge = (dob?: string) => `${age(dob)}yo`;
	return (
		<div style={styles.patientDemographics}>
			{name && (
				<div>
					<span style={styles.highlightedText}>Patient Name: </span>
					<span style={styles.text}>{name}</span>
				</div>
			)}
			{formattedDate(dateOfBirth) && (
				<div>
					<span style={styles.highlightedText}>Date of Birth: </span>
					<span style={styles.text}>{`${formattedDate(dateOfBirth)} ${formattedAge(dateOfBirth)}${
						gender ? ` ${gender}` : ''
					}`}</span>
				</div>
			)}
			{mrn && (
				<div>
					<span style={styles.highlightedText}>MRN: </span>
					<span style={styles.text}>{mrn}</span>
				</div>
			)}
			{formattedDate(dictationDate) && (
				<div>
					<span style={styles.highlightedText}>Date of Service: </span>
					<span style={styles.text}>{formattedDate(dictationDate)}</span>
				</div>
			)}
		</div>
	);
}

function renderDiagnoses(
	header: string,
	diagnoses?: string | Array<{ SNOMEDCode: string; ICD10Code: string; Description: string }>
) {
	if (typeof diagnoses === 'string') {
		return (
			<div style={styles.section}>
				<p style={styles.header}>{header}</p>
				<p style={styles.text}>{diagnoses}</p>
			</div>
		);
	}
	if (diagnoses && diagnoses.length > 0) {
		return (
			<div style={styles.section}>
				<p style={styles.header}>{header}</p>
				<ul style={styles.ul}>
					{diagnoses.map((d) => (
						<li key={d.SNOMEDCode} style={styles.li}>
							<div style={styles.bullet} />
							<span style={styles.liText}>
								{d.Description}
								{d.ICD10Code && ` (ICD10: ${d.ICD10Code})`}
							</span>
						</li>
					))}
				</ul>
			</div>
		);
	}
	return null;
}

export function handleDownloadPdf(node: React.ReactNode, documentName: string) {
	// Create a hidden div element
	const hiddenElement = document.createElement('div');
	// hiddenElement.style.display = 'none';

	// Append the hidden element to the body
	document.body.appendChild(hiddenElement);

	// Render the PdfContent component into the hidden element
	const root = createRoot(hiddenElement);
	root.render(node);

	const opt = {
		margin: [15, 15],
		filename: `${documentName || 'document'}.pdf`,
		image: { type: 'jpeg', quality: 0.98 },
		html2canvas: { scale: 2, letterRendering: true },
		jsPDF: { unit: 'pt', format: 'letter', orientation: 'portrait' },
		pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
	};

	// Generate and download the PDF
	html2pdf()
		.set(opt)
		.from(hiddenElement)
		.save()
		.then(() => {
			// Clean up by unmounting the React component and removing the hidden element
			root.unmount();
			document.body.removeChild(hiddenElement);
		});
}

const summaryToNote = (summary: IOpenAISummary): INote => {
	const hpi = summary.HPI || summary.Subjective;
	const pe = summary.PhysicalExam || summary.Objective;
	const plan = summary.AssessmentAndPlan?.Plan;

	return {
		hpi: hpi ? textTransformer(hpi) : undefined,
		ros: summary.ReviewOfSystems ? textTransformer(summary.ReviewOfSystems) : undefined,
		pe: pe ? textTransformer(pe) : undefined,
		radiology: summary.Radiology ? textTransformer(summary.Radiology) : undefined,
		imaging: summary.Imaging ? textTransformer(summary.Imaging) : undefined,
		discussion: summary.Discussion ? textTransformer(summary.Discussion) : undefined,
		assessment: summary.Assessment ? textTransformer(summary.Assessment) : undefined,
		plan: plan ? textTransformer(plan) : undefined,
		diagnoses: parseDiagnoses(summary.Diagnoses) || undefined,
		emCode:
			summary.EMCode && typeof summary.EMCode === 'string' && !summary.EMCode.includes('Not provided in the transcript')
				? summary.EMCode
				: undefined,
		note: summary.Note,
	};
};

interface INotePdfProps {
	note?: IOpenAISummary | null;
	text?: string | null;
	patientDemographics?: INotePatientDemographics;
}

function NotePdfCustom({ patientDemographics, note, text }: INotePdfProps) {
	const doc = note ? summaryToNote(note) : null;

	return (
		<div>
			{doc ? (
				<div style={styles.page}>
					{patientDemographics && renderPatientDemographics(patientDemographics)}
					{renderText('History of Present Illness', doc.hpi)}
					{renderText('Review of Systems', doc.ros)}
					{renderText('Physical Exam', doc.pe)}
					{renderText('Radiology', doc.radiology)}
					{renderText('Imaging', doc.imaging)}
					{renderText('Discussion Notes', doc.discussion)}
					{renderText('Assessment', doc.assessment)}
					{renderText('Plan', doc.plan)}
					{renderDiagnoses('Diagnoses', doc.diagnoses)}
					{/* {renderText('E&M', doc.emCode)} */}
					{renderText('Note', doc.note)}
				</div>
			) : (
				<div>{renderText('Summary', text || '')}</div>
			)}
		</div>
	);
}

export default NotePdfCustom;
