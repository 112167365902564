import * as React from 'react';
import { FormControl, MenuItem, TextField } from '@mui/material';
import { BaseTextFieldProps } from '@mui/material/TextField/TextField';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';

export interface IItem<T extends string | ReadonlyArray<string> | number | undefined> {
	value: T;
	text: string;
}

interface ISelectFieldProps<T extends string | ReadonlyArray<string> | number | undefined> extends BaseTextFieldProps {
	disabled?: boolean;
	placeholder?: string;
	fullWidth?: boolean;
	data: IItem<T>[];
	disabledValues?: T[];
	onChange?: StandardInputProps['onChange'];
}

function SelectFiled<T extends string | ReadonlyArray<string> | number | undefined>({
	fullWidth,
	disabled,
	placeholder,
	data,
	disabledValues,
	onChange,
	...props
}: ISelectFieldProps<T>) {
	return (
		<FormControl fullWidth={fullWidth}>
			<TextField
				fullWidth={fullWidth}
				onChange={onChange}
				disabled={disabled}
				/* eslint-disable-next-line react/jsx-props-no-spreading */
				{...props}
				select
			>
				{data.map((x) => (
					<MenuItem
						key={String(x.value || 'no_value')}
						value={x.value}
						disabled={disabled || !!disabledValues?.find((v) => v === x.value)}
					>
						{x.text}
					</MenuItem>
				))}
			</TextField>
		</FormControl>
	);
}

export default SelectFiled;
