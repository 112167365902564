import { useCallback, useEffect, useState } from 'react';

type Value<T> = T | null;

function useCachedValue<T>(key: string): Value<T> {
	// Get from local storage then
	// parse stored json or return initialValue
	const readValue = useCallback((): Value<T> => {
		// Prevent build error "window is undefined" but keep working
		if (typeof window === 'undefined') {
			return null;
		}

		const item = window.localStorage.getItem(key);
		try {
			return item ? (JSON.parse(item) as T) : null;
		} catch (error) {
			return item as T | null;
		}
	}, [key]);

	// State to store our value
	// Pass initial state function to useState so logic is only executed once
	const [storedValue, setStoredValue] = useState<Value<T>>(readValue);

	// Listen if localStorage changes
	useEffect(() => {
		setStoredValue(readValue());
	}, []);

	const handleStorageChange = useCallback(
		(event: StorageEvent | CustomEvent) => {
			if ((event as StorageEvent)?.key && (event as StorageEvent).key !== key) {
				return;
			}
			setStoredValue(readValue());
		},
		[key, readValue]
	);

	// this only works for other documents, not the current one
	window.addEventListener('storage', handleStorageChange);

	return storedValue;
}

export default useCachedValue;
