import * as React from 'react';
import { useEffect, useState } from 'react';
import { Box, Button, SxProps, Theme } from '@mui/material';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

export interface IExpandableMenuItem {
	title: string;
	Item: React.ReactNode;
}

interface IExpandableMenuProps {
	flex?: number;
	containerSx?: SxProps<Theme>;
	childSx?: SxProps<Theme>;
	items: IExpandableMenuItem[];
	initOpen?: string;
	disableCollapse?: boolean;
	onTabChanged?: (tabTitle: string | null) => void;
}
function ExpandableMenu({
	flex,
	containerSx,
	childSx,
	items,
	initOpen,
	onTabChanged,
	disableCollapse,
}: IExpandableMenuProps) {
	const [tabInitiated, setTabInitiated] = useState<boolean>(false);
	const [activeItem, setActiveItem] = useState<string | null>(null);

	const onChangeTab = (tabTitle: string | null) => {
		setActiveItem(tabTitle);
		onTabChanged?.(tabTitle);
		setTabInitiated(true);
	};

	useEffect(() => {
		if (!tabInitiated && initOpen) {
			const initTab = items.find((x) => x.title === initOpen);
			if (initTab) {
				setActiveItem(initTab.title);
				onTabChanged?.(initTab.title);
				setTabInitiated(true);
			}
		}
	}, [initOpen, items, tabInitiated]);

	const currentItem = activeItem != null ? items.find((x) => x.title === activeItem) : null;

	if (!items.length) {
		return null;
	}

	return (
		<Box sx={{ ...containerSx, display: 'flex', flex: currentItem ? flex || 1 : undefined }}>
			<Box sx={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
				{items.filter((x) => x.title !== activeItem).length ? (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							flexDirection: 'column',
							height: '100%',
							width: 32,
						}}
					>
						{items.map((x) =>
							x.title !== activeItem ? (
								<Button
									key={x.title}
									variant="text"
									size="small"
									sx={{
										my: 5,
										transform: 'rotate(270deg)',
										'&.MuiButtonBase-root:hover': {
											bgcolor: 'transparent',
										},
									}}
									onClick={() => onChangeTab(x.title)}
									endIcon={<KeyboardDoubleArrowDownIcon />}
								>
									{x.title}
								</Button>
							) : null
						)}
					</Box>
				) : null}
				{items.map((item) => (
					<Box
						key={item.title}
						sx={{
							display: item.title === activeItem ? 'flex' : 'none',
							flexDirection: 'column',
							height: '100%',
							width: '100%',
							...childSx,
						}}
					>
						{!disableCollapse && (
							<Button sx={{ mt: 1 }} onClick={() => onChangeTab(null)} startIcon={<KeyboardDoubleArrowLeftIcon />}>
								{item.title}
							</Button>
						)}
						<Box sx={{ height: '100%', overflowY: 'auto', overflowX: 'scroll' }}>{item.Item}</Box>
					</Box>
				))}
			</Box>
		</Box>
	);
}

export default ExpandableMenu;
