export const jobsConstants = {
	OPEN_DICTATION: 'JOBS/OPEN_DICTATION',
	SET_DICTATION_DATA: 'JOBS/SET_DICTATION_DATA',
	SET_DICTATION_DATA_IS_LOADING: 'JOBS/SET_DICTATION_DATA_IS_LOADING',

	JOBS_SEARCH_REQUEST: 'JOBS/JOBS_SEARCH_REQUEST',
	JOBS_SEARCH_IS_LOADING: 'JOBS/JOBS_SEARCH_IS_LOADING',
	JOBS_SET_RESULTS: 'JOBS/JOBS_SET_RESULTS',

	USERS_GET_REQUEST: 'JOBS/USERS_GET_REQUEST',
	USERS_GET_IS_LOADING: 'JOBS/USERS_GET_IS_LOADING',
	USERS_SET_RESULTS: 'JOBS/USERS_SET_RESULTS',

	UPDATE_JOB: 'JOBS/UPDATE_JOB',

	SET_AI_PROMPT: 'JOBS/SET_AI_PROMPT',
	SET_AI_INSTRUCTIONS: 'JOBS/SET_AI_INSTRUCTIONS',
	SET_LAST_TIME_PROMPT_SUBMITTED: 'JOBS/SET_LAST_TIME_PROMPT_SUBMITTED',
	REQUEST_AI_SUMMARY_FOR_PROMPT: 'JOBS/REQUEST_AI_SUMMARY_FOR_PROMPT',
	UPDATE_PROMPT_AI_SUMMARY: 'JOBS/UPDATE_PROMPT_AI_SUMMARY',
	SET_PROMPT_AI_SUMMARY: 'JOBS/SET_PROMPT_AI_SUMMARY',
};
