import * as React from 'react';
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { namespaces } from '../../application/i18n.constants';

const StyledListItemIcon = styled(ListItemIcon)(() => ({
	minWidth: 32,
}));

function AdvancedSearchListItem({ label, onMouseDown }: { label: string; onMouseDown: () => void }) {
	return (
		<ListItem disablePadding>
			<ListItemButton onMouseDown={onMouseDown}>
				<StyledListItemIcon>
					<SearchIcon />
				</StyledListItemIcon>
				<ListItemText primary={label} primaryTypographyProps={{ variant: 'caption', noWrap: true }} />
			</ListItemButton>
		</ListItem>
	);
}

interface INavbarSearchDropdownProps {
	handlePatientsSearch: () => void;
	handleJobsSearch: () => void;
	searchQuery: string;
}

function NavbarSearchDropdown({ handleJobsSearch, handlePatientsSearch, searchQuery }: INavbarSearchDropdownProps) {
	const { t } = useTranslation(namespaces.components.navbarSearchDropdown);

	return (
		<Box>
			<List
				subheader={
					<ListSubheader color="primary" component="div" id="advanced-search">
						{t('patientSearch')}
					</ListSubheader>
				}
			>
				<AdvancedSearchListItem
					label={!searchQuery ? t('patientSearch') : t('pressEnterToSearch', { searchQuery })}
					onMouseDown={handlePatientsSearch}
				/>
			</List>
			<List
				subheader={
					<ListSubheader color="primary" component="div" id="advanced-search">
						{t('jobsSearch')}
					</ListSubheader>
				}
			>
				<AdvancedSearchListItem
					label={!searchQuery ? t('jobsSearch') : t('jobsSearchFor', { searchQuery })}
					onMouseDown={handleJobsSearch}
				/>
			</List>
		</Box>
	);
}

export default NavbarSearchDropdown;
