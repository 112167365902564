import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { OverridableStringUnion } from '@mui/types';
import { CircularProgressPropsColorOverrides } from '@mui/material/CircularProgress/CircularProgress';

interface ISpinnerProps {
	color?: OverridableStringUnion<
		'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'inherit',
		CircularProgressPropsColorOverrides
	>;
	size?: number | string;
}
export default function Spinner({ color, size }: ISpinnerProps) {
	return <CircularProgress color={color} size={size} />;
}

Spinner.defaultProps = {
	color: undefined,
};
