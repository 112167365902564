import * as React from 'react';
import BillingServicesSection, { IBillingServicesSectionProps } from './BillingServicesSection';
import { EHRType } from '../../core/models/ehr.models';

interface IEhrBillingServicesSectionProps extends IBillingServicesSectionProps {
	ehr: EHRType;
}
function EhrBillingServicesSection({ ehr, ...props }: IEhrBillingServicesSectionProps) {
	if (ehr === EHRType.NextGen) {
		return (
			<BillingServicesSection
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
				billingCodes={props.billingCodes.filter((x) => x.serviceID || x.serviceType?.toLowerCase() === 'emcode')}
			/>
		);
	}

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <BillingServicesSection {...props} />;
}

export default EhrBillingServicesSection;
