import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Box, Tab, Tabs, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { namespaces } from '../../application/i18n.constants';
import { navigator, push } from '../../system/navigator';
import { AdminTabPath } from '../App/routes';
import CustomerDotPhrases from './CustomerMacros/CustomerMacros';
import ProviderMacros from './ProviderMacros/ProviderMacros';
import PageContainer from '../../components/page-container/PageContainer';
import WordReplacements from './WordReplacements/WordReplacements';
import AdminPages from './adminPages';
import { IScriberPermissions } from '../../core/models/users/user.models';
import { useAppSelector } from '../../core/core.types';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index } = props;

	return value === index ? <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center' }}>{children}</Box> : null;
}

function AdminPage() {
	const { t } = useTranslation(namespaces.pages.admin);
	const smallScreen = useMediaQuery((th: Theme) => th.breakpoints.down('sm'));
	const currentPath = navigator.location.pathname as AdminTabPath;
	const permissions = useAppSelector<IScriberPermissions>((state) => state.user.settings.permissions);

	const subPages = useMemo(() => {
		return AdminPages.find((x) => x.page === 'admin')?.subPages?.filter(
			(x) => x.requiredPermissions !== 'admin' || permissions.admin
		);
	}, []);
	const paths: AdminTabPath[] = useMemo(() => subPages?.map((page) => page.route as AdminTabPath) || [], [subPages]);

	const getIndexByPath = (path: AdminTabPath): number => (paths.indexOf(path) >= 0 ? paths.indexOf(path) : 0);
	const [tabValue, setTabValue] = useState<number>(getIndexByPath(currentPath));

	useEffect(() => {
		setTabValue(getIndexByPath(currentPath));
	}, [currentPath]);

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		push(paths[newValue]);
	};

	return (
		<PageContainer disablePadding>
			<Box
				sx={{
					flex: 1,
					bgcolor: 'background.paper',
					display: 'flex',
					flexDirection: smallScreen ? 'column' : 'row',
				}}
			>
				<Tabs
					orientation={smallScreen ? 'horizontal' : 'vertical'}
					value={tabValue}
					onChange={handleTabChange}
					sx={!smallScreen ? { borderRight: 1, borderColor: 'divider' } : null}
				>
					{subPages?.map((page) => (
						<Tab label={t(page.page)} key={page.page} />
					))}
				</Tabs>
				{paths.map((path, index) => {
					if (path === '/admin/customer-macros') {
						return (
							<TabPanel value={tabValue} index={index} key={path}>
								<CustomerDotPhrases />
							</TabPanel>
						);
					}
					if (path === '/admin/provider-macros') {
						return (
							<TabPanel value={tabValue} index={index} key={path}>
								<ProviderMacros />
							</TabPanel>
						);
					}
					if (path === '/admin/wordReplacements') {
						return (
							<TabPanel value={tabValue} index={index} key={path}>
								<WordReplacements />
							</TabPanel>
						);
					}
					return null;
				})}
			</Box>
		</PageContainer>
	);
}

export default AdminPage;
