import React from 'react';
import { Navigate } from 'react-router-dom';
import { routes } from '../../pages/App/routes';

interface IProtectedRouteProps {
	token: string | null;
	children: JSX.Element;
}

function ProtectedRoute({ token, children }: IProtectedRouteProps): JSX.Element {
	if (!token) {
		return <Navigate to={routes.login} />;
	}
	return children;
}

export default ProtectedRoute;
