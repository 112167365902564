import { routes } from '../App/routes';

export interface Page {
	page: string;
	route: string;
	subPages?: Page[];
	requiredPermissions?: string;
}

const pages: Page[] = [
	{ page: 'workList', route: routes.home },
	{ page: 'accountSettings', route: routes.accountSettings },
	{
		page: 'admin',
		route: routes.admin,
		subPages: [
			{ page: 'customerMacros', route: routes.adminTabs.customerMacros, requiredPermissions: 'admin' },
			{ page: 'providerMacros', route: routes.adminTabs.providerMacros },
			{ page: 'wordReplacements', route: routes.adminTabs.wordReplacements },
		],
	},
];

export default pages;
