import React from 'react';

import { CssBaseline } from '@mui/material';
import { createTheme, StyledEngineProvider, ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import palette from './palette';
import typography from './typography';
import transitions from './transitions';

interface IThemeProviderProps {
	children: React.ReactNode;
}

const theme = createTheme({
	palette,
	typography,
	transitions,
});

export default function ThemeProvider({ children }: IThemeProviderProps) {
	return (
		<StyledEngineProvider injectFirst>
			<MUIThemeProvider theme={theme}>
				<CssBaseline />
				{children}
			</MUIThemeProvider>
		</StyledEngineProvider>
	);
}
