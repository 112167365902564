import { call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
	deleteProviderMacro,
	deleteProviderWordReplacement,
	getProviderMacros,
	getProviderWordReplacements,
	importProviderMacros,
	saveProviderMacro,
	saveProviderWordReplacement,
	updateProviderMacro,
	updateProviderWordReplacement,
} from '../../api/user/user.api';
import { handleGlobalException } from '../../../application/exception-handlers';
import { actions, IAction } from '../actions';
import {
	ICopyProviderMacrosActionPayload,
	IDeleteCustomerMacroActionPayload,
	IDeleteCustomerWordReplacementActionPayload,
	IDeleteProviderMacroActionPayload,
	IDeleteProviderWordReplacementActionPayload,
	IGetCustomerWordReplacementsActionPayload,
	IGetProviderMacrosActionPayload,
	IImportCustomerMacrosActionPayload,
	IImportProviderMacrosActionPayload,
	ISaveCustomerMacroActionPayload,
	ISaveCustomerWordReplacementActionPayload,
	ISaveProviderMacroActionPayload,
	ISaveProviderWordReplacementActionPayload,
	IUpdateCustomerMacroActionPayload,
	IUpdateCustomerWordReplacementActionPayload,
	IUpdateProviderMacroActionPayload,
	IUpdateProviderWordReplacementActionPayload,
} from './admin.actions';
import { IMacro } from '../../models/macros/macros.models';
import { AppState } from '../../core.types';
import { IProvider } from '../../models/providers/providers.models';
import { getProvidersV2 } from '../../api/providers/providers.api';
import { sortProviders } from '../../services/providers/providers.services';
import { IServiceResult } from '../../models/service.models';
import {
	deleteCustomerMacro,
	deleteCustomerWordReplacement,
	getCustomerMacros,
	getCustomerWordReplacements,
	importCustomerMacros,
	saveCustomerMacro,
	saveCustomerWordReplacement,
	updateCustomerMacro,
	updateCustomerWordReplacement,
} from '../../api/customer/customer.api';
import { IWordReplacement } from '../../models/replacements/replacements.models';
import { IMacroCreateResult } from '../../api/user/user.api.models';

// CUSTOMER MACROS
export function* saveCustomerMacroSaga(
	action: IAction<ISaveCustomerMacroActionPayload | IUpdateCustomerMacroActionPayload>
) {
	try {
		yield put(actions.admin.setSaveCustomerMacroIsLoading(true));
		let saveResult: IServiceResult<number> = { success: false, data: 0 };

		if (action.type === actions.admin.saveCustomerMacro.type) {
			const { section, shortCode, description, macro, spokenForm } = action.payload as ISaveCustomerMacroActionPayload;

			const request = {
				section,
				shortCode,

				description,
				macro,
				spokenForm,
			};

			saveResult = yield call(saveCustomerMacro, request);
		} else if (action.type === actions.admin.updateCustomerMacro.type) {
			const { macroId, macro, section, spokenForm } = action.payload as IUpdateCustomerMacroActionPayload;

			const request = {
				macroId,
				section,

				macro,
				spokenForm,
			};

			saveResult = yield call(updateCustomerMacro, request);
		}

		if (!saveResult.success) {
			toast.error(`Save customer macro: ${saveResult.error || 'Something went wrong'}`);
		} else {
			toast.success('Save customer macro: success');
			yield put(actions.admin.getCustomerMacrosAction());
		}
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.admin.setSaveCustomerMacroIsLoading(false));
	}
}

export function* deleteCustomerMacroSaga(action: IAction<IDeleteCustomerMacroActionPayload>) {
	try {
		const { macroId } = action.payload;

		const request = {
			macroId,
		};

		yield put(actions.admin.setSaveCustomerMacroIsLoading(true));
		const deleteResult: IServiceResult<void> = yield call(deleteCustomerMacro, request);
		if (!deleteResult.success) {
			toast.error(`Delete customer macro: ${deleteResult.error || 'Something went wrong'}`);
		} else {
			const macros: IMacro[] = yield select((state: AppState) => state.admin.macros.customerMacros);
			if (macros) {
				const filteredMacros = macros.filter((x) => x.macroId !== macroId);
				if (filteredMacros) {
					yield put(actions.admin.setCustomerMacros(filteredMacros));
					toast.success('Delete customer macro: success');
				}
			}
		}
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.admin.setSaveCustomerMacroIsLoading(false));
	}
}

export function* getCustomerMacrosSaga() {
	try {
		yield put(actions.admin.setCustomerMacrosIsLoading(true));

		const result: IMacro[] = yield call(getCustomerMacros);
		if (result) {
			yield put(actions.admin.setCustomerMacros(result));
		}
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.admin.setCustomerMacrosIsLoading(false));
	}
}

export function* importCustomerMacrosSaga(action: IAction<IImportCustomerMacrosActionPayload>) {
	try {
		yield put(actions.admin.setSaveCustomerMacroIsLoading(true));

		const { customerId, file } = action.payload;

		const result: IServiceResult<IMacroCreateResult> = yield call(importCustomerMacros, { customerId, file });
		if (!result.success) {
			toast.error(`Import customer macros: ${result.error || 'Something went wrong'}`);
		} else {
			if (result.data.MacrosCount === result.data.MacrosCreated) {
				toast.success('Import customer macros: success');
			} else {
				toast.warning(`Import customer macros: Some macros could not be imported.`);
			}
			yield put(actions.admin.getCustomerMacrosAction());
		}
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.admin.setSaveCustomerMacroIsLoading(false));
	}
}

// PROVIDER MACROS
export function* saveProviderMacroSaga(
	action: IAction<ISaveProviderMacroActionPayload | IUpdateProviderMacroActionPayload>
) {
	try {
		yield put(actions.admin.setSaveProviderMacroIsLoading(true));

		let saveResult: IServiceResult<number> = { success: false, data: 0 };

		const { providerId } = action.payload;

		if (action.type === actions.admin.saveProviderMacro.type) {
			const { section, shortCode, description, macro, spokenForm } = action.payload as ISaveProviderMacroActionPayload;

			const request = {
				providerId,

				section,
				shortCode,

				description,
				macro,
				spokenForm,
			};

			saveResult = yield call(saveProviderMacro, request);
		} else if (action.type === actions.admin.updateProviderMacro.type) {
			const { macroId, section, macro, spokenForm } = action.payload as IUpdateProviderMacroActionPayload;

			const request = {
				macroId,
				section,
				macro,
				spokenForm,
			};

			saveResult = yield call(updateProviderMacro, request);
		}

		if (!saveResult.success) {
			toast.error(`Save provider macro: ${saveResult.error || 'Something went wrong'}`);
		} else {
			toast.success('Save provider macro: success');
			yield put(actions.admin.getProviderMacrosAction({ providerId }));
		}
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.admin.setSaveProviderMacroIsLoading(false));
	}
}

export function* deleteProviderMacroSaga(action: IAction<IDeleteProviderMacroActionPayload>) {
	try {
		const { macroId } = action.payload;

		const request = {
			macroId,
		};

		yield put(actions.admin.setSaveProviderMacroIsLoading(true));
		const deleteResult: IServiceResult<void> = yield call(deleteProviderMacro, request);
		if (!deleteResult.success) {
			toast.error(`Delete provider macro: ${deleteResult.error || 'Something went wrong'}`);
		} else {
			const macros: IMacro[] = yield select((state: AppState) => state.admin.macros.providerMacros);
			if (macros) {
				const filteredMacros = macros.filter((x) => x.macroId !== macroId);
				if (filteredMacros) {
					yield put(actions.admin.setProviderMacros(filteredMacros));
					toast.success('Delete provider macro: success');
				}
			}
		}
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.admin.setSaveProviderMacroIsLoading(false));
	}
}

export function* getProviderMacrosSaga(action: IAction<IGetProviderMacrosActionPayload>) {
	const { providerId } = action.payload;

	try {
		yield put(actions.admin.setProviderMacrosIsLoading(true));

		const result: IMacro[] = yield call(getProviderMacros, { providerId });
		if (result) {
			yield put(actions.admin.setProviderMacros(result));
		}
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.admin.setProviderMacrosIsLoading(false));
	}
}

export function* importProviderMacrosSaga(action: IAction<IImportProviderMacrosActionPayload>) {
	try {
		yield put(actions.admin.setSaveProviderMacroIsLoading(true));

		const { providerId, file } = action.payload;

		const result: IServiceResult<IMacroCreateResult> = yield call(importProviderMacros, { providerId, file });
		if (!result.success) {
			toast.error(`Import provider macros: ${result.error || 'Something went wrong'}`);
		} else {
			if (result.data.MacrosCount === result.data.MacrosCreated) {
				toast.success('Import provider macros: success');
			} else {
				toast.warning(`Import provider macros: Some macros could not be imported.`);
			}
			yield put(actions.admin.getProviderMacrosAction({ providerId }));
		}
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.admin.setSaveProviderMacroIsLoading(false));
	}
}

export function* copyProviderMacrosSaga(action: IAction<ICopyProviderMacrosActionPayload>) {
	try {
		yield put(actions.admin.setSaveProviderMacroIsLoading(true));

		let successCount = 0;
		// eslint-disable-next-line no-restricted-syntax
		for (const x of action.payload.macros) {
			let saveResult: IServiceResult<number> = { success: false, data: 0 };
			const { section, shortCode, description, macro, spokenForm } = x;

			const request = {
				providerId: action.payload.providerId,

				section,
				shortCode,

				description,
				macro,
				spokenForm,
			};

			saveResult = yield call(saveProviderMacro, request);

			if (!saveResult.success) {
				toast.warning(`Copy provider macro: ${saveResult.error || 'Something went wrong'}`);
			} else {
				// eslint-disable-next-line no-plusplus
				++successCount;
			}

			if (successCount === action.payload.macros.length) {
				toast.success('Copy provider macros: success');
			}
		}
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.admin.setSaveProviderMacroIsLoading(false));
	}
}

export // CUSTOMER WORD REPLACEMENTS

function* saveCustomerWordReplacementSaga(
	action: IAction<ISaveCustomerWordReplacementActionPayload | IUpdateCustomerWordReplacementActionPayload>
) {
	try {
		yield put(actions.admin.setSaveCustomerWordReplacementIsLoading(true));
		let saveResult: IServiceResult<number> = { success: false, data: 0 };

		if (action.type === actions.admin.saveCustomerWordReplacement.type) {
			const { word, wordReplacement, customerId } = action.payload as ISaveCustomerWordReplacementActionPayload;
			const request = { word, wordReplacement, customerId };

			saveResult = yield call(saveCustomerWordReplacement, request);
		} else if (action.type === actions.admin.updateCustomerWordReplacement.type) {
			const { id, word, wordReplacement, customerId } = action.payload as IUpdateCustomerWordReplacementActionPayload;
			const request = {
				id,
				word,
				wordReplacement,
				customerId,
			};

			saveResult = yield call(updateCustomerWordReplacement, request);
		}

		if (!saveResult.success) {
			toast.error(`Save customer word replacement: ${saveResult.error || 'Something went wrong'}`);
		} else {
			toast.success('Save customer word replacement: success');
			yield put(actions.admin.getCustomerWordReplacements({ customerId: action.payload.customerId }));
		}
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.admin.setSaveCustomerWordReplacementIsLoading(false));
	}
}

export function* getCustomerWordReplacementsSaga(action: IAction<IGetCustomerWordReplacementsActionPayload>) {
	try {
		yield put(actions.admin.setCustomerWordReplacementsIsLoading(true));

		const result: IWordReplacement[] = yield call(getCustomerWordReplacements, {
			customerId: action.payload.customerId,
		});
		if (result) {
			yield put(actions.admin.setCustomerWordReplacements(result));
		}
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.admin.setCustomerWordReplacementsIsLoading(false));
	}
}

export function* deleteCustomerWordReplacementSaga(action: IAction<IDeleteCustomerWordReplacementActionPayload>) {
	try {
		const { id } = action.payload;

		const request = {
			id,
		};

		yield put(actions.admin.setSaveCustomerWordReplacementIsLoading(true));
		const deleteResult: IServiceResult<void> = yield call(deleteCustomerWordReplacement, request);
		if (!deleteResult.success) {
			toast.error(`Delete customer word replacement: ${deleteResult.error || 'Something went wrong'}`);
		} else {
			const words: IWordReplacement[] = yield select(
				(state: AppState) => state.admin.wordReplacements.customerWordReplacements
			);
			if (words) {
				const filteredWords = words.filter((x) => x.id !== id);
				if (filteredWords) {
					yield put(actions.admin.setCustomerWordReplacements(filteredWords));
					toast.success('Delete customer word replacement: success');
				}
			}
		}
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.admin.setSaveCustomerWordReplacementIsLoading(false));
	}
}

// PROVIDER WORD REPLACEMENTS
export function* saveProviderWordReplacementSaga(
	action: IAction<ISaveProviderWordReplacementActionPayload | IUpdateProviderWordReplacementActionPayload>
) {
	try {
		yield put(actions.admin.setSaveProviderWordReplacementIsLoading(true));
		let saveResult: IServiceResult<number> = { success: false, data: 0 };

		if (action.type === actions.admin.saveProviderWordReplacement.type) {
			const { word, wordReplacement, providerId } = action.payload as ISaveProviderWordReplacementActionPayload;
			const request = { word, wordReplacement, providerId };

			saveResult = yield call(saveProviderWordReplacement, request);
		} else if (action.type === actions.admin.updateProviderWordReplacement.type) {
			const { id, word, wordReplacement, providerId } = action.payload as IUpdateProviderWordReplacementActionPayload;
			const request = {
				id,
				word,
				wordReplacement,
				providerId,
			};

			saveResult = yield call(updateProviderWordReplacement, request);
		}

		if (!saveResult.success) {
			toast.error(`Save provider word replacement: ${saveResult.error || 'Something went wrong'}`);
		} else {
			toast.success('Save provider word replacement: success');
			yield put(actions.admin.getProviderWordReplacements({ providerId: action.payload.providerId }));
		}
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.admin.setSaveProviderWordReplacementIsLoading(false));
	}
}

export function* getProviderWordReplacementsSaga(action: IAction<IGetProviderMacrosActionPayload>) {
	try {
		yield put(actions.admin.setProviderWordReplacementsIsLoading(true));

		const result: IWordReplacement[] = yield call(getProviderWordReplacements, {
			providerId: action.payload.providerId,
		});
		if (result) {
			yield put(actions.admin.setProviderWordReplacements(result));
		}
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.admin.setProviderWordReplacementsIsLoading(false));
	}
}

export function* deleteProviderWordReplacementSaga(action: IAction<IDeleteProviderWordReplacementActionPayload>) {
	try {
		const { id } = action.payload;

		const request = {
			id,
		};

		yield put(actions.admin.setSaveProviderWordReplacementIsLoading(true));
		const deleteResult: IServiceResult<void> = yield call(deleteProviderWordReplacement, request);
		if (!deleteResult.success) {
			toast.error(`Delete provider word replacement: ${deleteResult.error || 'Something went wrong'}`);
		} else {
			const words: IWordReplacement[] = yield select(
				(state: AppState) => state.admin.wordReplacements.providerWordReplacements
			);
			if (words) {
				const filteredWords = words.filter((x) => x.id !== id);
				if (filteredWords) {
					yield put(actions.admin.setProviderWordReplacements(filteredWords));
					toast.success('Delete provider word replacement: success');
				}
			}
		}
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.admin.setSaveProviderWordReplacementIsLoading(false));
	}
}

// Providers
export function* getProvidersV2Saga() {
	try {
		yield put(actions.admin.setProvidersIsLoadingAction(true));
		const result: IProvider[] = yield call(getProvidersV2);
		if (result) {
			yield put(actions.admin.setProvidersAction({ providers: sortProviders(result) }));
		}
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.admin.setProvidersIsLoadingAction(false));
	}
}
