export const namespaces = {
	common: {
		main: 'common',
		validations: 'validations',
	},
	pages: {
		signIn: 'pages.signIn',
		accountSettings: 'pages.accountSettings',
		patientSearch: 'pages.patientSearch',
		jobsSearch: 'pages.jobsSearch',
		jobs: 'pages.jobs',
		dictation: 'pages.dictation',
		admin: 'pages.admin',
		wordReplacements: 'pages.wordReplacements',
	},
	components: {
		navbar: 'components.navbar',
		navbarSearchDropdown: 'components.navbarSearchDropdown',
		selectProviders: 'components.selectProviders',
		selectEncounters: 'components.selectEncounters',
		searchPatient: 'components.searchPatient',
		searchBillingServices: 'components.searchBillingServices',
		verticalTabBar: 'components.verticalTabBar',
		patientDetails: 'components.patientDetails',
		diagnosis: 'components.diagnosis',
		diagnosesAndOrdersDialog: 'components.diagnosesAndOrdersDialog',
		procedureDocumentationDialog: 'components.procedureDocumentationDialog',
		pullAssessmentPlanDialog: 'components.pullAssessmentPlanDialog',
		billingDetails: 'components.billingDetails',
		dialog: 'components.dialog',
		qaNotes: 'components.qaNotes',
		sessionTimeoutDialog: 'components.sessionTimeoutDialog',
		abridgeSummary: 'components.abridgeSummary',
		dotPhrases: 'components.dotPhrases',
	},
};

export const languages = {
	en: 'en',
};
