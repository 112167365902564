import { Box, Button, List, Typography } from '@mui/material';
import React, { ForwardedRef, forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { debounce } from 'lodash';
import DiagnosisField, { DiagnoseTextFieldType } from '../../diagnosis/DiagnosisField';
import DiagnoseIcon from '../../diagnosis/diagnose-list/diagnosis.png';
import { IDiagnoseField, IOpenAISummary } from '../../../core/models/dictations/dictations.models';
import DiagnosisTextField, { ITextField } from '../../diagnosis/DiagnosisTextField';
import { EHRType } from '../../../core/models/ehr.models';
import SelectDiagnosesDialog from '../../diagnoses-and-orders/SelectDiagnosesDialog';
import { canEhrAddDiagnosis } from '../../../core/services/documents/documents.services';
import {
	buildAssessmentAndPlan,
	buildPE,
	hasHeader,
	parseDiagnoses,
	textTransformer,
} from '../../../utils/textTransformers.utils';

interface ISummaryTextField {
	getText: () => string | undefined;
}

export type SummaryFieldType = DiagnoseTextFieldType | 'billing' | 'diagnoses';
export type SummaryFieldDataType = {
	text?: string;
	diagnoses?: Array<{ SNOMEDCode: string; ICD10Code: string; Description: string }>;
};

interface ISummaryTextFieldProps {
	id?: SummaryFieldType;
	name: string;
	text: string;
	onAppend?: (fieldId: DiagnoseTextFieldType | 'billing', data: string) => void;
}
const InternalSummaryTextField: React.ForwardRefRenderFunction<ISummaryTextField, ISummaryTextFieldProps> =
	function SummaryTextFieldFun(
		{ id, name, text, onAppend }: ISummaryTextFieldProps,
		forwardedRef: ForwardedRef<ISummaryTextField>
	) {
		const diagnoseFieldRef = useRef<ITextField>(null);

		const append = (fieldId: string) => {
			if (onAppend) {
				onAppend(fieldId as DiagnoseTextFieldType | 'billing', text);
			}
		};

		useImperativeHandle(forwardedRef, () => ({
			getText() {
				if (diagnoseFieldRef?.current) {
					return diagnoseFieldRef.current.getText();
				}
				return undefined;
			},
		}));

		return (
			<DiagnosisTextField
				ref={diagnoseFieldRef}
				id={id || name.replace(' ', '_')}
				name={name}
				text={text}
				onAppend={onAppend ? append : undefined}
				onCopy={() => navigator.clipboard.writeText(text)}
			/>
		);
	};

const SummaryTextField = forwardRef(InternalSummaryTextField);

interface IOpenAiSummaryProps {
	ehr: EHRType;
	summaryTitle?: string;
	summary?: IOpenAISummary | null;
	addDisabled?: boolean;
	text?: string | null;
	selectedDiagnoses: IDiagnoseField[];
	onAppend?: (data: {
		[fieldId in SummaryFieldType]?: SummaryFieldDataType;
	}) => void;
}

function OpenAiSummary({
	ehr,
	summary,
	summaryTitle,
	addDisabled,
	text,
	selectedDiagnoses,
	onAppend,
}: IOpenAiSummaryProps) {
	const hpiRef = useRef<ISummaryTextField>(null);
	const discussionRef = useRef<ISummaryTextField>(null);
	const rosRef = useRef<ISummaryTextField>(null);
	const peRef = useRef<ISummaryTextField>(null);
	const radiologyRef = useRef<ISummaryTextField>(null);
	const imagingRef = useRef<ISummaryTextField>(null);
	const planRef = useRef<ISummaryTextField>(null);
	const assessmentRef = useRef<ISummaryTextField>(null);
	const emRef = useRef<ISummaryTextField>(null);

	const [diagnoseSearch, setDiagnoseSearch] = useState<string | null>(null);

	const debounceAppend = React.useRef(
		debounce(
			(data: {
				[fieldId in SummaryFieldType]?: SummaryFieldDataType;
			}) => {
				if (onAppend) {
					onAppend(data);
				}
			},
			300
		)
	).current;

	React.useEffect(() => {
		return () => {
			debounceAppend.cancel();
		};
	}, [debounceAppend]);

	const summaryDiagnoses = useMemo(() => parseDiagnoses(summary?.Diagnoses), [summary?.Diagnoses]);

	const onAppendDiagnoses = onAppend
		? (diagnoses: Array<{ SNOMEDCode: string; ICD10Code: string; Description: string }>) => {
				debounceAppend({ diagnoses: { diagnoses } });
		  }
		: undefined;

	const canAddDiagnose = (diag: { SNOMEDCode: string; ICD10Code: string; Description: string }) =>
		onAppendDiagnoses &&
		!selectedDiagnoses.find((x) => x.SNOMEDCode === diag.SNOMEDCode) &&
		diag.SNOMEDCode &&
		diag.SNOMEDCode.length > 0 &&
		diag.ICD10Code &&
		diag.ICD10Code.length > 0;

	const canTryAddDiagnose = (diag: { SNOMEDCode: string; ICD10Code: string; Description: string }) =>
		onAppendDiagnoses &&
		(canAddDiagnose(diag) ||
			(diag.Description.length > 0 &&
				(!diag.SNOMEDCode || diag.SNOMEDCode.length === 0 || !diag.ICD10Code || diag.ICD10Code.length === 0)));

	const diagnosesToBeAdded = Array.isArray(summary?.Diagnoses)
		? (summary?.Diagnoses || []).filter((x) => canAddDiagnose(x))
		: [];

	const canAddAllDiagnoses = (summary?.Diagnoses || []).length === diagnosesToBeAdded.length;
	const onAppendAll = () => {
		function clean(obj: Record<string, SummaryFieldDataType | null | undefined>) {
			// eslint-disable-next-line no-restricted-syntax
			for (const propName in obj) {
				if (
					obj[propName] === null ||
					obj[propName] === undefined ||
					((!obj[propName]?.text || obj[propName]?.text === '') &&
						(!obj[propName]?.diagnoses || obj[propName]?.diagnoses === []))
				) {
					// eslint-disable-next-line no-param-reassign
					delete obj[propName];
				}
			}
			return obj;
		}

		if (onAppend) {
			const hpi = hpiRef?.current?.getText() || '';
			const ros = rosRef?.current?.getText() || '';
			const pe = peRef?.current?.getText() || '';
			const discussion = discussionRef?.current?.getText() || '';
			const radiology = radiologyRef?.current?.getText() || '';
			const imaging = imagingRef?.current?.getText() || '';
			const assessment = assessmentRef?.current?.getText() || '';
			const plan = planRef?.current?.getText() || '';
			const em = emRef?.current?.getText() || '';

			const data: {
				[fieldId in SummaryFieldType]?: SummaryFieldDataType;
			} = {
				hpiSketchPad: { text: hpi },
				rosSketchPad: { text: ros },
				peSketchPad: { text: buildPE(pe, radiology, imaging) },
				discussionNotes: { text: discussion },
				assessment: { text: assessment },
				plan: { text: plan },
				assessmentPlan: { text: buildAssessmentAndPlan(assessment, plan) },
				billing: { text: em },
				diagnoses: { diagnoses: canAddAllDiagnoses && canEhrAddDiagnosis(ehr) ? diagnosesToBeAdded : [] },
			};

			debounceAppend(clean(data));
		}
	};

	const onAppendTextField =
		!addDisabled && onAppend
			? (id: DiagnoseTextFieldType | 'billing', textData: string) => {
					debounceAppend({ [id]: { text: textData, diagnoses: [] } });
			  }
			: undefined;

	const onAppendTextFieldWithTitle = (params?: { title: string; prefix: boolean }) =>
		onAppendTextField
			? (id: DiagnoseTextFieldType | 'billing', data: string) =>
					onAppendTextField(id, params && params.prefix && !hasHeader(data) ? `${params.title}:\n${data}` : data)
			: undefined;

	return (
		<Box sx={{ flex: 1 }}>
			<Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', px: 2 }}>
				<Box />
				<Box>{summaryTitle ? <Typography>{summaryTitle}</Typography> : null}</Box>
				<Box>
					{summary && onAppendAll && !addDisabled && (
						<Button variant="text" onClick={onAppendAll}>
							ADD ALL
						</Button>
					)}
				</Box>
			</Box>
			<Box sx={{ height: '100%', overflowY: 'scroll', px: 2 }}>
				<List
					sx={{
						listStyleType: 'disc',
						'& .MuiListItem-root': {
							display: 'list-item',
						},
					}}
				>
					{summary && (
						<>
							{(summary.HPI || summary.Subjective) && (
								<SummaryTextField
									ref={hpiRef}
									id="hpiSketchPad"
									name="History of Present Illness"
									text={textTransformer(summary.HPI || summary.Subjective || '')}
									onAppend={onAppendTextField}
								/>
							)}
							{summary.ReviewOfSystems && (
								<SummaryTextField
									ref={rosRef}
									id="rosSketchPad"
									name="Review of Systems"
									text={textTransformer(summary.ReviewOfSystems)}
									onAppend={onAppendTextField}
								/>
							)}
							{(summary.PhysicalExam || summary.Objective) && (
								<SummaryTextField
									ref={peRef}
									id="peSketchPad"
									name="Physical Examination"
									text={textTransformer(summary.PhysicalExam || summary.Objective || '')}
									onAppend={onAppendTextField}
								/>
							)}
							{summary.Radiology && (
								<SummaryTextField
									ref={radiologyRef}
									id="peSketchPad"
									name="Radiology"
									text={textTransformer(summary.Radiology)}
									// onAppend={onAppendTextFieldWithTitle({ title: 'Radiology', prefix: true })}
									onAppend={onAppendTextFieldWithTitle({ title: 'Radiology', prefix: false })}
								/>
							)}
							{summary.Imaging && (
								<SummaryTextField
									ref={imagingRef}
									id="peSketchPad"
									name="Imaging"
									text={textTransformer(summary.Imaging)}
									// onAppend={onAppendTextFieldWithTitle({ title: 'Imaging', prefix: true })}
									onAppend={onAppendTextFieldWithTitle({ title: 'Imaging', prefix: false })}
								/>
							)}
							{summary.Discussion && (
								<SummaryTextField
									ref={discussionRef}
									id="discussionNotes"
									name="Discussion Notes"
									text={textTransformer(summary.Discussion || '')}
									onAppend={onAppendTextField}
								/>
							)}
							{summary.Assessment && (
								<SummaryTextField
									ref={assessmentRef}
									id={
										[EHRType.Athena, EHRType.AllscriptsTW, EHRType.AllscriptsPro].includes(ehr)
											? 'assessmentPlan'
											: 'assessment'
									}
									name="Assessment"
									text={textTransformer(summary.Assessment)}
									// onAppend={onAppendTextFieldWithTitle({ title: 'Assessment', prefix: ehr === EHRType.Athena })}
									onAppend={onAppendTextFieldWithTitle({ title: 'Assessment', prefix: false })}
								/>
							)}
							{summary.AssessmentAndPlan?.Plan && (
								<SummaryTextField
									ref={planRef}
									id={
										[EHRType.Athena, EHRType.AllscriptsTW, EHRType.AllscriptsPro].includes(ehr)
											? 'assessmentPlan'
											: 'plan'
									}
									name="Plan"
									text={textTransformer(summary.AssessmentAndPlan.Plan)}
									// onAppend={onAppendTextFieldWithTitle({ title: 'Plan', prefix: ehr === EHRType.Athena })}
									onAppend={onAppendTextFieldWithTitle({ title: 'Plan', prefix: false })}
								/>
							)}
							{summary.Diagnoses &&
								((Array.isArray(summaryDiagnoses) && summaryDiagnoses.length > 0) ||
									typeof summaryDiagnoses === 'string') &&
								(Array.isArray(summaryDiagnoses) ? (
									<DiagnosisField
										id="Diagnoses"
										name="Diagnoses"
										onAppend={
											onAppendDiagnoses && diagnosesToBeAdded.length > 0 && canAddAllDiagnoses
												? () => onAppendDiagnoses(diagnosesToBeAdded)
												: undefined
										}
									>
										<List component="div">
											{summaryDiagnoses.map((diag) => (
												<Button
													key={JSON.stringify(diag)}
													variant="text"
													disabled={!canTryAddDiagnose(diag) || !canEhrAddDiagnosis(ehr)}
													endIcon={canTryAddDiagnose(diag) ? <ChevronRightIcon /> : null}
													sx={{
														display: 'flex',
														flexDirection: 'row',
														justifyContent: 'flex-start',
														alignItems: 'center',
														py: 0.5,
														'&.Mui-disabled': {
															color: (theme) => theme.palette.text.primary,
														},
													}}
													component="div"
													onClick={
														// eslint-disable-next-line no-nested-ternary
														onAppendDiagnoses && canTryAddDiagnose(diag)
															? canAddDiagnose(diag)
																? () => onAppendDiagnoses([diag])
																: () => setDiagnoseSearch(diag.Description)
															: undefined
													}
												>
													<img
														alt={`diagnose-${diag.SNOMEDCode}`}
														src={DiagnoseIcon}
														width="25"
														height="25"
														style={{ marginRight: 8 }}
													/>
													<Box>
														<Typography display="inline">{diag.Description} </Typography>
														{diag.ICD10Code && <Typography display="inline">ICD10: {diag.ICD10Code}</Typography>}
													</Box>
													{/* {canAddDiagnose(diag) && <ChevronRightIcon sx={{ fontSize: '1.3rem' }} />} */}
												</Button>
											))}
										</List>
									</DiagnosisField>
								) : (
									<SummaryTextField name="Diagnoses" text={summaryDiagnoses as unknown as string} />
								))}
							{summary.EMCode &&
								typeof summary.EMCode === 'string' &&
								!summary.EMCode.includes('Not provided in the transcript') && (
									<SummaryTextField
										ref={emRef}
										id="billing"
										name="E&M"
										text={summary.EMCode}
										onAppend={onAppendTextField}
									/>
								)}
							{summary.Note && <SummaryTextField name="Note" text={summary.Note} />}
						</>
					)}
					{text && !summary && <SummaryTextField name="Summary" text={text} />}
				</List>
				<SelectDiagnosesDialog
					searchText={diagnoseSearch || ''}
					selectedDiagnoses={selectedDiagnoses}
					onSelectDiagnose={(diag) => {
						if (onAppend) {
							const icd10Code = diag.icdCodes.find((x) => x.codeset?.toLowerCase() === 'icd10');
							if (icd10Code) {
								const diagnoses: SummaryFieldDataType = {
									diagnoses: [
										{
											SNOMEDCode: diag.SNOMEDCode,
											ICD10Code: icd10Code.code,
											Description: diag.caption,
										},
									],
								};
								onAppend({
									diagnoses,
								});
							}
						}
					}}
					open={diagnoseSearch != null}
					onClose={() => setDiagnoseSearch(null)}
				/>
			</Box>
		</Box>
	);
}

export default OpenAiSummary;
