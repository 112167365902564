import * as React from 'react';
import { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import CustomTextArea, { ICustomTextAreaProps } from './CustomTextArea';

export interface ILazyInput {
	setText: (text: string) => void;
}

interface ILazyInputProps extends Omit<ICustomTextAreaProps, 'value' | 'onChange'> {
	value: string;
	onLazyChange?: (text: string) => void;
	onChange?: (text: string) => void;
}

const LazyInput: React.ForwardRefRenderFunction<ILazyInput, ILazyInputProps> = function LazyInputFunc(
	{ value, onChange, onLazyChange, onBlur, onFocus, ...props }: ILazyInputProps,
	forwardedRef: ForwardedRef<ILazyInput>
) {
	const [fieldText, setFieldText] = useState<string>(value || '');
	const [oldFieldText, setOldFieldText] = useState<string>(fieldText);

	const [inputFocused, setInputFocused] = useState<boolean>(false);

	useImperativeHandle(forwardedRef, () => ({
		setText(text: string) {
			setFieldText(text);
			if (onChange) {
				onChange(text);
			}
		},
	}));

	useEffect(() => {
		if (value !== fieldText) {
			setFieldText(value);
		}
	}, [value]);

	useEffect(() => {
		const interval = setInterval(() => {
			if (onLazyChange) {
				if (inputFocused && fieldText !== oldFieldText) {
					onLazyChange(fieldText);
					setOldFieldText(fieldText);
				}
			}
		}, 5000);

		return () => clearInterval(interval);
	}, [fieldText, oldFieldText, inputFocused]);

	return (
		<CustomTextArea
			value={fieldText}
			onBlur={(e) => {
				setInputFocused(false);
				if (onBlur) {
					onBlur(e);
				}
				if (onLazyChange) {
					onLazyChange(e.target.value);
				}
			}}
			onFocus={(e) => {
				if (onFocus) {
					onFocus(e);
				}
				setInputFocused(true);
			}}
			onChange={(event) => {
				if (onChange) {
					onChange(event.target.value);
				}
				setFieldText(event.target.value);
			}}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		/>
	);
};

export default forwardRef(LazyInput);
