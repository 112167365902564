import { cloneDeep } from 'lodash';

export default class LogObjectSanitizer {
	public fields: string[] = [];

	constructor(privateFields: string[]) {
		this.fields = privateFields;
	}

	public static setup(fields: string[]) {
		const instance = new LogObjectSanitizer(fields);

		return {
			objectSanitizer: instance.objectSanitizer.bind(instance),
		};
	}

	// eslint-disable-next-line
	public objectSanitizer(request: object): object | any {
		const result = cloneDeep(request);
		try {
			this.searchBody(result);
			return result;
		} catch (ex) {
			// eslint-disable-next-line no-console
			console.warn(ex);
			return result;
		}
	}

	// eslint-disable-next-line
	private searchBody(body: any = {}) {
		// iterate over collection of objects ex. [{}, ...]
		if (body && body.constructor === Array) {
			body.forEach((item) => this.searchBody(item));
		} else {
			// eslint-disable-next-line no-restricted-syntax
			for (const key in body) {
				// eslint-disable-next-line no-prototype-builtins
				if (body.hasOwnProperty(key)) {
					const keyName = body[key];

					const isTypeValuePair = key === 'type' && 'value' in body;

					if (typeof keyName === 'object') {
						if (!isTypeValuePair) {
							this.searchBody(keyName);
						}
					}

					if (isTypeValuePair) {
						this.mask(body, body.type, 'value');
					} else {
						this.mask(body, key);
					}
				}
			}
		}
	}

	private mask(body: object, searchKeyName: string, maskKeyName?: string) {
		// eslint-disable-next-line no-param-reassign
		maskKeyName = maskKeyName || searchKeyName;

		const isSensitiveFieldName = this.match(searchKeyName);

		if (isSensitiveFieldName) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			// eslint-disable-next-line no-param-reassign
			body[maskKeyName] = '*';
		}
	}

	private match(keyName = ''): boolean {
		const { fields } = this;
		const normalizedKeyName = keyName.toLowerCase();

		return fields.some((field) => normalizedKeyName.indexOf(field.toLowerCase()) > -1);
	}
}

export const commonPrivateFields = [
	'authorization',
	'api-key',
	'apiKey',
	'password',
	'patientId',
	'firstName',
	'lastName',
	'patientFirstName',
	'patientLastName',
	'dob',
	'dateOfBirth',
	'ssn',
	'demographic',
];

export const getObjectSanitizer = (): ((obj: object) => object) => {
	const { objectSanitizer } = LogObjectSanitizer.setup([...commonPrivateFields]);
	return objectSanitizer;
};
