import { DiagnoseLaterality, IEncounterDocumentData, JobStatus } from './document.models';
import { ICustomPatientDemographicsData } from '../patients/patients.models';

export interface IDictation {
	patientID: string;
	appointmentID: string | null;
	appointmentDateTime: string | null;
	patientFirstName: string;
	patientLastName: string;
	documentID: string | null;
	documentType: string;
	blobID: string;
	blobUrl: string;
	careProviderName: string;
	providerUserInfoID: string | null;
	documentStatus: '' | JobStatus;
	documentSaveStatus: string;
	createdDateTime: string;
	isCheckedOut: boolean;
	checkedOutBy: string;
	markedForQA: boolean;
	isHeld: boolean;
	stat: boolean;
}

export interface IJobDictation extends Omit<IDictation, 'markedForQA' | 'documentSaveStatus' | 'appointmentID'> {}

export interface IJobsSearchFilters {
	patientID?: string;
	dictatedBy?: string;
	transcribedBy?: string;
	qaBy?: string;
	dictatedStartDate?: string;
	dictatedEndDate?: string;
	sentStartDate?: string;
	sentEndDate?: string;
	documentType?: string;
	documentStatus?: string;
}

export interface IDepartmentDictation extends IDictation {
	departmentId: string;
}

export interface IICDCode {
	description: string;
	code: string;
	codeset: string;
}

export interface IDiagnoseField extends Omit<IUnrankedDiagnoseField, 'ranking'> {
	ranking: number;
}

export interface IUnrankedDiagnoseField {
	ranking: number;
	diagnosisId: string | null;
	caption: string;
	icdCodes: IICDCode[];
	selected: boolean;
	lexicalId: string;
	modified: number;
	problem: boolean;
	problemStatus: number;
	note: string;
	addToProblemList: boolean;
	canPostCoordinate: boolean;
	laterality?: DiagnoseLaterality;
	SNOMEDCode: string;
	orders: IOrderField[];
	SNOMEDName: string | null;
}

export interface IDiagnoseFields {
	chiefComplaints: Array<{ ID: number; Selected: boolean; Value: string }>;
	hpiSketchPad: string;
	rosSketchPad: string;
	peSketchPad: string;
	patientGoals: string;
	patientInstructions: string;
	discussionNotes: string;
	assessmentPlan: string;
	assessment: string;
	plan: string;
	diagnoses: IDiagnoseField[];
	procedureDocumentation: IProcedureDocumentationField[];
}

export interface IProcedureDocumentationField {
	procedureTemplateId: string;
	modified: number;
	procedureDocumentationText: string;
	templateData: string;
	procedureTemplateName: string;
}

export interface IProcedureDocumentationTemplate {
	userId: number;
	ehrTemplateId: number;
	documentTypeId: number;
	templateName: string;
	createdDate: string;
	lastSelectedDate: string;
	templateType: 'proceduredocumentation';
	templateText: string;
}

export interface IProcedureCode {
	procedureCode: string;
	description: string;
	serviceType: string;
}

export interface IOrdersSearchQuery {
	encounterId: string;
	patientId: string;
	SNOMEDCode: string;
	searchPhrase: string;
}

export interface IInterpretationField {
	note: string;
	interpretationId: string;
	orderId: string;
	discussedWithPatient: boolean;
}
export interface IOrderField {
	orderId?: string | null;
	selected: boolean;
	modified: number;
	procedure: {
		procedureLexicalCode: string;
		selected: boolean;
		procedureDescription: string;
	};
	description: string;
	interpretation?: {
		note: string;
		interpretationId: string;
		orderId: string;
		discussedWithPatient: boolean;
	};
	orderTypeGroup: string;
	orderTypeId: number;
	lastModifiedDate?: string;
	status?: 'REVIEW';
	createdBy?: string;
	orderedBy?: string;
	orderedDate?: string;
	class?: {
		classDescription: 'order';
		class: 'ORDER';
	};
	lastModifiedBy?: string;
	assignedTo?: string;
	orderItemId?: string | null;
}

export interface ITimestampedTranscription {
	ID: string;
	LexicalText: string;
	DisplayText: string;
	Timestamp: number;
	SpeechDuration: number;
	Words: Array<{
		Text: string;
		Confidence: number;
		Timestamp: number;
		SpeechDuration: number;
	}>;
}

export interface IOrderSearchResult {
	description: string;
	orderTypeID: number;
	orderTypeGroup: string;
	orderID: string | null;
	orderItemID: string | null;
}

export interface IOrderSet {
	orderSetName: string;
	orderSetId: number;
}

export interface IBillingModifier {
	modifierID: string;
	description: string;
	isValid: boolean;
}

export interface IBillingCode {
	serviceID?: number | null;
	serviceType: 'emcode' | 'EMCODE' | 'proceduretemplate' | 'PROCEDURETEMPLATE' | 'imaging' | 'IMAGING' | 'lab' | 'LAB';
	procedureCode: string;
	iCD10Codes: Array<string>;
	modifiers: Array<IBillingModifier>;
	billForService: boolean;
	units: number | null;
	modified: number;
	isValid?: boolean;
	encounterObjectName?: string;
	description?: string;
	isSelected?: boolean;
	encounterObjectID?: string;
	procedureDocumentationTemplateID?: string;
}

export interface IProcedureCodeSearchQuery {
	searchTerm: string;
	encounterId: string;
}

export interface IQANote {
	id?: number;
	audioTimeMarker: number;
	note: string;
	originalNote: string;
	createdDateTime: string;
	username: string;
	isDone: boolean;
	originalIsDone: boolean;
}

export interface IAbridgeTranscriptItem {
	word: string;
	st: number;
	end: number;
	isConcept?: number;
	conceptNormalized?: string;
}

export enum TranscriptionOption {
	azure = 'azure',
	mModal = 'mModal',
	nuance = 'nuance',
}

export interface IAbridgeTranscript {
	[x: string]: {
		sent: IAbridgeTranscriptItem[];
		speaker: number;
	};
}

export interface IAbridgeTranscriptSummary {
	[x: string]: Array<{
		summary: string;
		inp_segments: Array<Array<number>>;
		id: string;
	}>;
}

export interface IOpenAISummary {
	AssessmentAndPlan?: {
		Plan?: string;
	};
	Discussion?: string;
	Diagnoses?: string;
	EMCode?: string;
	Objective?: string;
	Radiology?: string;
	Imaging?: string;
	Subjective?: string;
	HPI?: string;
	PhysicalExam?: string;
	ReviewOfSystems?: string;
	Assessment?: string;
	Note?: string;
}

export interface IDocumentVersion {
	versionId: number;
	username: string;
	displayName: string;
	document: IEncounterDocumentData;
	billingClaimCreated: boolean;
	billing: IBillingCode[];
}

export interface IDocumentVersions {
	versions: IDocumentVersion[];
	transcription: {
		azureASR: { text: string | null; timestampedText: ITimestampedTranscription[] | null };
		mModalASR: string | null;
		nuanceASR: string | null;
		openAISummary: string | null;
		openAISummaryStructured: IOpenAISummary | null;
		prompt: string | null;
		instructions: string | null;
		patientDemographics: ICustomPatientDemographicsData | null;
	};
	qaNotes: IQANote[];
	providerNote: string;
}
