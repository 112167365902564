import { createReducer } from '@reduxjs/toolkit';
import { authActions } from './auth.actions';

export interface IAuthState {
	signIn: {
		loading: boolean;
		success: boolean;
		error: string;
		emailAddress: string;
		mfaRequired: boolean;
		invalidMfaPin: boolean;
	};
	resetPassword: {
		code: string | null;
		codeRequested: boolean;
		codeConfirmed: boolean | null;
		loading: boolean;
		success: boolean;
		error: string | null;
	};
}

const initialState: IAuthState = {
	signIn: {
		loading: false,
		success: false,
		error: '',
		emailAddress: '',
		mfaRequired: false,
		invalidMfaPin: false,
	},
	resetPassword: {
		code: null,
		codeRequested: false,
		codeConfirmed: null,
		loading: false,
		success: false,
		error: null,
	},
};

const authReducer = createReducer<IAuthState>(initialState, (builder) => {
	builder
		.addCase(authActions.signInResultAction, (state, action) => {
			state.signIn.success = action.payload.success;
			state.signIn.error = action.payload.error;
			state.signIn.mfaRequired = action.payload.mfaRequired || false;
			state.signIn.invalidMfaPin = action.payload.invalidMfaPin || false;
			state.signIn.emailAddress = action.payload.emailAddress || '';
		})
		.addCase(authActions.setMfaPinIsInvalid, (state, action) => {
			state.signIn.invalidMfaPin = action.payload || false;
		})

		.addCase(authActions.signInIsLoadingAction, (state, action) => {
			state.signIn.loading = action.payload.loading;
		})

		.addCase(authActions.initResetPasswordRequest, (state) => {
			state.resetPassword = initialState.resetPassword;
		})
		.addCase(authActions.setResetPasswordIsLoading, (state, action) => {
			state.resetPassword.loading = action.payload;
		})
		.addCase(authActions.setResetPasswordRequestResult, (state, action) => {
			if (action.payload.code !== undefined) {
				state.resetPassword.code = action.payload.code;
			}
			if (action.payload.codeRequested !== undefined) {
				state.resetPassword.codeRequested = action.payload.codeRequested;
			}
			if (action.payload.codeConfirmed !== undefined) {
				state.resetPassword.codeConfirmed = action.payload.codeConfirmed;
			}
			if (action.payload.error !== undefined) {
				state.resetPassword.error = action.payload.error;
			}
			if (action.payload.success !== undefined) {
				state.resetPassword.success = action.payload.success;
			}
		});
});

export default authReducer;
