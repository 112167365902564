import * as React from 'react';
import { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Box, SxProps, TextField, TextFieldProps, Theme } from '@mui/material';

interface ISearchBarProps {
	value?: string;
	placeholder?: string;
	disabled?: boolean;
	sx?: SxProps<Theme>;
	searchDelay?: number;
	textFieldProps?: TextFieldProps;
	onSearch?: (query: string) => void;
	onInputChange?: (query: string) => void;
	onTyping?: (isTyping: boolean) => void;
}
function SearchBar({
	value,
	placeholder,
	sx,
	searchDelay,
	textFieldProps,
	disabled,
	onSearch,
	onInputChange,
	onTyping,
}: ISearchBarProps) {
	const [searchQuery, setSearchQuery] = React.useState<string>(value !== undefined ? value : '');
	const [isTyping, setIsTyping] = useState<boolean>(false);

	useEffect(() => {
		setIsTyping(true);

		const delayDebounceFn = setTimeout(() => {
			setIsTyping(false);
			if (onSearch) {
				onSearch(searchQuery);
			}
		}, searchDelay || 1500);

		return () => clearTimeout(delayDebounceFn);
	}, [searchQuery]);

	useEffect(() => {
		if (value !== undefined) {
			setSearchQuery(value);
		}
	}, [value]);

	useEffect(() => {
		if (onTyping) {
			onTyping(isTyping);
		}
	}, [isTyping]);

	const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (onSearch) {
			onSearch(searchQuery);
		}
	};

	return (
		<Box sx={{ p: 1, ...sx }}>
			<form onSubmit={handleSearch}>
				<TextField
					placeholder={placeholder}
					autoFocus
					variant="standard"
					fullWidth
					disabled={disabled}
					value={searchQuery}
					onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
						setSearchQuery(e.target.value);
						if (onInputChange) {
							onInputChange(e.target.value);
						}
					}}
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...textFieldProps}
					InputProps={{
						...{
							startAdornment: <SearchIcon sx={{ mx: 1 }} />,
							'aria-label': 'search',
						},
						...textFieldProps?.InputProps,
						...{
							style: {
								fontSize: '0.875rem',
								height: 56,
								...textFieldProps?.InputProps?.style,
							},
						},
					}}
				/>
			</form>
		</Box>
	);
}

export default SearchBar;
