import { TextareaAutosize, TextareaAutosizeProps, useTheme } from '@mui/material';
import * as React from 'react';

export interface ICustomTextAreaProps extends TextareaAutosizeProps {
	inputRef?: React.Ref<HTMLTextAreaElement>;
}
function CustomTextArea({ style, inputRef, ...props }: ICustomTextAreaProps) {
	const theme = useTheme();

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	return (
		<TextareaAutosize
			ref={inputRef}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			style={{
				...theme.typography.body1,
				borderTop: 0,
				borderLeft: 0,
				borderRight: 0,
				borderBottom: '1px solid',
				outline: 'none',
				...style,
			}}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		/>
	);
}

export default CustomTextArea;
