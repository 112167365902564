export const authConstants = {
	SIGN_IN_REQUEST: 'AUTH/SIGN_IN_REQUEST',
	SIGN_IN_IS_LOADING: 'AUTH/SIGN_IN_IS_LOADING',
	SIGN_IN_RESULT: 'AUTH/SIGN_IN_RESULT',
	SIGN_OUT: 'AUTH/SIGN_OUT',

	SET_MFA_PIN_IS_INVALID: 'AUTH/SET_MFA_PIN_IS_INVALID',

	INIT_PASSWORD_REQUEST: 'AUTH/INIT_PASSWORD_REQUEST',
	RESET_PASSWORD_REQUEST: 'AUTH/RESET_PASSWORD_REQUEST',
	RESET_PASSWORD_CONFIRM_NEW_PASSWORD: 'AUTH/RESET_PASSWORD_CONFIRM_NEW_PASSWORD',
	RESET_PASSWORD_SEND_CONFIRMATION_CODE: 'AUTH/RESET_PASSWORD_SEND_CONFIRMATION_CODE',
	SET_RESET_PASSWORD_IS_LOADING: 'AUTH/SET_RESET_PASSWORD_IS_LOADING',
	SET_RESET_PASSWORD_REQUEST_RESULT: 'AUTH/SET_RESET_PASSWORD_REQUEST_RESULT',
};
