import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, LinearProgress, Paper } from '@mui/material';
import Dialog from '../dialog/Dialog';
import { useAppDispatch } from '../../core/core.types';
import SearchBar from '../search-bar/SearchBar';
import { actions } from '../../core/state/actions';
import { namespaces } from '../../application/i18n.constants';
import DataTable from '../data-table/DataTable';
import { IBillingCode, IProcedureCode } from '../../core/models/dictations/dictations.models';
import CommonBillingServiceItemList from './CommonBillingServiceItemList';

interface IProcedureCodeSearchResultsProps {
	loading: boolean;
	procedureCodeSearchResults: IProcedureCode[];
	handleRowClick: (rowId: number) => void;
}

function EmCodeSearchResults({
	loading,
	procedureCodeSearchResults,
	handleRowClick,
}: IProcedureCodeSearchResultsProps) {
	const { t } = useTranslation(namespaces.components.searchBillingServices);
	const columns = [
		{
			name: t('code'),
		},
		{
			name: t('description'),
		},
	];
	const rows = procedureCodeSearchResults.map((emCode) => ({
		data: [
			{
				value: emCode.procedureCode,
			},
			{
				value: emCode.description,
			},
		],
	}));

	return (
		<Box>{loading ? <LinearProgress /> : <DataTable columns={columns} rows={rows} onRowClick={handleRowClick} />}</Box>
	);
}

export interface IBillingServicesDialogProps {
	open: boolean;
	billingCodes: IBillingCode[];
	codeSearchIsLoading: boolean;
	procedureCodeSearchResults: IProcedureCode[];
	isLoading: boolean;
	onClose: () => void;
	onAddBillingCode: (procedureCode: string) => void;
}

function BillingServicesDialog({
	isLoading,
	billingCodes,
	codeSearchIsLoading,
	procedureCodeSearchResults,
	open,
	onClose,
	onAddBillingCode,
}: IBillingServicesDialogProps) {
	const dispatch = useAppDispatch();
	const { t } = useTranslation(namespaces.components.searchBillingServices);

	const [isInput, setIsInput] = useState<boolean>(false);
	const [searchActive, setSearchActive] = useState<boolean>(false);
	const [searchQuery, setSearchQuery] = useState<string>('');

	const loading = searchQuery.length > 0 && (isInput || codeSearchIsLoading);
	const filteredBillingCodes = procedureCodeSearchResults.filter(
		(item) => billingCodes.map((code) => code.modified !== 2 && code.procedureCode).indexOf(item.procedureCode) === -1
	);
	useEffect(() => {
		setIsInput(true);
		if (!searchQuery) setSearchActive(false);
		if (searchQuery.length >= 2) setSearchActive(true);
		const delayDebounceFn = setTimeout(() => {
			if (searchQuery) {
				dispatch(actions.worklist.searchEMCode(searchQuery));
			}
			setIsInput(false);
		}, 1500);

		return () => clearTimeout(delayDebounceFn);
	}, [searchQuery]);

	const handleCodeSelect = (rowId: number) => {
		const { procedureCode } = filteredBillingCodes[rowId];
		onAddBillingCode(procedureCode);
		setSearchActive(false);
		setSearchQuery('');
		onClose();
	};

	const handleAddBillingCode = (procedureCode: string) => {
		onAddBillingCode(procedureCode);
		onClose();
	};

	return (
		<Dialog fullWidth maxWidth="sm" onClose={onClose} open={open} loading={isLoading} title={t('title')}>
			<Paper
				sx={{
					overflow: 'auto',
				}}
			>
				<SearchBar value={searchQuery} onInputChange={(value) => setSearchQuery(value)} />
				{searchActive ? (
					<EmCodeSearchResults
						loading={loading}
						procedureCodeSearchResults={filteredBillingCodes}
						handleRowClick={handleCodeSelect}
					/>
				) : (
					<CommonBillingServiceItemList
						onAddBillingCode={handleAddBillingCode}
						activeCodes={billingCodes.map((code) => code.modified !== 2 && code.procedureCode)}
					/>
				)}
			</Paper>
		</Dialog>
	);
}

export default BillingServicesDialog;
