import * as React from 'react';
import { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { sortBy } from 'lodash';
import { namespaces } from '../../application/i18n.constants';
import ValidationForm from '../validators/ValidationForm';
import ValidationTextField from '../validators/ValidationTextField';
import { IProvider } from '../../core/models/providers/providers.models';
import AutocompleteSelectField from '../select/AutocompleteSelectField';
import { IWordReplacement } from '../../core/models/replacements/replacements.models';

export interface IAddReplacementsDialogProps {
	open: boolean;
	providerDisabled: boolean;
	cancel: {
		action: () => void;
		text: string;
	};
	add: {
		action: (params: {
			providerId: string | null;
			customerId: string | null;
			word: string;
			wordReplacement: string;
		}) => void;
		text: string;
	};
	update: {
		action: (params: {
			id: string;
			providerId: string | null;
			customerId: string | null;
			word: string;
			wordReplacement: string;
		}) => void;
		text: string;
	};
	replacement?: IWordReplacement;
	providers?: {
		allProviders: IProvider[];
		preSelectedProvider?: string;
	};
	customers?: {
		allCustomers: IProvider[];
		preSelectedCustomer?: string;
	};
}

function SaveWordReplacementDialog({
	providerDisabled,
	open,
	cancel,
	replacement,
	add,
	update,
	providers,
	customers,
}: IAddReplacementsDialogProps) {
	const { t } = useTranslation(namespaces.pages.wordReplacements);

	const [disableValidation, setDisableValidation] = useState<boolean>(true);
	const [selectedProvider, setSelectedProvider] = useState<string | null>(providers?.preSelectedProvider || null);
	const [selectedCustomer, setSelectedCustomer] = useState<string | null>(customers?.preSelectedCustomer || null);

	const mode: 'add' | 'update' = replacement ? 'update' : 'add';

	const [isFromValid, setIsFromValid] = useState<boolean>(false);
	const [wordReplacement, setWordReplacement] = useState<string>(
		mode === 'update' && replacement ? replacement?.replacement : ''
	);
	const [word, setWord] = useState<string>(mode === 'update' && replacement ? replacement?.text : '');

	const [showConfirm, setShowConfirm] = useState<boolean>(false);

	const resetFields = () => {
		setWord(mode === 'update' && replacement ? replacement?.text : '');
		setWordReplacement(mode === 'update' && replacement ? replacement?.replacement : '');
		setSelectedProvider(providers?.preSelectedProvider || null);
		setSelectedCustomer(customers?.preSelectedCustomer || null);
	};

	useEffect(() => {
		if (open) {
			resetFields();
		}
	}, [open]);

	const handleResetForm = () => {
		setShowConfirm(false);
	};

	const handleAddReplacement = () => {
		add.action({
			providerId: selectedProvider || '',
			customerId: selectedCustomer || '',
			word,
			wordReplacement,
		});
		resetFields();
		setTimeout(() => setShowConfirm(false), 200);
	};

	const handleAUpdateReplacement = () => {
		update.action({
			id: replacement?.id || '',
			providerId: selectedProvider || '',
			customerId: selectedCustomer || '',
			word,
			wordReplacement,
		});
		resetFields();
		setTimeout(() => setShowConfirm(false), 200);
	};

	const onSubmit = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		if (isFromValid) {
			setShowConfirm(true);
		} else {
			setDisableValidation(false);
		}
	};

	const handleSetProvider = (providerId: string) => {
		setSelectedProvider(providerId);
	};

	const handleSetCustomer = (providerId: string) => {
		setSelectedCustomer(providerId);
	};

	const filled = (!providers || selectedProvider) && (!customers || selectedCustomer) && word && wordReplacement;

	return (
		<Dialog fullWidth onClose={cancel.action} open={open}>
			<Box flexDirection="column" aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title" align="center">
					{mode === 'add' ? t('addNewReplacement') : t('updateReplacement')}
				</DialogTitle>
				<DialogContent>
					{showConfirm ? (
						<Box sx={{ padding: 1, display: 'flex', justifyContent: 'center' }}>
							<Typography>{`${
								mode === 'add'
									? t('addReplacementText', { word, replacement: wordReplacement })
									: t('updateReplacementText', { word, replacement: wordReplacement })
							}`}</Typography>
						</Box>
					) : (
						<Grid
							container
							sx={{
								width: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<ValidationForm
								sx={{ mt: 1 }}
								component="form"
								disableValidation={disableValidation}
								onValidationChange={(valid) => setIsFromValid(valid)}
							>
								{providers ? (
									<Box alignItems="center" display="flex" flexDirection="row">
										<AutocompleteSelectField
											textFieldProps={{
												required: true,
												placeholder: t('provider'),
											}}
											disabled={providerDisabled || mode === 'update'}
											sx={{ width: '100%' }}
											fullWidth
											onChange={(e, value) => handleSetProvider(value)}
											// groupBy={(option) => option.firstLetter}
											disableClearable
											value={providers.preSelectedProvider || ''}
											data={[
												...sortBy([...providers.allProviders], [(o) => o.name.toLowerCase()]).map((x) => ({
													value: x.id,
													text: x.name,
												})),
											]}
											size="medium"
										/>
									</Box>
								) : null}
								{customers ? (
									<Box alignItems="center" display="flex" flexDirection="row">
										<AutocompleteSelectField
											textFieldProps={{
												required: true,
												placeholder: t('customer'),
											}}
											disabled={mode === 'update'}
											sx={{ width: '100%' }}
											fullWidth
											onChange={(e, value) => handleSetCustomer(value)}
											// groupBy={(option) => option.firstLetter}
											disableClearable
											value={customers.preSelectedCustomer || ''}
											data={[
												...sortBy([...customers.allCustomers], [(o) => o.name.toLowerCase()]).map((x) => ({
													value: x.id,
													text: x.name,
												})),
											]}
											size="medium"
										/>
									</Box>
								) : null}
								<ValidationTextField
									id="wordInput"
									required={mode === 'add'}
									sx={{ width: '100%', mt: 2 }}
									multiline
									disabled={mode === 'update'}
									rows={4}
									type="text"
									value={word}
									label={t('word')}
									onChange={(e) => setWord(e.target.value)}
								/>
								<ValidationTextField
									id="wordReplacementInput"
									required
									sx={{ width: '100%', mt: 2 }}
									multiline
									rows={4}
									type="text"
									value={wordReplacement}
									label={t('wordReplacement')}
									onChange={(e) => setWordReplacement(e.target.value)}
								/>
							</ValidationForm>
						</Grid>
					)}
				</DialogContent>
				{showConfirm ? (
					<DialogActions>
						<Button onClick={handleResetForm}>{t('back')}</Button>
						<Button
							variant="contained"
							color="success"
							type="submit"
							onClick={mode === 'add' ? handleAddReplacement : handleAUpdateReplacement}
						>
							{t('continue')}
						</Button>
					</DialogActions>
				) : (
					<DialogActions>
						<Button onClick={cancel.action}>{cancel.text}</Button>
						<Button variant="contained" disabled={!filled} onClick={onSubmit}>
							{add.text}
						</Button>
					</DialogActions>
				)}
			</Box>
		</Dialog>
	);
}

export default SaveWordReplacementDialog;
