import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Alert, Box, Link, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import dayjs from 'dayjs';
import { namespaces } from '../../application/i18n.constants';
import { routes } from '../App/routes';
import { getResetPasswordAttemptsCache, setResetPasswordAttemptsCache } from '../../system/local-storage';
import PageContainer from '../../components/page-container/PageContainer';
import ConfirmCodeStep from '../../components/singin/ConfirmCodeStep';

function RequestStep({
	username,
	email,
	loading,
	setUsername,
	setEmail,
	onPasswordRestRequest,
}: {
	username: string;
	email: string;
	loading: boolean;
	setUsername: (username: string) => void;
	setEmail: (email: string) => void;
	onPasswordRestRequest: (username: string, email: string) => void;
}) {
	const { t } = useTranslation(namespaces.pages.signIn);

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', width: 320 }}>
			<TextField
				value={username}
				margin="normal"
				required
				fullWidth
				id="username"
				label={t('username')}
				name="username"
				autoComplete="username"
				autoFocus
				onChange={(e) => setUsername(e.target.value)}
			/>
			<TextField
				value={email}
				margin="normal"
				required
				fullWidth
				id="email"
				label={t('email')}
				name="email"
				autoComplete="email"
				autoFocus
				onChange={(e) => setEmail(e.target.value)}
			/>
			<LoadingButton
				loading={loading}
				type="submit"
				disabled={username.length < 1 || email.length < 1}
				fullWidth
				variant="contained"
				sx={{ mt: 3, mb: 2 }}
				onClick={() => onPasswordRestRequest(username, email)}
			>
				{t('resetPassword')}
			</LoadingButton>
		</Box>
	);
}

function ConfirmPasswordStep({
	newPassword,
	confirmPassword,
	loading,
	setNewPassword,
	setConfirmPassword,
	onConfirmNewPassword,
}: {
	newPassword: string;
	confirmPassword: string;
	loading: boolean;
	setNewPassword: (password: string) => void;
	setConfirmPassword: (password: string) => void;
	onConfirmNewPassword: (password: string, confirmPassword: string) => void;
}) {
	const { t } = useTranslation(namespaces.pages.signIn);

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', width: 320 }}>
			<TextField
				value={newPassword}
				margin="normal"
				required
				fullWidth
				type="password"
				id="newPassword"
				label={t('newPassword')}
				name="newPassword"
				autoComplete="new-password"
				autoFocus
				onChange={(e) => setNewPassword(e.target.value)}
			/>
			<TextField
				value={confirmPassword}
				margin="normal"
				required
				fullWidth
				type="password"
				id="confirmPassword"
				label={t('confirmPassword')}
				name="confirmPassword"
				autoComplete="new-password"
				autoFocus
				onChange={(e) => setConfirmPassword(e.target.value)}
			/>
			<LoadingButton
				loading={loading}
				type="submit"
				disabled={newPassword.length < 1 || confirmPassword.length < 1}
				fullWidth
				variant="contained"
				sx={{ mt: 3, mb: 2 }}
				onClick={() => onConfirmNewPassword(newPassword, confirmPassword)}
			>
				{t('changePassword')}
			</LoadingButton>
		</Box>
	);
}

function SuccessStep() {
	const { t } = useTranslation(namespaces.pages.signIn);
	return (
		<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
			<Typography component="h1" variant="h5">
				{t('passwordSuccessfullyChanged')}
			</Typography>
			<Link href={routes.login}>{t('signIn')}</Link>
		</Box>
	);
}

function AttemptsBreachedStep() {
	const { t } = useTranslation(namespaces.pages.signIn);
	return (
		<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
			<Typography component="h1" variant="h5">
				{t('attemptsBreached')}
			</Typography>
			<Link href={routes.login}>{t('signIn')}</Link>
		</Box>
	);
}

interface IResetPasswordProps {
	loading: boolean;
	success: boolean;
	codeRequested: boolean;
	codeConfirmed: boolean | null;
	error: string | null;
	onPasswordRestRequest: (username: string, email: string) => void;
	onPasswordReset: (username: string, email: string, newPassword: string, confirmPassword: string) => void;
	onConfirmCode: (username: string, email: string, confirmationCode: string) => void;
}

type ResetPasswordStep = 'requestRest' | 'confirmCode' | 'attemptsBreached' | 'confirmPassword' | 'success';

function ResetPasswordPage({
	loading,
	success,
	codeRequested,
	codeConfirmed,
	error,
	onPasswordRestRequest,
	onPasswordReset,
	onConfirmCode,
}: IResetPasswordProps) {
	const { t } = useTranslation(namespaces.pages.signIn);
	const [username, setUsername] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [confirmCode, setConfirmCode] = useState<string>('');
	const [newPassword, setNewPassword] = useState<string>('');
	const [confirmPassword, setConfirmPassword] = useState<string>('');
	const [attempts, setAttempts] = useState<{ attempts: number; lastAttempt: Date | null }>(
		getResetPasswordAttemptsCache() || { attempts: 0, lastAttempt: null }
	);

	const [hideErrors, setHideErrors] = useState<boolean>(false);

	useEffect(() => {
		setResetPasswordAttemptsCache(attempts);
	}, [attempts]);

	const onConfirmCodeCallback = () => {
		onConfirmCode(username, email, confirmCode);
		setAttempts((x) => {
			const now = new Date();
			if (!x.lastAttempt || dayjs(now).diff(x.lastAttempt, 'minutes') < 5) {
				return { attempts: x.attempts + 1, lastAttempt: x.lastAttempt || now };
			}
			return { attempts: 0, lastAttempt: now };
		});
	};

	const getStep = (): ResetPasswordStep => {
		if (success) {
			return 'success';
		}
		if (codeConfirmed) {
			return 'confirmPassword';
		}
		if (attempts.attempts >= 5 && attempts.lastAttempt && dayjs().diff(attempts.lastAttempt, 'minutes') < 5) {
			return 'attemptsBreached';
		}
		if (codeRequested) {
			return 'confirmCode';
		}
		return 'requestRest';
	};

	const onChange = () => {
		setHideErrors(true);
	};

	const onConfirm = () => {
		setHideErrors(false);
	};

	const getAlert = useCallback(() => {
		if (hideErrors || loading) {
			return null;
		}
		if (codeConfirmed === false) {
			return (
				<Alert sx={{ width: 320 }} severity="error">
					{t('codeDoesntMatch')}
				</Alert>
			);
		}
		if (error) {
			return (
				<Alert sx={{ width: 320 }} severity="error">
					{error}
				</Alert>
			);
		}

		return null;
	}, [t, hideErrors, codeConfirmed, error]);

	const getTitle = () => {
		const step = getStep();
		if (step === 'success') {
			return null;
		}
		if (step === 'attemptsBreached') {
			return null;
		}
		if (step === 'requestRest') {
			return (
				<Typography component="h1" variant="h5">
					{t('resetPassword')}
				</Typography>
			);
		}
		if (step === 'confirmCode') {
			return (
				<Typography component="h1" variant="h5">
					{t('confirmCode')}
				</Typography>
			);
		}
		if (step === 'confirmPassword') {
			return (
				<Typography component="h1" variant="h5">
					{t('changePassword')}
				</Typography>
			);
		}

		return null;
	};

	return (
		<PageContainer
			navbar={false}
			disablePadding
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			{getTitle()}
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					mt: 2,
					width: 360,
				}}
			>
				{getAlert()}
				{getStep() === 'requestRest' && (
					<RequestStep
						loading={loading}
						email={email}
						username={username}
						setUsername={(value) => {
							setUsername(value);
							onChange();
						}}
						setEmail={(value) => {
							setEmail(value);
							onChange();
						}}
						onPasswordRestRequest={(usr, eml) => {
							onPasswordRestRequest(usr, eml);
							onConfirm();
						}}
					/>
				)}
				{getStep() === 'confirmCode' && (
					<ConfirmCodeStep
						loading={loading}
						code={confirmCode}
						setCode={(value) => {
							setConfirmCode(value);
							onChange();
						}}
						onConfirmCode={() => {
							onConfirmCodeCallback();
							onConfirm();
						}}
					/>
				)}
				{getStep() === 'confirmPassword' && (
					<ConfirmPasswordStep
						loading={loading}
						newPassword={newPassword}
						confirmPassword={confirmPassword}
						setNewPassword={(value) => {
							setNewPassword(value);
							onChange();
						}}
						setConfirmPassword={(value) => {
							setConfirmPassword(value);
							onChange();
						}}
						onConfirmNewPassword={(p1, p2) => {
							onPasswordReset(username, email, p1, p2);
							onConfirm();
						}}
					/>
				)}
				{getStep() === 'success' && <SuccessStep />}
				{getStep() === 'attemptsBreached' && <AttemptsBreachedStep />}
			</Box>
		</PageContainer>
	);
}

export default ResetPasswordPage;
