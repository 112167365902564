import * as React from 'react';
import { ForwardedRef, forwardRef, useImperativeHandle, useRef } from 'react';
import { Box, Button } from '@mui/material';

export interface ITextMessage {
	scrollMessageIntoView: () => void;
}

export interface ITextMessageData {
	timestamp: number;
	text: string;
	speaker: number;
}
export interface ITextMessageProps {
	message: ITextMessageData;
	active: boolean;
	handleMessageClick: (timeStamp: number) => void;
}

const TextMessage: React.ForwardRefRenderFunction<ITextMessage, ITextMessageProps> = function TextMessageFunc(
	{ message, active, handleMessageClick }: ITextMessageProps,
	forwardedRef: ForwardedRef<ITextMessage>
) {
	const messageContainerRef = useRef<HTMLDivElement>();

	useImperativeHandle(forwardedRef, () => ({
		scrollMessageIntoView() {
			messageContainerRef.current?.scrollIntoView({ behavior: 'smooth' });
		},
	}));

	return (
		<Box
			ref={messageContainerRef}
			sx={{
				display: 'flex',
				justifyContent: message.speaker ? 'right' : 'left',
				wordBreak: 'break-word',
			}}
		>
			{message && (
				<Button
					variant="outlined"
					onClick={() => handleMessageClick(message.timestamp)}
					sx={{
						fontWeight: 'normal',
						textTransform: 'none',
						marginBottom: '0.5rem',
						borderRadius: '1rem',
						fontSize: '1rem',
						textAlign: 'inherit',
						backgroundColor: message.speaker ? '#e3f2fd' : '#f5f5f5',
						borderColor: active ? 'grey' : '#f5f5f5',
					}}
				>
					<span>{message.text}</span>
				</Button>
			)}
		</Box>
	);
};

export default forwardRef(TextMessage);
