import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Menu, MenuItem } from '@mui/material';
import { debounce } from 'lodash';
import { namespaces } from '../../../application/i18n.constants';

export interface ISaveOptions {
	sendToQA?: boolean;
	markAsComplete?: boolean;
	forceSave?: boolean;
	forceToQA?: boolean;
}

function DictationSaveMenu({
	renderButton,
	anchorEl,
	open,
	canCheckin,
	canMarkAsComplete,
	onMarkAsComplete,
	forceToQA,
	onClose,
	onSave,
	canSendToQa,
	canSave,
	canHold,
	canSend,
}: {
	renderButton: () => React.ReactNode;
	anchorEl: null | HTMLElement;
	open: boolean;
	canCheckin: boolean;
	canMarkAsComplete: boolean;
	onMarkAsComplete: () => void;
	forceToQA: boolean;
	onClose: () => void;
	canSend: boolean;
	canSendToQa: boolean;
	canSave: boolean;
	canHold: boolean;
	onSave: (options: ISaveOptions) => void;
}) {
	const { t } = useTranslation(namespaces.pages.dictation);

	const debounceSave = debounce(onSave, 500);
	const debounceMarkAsComplete = debounce(onMarkAsComplete, 500);

	return (
		<Box>
			{renderButton()}
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={onClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				{canCheckin && (
					<MenuItem disabled={!canSendToQa} onClick={() => debounceSave({ sendToQA: true, forceToQA })}>
						{t('sendToQa')}
					</MenuItem>
				)}
				{canCheckin && canHold && (
					<MenuItem onClick={() => debounceSave({ sendToQA: false, forceToQA })}>{t('saveAndHold')}</MenuItem>
				)}
				{canCheckin && canSave && (
					<MenuItem disabled={forceToQA} onClick={() => debounceSave({ forceSave: true })}>{`${t(
						'saveAndClose'
					)} (dev only)`}</MenuItem>
				)}
				{canCheckin && (
					<MenuItem disabled={!canSend || forceToQA} onClick={() => debounceSave({ markAsComplete: true })}>
						{t('saveAndSend')}
					</MenuItem>
				)}
				{canMarkAsComplete && <MenuItem onClick={() => debounceMarkAsComplete()}>{t('markAsComplete')}</MenuItem>}
			</Menu>
		</Box>
	);
}

export default DictationSaveMenu;
