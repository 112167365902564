import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { environment } from '../environment/environment';
import { AnyObject } from '../shared/types';
import { logRocketTrackEvent } from './log-rocket';

const { ENV, APP_INSIGHTS_CONNECTION_STRING } = environment;
export const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
	config: {
		connectionString: APP_INSIGHTS_CONNECTION_STRING,
		enableAutoRouteTracking: true,
		extensions: [reactPlugin],
	},
});

export const setAppInsightsUser = ({ username }: { username: string }) => {
	if (appInsights.context) {
		appInsights.setAuthenticatedUserContext(username, undefined, true);
		appInsights.context.user.id = username;
	}
};

export const clearAppInsightsUser = () => {
	if (appInsights.context) {
		appInsights.clearAuthenticatedUserContext();
	}
};

export const trackError = (key: string, message: string, payload?: AnyObject) => {
	if (appInsights.context) {
		appInsights.trackTrace({ message, severityLevel: 3 }, { ...payload, key });
	} else {
		// eslint-disable-next-line no-console
		console.log(`key: ${key} message:${message} payload:${JSON.stringify(payload)}`);
	}
};

const trackUnknownError = (key: string, error: unknown, payload?: AnyObject) => {
	if (appInsights.context) {
		if (error instanceof Error) {
			appInsights.trackTrace(
				{ message: error ? JSON.stringify(error, Object.getOwnPropertyNames(error)) : '', severityLevel: 3 },
				{ ...payload, key }
			);
		}
	} else {
		// eslint-disable-next-line no-console
		console.log(`key: ${key} error:${error} payload:${JSON.stringify(payload)}`);
	}
};

export const trackException = (key: string, error: Error | unknown, payload?: AnyObject) => {
	if (appInsights.context) {
		if (error instanceof Error) {
			appInsights.trackException(
				{ exception: error, severityLevel: 3 },
				{ ...payload, error: error ? JSON.stringify(error, Object.getOwnPropertyNames(error)) : '', key }
			);
		}
		trackUnknownError(key, error, payload);
	} else {
		// eslint-disable-next-line no-console
		console.log(`key: ${key} error:${error} payload:${JSON.stringify(payload)}`);
	}
};

// WARNING: Do not pass HPI data (patientId, DOB, Patient Full Name...)
export const trackEvent = (
	key: string,
	name: string,
	payload?: { [key: string]: string | number | boolean | string[] | number[] | boolean[] | undefined }
) => {
	logRocketTrackEvent(name, payload);

	if (appInsights.context) {
		appInsights.trackEvent({ name }, { ...payload, key });
		appInsights.trackTrace({ message: `Trigger event: ${name}`, severityLevel: 1 }, { ...payload, key });
	}
};

export const trackTrace = (key: string, message: string, payload?: AnyObject) => {
	if (appInsights.context) {
		appInsights.trackTrace({ message, severityLevel: 1 }, { ...payload, key });
	}
};

const enabled = (env: string): boolean => {
	const enabledEnvs = ['qa', 'prod'];
	return !!enabledEnvs.find((x) => x === env);
};

const loadAzureAppInsights = () => {
	if (enabled(ENV)) {
		appInsights.loadAppInsights();
	}
};

export default loadAzureAppInsights;
